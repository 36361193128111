//Global Class'lar

.sb-agreement-modal {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 25;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.36);
    padding: 20px;

    .capsul {
        position: relative;
        z-index: 2;
        background: #fff;
        border-radius: 8px;
        box-shadow:
            0 0 0.5px 0 rgba(0, 0, 0, 0.14),
            0 1px 1px 0 rgba(0, 0, 0, 0.24);
        align-self: center;
        //max-width: 420px;
        max-width: 100%;
        opacity: 1;
        visibility: visible;
        max-height: 97%;
        overflow: auto;
        //padding: 15px;
        padding: 3rem;

        .close-icon {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }

        .content {
            width: 500px;
            height: 400px;
            max-width: 100%;
            margin-top: 50px;
            overflow: auto;
        }
    }
}

.isAnonym {
    .anonim-cont {
        max-width: 300px;
        margin: 40px auto;
    }
}

.v2-sb-add-new-cart {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 25;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.36);
    padding: 20px;
    .capsul {
        position: relative;
        z-index: 2;
        background: #fff;
        border-radius: 8px;
        box-shadow:
            0 0 0.5px 0 rgba(0, 0, 0, 0.14),
            0 1px 1px 0 rgba(0, 0, 0, 0.24);
        align-self: center;
        width: 500px;
        max-width: 100%;
        opacity: 1;
        visibility: visible;
        max-height: 97%;
        overflow: auto;
        //padding: 15px;
        padding: 4rem;

        .head {
            white-space: nowrap;
            .left {
                width: 50%;
                display: inline-block;
                vertical-align: middle;
                white-space: normal;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    border-bottom: 2px solid #00a862;
                    padding-bottom: 5px;
                }
            }

            .right {
                width: 50%;
                display: inline-block;
                vertical-align: middle;
                white-space: normal;
                text-align: right;

                .close-icon {
                    cursor: pointer;
                }
            }
        }

        .detail-head {
            margin-top: 15px;
            p {
                color: gray;
            }
        }

        .form-capsul {
            margin-top: 35px;
            .form-item {
                margin-bottom: 20px;
                white-space: nowrap;
                .col-50 {
                    display: inline-block;
                    width: 50%;
                    vertical-align: top;
                }

                .form-title {
                    display: block;
                    font-size: 12px;
                    font-weight: 600;
                    color: #0f1f2c;
                    margin-bottom: 10px;
                }

                label {
                    display: block;
                    font-size: 12px;
                    font-weight: 600;
                    color: #0f1f2c;
                    margin-bottom: 10px;
                }

                .form-inp {
                    width: 100%;
                    height: 47px;
                    border-radius: 4px;
                    border: solid 1px #e1e8ee;
                    padding: 15px;
                }

                .form-select {
                    width: 100%;
                    height: 47px;
                    border-radius: 4px;
                    border: 1px solid #e1e8ee;
                    padding: 15px;
                }

                .month-cap {
                    padding-right: 10px;
                }

                .year-cap {
                    padding-left: 10px;
                }
            }

            .aggr-mp {
                white-space: normal;

                .option__labelText {
                    font-weight: 400 !important;
                    font-size: 12px;
                    padding-top: 0 !important;
                    position: relative;
                    top: -2px;
                }

                .txt {
                    font-size: 12px;
                    color: gray;
                }
            }

            .action {
                white-space: normal;
                margin-top: 40px;
                .left {
                    display: inline-block;
                    width: 40%;
                    vertical-align: middle;

                    .mp-icon {
                        width: 140px;
                    }
                }

                .right {
                    display: inline-block;
                    width: 60%;
                    vertical-align: middle;
                    text-align: right;
                }
            }
        }
    }
}

.v3-sb-generic-modal {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 25;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.36);
    padding: 20px;
    .capsul {
        position: relative;
        z-index: 2;
        background: #fff;
        border-radius: 8px;
        box-shadow:
            0 0 0.5px 0 rgba(0, 0, 0, 0.14),
            0 1px 1px 0 rgba(0, 0, 0, 0.24);
        align-self: center;
        width: 600px;
        max-width: 100%;
        opacity: 1;
        visibility: visible;
        max-height: 97%;
        overflow: auto;
        //padding: 15px;
        padding: 4rem;

        .head {
            white-space: nowrap;
            .left {
                width: 80%;
                display: inline-block;
                vertical-align: middle;
                white-space: normal;

                .title {
                    color: #3d3a35;
                    font-size: 16px;
                    font-weight: bold;
                    border-bottom: 2px solid #00a862;
                    padding-bottom: 5px;
                }
            }

            .right {
                width: 20%;
                display: inline-block;
                vertical-align: middle;
                white-space: normal;
                text-align: right;

                .close-icon {
                    cursor: pointer;
                }
            }
        }

        .content {
            margin-top: 30px;
            .text {
                font-size: 12px;
            }
        }

        .action {
            margin-top: 15px;

            .sb-button {
                margin-bottom: 10px;
            }
        }
    }
}

.sb-term-modal {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100 !important;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.36);

    .in-modal {
        position: relative;
        z-index: 3;
        background: #fff;
        border-radius: 8px;
        box-shadow:
            0 0 0.5px 0 rgba(0, 0, 0, 0.14),
            0 1px 1px 0 rgba(0, 0, 0, 0.24);
        align-self: center;
        width: 60vw;
        opacity: 1;
        visibility: visible;
        max-height: 97%;
        overflow: auto;
        padding: 3rem;

        object {
            width: 100%;
            height: 50vh;
        }
    }
}

@media (max-width: 768px) {
    .sb-agreement-modal {
        .capsul {
            padding: 15px !important;
        }
    }

    .v2-sb-add-new-cart {
        padding: 0 !important;
        .capsul {
            max-height: 100% !important;
            padding: 20px;
            border-radius: 0 !important;
            align-self: unset !important;
            width: 100% !important;
            .action {
                .right {
                    zoom: 0.75;
                }
            }
        }
    }

    .v3-sb-generic-modal {
        padding: 0 !important;
        .capsul {
            max-height: 100% !important;
            padding: 20px;
            border-radius: 0 !important;
            align-self: unset !important;
            width: 100% !important;

            .head {
                .left {
                    .title {
                        border: none;
                    }
                }
            }

            .action {
                .right {
                    zoom: 0.75;
                }
            }
        }
    }

    .sb-term-modal {
        .in-modal {
            width: 90%;
        }
    }
}
