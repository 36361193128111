.emailVerifiedCapsul{

}


@media (min-width: 1024px){
  .emailVerifiedCapsul{
      .sb-verfied-cap{
          height: calc(100vh - 400px) !important;
      }
  }
 }