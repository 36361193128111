.sb-delivery{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }
    
    .sb-contentCrate{
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }
        }

        .delivers-content{
            .top-content{
                background-color: #1d3e38;
                margin: 20px 20px;
                height: 600px;
                .top-logo{
                    text-align: center;
                    padding-top: 30px;

                    .sb-logo-reserve{
                        width: 200px;
                    }

                    .sb-logo-pin{
                        width: 90px;
                        display: block;
                        margin: 10px auto;
                    }

                    .sb-bot-txt{
                        color: white;
                        font-weight: bold;
                    }

                    .sb-top-bg{
                        width: 65%;
                        
                    }
                }
            }

            .mid-content{
                text-align: center;
                margin-top: 150px;

                .comp-logo{
                    background-color: #f5f0eb;
                    width: 100%;
                    padding: 20px;
                    text-align: center;

                    img{
                        vertical-align: middle;
                    }

                    .ys-logo{
                        background-color: #f80a4f;
                        width: 115px;
                        height: 45px;
                        line-height: 45px;
                        border-radius: 4px;
                        text-align: center;
                        display: inline-block;
                        margin-right: 15px;

                        img{
                            padding: 10px;
                        }
                    }

                    .gy-logo{
                        background-color: #5d3ebc;
                        width: 115px;
                        height: 45px;
                        line-height: 45px;
                        border-radius: 4px;
                        text-align: center;
                        display: inline-block;
                        
                        img{
                            padding: 10px;
                        }
                    }
                }

                .mid-title{
                    color:#1d6243;
                }

                p{
                    color:#585a5a;
                    padding: 0 20px;
                }
            }

            .foot-content{
                text-align: center;
                p{
                    color:#585a5a;
                    padding: 0 20px;
                }
            }
        }

    }
}


@media (min-width: 1280px){
   .sb-delivery{
       
        .sb-headerCrate{
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }
        
        .sb-contentCrate{
            
        }
    }
}

@media (min-width: 1024px){
    .sb-delivery{
        padding-top: 83px;

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }
        
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-contentColumn {
                //padding-top: 100px;
                padding-top: 60px;
                padding-bottom: 100px;
                max-width: 670px !important;

                .content___LdxU3{
                    img{
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33{
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .sb-delivery{
        
        .sb-headerCrate{

        }
        
        .sb-contentCrate{
           
        }
    
    }
}

@media (min-width: 480px){
    .sb-delivery{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 375px){
    .sb-delivery{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .sb-contentColumn--narrow {
                max-width: 95%;
                padding-left: 0;
                padding-right: 0;
            }



        }
    }
}

@media (max-width: 768px){
    .sb-delivery{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
       
        }
    
    }
}