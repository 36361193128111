.sb-account-capsul{
    position: relative;
    z-index: 1;

    .sb-headerCrate {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;

            .sb-heading--large {
                font-size: 2.7rem;
                line-height: 1.385;
            }
        }
    }

    .sb-contentCrate{

        .sb-content-capsul{

            .my-cards {
                width: 300px;
                margin: 150px auto;

                .title {
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                    color: #777777;
                    margin-bottom: 50px;
                }

                .credit-cards {
                    margin-top: 20px;
                    
                    .selected {
                        background-color: #d6e1da !important;
                        border: 1px solid #016341 !important;
                    }

                    .card-item {
                        border-bottom: 1px solid #ababab;
                        margin-bottom: 20px;
                        position: relative;
                        padding: 15px 15px 10px;
                        cursor: pointer;
                        border: 2px solid #e6e5e5;

                        .card-logo {
                            display: inline-block;
                            vertical-align: middle;

                            img {
                                width: 64px;
                                margin-right: 10px;
                                vertical-align: middle;
                            }
                        }

                        .card-inform {
                            display: inline-block;
                            vertical-align: middle;

                            .card-title {
                                display: block;
                                color: #555555;
                                font-size: 15px;
                            }

                            .card-number {
                                display: block;
                                color: #9e9e9e;
                                font-size: 15px;
                            }
                        }

                        .card-action {
                            display: inline-block;
                            vertical-align: middle;

                            .card-delete-btn {
                                display: none;
                                background-color: #e75b52;
                                color: white;
                                position: absolute;
                                right: -90px;
                                font-size: 21px;
                                padding: 19px;
                                bottom: 0px;
                                font-weight: 100;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.8;
                                    -moz-transition: 0.4s;
                                    -o-transition: 0.4s;
                                    -webkit-transition: 0.4s;
                                    transition: 0.4s;
                                }
                            }
                        }

                        &:hover {
                            -moz-transition: 0.3s;
                            -o-transition: 0.3s;
                            -webkit-transition: 0.3s;
                            transition: 0.3s;
                            background-color: #8080800f;


                            .card-action > .card-delete-btn {
                                //display: inline-block;
                            }
                        }

                        .deleteIcon{
                            position: absolute;
                            right: 5px;
                            top: 5px;
                            cursor: pointer;
                        }
                    }
                }

                .add-credit-card {
                    .add-credit-card-btn {
                        font-size: 18px;
                        cursor: pointer;

                        i {
                            border-radius: 5px;
                            border: 1px solid #c1c1c1;
                            padding: 15px 20px;
                            font-size: 14px;
                            color: #c1c1c1;
                            margin-right: 5px;
                        }
                    }

                    .masterpass-icon {
                        width: 124px;
                        display: block;
                        margin-left: 55px;
                        margin-top: 10px;
                    }
                }
            }

            .add-money {
                width: 350px;
                margin: 150px auto;
                position: relative;

                .title {
                    display: block;
                    font-size: 18px;
                    //font-weight: 500;
                    color: #777777;
                    margin-bottom: 50px;
                }

                .balance-sum{
                    display: table;
                    margin: 20px 0;
                    background-color: #016341;
                    color: white;
                    padding: 10px;
                    border-radius: 20px;
                }

                .blocking-input {
                    position: relative;
                    display: block;
                    margin-bottom: 20px;

                    .show-password {
                        width: 24px;
                        height: 24px;
                        fill: rgba(0,0,0,.56) !important;
                        position: absolute;
                        right: 0;
                        bottom: 14px;
                        cursor: pointer;
                    }

                    .hide-password {
                        width: 24px;
                        height: 24px;
                        fill: rgba(0,0,0,.56) !important;
                        position: absolute;
                        right: 0;
                        bottom: 14px;
                        cursor: pointer;
                    }

                    .form-inpt {
                        width: 100%;
                        margin-top: 20px;
                        height: 45px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d3436;
                        border-bottom: 1px solid rgba(0,0,0,.2);
                        background-color: transparent;
                        border-radius: 0;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        border-top-width: 0;
                        border-left-width: 0;
                        border-right-width: 0;
                        padding-right: 40px;
                    }

                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        left: 0;
                        top: 38px;
                        transition: 0.2s ease all;
                        color: rgba(0,0,0,.56) !important;
                        font-weight: 500;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0 0px;
                    }

                    textarea:focus, input:focus {
                        outline: none;
                    }
                }


                .credit-cards {
                    margin-top: 20px;
                    .selected {
                        background-color: #d6e1da !important;
                        border: 1px solid #016341 !important;
                    }

                    .card-item {
                        border-bottom: 1px solid #ababab;
                        margin-bottom: 20px;
                        position: relative;
                        padding: 15px 15px 10px;
                        cursor: pointer;
                        border: 2px solid #e6e5e5;
                        border: 2px solid #e6e5e5;
                        border-radius: 4px;

                        .card-logo {
                            display: inline-block;
                            vertical-align: middle;

                            img {
                                width: 64px;
                                margin-right: 10px;
                            }
                        }

                        .card-inform {
                            display: inline-block;
                            vertical-align: middle;

                            .card-title {
                                display: block;
                                color: #555555;
                                font-size: 18px;
                            }

                            .card-number {
                                display: block;
                                color: #9e9e9e;
                                font-size: 18px;
                            }
                        }

                        .card-action {
                            display: inline-block;
                            vertical-align: middle;

                            .card-delete-btn {
                                display: none;
                                background-color: #e75b52;
                                color: white;
                                position: absolute;
                                right: -90px;
                                font-size: 21px;
                                padding: 19px;
                                bottom: 0px;
                                font-weight: 100;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.8;
                                    -moz-transition: 0.4s;
                                    -o-transition: 0.4s;
                                    -webkit-transition: 0.4s;
                                    transition: 0.4s;
                                }
                            }
                        }

                        &:hover {
                            -moz-transition: 0.3s;
                            -o-transition: 0.3s;
                            -webkit-transition: 0.3s;
                            transition: 0.3s;
                            background-color: #8080800f;


                            .card-action > .card-delete-btn {
                                //display: inline-block;
                            }
                        }

                        .deleteIcon{
                            position: absolute;
                            right: 5px;
                            top: 5px;
                            cursor: pointer;
                        }
                    }
                }

                .add-credit-card {
                    .add-credit-card-btn {
                        font-size: 18px;
                        cursor: pointer;

                        i {
                            border-radius: 5px;
                            border: 1px solid #c1c1c1;
                            padding: 15px 20px;
                            font-size: 14px;
                            color: #c1c1c1;
                            margin-right: 5px;
                        }
                    }

                    .masterpass-icon {
                        width: 124px;
                        display: block;
                        margin-left: 55px;
                        margin-top: 10px;
                    }
                }

                .addMoneyConfirm {
                    position: absolute;
                    display: inline-block;
                    z-index: 1;
                    padding: 18px 2.4rem;
                    background: #00a862;
                    box-shadow: 0 0 6px rgba(0,0,0,.24), 0 8px 12px rgba(0,0,0,.14);
                    border-radius: 500px;
                    color: #fff;
                    font-size: 19px;
                    font-size: 1.9rem;
                    font-weight: 500;
                    line-height: 1.2;
                    overflow: hidden;
                    text-align: center;
                    text-decoration: none;
                    transition-duration: .2s;
                    transition-property: transform,box-shadow,width,height;
                    transition-timing-function: ease;
                    cursor: pointer;
                    transition: background .8s;
                    right: 0;


                    &:hover {
                        background: #039458 radial-gradient(circle, transparent 1%, #039458 1%) center/15000%;
                    }

                    &:active {
                        background-color: #07c274;
                        background-size: 100%;
                        transition: background 0s;
                    }
                }
            }
            
            .accountIndex{

                .account-list{
                    .account-item{
                        padding: 20px;
                        border-bottom: 1px solid rgba(0,0,0,.1);
                        cursor: pointer;
                        

                        &:hover{
                            //background-color: gainsboro;
                            background-color: #dcdcdc59;
                            -webkit-transition: all 0.5s ease-in-out;
                            -moz-transition: all 0.5s ease-in-out;
                            -ms-transition: all 0.5s ease-in-out;
                            -o-transition: all 0.5s ease-in-out;
                            transition: all 0.5s ease-in-out;
                        }

                        .title{
                            font-size: 22px;

                        }

                        .text{
                            color:rgba(0,0,0,.56) !important;
                            line-height: initial;
                        }

                        &:last-child{
                            //border:none;
                        }

                        .item-icon{
                            margin-left: 20px;
                            width:36px;
                            height: 36px;
                        }
                    }
                }
            }

            .accountHistory{
                padding: 20px;
                .history-list{
                    margin-top: 40px;
                    .history-item{
                        box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
                        background-color: white;
                        border-radius: 2px;
                        margin-bottom: 30px;
                        padding: 15px;
                        cursor: pointer;

                        .grid{
                            margin:0 !important; 
                            padding-top: 15px;
                        }

                        .icon{
                            display: inline-block;
                            width: 32px;
                        }
                        .inform{
                            display: inline-block;
                            line-height: 0.8;
                            padding-left: 20px;
                            
                            .title{
                                font-weight: bold;
                            }

                        }

                        .detail{
                            line-height: 1.5;
                            margin-top: -5px;
                            
                            h3{
                                margin: 0;
                            }

                            .s1{
                                display: block;
                            }
                            .s2{
                                display: block;
                            }
                            .s3{
                                display: block;
                            }
                        }
                    }
                }
            }

            .invitation{
                padding: 20px;
                height: calc(100vh - 120px);
                .invitation-code{
                    box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
                    background-color: #fff;
                    border-radius: 2px;
                    margin-bottom: 30px;
                    padding: 15px;
                    text-align: center;
                    .code{
                        display: block;
                        font-size: 28px;
                        font-weight: bold;
                        color: #00a862;
                    }

                    .text{

                    }
                }
                
                .invitation-list{
                    margin-top: 40px;
                    box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
                    background-color: #fff;
                    border-radius: 2px;
                    margin-bottom: 30px;

                    .head{
                        background-color: #c4c4c4;
                        padding: 10px;
                        color: #000000;
                        border-bottom: 3px solid #afafaf;
                        font-size: 15px;
                    }

                    .invitation-item{
                        border-bottom: 1px solid #e8e5e5;
                        padding: 30px 20px;
                        .icon{
                            display: inline-block;
                            width: 32px;
                            vertical-align: middle;
                        }

                        .inform{
                            display: inline-block;
                            padding-left: 20px;
                            width: calc(100% - 100px);
                            line-height: 18px;
                            vertical-align: middle;
                            
                            .title{
                                font-weight: bold;
                            }

                        }

                        .detail{
                            line-height: 1.5;
                            margin-top: -5px;
                            width: 80%;
                            display: inline-block;
                            vertical-align: middle;
                            
                            h3{
                                margin: 0;
                            }

                            .s1{
                                display: block;
                            }
                            .s2{
                                display: block;
                            }
                            .s3{
                                display: block;
                            }
                        }

                        .date-box{
                            vertical-align: middle;
                            display: inline-block;
                            text-align: right;
                            width: 20%;
                        }

                        &:last-child{
                            border:none;
                        }
                    }

                    .non-invitation{
                        text-align: center;
                        width: 460px;
                        max-width: 100%;
                        margin: 0 auto;
                        padding: 20px;
                    }
                }

                .land-capsul{
                    .content{
                        max-width: 300px;
                        margin: 60px auto;
                    }
                }

                .sticky-box{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding-right:40px;
                    .left{
                        width: 50%;
                        display: inline-block;
                        vertical-align: middle;

                        .more-inform-btn{
                            display: inline-block;
                            border: 1px solid black;
                            border-radius: 25px;
                            padding: 5px 10px;
                            cursor: pointer;

                            &:hover{
                                background-color: black;
                                color: white;
                            }
                        }
                    }

                    .right{
                        width: 50%;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: right;
                    }
                }
            }

            .noActiveCard{
                width: 320px;
                margin: 150px auto;
                padding: 20px;
                .noActiveCard-img{
                    display:block;
                }

                .text{
                    display:block;
                    .title{

                    }
                    .detail{
                        font-size: 12px;
                        color: gray;
                    }
                }

                .add-credit-card{
                    display:block;
                    margin-top: 20px;

                    .add-credit-card-btn{
                        font-size: 14px;
                        cursor: pointer;
                        color: gray;

                        i{
                            border-radius: 5px;
                            border: 1px solid #c1c1c1;
                            padding: 10px 20px;
                            font-size: 14px;
                            color: #c1c1c1;
                            margin-right: 5px;
                        }
                    }
                }

                .masterpass{
                    text-align: center;
                    margin-top: 10px;
                    img{
                        width: 185px;
                    }
                }
            }

            .receipt{
                .head{
                    width: 100%;
                    background-color: white;
                    padding: 25px;
                    border-bottom: 1px solid lightgrey;

                    .title{
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .id{
                        float: right;
                        padding-top: 5px;
                    }
                }

                .receipt-list{
                    padding: 30px;
                    .receipt-date{
                        
                    }

                    .receipt-item{
                        margin-top: 20px;
                        border-bottom: 3px dotted #d3d3d3;
                        padding-bottom: 10px;

                        .receipt-title{
                            font-size: 16px;
                            font-weight: bold;
                        }
                        
                    }

                }

                .receipt-total{
                    width: 300px;
                    float: right;
                    margin-top: 60px;

                    .s1{
                        .t1{
                            width: 70px;
                            display: inline-block;
                            font-weight: bold;
                        }

                        .t2{

                        }
                    }
                }
            }

            .rewards{
                padding: 20px;
                .myRewards{
                    width: 500px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 2% auto;

                    .reTitle{
                        font-size: 1.3rem;
                        line-height: 1.229;
                    }

                    .earnedRewards{
                        text-align: center;
                        background-color: white;
                        box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
                        border-radius: 2px;
                        padding-bottom: 20px;
                        
                        .svgBox{
                            position: relative;

                            .rewards-svg {
                                .bg-rewards{
                                    //fill: none;
                                    //stroke-width: 20px;
                                    //stroke-miterlimit: round;
                                }
    
                                .meter-rewards {
                                    //fill: none;
                                    //stroke-width: 20px;
                                    //stroke-miterlimit: round;
                                    //transition: stroke-dashoffset 850ms ease-in-out;
                                    //stroke-dasharray: 200; 
                                    //stroke-dashoffset: 200;
                                }
    
                                path{
                                    will-change: auto;
                                    stroke-width: 15px;
                                    stroke-miterlimit: round;
                                    transition: stroke-dashoffset 850ms ease-in-out;
                                }
                                
                            }

                            .digitalRewardsIcon{
                                position: absolute;
                                top: 0;
                                right: 0;
                                left: 0;
                                bottom: 0;
                                margin: auto;
                                color:#656565;
                            }

                            .countRewards{
                                position: absolute;
                                bottom: 50px;
                                left: 0;
                                right: 0;
                                font-size: 28px;
                                font-weight: bold;
                                color: #656565;
                            }

                            .lastUpdate{
                                display: block;
                                color: #adadad;
                            }

                        }

                        
                        .hasBeenRewards{
                            margin-top: 10px;
                            .earnedRewardsCount{

                                .count{
                                    font-size: 39px;
                                    font-weight: bold;
                                    vertical-align: middle;
                                    color: #3e3e3e;
                                }
                                
                                .bardakIcon{
                                    width: 40px;
                                    height: 40px;
                                    vertical-align: middle;
                                    color: #3e3e3e;
                                }

                                .title{
                                    display: block;
                                    color: #3e3e3e;
                                    font-size: 18px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }

                    .whenWillEnd{
                        margin-top:100px;
                        .titles{
                            font-size: 1.3rem;
                            line-height: 1.229;
                        }
                    }

                    .rules{
                        .titles{
                            font-size: 1.3rem;
                            line-height: 1.229;
                        }

                        .benefitContainer{
                            .benefitImage {
                                width: 140px;
                                min-width: 140px;
                            }

                            &:nth-of-type(2n){
                                -webkit-box-orient: horizontal;
                                -webkit-box-direction: reverse;
                                flex-direction: row-reverse;
                            }
                        }
                    }
                }

                .howItWork{
                    width: 500px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 6% auto;

                    .title {
                        font-size: 2.3rem;
                        line-height: 1.455;
                    }

                    .pb5{
                        .imgWrapper {
                            min-width: 100px;
                        }
                        .sb-heading{
                            margin-bottom: 0px !important;
                            margin-top: 0px !important;
                        }

                        .sb-heading--medium {
                            font-size: 2.3rem;
                            line-height: 1.455;
                        }
                    }
                }
            }

            .personalInfo{
                padding: 20px;
                .informForm{
                    width: 350px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 8% auto;

                    .pb1{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                    }

                    .saveBtn{
                        position: fixed;
                        bottom: 0;
                        right: 0;
                        z-index: 10;
                        padding: 1.6rem;
                    }

                    .birthday{
                        margin-top: 15px;

                        .title{
                            color: var(--colorTextBlackSoft)!important
                        }

                        .rdt{
                            border-bottom: 1px solid rgba(0,0,0,.2);
                            margin-bottom: 0;
                            margin-top: 0;
                            .form-control{
                                -webkit-appearance: none;  
                                -moz-appearance: none;  
                                appearance: none;  
                                background-color: transparent;  
                                border: 0;  
                                border-radius: 0;  
                                color: rgba(0, 0, 0, .9);  
                                display: block;  
                                font-size: 1.6rem;  
                                font-weight: 700;  
                                line-height: 10px;  
                                line-height: 1rem;  
                                //padding: 32px 0 6px;
                                padding: 10px 0 6px;
                                width: 100%;  
                                z-index: 1
                                
                                
                            }
    
                            .form-control::-ms-clear,
                            .form-control::-ms-reveal {
                              display: none
                            }
    
                            .form-control:focus {
                                outline: none!important
                              }
                        }
                    }

                    
                }

            }

            .settings{
                padding: 20px;
                .settingsForm{
                    width: 340px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 8% auto;

                    .titles{
                        font-size: 1.8rem;
                        line-height: 1.411;
                        margin-bottom: 50px;
                    }

                    .pb1{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                        margin-bottom: 20px;

                        .mb9{
                            margin-top: var(--space-5)!important;
                            margin-bottom: var(--space-2)!important;
                        }

                        .title{
                            color: #808080;
                            font-size: 12px;
                            
                        }

                        .sb-editField{
                            margin-top: 5px !important;

                            .floatLabel{
                                font-size: 16px;
                                font-weight: bold;
                                color: #404040 !important;
                            }
                        }
                    }

                    .err-msg{
                        color:#e75b52;
                        font-size: 13px;
                    }

                    .help-item{
                        padding: 1.6rem 0 !important;
                    }
                }
            }

            .editEmail{
                padding: 20px;

                .editEmailForm{
                    width: 355px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;

                    .pb2{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                    }

                    .informNote{
                        font-size: 13px;
                    }
                }

                .digitVerfiy{
                    width: 355px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;

                    .title{
                        font-size: 1.8rem;
                        line-height: 1.411;
                    }
                    
                    .pb2{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                    }

                    .informNote{
                        font-size: 13px;
                    }

                }
                
            }

            .editPhone{
                padding: 20px;

                .editPhoneForm{
                    width: 355px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;

                    .pb2{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                    }

                    .cancelBtn{
                        margin-right: 30px;

                        button{
                            background-color:#616161;
                        }
                    }
                }

                .phoneVerificationCode{
                    width: 355px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;

                    .title{
                        font-size:14px;
                        margin-bottom: 15px;
                    }

                    .deepNote{
                        font-size:13px;
                    }
                    
                    .pb2{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                    }

                    .cancelBtn{
                        margin-right: 30px;

                        button{
                            background-color:#616161;
                        }
                    }

                }
            }

            .editPassword{
                padding: 20px;
                
                .editPasswordForm{

                    width: 355px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;

                    .pb3{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;

                    }

                    .password-targets{

                        margin-top: 20px;

                        .target-item{
                            display:block;
                            position: relative;

                            .title{
                                padding-left:20px;
                            }

                            .fieldStatus__icon{
                                top: 0 !important;
                            }

                            .check{
                                color: #00653e;
                            }
    
                            .close{
                                color: #e75b52;
                            }
                        }

                    }
                }

                .sb-contentColumn{
                    padding: 2.4rem 1.6rem 6.4rem;

                    .pb6{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;

                        .rememberMe{
                            margin-top: 20px;
                        }

                    }

                    
                    .cancelBtn{
                        margin-right: 30px;

                        button{
                            background-color:#616161;
                        }
                    }
                }
            }

        }
    }

}


@media (min-width: 1280px){
 
     .sb-account-capsul{
         .sb-headerCrate{
     
             .sb-headerCrate--content{
                 padding-left: 11.2rem !important;
                 padding-right: 11.2rem !important;
             }
         }
 
         .sb-contentCrate {
             
         }
     }
 }
 
 @media (min-width: 1024px){
  
 
     .sb-account-capsul{
         .sb-headerCrate{
             position: fixed;
             top: 0;
             bottom: 0;
             left: 0;
             overflow: auto;
             width: 40vw;
 
             .sb-headerCrate--content{
                 padding: 0 3.2rem;
             }
         }
 
         .sb-contentCrate {
             margin-left: 40vw;
             padding-top: 83px;
 
 
             .accountIndex{
                 .account-list{
                     .account-item{
                         padding:3rem 10rem !important;
 
                         .text{
                             line-height: 0px !important; 
                         }
                     }
                 }
             }
 
             .autoReload{
      
                 .foot-modal{
 
                     width: 60% !important;
 
                     .in-modal{
 
                     }
 
                     .close-icon{
                         position: absolute !important;
                     }
                 }
             }
 
             .pinCode{
      
                 .foot-modal{
 
                     width: 60% !important;
 
                     .in-modal{
 
                     }
 
                     .close-icon{
                         position: absolute !important;
                     }
                 }
             }
 
             .addPyhsicalCard{
                 .base___3qZEk {
                     margin-top: 100px;
                 }
 
                 .base___3qZEk {
                     margin-top: 100px;
                 }
 
                 .lg-pl0, .lg-px0 {
                     padding-left: 0!important;
                 }
 
                 .base___3dWsJ{
                     padding: 3.2rem 4.8rem !important; 
                 }
             }

            .personalInfo{

                .informForm{

                    .saveBtn{
                        padding: 3.2rem 4.8rem !important;
                    }
                }

            }
         }
     }
 }
 
 @media (min-width: 768px){
    
    .rewards{
        .myRewards{
            .rules{

                .benefitContainer{
             
                }
    
                .md-py3 {
                    padding-top: 1.6rem!important;
                    padding-bottom: 1.6rem!important;
                }
            }
        }
    }


 }
 
 @media (min-width: 480px){
 
 }
 
 @media (min-width: 375px){
     
 }
 
 @media (max-width: 768px){
   
    .sb-account-capsul{
        .sb-contentCrate{
            .sb-content-capsul{
                .paymentMethods{
                    .add-money{
                        margin: 25px auto !important;
                    }
                }
                .noActiveCard{
                    margin: 25px auto !important;
                }

                .invitation{
                    height: auto !important;
                    .sticky-box{
                        position: unset !important;
                       .left{
                            width: 100% !important;
                            text-align: left;

                            .more-inform-btn{
                                margin-bottom: 20px;
                            }
                       }

                       .right{
                            width: 100% !important;
                            text-align: left;
                       }
                    }
                }
            }
        }
    }

    .invitation {
        padding: 20px 10px 120px!important;
        .history-item {
            padding: 10px 5px !important;
            .inform{
                padding-left: 0 !important;

                .title{
                    display: block;
                    line-height: normal;
                    margin-bottom: 10px;
                    font-size: 12px;
                }

                .text{
                    line-height: normal;
                    font-size: 12px;
                }

                p{
                    padding: 0;
                    margin: 0;
                    font-size: 12px;
                }
            }

            .detail{
                width: 85%;
                display: inline-block;
                vertical-align: middle;
            }

            .detail-full{
                width: 100%;
                display: block;
            }

            .empt{
                display: none;
            }

            .sendBtn{
                text-align: right !important;
                width: 15%;
                display: inline-block;
                vertical-align: middle;

                svg{    
                    width: 24px;
                }
            }
          
        }
    }

    .sticky-actions{
        bottom: 15px !important;
        right: 15px !important;
        .sb-frap{
            zoom: 0.8;
        }
    }
 }
 

 .sticky-actions{
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    z-index: 10;

    .sb-frap{
        display: block;
        margin-top: 10px;
    }
}
    