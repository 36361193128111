body {
    height: 100%;
    margin: 0;
    background-color: #f7f7f7;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -.014rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.globalCursor{
    cursor: pointer;
}

.sb-rule {
    border: 0;
    margin: 0;
}

.sb-rule--thin:before {
    border-top-width: 1px !important;
}

.sb-rule:before {
    content: "";
    display: block;
    border-top: 2px solid rgba(0,0,0,.1);
}

.hardDisplayNone{
    display: none !important;
}

.sb-loadingCoffeeCup {
    height: 85px;
    width: 73px;
    opacity: 0.7;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .sb-loadingCoffeeSteam {
    opacity: 0.4;
    stroke-dasharray: 25;
    -webkit-animation: dash 2.4s infinite linear;
            animation: dash 2.4s infinite linear;
  }
  
  @-webkit-keyframes dash {
    from {
        stroke-dashoffset: 25;
    }
    to {
      stroke-dashoffset: -25;
    }
  }
  
  @keyframes dash {
    from {
        stroke-dashoffset: 25;
    }
    to {
      stroke-dashoffset: -25;
    }
  }
  

.gray-deactive-bg-color{
  background-color:#8e8e8e !important;
  cursor:not-allowed !important
}

.whiteBtn{
  color:black !important;
  background-color: none !important;
  border-color: black;

  &:hover{
      background-color: rgba(156, 156, 156, 0.1) !important;
  }
}

.blackBtn{
  color:white !important;
  background-color: black !important;
  border-color: black;

  &:hover{
    background-color: rgba(0,0,0,.7) !important;
  }
}