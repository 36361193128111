.sb-heading {
  font-weight: 400;
}
.sb-heading-xl {
  font-size: 3.1rem;
  line-height: 1.29;
}
.sb-heading-lg {
  font-size: 2.6rem;
  line-height: 1.385;
}
.sb-heading-md {
  font-size: 2.2rem;
  line-height: 1.455;
}
.sb-heading-sm {
  font-size: 1.9rem;
  line-height: 1.263;
}
.sb-heading-xs{
  font-size: 1.7rem;
  line-height: 1.411;
}
.sb-heading-xxs {
  font-size: 1.2rem;
  line-height: 1.229;
}