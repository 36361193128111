.homeCapsulv2 {
    position: relative;
    z-index: 1;

    .sb-headerCrate {
        position: relative;
        z-index: 1;
        box-shadow:
            0 0 2px 0 rgba(0, 0, 0, 0.24),
            0 4px 4px 0 rgba(0, 0, 0, 0.12);

        .sb-headerCrate--content {
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;

            .sb-heading--large {
                font-size: 2.7rem;
                line-height: 1.385;
            }
        }
    }

    .sb-contentCrate {
        overflow: hidden;

        .sb-content-capsul {
            .main-v2 {
                .top-banner {
                    .top-banner-cover {
                        img {
                        }
                    }

                    .top-banner-detail {
                        padding: 20px 40px;

                        .title {
                            font-weight: 400;
                            font-size: 2.7rem;
                            line-height: 3.7rem;
                            letter-spacing: -0.015rem;
                        }

                        .text {
                        }

                        .banner-action {
                        }
                    }
                }

                .campaign-list {
                    padding: 20px 40px;

                    .campaign-item {
                        margin-bottom: 50px;

                        .left {
                            width: 50%;
                            display: inline-block;
                            vertical-align: top;
                        }

                        .right {
                            width: 50%;
                            display: inline-block;
                            vertical-align: top;
                            padding-left: 20px;

                            .title {
                                font-weight: 700;
                                font-size: 2rem;
                                letter-spacing: -0.015rem;
                                line-height: 2.7rem;
                            }

                            .text {
                            }
                        }
                    }

                    p {
                        color: #6c6c6c;
                    }
                }

                .bottom-banner {
                    .bottom-detail {
                        padding: 20px 40px;

                        .text {
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.sticky-pay-btn {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    z-index: 10;
}

@media (min-width: 1280px) {
    .homeCapsulv2 {
        .sb-headerCrate {
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }

        .sb-contentCrate {
        }
    }
}

@media (min-width: 1024px) {
    .homeCapsulv2 {
        .sb-headerCrate {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .sb-headerCrate--content {
                padding: 0 3.2rem;
            }
        }

        .sb-contentCrate {
            margin-left: 40vw;
            padding-top: 83px;

            .sb-home-rewards {
                .title {
                    .sb-heading {
                        font-size: 1.3rem;
                        line-height: 1.229;
                    }
                }
            }

            .streamCardsContainer {
                max-width: 500px;
            }

            .lg-mb6 {
                margin-bottom: 4rem !important;
            }

            .lg-px0 {
                padding-right: 0 !important;
            }

            .lg-pl0,
            .lg-px0 {
                padding-left: 0 !important;
            }

            .pb4 {
                .carousel {
                    .carousel__slideContainer {
                        .carousel__slider {
                            .carousel__sliderItem {
                                text-align: center;
                            }

                            .lg-size4of12 {
                                width: 33.33333% !important;
                            }
                        }
                    }
                }
            }

            .container {
                padding: 0 4.8rem;

                .sb-button--positive.sb-button--black,
                .sb-button--positive.sb-button--rewardsDefault {
                    background-color: #000;
                    border-color: #000;

                    &:hover {
                        color: white;
                        text-decoration: none;
                        opacity: 0.7;
                    }
                }

                .sb-button--default.sb-button--black,
                .sb-button--default.sb-button--rewardsDefault {
                    color: rgba(0, 0, 0, 0.87);
                    border-color: rgba(0, 0, 0, 0.87);

                    &:hover {
                        text-decoration: none;
                        background-color: rgba(0, 0, 0, 0.06);
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .homeCapsulv2 {
        .sb-headerCrate {
            .sb-headerCrate--content {
            }
        }

        .sb-contentCrate {
            .sb-content-capsul {
                .pb4 {
                    .carousel {
                        .carousel__slideContainer {
                            .carousel__slider {
                                .carousel__sliderItem {
                                    text-align: center;
                                }

                                .lg-size4of12 {
                                    width: 41.66667%;
                                }
                            }
                        }
                    }
                }

                .lg-px6 {
                    padding-right: 4rem !important;
                }

                .lg-pl6,
                .lg-px6 {
                    padding-left: 4rem !important;
                }

                .md-pt7 {
                    padding-top: 4.8rem !important;
                }

                .md-px4 {
                    padding-right: 2.4rem !important;
                }

                .md-pl4,
                .md-px4 {
                    padding-left: 2.4rem !important;
                }

                .md-pb4 {
                    padding-bottom: 2.4rem !important;
                }

                .md-pt2 {
                    padding-top: 0.8rem !important;
                }

                .md-pt7 {
                    padding-top: 4.8rem !important;
                }

                .md-px4 {
                    padding-right: 2.4rem !important;
                }

                .md-pl4,
                .md-px4 {
                    padding-left: 2.4rem !important;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .profileStatusContainer {
        padding-bottom: 1.6rem;

        .left-cap {
            .rewards-svg {
                height: 120px !important;
                width: 150px !important;
            }

            .digitalRewardsIcon {
                width: 40px;
                height: 40px;
            }
        }

        .right-cap {
            .top {
                .left-x {
                    margin-right: 20px !important;
                }

                .right-x {
                }
            }

            .bottom {
                .t1 {
                    .title {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 480px) {
    .sb-contentCrate {
        .sb-content-capsul {
            .pb4 {
                .carousel {
                    .carousel__slideContainer {
                        .carousel__slider {
                            .carousel__sliderItem {
                                text-align: center;
                            }

                            .lg-size4of12 {
                                width: 50%;
                            }
                        }
                    }
                }
            }

            .md-pb4 {
                padding-bottom: 2.4rem !important;
            }

            .md-pt2 {
                padding-top: 0.8rem !important;
            }

            .md-pt7 {
                padding-top: 4.8rem !important;
            }

            .md-px4 {
                padding-right: 2.4rem !important;
            }

            .md-pl4,
            .md-px4 {
                padding-left: 2.4rem !important;
            }
        }
    }
}

@media (min-width: 375px) {
    .homeCapsulv2 {
        .sb-headerCrate {
            .sb-headerCrate--content {
            }
        }

        .sb-contentCrate {
        }
    }
}

@media (max-width: 768px) {
    .homeCapsulv2 {
        .sb-headerCrate {
            .sb-headerCrate--content {
            }
        }

        .sb-contentCrate {
        }
    }

    .sticky-pay-btn {
        bottom: 15px !important;
        right: 15px !important;
    }

    .sb-contentCrate {
        .sb-content-capsul {
            .main-v2 {
                .top-banner {
                    .top-banner-detail {
                        padding: 10px !important;
                    }
                }

                .campaign-list {
                    padding: 10px !important;

                    .campaign-item {
                        .left {
                            width: 100% !important;
                        }

                        .right {
                            width: 100% !important;
                            padding-left: 0 !important;
                        }
                    }
                }

                .bottom-banner {
                    .bottom-detail {
                        padding: 10px !important;
                    }
                }
            }

            .pb4 {
                .carousel {
                    .carousel__slideContainer {
                        .carousel__slider {
                            .carousel__sliderItem {
                                width: 33% !important;
                                text-align: center;

                                span {
                                    font-size: 10px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
