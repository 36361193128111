.sb-survey-form{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;

            .survey-img{

            }
        }
    }
    
    .sb-contentCrate{
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }

            .sb-survey-form-head{
                padding: 0 20px 20px;
                
                svg{
                    width: 60px;
                    display: inline-block !important;
                    vertical-align: middle;
                }

                .sb-survey-form-title{
                    vertical-align: middle;
                    display: inline-block;
                    font-size: 24px;
                    margin-left: 20px;
                    font-weight: 500;
                }
            }

            .sb-survey-form-content{
                background-color: white;
                border: 4px solid #016241;
                width: 95% !important;
                margin:  30px auto;
                padding: 0 20px 20px 20px;

                .sb-survey-form-content-title{
                    color: #016241;
                }

                .survey-item-single{
                    border: 2px solid black;
                    padding: 15px;
                    margin-bottom: 20px;

                    .survey-detail{
                        .question-title{
                            display: block;
                            font-weight: bold;
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                    }

                    .survey-action{
                        text-align: right;
                    }

                    .get-user-action{
                        .action-item{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 30px;
                        }
                    }
                }

                .survey-item-multiple{
                    border: 2px solid #000;
                    padding: 15px;
                    margin-bottom: 20px;

                    .userLocaleTxt{
                        margin-bottom: 40px;
                        margin-top: 10px;
                    }

                    .matrix-table{
                        .survey-table-header{
                            vertical-align: bottom;
                            text-align: center;

                            td{
                                font-weight: bold;
                            }
                        }

                        .AltAnswerText{
                            &:nth-child(2n+1){
                                background-color: #f1f1f1;
                            }
                        }
                    }
                }

                textarea{
                    width: 100%;
                    margin: 20px 0;
                    resize: none;
                }
            }
        }
    }
}


@media (min-width: 1280px){
   .sb-survey-form{
       
        .sb-headerCrate{
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }
        
        .sb-contentCrate{
            
        }
    }
}

@media (min-width: 1024px){
    .sb-survey-form{
        padding-top: 83px;

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }
        
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-contentColumn {
                padding-top: 4px;
                padding-bottom: 40px;
                //max-width: 670px !important;

                .content___LdxU3{
                    img{
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33{
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .sb-survey-form{
        
        .sb-headerCrate{
        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 480px){
    .sb-survey-form{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 375px){
    .sb-survey-form{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .sb-contentColumn--narrow {
               
                padding-left: 0;
                padding-right: 0;
            }
        }
    
    }
}

@media (max-width: 768px){
    .sb-survey-form{

        .sb-headerCrate{
            text-align: center;
            .survey-img{
                display: none;
            }
        }
        
        .sb-contentCrate{
            
        }
    
    }
}