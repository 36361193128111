.homeCapsul{
    position: relative;
    z-index: 1;

    .sb-headerCrate {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;

            .sb-heading--large {
                font-size: 2.7rem;
                line-height: 1.385;
            }
        }
    }

    .sb-contentCrate{

        overflow: hidden;

        .sb-content-capsul{
            .sb-home-rewards{
                .title{

                }

                .pb4{  
                    .carousel{
                        z-index: 0;
                        touch-action: pan-y pinch-zoom;

                        .carousel__slideContainer{
                            -webkit-transform: translateZ(0)!important;
                            transform: translateZ(0)!important;
                            overflow: hidden;

                            .carousel__slider{
                                position: relative;
                                z-index: 0;
                                white-space: nowrap;

                                .carousel__sliderItem {
                                    white-space: normal;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    
                }

                .container{
                    &:before{
                        display: unset;
                        content: unset;
                    }

                    .whiteBtn{
                        color:black !important;
                        background-color: none !important;
                        border-color: black;

                        &:hover{
                            background-color: rgba(156, 156, 156, 0.1) !important;
                        }
                    }
                }
            }

            .streamCardsContainer{
                padding-top: 1.6rem;
                padding-left: .8rem;
                padding-right: .8rem;

                .sb-card{

                    .sb-button {

                        &:hover{
                            text-decoration: none;
                            color: white;
                            background-color: rgba(0,130,72,.9);
                            border-color: rgba(0,130,72,.9);
                        }
                    }

                }
            }

            .profileStatusContainer{
                padding-top: 1.6rem;
                padding-left: .8rem;
                padding-right: .8rem;
                background-color: #fff;
                border-radius: 2px;
                box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
                max-width: 455px;
                margin-top: 40px;

                .cont-title{
                    font-size: 14px;
                    padding-left: 30px;
                    margin-bottom: 20px;
                }

                .left-cap{
                    position: relative;
                    width: 40%;
                    display: inline-block;
                    vertical-align: top;
                    .rewards-svg {
                        .bg-rewards{
                            //fill: none;
                            //stroke-width: 20px;
                            //stroke-miterlimit: round;
                        }

                        .meter-rewards {
                            //fill: none;
                            //stroke-width: 20px;
                            //stroke-miterlimit: round;
                            //transition: stroke-dashoffset 850ms ease-in-out;
                            //stroke-dasharray: 200; 
                            //stroke-dashoffset: 200;
                        }

                        path{
                            will-change: auto;
                            stroke-width: 15px;
                            stroke-miterlimit: round;
                            transition: stroke-dashoffset 850ms ease-in-out;
                        }
                        
                    }

                    .digitalRewardsIcon{
                        position: absolute;
                        //top: 0;
                        //right: 0;
                        top: -25px;
                        right: -5px;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        color:#656565;
                    }

                    .countRewards{
                        position: absolute;
                        //bottom: 0px;
                        //left: 0;
                        bottom: 25px;
                        left: 8px;
                        right: 0;
                        font-size: 18px;
                        font-weight: 700;
                        color: #656565;
                        text-align: center;
                    }

                    .lastUpdate{
                        display: block;
                        color: #adadad;
                    }

                }

                .right-cap{
                    width: 60%;
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 20px;
                    .top{
                        display: block;
                        margin-bottom: 15px;
                        .left-x{
                            display: inline-block;
                            margin-right: 50px;
                            .z1{
                                vertical-align: middle;
                                display: inline-block;
                                margin-right: 5px;
                                .count{
                                    font-weight: bold;
                                    font-size: 20px;
                                }
                            }

                            .z2{
                                vertical-align: middle;
                                display: inline-block;
                            }

                            .title{
                                display: block;
                                font-size: 12px;
                                font-weight: bold;
                            }
                        }

                        .right-x{
                            display: inline-block;
                            .z1{
                                vertical-align: middle;
                                display: inline-block;
                                margin-right: 5px;
                                .count{
                                    font-weight: bold;
                                    font-size: 20px;
                                }
                            }

                            .z2{
                                vertical-align: middle;
                                display: inline-block;
                            }

                            .title{
                                display: block;
                                font-size: 12px;
                                font-weight: bold;
                            }
                        }
                    }

                    .bottom{
                        display: block;
                        .t1{
                            display: block;
                            .icon{
                                vertical-align: middle;
                                display: inline-block;
                                padding-right: 10px;
                                width: 30px;
                            }

                            .title{
                                color: rgba(0,0,0,.87);
                                font-size: 14px;
                                font-weight: 600;
                                vertical-align: middle;
                            }
                        }

                        .t2{
                            margin-top: 10px;
                            display: block;
                            text-align: right;
                            padding-right: 30px;
                            .title{
                                display: inline-block;  
                                width: 150px;
                                background: none;
                                border-style: solid;
                                border-width: 1px;
                                border-radius: 50px;
                                padding: 7px 16px;
                                font-size: 1.4rem;
                                font-weight: 700;
                                line-height: 1.2;
                                text-align: center;
                                text-decoration: none;
                                cursor: pointer;

                                &:hover{
                                    background-color: rgba(0,0,0,.87);
                                    color: white;
                                }
                            }
                        }
                    }
                }


            }


            .sb-small-footer{
                .container-footer{
                    display: -webkit-box;
                    display: flex;

                    .find-store{
                        a{
                            text-decoration: none;
                            &:hover{
                                color: #008248;
                            }
                        }
                    }

                    .footer-links{
                        a{
                            color: currentColor;
                        }
                    }

                }

                .container-footer>* {
                    padding: 0 6.4rem 0 0;
                }
            }
            
        }
    }

}

.sticky-pay-btn{
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    z-index: 10;
}

@media (min-width: 1280px){
    .homeCapsul{
        .sb-headerCrate{
    
            .sb-headerCrate--content{
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }

        .sb-contentCrate {
            
        }
    }
}

@media (min-width: 1024px){
    .homeCapsul{
        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .sb-headerCrate--content{
                padding: 0 3.2rem;
            }
        }

        .sb-contentCrate {
            margin-left: 40vw;
            padding-top: 83px;

            .sb-home-rewards{
                .title{
                    .sb-heading{
                        font-size: 1.3rem;
                        line-height: 1.229;
                    }
                }
            }

            .streamCardsContainer{
                max-width: 500px;
            }

            .lg-mb6 {
                margin-bottom: 4rem!important;
            }

            .lg-px0 {
                padding-right: 0!important;
            }

            .lg-pl0, .lg-px0 {
                padding-left: 0!important;
            }

            .pb4{

                .carousel{

                    .carousel__slideContainer{
              

                        .carousel__slider{

                            .carousel__sliderItem {
                                text-align: center;
                            }

                            .lg-size4of12 {
                                width: 33.33333% !important;
                            }
                        }
                    }
                }
                
            }


            .container {
                padding: 0 4.8rem;

                .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                    background-color: #000;
                    border-color: #000;

                    &:hover{
                        color: white;
                        text-decoration: none;
                        opacity: 0.7;
                    }
                }

                .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                    color: rgba(0,0,0,.87);
                    border-color:  rgba(0,0,0,.87);

                    &:hover{
                        text-decoration: none;
                        background-color: rgba(0,0,0,.06);
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .homeCapsul{
        .sb-headerCrate{

            .sb-headerCrate--content{
               
            }
        }

        .sb-contentCrate {

            .sb-content-capsul{

                .pb4{
                
                    .carousel{
    
                        .carousel__slideContainer{
                  
    
                            .carousel__slider{
    
                                .carousel__sliderItem {
                                    text-align: center;
                                }
    
                                .lg-size4of12 {
                                    width: 41.66667%;
                                }
                            }
                        }
                    }
                    
                }

                .lg-px6 {
                    padding-right: 4rem!important;
                }

                .lg-pl6, .lg-px6 {
                    padding-left: 4rem!important;
                }

                .md-pt7 {
                    padding-top: 4.8rem!important;
                }

                .md-px4 {
                    padding-right: 2.4rem!important;
                }

                .md-pl4, .md-px4 {
                    padding-left: 2.4rem!important;
                }

                .md-pb4 {
                    padding-bottom: 2.4rem!important;
                }
                .md-pt2 {
                    padding-top: .8rem!important;
                }

                .md-pt7 {
                    padding-top: 4.8rem!important;
                }

                .md-px4 {
                    padding-right: 2.4rem!important;
                }

                .md-pl4, .md-px4 {
                    padding-left: 2.4rem!important;
                }
            }
        }
    }
}

@media (max-width: 480px){
    .profileStatusContainer{
        padding-bottom: 1.6rem;
        .left-cap{
            .rewards-svg{
                height: 120px !important;
                width: 150px !important;
            }

            .digitalRewardsIcon{
                width: 40px;
                height: 40px;
            }
        }

        .right-cap{
            .top{
                .left-x{
                    margin-right: 20px !important;
                }

                .right-x{

                }
            }

            .bottom{
                .t1{
                    .title{
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 480px){
    .sb-contentCrate {

        .sb-content-capsul{

            .pb4{
            
                .carousel{

                    .carousel__slideContainer{
              

                        .carousel__slider{

                            .carousel__sliderItem {
                                text-align: center;
                            }

                            .lg-size4of12 {
                                width: 50%;
                            }
                        }
                    }
                }
                
            }

            .md-pb4 {
                padding-bottom: 2.4rem!important;
            }
            .md-pt2 {
                padding-top: .8rem!important;
            }

            .md-pt7 {
                padding-top: 4.8rem!important;
            }

            .md-px4 {
                padding-right: 2.4rem!important;
            }

            .md-pl4, .md-px4 {
                padding-left: 2.4rem!important;
            }
        }
    }

}

@media (min-width: 375px){
    .homeCapsul{
        .sb-headerCrate{

            .sb-headerCrate--content{
               
            }
        }

        .sb-contentCrate {
           
        }
    }
}

@media (max-width: 768px){
    .homeCapsul{
        .sb-headerCrate{

            .sb-headerCrate--content{
            
            }
        }

        .sb-contentCrate {
          
        }
    }

    .sticky-pay-btn{
        bottom: 15px !important;
        right: 15px !important;
    }



    .sb-contentCrate {

        .sb-content-capsul{

            .pb4{
            
                .carousel{

                    .carousel__slideContainer{
              

                        .carousel__slider{

                            .carousel__sliderItem {
                                width: 33% !important;
                                text-align: center;
                                span{
                                    font-size: 10px !important;
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
}

