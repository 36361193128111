:root {
  --space-1: .4rem;
  --space-2: .8rem;
  --space-3: 1.6rem;
  --space-4: 2.4rem;
  --space-5: 3.2rem;
  --space-6: 4rem;
  --space-7: 4.8rem;
  --space-8: 5.6rem;
  --space-9: 6.4rem;
}

:root {
  --breakpointSm: 480px;
  --breakpointMd: 768px;
  --breakpointLg: 1024px;
  --breakpointXl: 1280px;
  --breakpointXxl: 1600px;
}

:root {
  --colorWhite: #ffffff;
  --colorGrayNatural: #f7f7f7;
  --colorCream: #f3f1e7;
  --colorCeramic: #edebe9;
  --colorBlack: #000000;
  --colorBlackWarm: #2d2926;
  --colorBlackWarmer: #3d3935;
  --colorGreenStarbucks: #00653e;
  --colorGreenApron: #00a862;
  --colorGreenDarkApron: #008248;
  --colorGold: #c2a661;
  --colorRed: #e75b52;
  --colorYellow: #fbbc05;
  --colorTextBlack: rgba(0, 0, 0, .87);
  --colorTextBlackSoft: rgba(0, 0, 0, .56);
  --colorTextWhite: rgba(255, 255, 255, 1);
  --colorTextWhiteSoft: rgba(255, 255, 255, .70);
  --colorBlack10: rgba(0, 0, 0, .1)!important;
  --colorBlack20: rgba(0, 0, 0, .2)!important;
  --colorBlack30: rgba(0, 0, 0, .3)!important;
  --colorBlack40: rgba(0, 0, 0, .4)!important;
  --colorBlack50: rgba(0, 0, 0, .5)!important;
  --colorBlack60: rgba(0, 0, 0, .6)!important;
  --colorBlack70: rgba(0, 0, 0, .7)!important;
  --colorBlack80: rgba(0, 0, 0, .8)!important;
  --colorBlack90: rgba(0, 0, 0, .9)!important;
  --colorWhite10: hsla(0, 0%, 100%, .1)!important;
  --colorWhite20: hsla(0, 0%, 100%, .2)!important;
  --colorWhite30: hsla(0, 0%, 100%, .3)!important;
  --colorWhite40: hsla(0, 0%, 100%, .4)!important;
  --colorWhite50: hsla(0, 0%, 100%, .5)!important;
  --colorWhite60: hsla(0, 0%, 100%, .6)!important;
  --colorWhite70: hsla(0, 0%, 100%, .7)!important;
  --colorWhite80: hsla(0, 0%, 100%, .8)!important;
  --colorWhite90: hsla(0, 0%, 100%, .9)!important;

}
