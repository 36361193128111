@charset "UTF-8";
* {
  box-sizing: border-box; }

a {
  color: currentColor; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

html {
  font-size: 62.5%;
  /* Now 10px = 1rem! */ }

body {
  font-size: 1.3rem;
  font-family: SoDoSans, sans-serif;
  font-feature-settings: "calt"; }

img {
  max-width: 100%; }

header.sb-components-header {
  background: #edebe9;
  padding: 1rem; }

header.sb-components-header ul {
  list-style: none;
  padding: 0;
  margin: 0 -2rem; }

header.sb-components-header ul li {
  display: inline;
  margin: 0 2rem; }

header.sb-components-header ul li a {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.56);
  text-decoration: none;
  font-size: 1.2rem; }

.code-description p {
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: .1rem;
  margin-bottom: .8rem; }

.code-example {
  padding: 2rem;
  border: 1px solid #ccc;
  margin: 2rem 0; }

.sb-components-container {
  padding: 2rem; }

.text-bold {
  font-weight: 700 !important; }

.text-upper {
  text-transform: uppercase !important;
  letter-spacing: .1em !important; }

svg {
  fill: currentColor; }

@font-face {
  font-family: "Gotham Screensmart Bold";
  src: url("https://content.sbuxtr.com/static/fonts/gothamSSbold.eot?#iefix") format("embedded-opentype"), url("https://content.sbuxtr.com/static/fonts/gothamSSbold.woff") format("woff"), url("https://content.sbuxtr.com/static/fonts/gothamSSbold.ttf") format("truetype"), url("https://content.sbuxtr.com/static/fonts/gothamSSbold.svg#Font") format("svg"); }

@font-face {
  font-family: "LanderGrandeBlack";
  src:
    url("https://content.sbuxtr.com/static/fonts/LanderGrandeBlack.ttf") format("truetype"),
}

@font-face {
  font-family: "Gotham Screensmart Book";
  src: url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.eot?#iefix") format("embedded-opentype"), url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.woff") format("woff"), url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.ttf") format("truetype"), url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.svg#GothamSSm-Book") format("svg"); }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-BoldItalic.woff2") format("woff2"), url("/static/fonts/SoDoSans-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-Bold.woff2") format("woff2"), url("/static/fonts/SoDoSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-Light.woff2") format("woff2"), url("/static/fonts/SoDoSans-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-LightItalic.woff2") format("woff2"), url("/static/fonts/SoDoSans-LightItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-Regular.woff2") format("woff2"), url("/static/fonts/SoDoSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-Italic.woff2") format("woff2"), url("/static/fonts/SoDoSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-SemiBold.woff2") format("woff2"), url("/static/fonts/SoDoSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "SoDoSans";
  src: url("/static/fonts/SoDoSans-SemiBoldItalic.woff2") format("woff2"), url("/static/fonts/SoDoSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

:root {
  --space-1: .4rem;
  --space-2: .8rem;
  --space-3: 1.6rem;
  --space-4: 2.4rem;
  --space-5: 3.2rem;
  --space-6: 4rem;
  --space-7: 4.8rem;
  --space-8: 5.6rem;
  --space-9: 6.4rem; }

:root {
  --breakpointSm: 480px;
  --breakpointMd: 768px;
  --breakpointLg: 1024px;
  --breakpointXl: 1280px;
  --breakpointXxl: 1600px; }

:root {
  --colorWhite: #ffffff;
  --colorGrayNatural: #f7f7f7;
  --colorCream: #f3f1e7;
  --colorCeramic: #edebe9;
  --colorBlack: #000000;
  --colorBlackWarm: #2d2926;
  --colorBlackWarmer: #3d3935;
  --colorGreenStarbucks: #00653e;
  --colorGreenApron: #00a862;
  --colorGreenDarkApron: #008248;
  --colorGold: #c2a661;
  --colorRed: #e75b52;
  --colorYellow: #fbbc05;
  --colorTextBlack: rgba(0, 0, 0, .87);
  --colorTextBlackSoft: rgba(0, 0, 0, .56);
  --colorTextWhite: rgba(255, 255, 255, 1);
  --colorTextWhiteSoft: rgba(255, 255, 255, .70);
  --colorBlack10: rgba(0, 0, 0, .1)!important;
  --colorBlack20: rgba(0, 0, 0, .2)!important;
  --colorBlack30: rgba(0, 0, 0, .3)!important;
  --colorBlack40: rgba(0, 0, 0, .4)!important;
  --colorBlack50: rgba(0, 0, 0, .5)!important;
  --colorBlack60: rgba(0, 0, 0, .6)!important;
  --colorBlack70: rgba(0, 0, 0, .7)!important;
  --colorBlack80: rgba(0, 0, 0, .8)!important;
  --colorBlack90: rgba(0, 0, 0, .9)!important;
  --colorWhite10: hsla(0, 0%, 100%, .1)!important;
  --colorWhite20: hsla(0, 0%, 100%, .2)!important;
  --colorWhite30: hsla(0, 0%, 100%, .3)!important;
  --colorWhite40: hsla(0, 0%, 100%, .4)!important;
  --colorWhite50: hsla(0, 0%, 100%, .5)!important;
  --colorWhite60: hsla(0, 0%, 100%, .6)!important;
  --colorWhite70: hsla(0, 0%, 100%, .7)!important;
  --colorWhite80: hsla(0, 0%, 100%, .8)!important;
  --colorWhite90: hsla(0, 0%, 100%, .9)!important; }

.valign-baseline {
  vertical-align: baseline !important; }

.valign-middle {
  vertical-align: middle !important; }

.valign-top {
  vertical-align: top !important; }

.valign-bottom {
  vertical-align: bottom !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.hidden {
  display: none !important; }

.block {
  display: block !important; }

.inline {
  display: inline !important; }

.inline-block {
  display: inline-block !important; }

.table {
  display: table !important; }

.table-cell {
  display: table-cell !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-auto {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch; }

@media (min-width: var(--breakpointSm)) {
  .sm-hidden {
    display: none !important; }
  .sm-block {
    display: block !important; }
  .sm-inline {
    display: inline !important; }
  .sm-inline-block {
    display: inline-block !important; }
  .sm-table {
    display: table !important; }
  .sm-table-cell {
    display: table-cell !important; }
  .sm-float-left {
    float: left !important; }
  .sm-float-right {
    float: right !important; }
  .sm-float-none {
    float: none !important; }
  .sm-overflow-hidden {
    overflow: hidden !important; }
  .sm-overflow-auto {
    overflow: auto !important;
    -webkit-overflow-scrolling: touch; } }

@media (min-width: var(--breakpointMd)) {
  .md-hidden {
    display: none !important; }
  .md-block {
    display: block !important; }
  .md-inline {
    display: inline !important; }
  .md-inline-block {
    display: inline-block !important; }
  .md-table {
    display: table !important; }
  .md-table-cell {
    display: table-cell !important; }
  .md-float-left {
    float: left !important; }
  .md-float-right {
    float: right !important; }
  .md-float-none {
    float: none !important; }
  .md-overflow-hidden {
    overflow: hidden !important; }
  .md-overflow-auto {
    overflow: auto !important;
    -webkit-overflow-scrolling: touch; } }

@media (min-width: var(--breakpointLg)) {
  .lg-hidden {
    display: none !important; }
  .lg-block {
    display: block !important; }
  .lg-inline {
    display: inline !important; }
  .lg-inline-block {
    display: inline-block !important; }
  .lg-table {
    display: table !important; }
  .lg-table-cell {
    display: table-cell !important; }
  .lg-float-left {
    float: left !important; }
  .lg-float-right {
    float: right !important; }
  .lg-float-none {
    float: none !important; }
  .lg-overflow-hidden {
    overflow: hidden !important; }
  .lg-overflow-auto {
    overflow: auto !important;
    -webkit-overflow-scrolling: touch; } }

@media (min-width: var(--breakpointXl)) {
  .xl-hidden {
    display: none !important; }
  .xl-block {
    display: block !important; }
  .xl-inline {
    display: inline !important; }
  .xl-inline-block {
    display: inline-block !important; }
  .xl-table {
    display: table !important; }
  .xl-table-cell {
    display: table-cell !important; }
  .xl-float-left {
    float: left !important; }
  .xl-float-right {
    float: right !important; }
  .xl-float-none {
    float: none !important; }
  .xl-overflow-hidden {
    overflow: hidden !important; }
  .xl-overflow-auto {
    overflow: auto !important;
    -webkit-overflow-scrolling: touch; } }

@media (min-width: var(--breakpointXsl)) {
  .xxl-hidden {
    display: none !important; }
  .xxl-block {
    display: block !important; }
  .xxl-inline {
    display: inline !important; }
  .xxl-inline-block {
    display: inline-block !important; }
  .xxl-table {
    display: table !important; }
  .xxl-table-cell {
    display: table-cell !important; }
  .xxl-float-left {
    float: left !important; }
  .xxl-float-right {
    float: right !important; }
  .xxl-float-none {
    float: none !important; }
  .xxl-overflow-hidden {
    overflow: hidden !important; }
  .xxl-overflow-auto {
    overflow: auto !important;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 479px) {
  .smMax-hidden {
    display: none !important; } }

@media (max-width: 767px) {
  .mdMax-hidden {
    display: none !important; } }

@media (max-width: 1023px) {
  .lgMax-hidden {
    display: none !important; } }

@media (max-width: 1279px) {
  .xlMax-hidden {
    display: none !important; } }

.flex {
  display: -ms-flexbox !important;
  display: flex !important; }

@media (min-width: var(--breakpointSm)) {
  .sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; } }

@media (min-width: var(--breakpointMd)) {
  .md-flex {
    display: -ms-flexbox !important;
    display: flex !important; } }

@media (min-width: var(--breakpointLg)) {
  .lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; } }

@media (min-width: var(--breakpointXl)) {
  .xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; } }

@media (min-width: var(--breakpointXsl)) {
  .xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important; } }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

.justify-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.flex-auto {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
  min-width: 0 !important;
  min-height: 0 !important; }

.flex-none {
  -ms-flex: none !important;
  flex: none !important; }

.flex-shrink {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.flex-shrink-none {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-grow {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-grow-none {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-basis-none {
  -ms-flex-preferred-size: 0% !important;
  flex-basis: 0% !important; }

.order-0 {
  -ms-flex-order: 0 !important;
  order: 0 !important; }

.order-1 {
  -ms-flex-order: 1 !important;
  order: 1 !important; }

.order-2 {
  -ms-flex-order: 2 !important;
  order: 2 !important; }

.order-3 {
  -ms-flex-order: 3 !important;
  order: 3 !important; }

.order-last {
  -ms-flex-order: 99999 !important;
  order: 99999 !important; }

.order-first {
  -ms-flex-order: -1 !important;
  order: -1 !important; }

.container,
.container--headerCrate {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 1.6rem; }

@media (min-width: var(--breakpointLg)) {
  .container {
    padding: 0 4.8rem; }
  .container--headerCrate {
    padding: 0 3.2rem; } }

.grid {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left; }

.grid--compactGutter {
  margin: 0 -0.4rem; }

.grid--wideGutter {
  margin: 0 -1.6rem; }

.grid img {
  max-width: 100%;
  height: auto; }

@media (min-width: var(--breakpointXl)) {
  .grid--compactGutter {
    margin: 0 -0.8rem; }
  .grid--wideGutter {
    margin: 0 -2.4rem; } }

.grid--alignLeft {
  text-align: left; }

.grid--alignCenter {
  text-align: center; }

.grid--alignRight {
  text-align: right; }

.grid--valignTop .gridItem {
  vertical-align: top; }

.grid--valignMiddle .gridItem {
  vertical-align: middle; }

.grid--valignBottom .gridItem {
  vertical-align: bottom; }

.gridItem {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: top; }

.grid--compactGutter .gridItem {
  padding: 0 0.4rem; }

.grid--wideGutter .gridItem {
  padding: 0 1.6rem; }

@media (min-width: var(--breakpointXl)) {
  .grid--compactGutter .gridItem {
    padding: 0 0.8rem; }
  .grid--wideGutter .gridItem {
    padding: 0 2.4rem; } }

.color-white {
  color: var(--colorWhite) !important; }

.color-grayNatural {
  color: var(--colorGrayNatural) !important; }

.color-cream {
  color: var(--colorCream) !important; }

.color-ceramic {
  color: var(--colorCeramic) !important; }

.color-black {
  color: var(--colorBlack) !important; }

.color-blackWarm {
  color: var(--colorBlackWarm) !important; }

.color-blackWarmer {
  color: var(--colorBlackWarmer) !important; }

.color-greenStarbucks {
  color: var(--colorGreenStarbucks) !important; }

.color-greenApron {
  color: var(--colorGreenApron) !important; }

.color-greenDarkApron {
  color: var(--colorGreenDarkApron) !important; }

.color-gold {
  color: var(--colorGold) !important; }

.color-red {
  color: var(--colorRed) !important; }

.color-yellow {
  color: var(--colorYellow) !important; }

.color-textBlack {
  color: var(--colorTextBlack) !important; }

.color-textBlackSoft {
  color: var(--colorTextBlackSoft) !important; }

.color-textWhite {
  color: var(--colorWhite) !important; }

.color-textWhiteSoft {
  color: var(--colorTextWhiteSoft) !important; }

.color-black10 {
  color: var(--colorBlack10) !important; }

.color-black20 {
  color: var(--colorBlack20) !important; }

.color-black30 {
  color: var(--colorBlack30) !important; }

.color-black40 {
  color: var(--colorBlack40) !important; }

.color-black50 {
  color: var(--colorBlack50) !important; }

.color-black60 {
  color: var(--colorBlack60) !important; }

.color-black70 {
  color: var(--colorBlack70) !important; }

.color-black80 {
  color: var(--colorBlack80) !important; }

.color-black90 {
  color: var(--colorBlack90) !important; }

.color-white10 {
  color: var(--colorWhite10) !important; }

.color-white20 {
  color: var(--colorWhite20) !important; }

.color-white30 {
  color: var(--colorWhite30) !important; }

.color-white40 {
  color: var(--colorWhite40) !important; }

.color-white50 {
  color: var(--colorWhite50) !important; }

.color-white60 {
  color: var(--colorWhite60) !important; }

.color-white70 {
  color: var(--colorTextWhiteSoft) !important; }

.color-white80 {
  color: var(--colorWhite80) !important; }

.color-white90 {
  color: var(--colorWhite90) !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-white {
  background-color: var(--colorWhite) !important; }

.bg-grayNatural {
  background-color: var(--colorGrayNatural) !important; }

.bg-cream {
  background-color: var(--colorCream) !important; }

.bg-ceramic {
  background-color: var(--colorCeramic) !important; }

.bg-black {
  background-color: var(--colorBlack) !important; }

.bg-blackWarm {
  background-color: var(--colorBlackWarm) !important; }

.bg-blackWarmer {
  background-color: var(--colorBlackWarmer) !important; }

.bg-greenStarbucks {
  background-color: var(--colorGreenStarbucks) !important; }

.bg-greenApron {
  background-color: var(--colorGreenApron) !important; }

.bg-greenDarkApron {
  background-color: var(--colorGreenDarkApron) !important; }

.bg-gold {
  background-color: var(--colorGold) !important; }

.bg-red {
  background-color: var(--colorRed) !important; }

.bg-yellow {
  background-color: var(--colorYellow) !important; }

.bg-black10 {
  background-color: var(--colorBlack10) !important; }

.bg-black20 {
  background-color: var(--colorBlack20) !important; }

.bg-black30 {
  background-color: var(--colorBlack30) !important; }

.bg-black40 {
  background-color: var(--colorBlack40) !important; }

.bg-black50 {
  background-color: var(--colorBlack50) !important; }

.bg-black60 {
  background-color: var(--colorBlack60) !important; }

.bg-black70 {
  background-color: var(--colorBlack70) !important; }

.bg-black80 {
  background-color: var(--colorBlack80) !important; }

.bg-black90 {
  background-color: var(--colorBlack90) !important; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0,
.mx0 {
  margin-left: 0 !important; }

.mx0 {
  margin-right: 0 !important; }

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m1 {
  margin: var(--space-1) !important; }

.mt1 {
  margin-top: var(--space-1) !important; }

.mr1 {
  margin-right: var(--space-1) !important; }

.mb1 {
  margin-bottom: var(--space-1) !important; }

.ml1,
.mx1 {
  margin-left: var(--space-1) !important; }

.mx1 {
  margin-right: var(--space-1) !important; }

.my1 {
  margin-top: var(--space-1) !important;
  margin-bottom: var(--space-1) !important; }

.mxn1 {
  margin-left: -0.4rem !important;
  margin-right: -0.4rem !important; }

.m2 {
  margin: var(--space-2) !important; }

.mt2 {
  margin-top: var(--space-2) !important; }

.mr2 {
  margin-right: var(--space-2) !important; }

.mb2 {
  margin-bottom: var(--space-2) !important; }

.ml2,
.mx2 {
  margin-left: var(--space-2) !important; }

.mx2 {
  margin-right: var(--space-2) !important; }

.my2 {
  margin-top: var(--space-2) !important;
  margin-bottom: var(--space-2) !important; }

.mxn2 {
  margin-left: -0.8rem !important;
  margin-right: -0.8rem !important; }

.m3 {
  margin: 1.6rem !important; }

.mt3 {
  margin-top: var(--space-3) !important; }

.mr3 {
  margin-right: var(--space-3) !important; }

.mb3 {
  margin-bottom: var(--space-3) !important; }

.ml3,
.mx3 {
  margin-left: var(--space-3) !important; }

.mx3 {
  margin-right: var(--space-3) !important; }

.my3 {
  margin-top: var(--space-3) !important;
  margin-bottom: var(--space-3) !important; }

.mxn3 {
  margin-left: -1.6rem !important;
  margin-right: -1.6rem !important; }

.m4 {
  margin: var(--space-4) !important; }

.mt4 {
  margin-top: var(--space-4) !important; }

.mr4 {
  margin-right: var(--space-4) !important; }

.mb4 {
  margin-bottom: var(--space-4) !important; }

.ml4,
.mx4 {
  margin-left: var(--space-4) !important; }

.mx4 {
  margin-right: var(--space-4) !important; }

.my4 {
  margin-top: var(--space-4) !important;
  margin-bottom: var(--space-4) !important; }

.mxn4 {
  margin-left: -2.4rem !important;
  margin-right: -2.4rem !important; }

.m5 {
  margin: var(--space-5) !important; }

.mt5 {
  margin-top: var(--space-5) !important; }

.mr5 {
  margin-right: var(--space-5) !important; }

.mb5 {
  margin-bottom: var(--space-5) !important; }

.ml5,
.mx5 {
  margin-left: var(--space-5) !important; }

.mx5 {
  margin-right: var(--space-5) !important; }

.my5 {
  margin-top: var(--space-5) !important;
  margin-bottom: var(--space-5) !important; }

.mxn5 {
  margin-left: -3.2rem !important;
  margin-right: -3.2rem !important; }

.m6 {
  margin: var(--space-6) !important; }

.mt6 {
  margin-top: var(--space-6) !important; }

.mr6 {
  margin-right: var(--space-6) !important; }

.mb6 {
  margin-bottom: var(--space-6) !important; }

.ml6,
.mx6 {
  margin-left: var(--space-6) !important; }

.mx6 {
  margin-right: var(--space-6) !important; }

.my6 {
  margin-top: var(--space-6) !important;
  margin-bottom: var(--space-6) !important; }

.mxn6 {
  margin-left: var(--space-1) !important;
  margin-right: var(--space-1) !important; }

.m7 {
  margin: var(--space-7) !important; }

.mt7 {
  margin-top: var(--space-7) !important; }

.mr7 {
  margin-right: var(--space-7) !important; }

.mb7 {
  margin-bottom: var(--space-7) !important; }

.ml7,
.mx7 {
  margin-left: var(--space-7) !important; }

.mx7 {
  margin-right: var(--space-7) !important; }

.my7 {
  margin-top: var(--space-7) !important;
  margin-bottom: var(--space-7) !important; }

.mxn7 {
  margin-left: -4.8rem !important;
  margin-right: -4.8rem !important; }

.m8 {
  margin: var(--space-8) !important; }

.mt8 {
  margin-top: var(--space-8) !important; }

.mr8 {
  margin-right: var(--space-8) !important; }

.mb8 {
  margin-bottom: var(--space-8) !important; }

.ml8,
.mx8 {
  margin-left: var(--space-8) !important; }

.mx8 {
  margin-right: var(--space-8) !important; }

.my8 {
  margin-top: var(--space-8) !important;
  margin-bottom: var(--space-8) !important; }

.mxn8 {
  margin-left: -5.6rem !important;
  margin-right: -5.6rem !important; }

.m9 {
  margin: var(--space-9) !important; }

.mt9 {
  margin-top: var(--space-9) !important; }

.mr9 {
  margin-right: var(--space-9) !important; }

.mb9 {
  margin-bottom: var(--space-9) !important; }

.ml9,
.mx9 {
  margin-left: var(--space-9) !important; }

.mx9 {
  margin-right: var(--space-9) !important; }

.my9 {
  margin-top: var(--space-9) !important;
  margin-bottom: var(--space-9) !important; }

.mxn9 {
  margin-left: -6.4rem !important;
  margin-right: -6.4rem !important; }

.ml-auto {
  margin-left: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mx-auto {
  margin-left: auto !important; }

@media (min-width: var(--breakpointSm)) {
  .sm-m0 {
    margin: 0 !important; }
  .sm-mt0 {
    margin-top: 0 !important; }
  .sm-mr0 {
    margin-right: 0 !important; }
  .sm-mb0 {
    margin-bottom: 0 !important; }
  .sm-ml0,
  .sm-mx0 {
    margin-left: 0 !important; }
  .sm-mx0 {
    margin-right: 0 !important; }
  .sm-my0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-m1 {
    margin: var(--space-1) !important; }
  .sm-mt1 {
    margin-top: var(--space-1) !important; }
  .sm-mr1 {
    margin-right: var(--space-1) !important; }
  .sm-mb1 {
    margin-bottom: var(--space-1) !important; }
  .sm-ml1,
  .sm-mx1 {
    margin-left: var(--space-1) !important; }
  .sm-mx1 {
    margin-right: var(--space-1) !important; }
  .sm-my1 {
    margin-top: var(--space-1) !important;
    margin-bottom: var(--space-1) !important; }
  .sm-mxn1 {
    margin-left: -0.4rem !important;
    margin-right: -0.4rem !important; }
  .sm-m2 {
    margin: var(--space-2) !important; }
  .sm-mt2 {
    margin-top: var(--space-2) !important; }
  .sm-mr2 {
    margin-right: var(--space-2) !important; }
  .sm-mb2 {
    margin-bottom: var(--space-2) !important; }
  .sm-ml2,
  .sm-mx2 {
    margin-left: var(--space-2) !important; }
  .sm-mx2 {
    margin-right: var(--space-2) !important; }
  .sm-my2 {
    margin-top: var(--space-2) !important;
    margin-bottom: var(--space-2) !important; }
  .sm-mxn2 {
    margin-left: -0.8rem !important;
    margin-right: -0.8rem !important; }
  .sm-m3 {
    margin: 1.6rem !important; }
  .sm-mt3 {
    margin-top: var(--space-3) !important; }
  .sm-mr3 {
    margin-right: var(--space-3) !important; }
  .sm-mb3 {
    margin-bottom: var(--space-3) !important; }
  .sm-ml3,
  .sm-mx3 {
    margin-left: var(--space-3) !important; }
  .sm-mx3 {
    margin-right: var(--space-3) !important; }
  .sm-my3 {
    margin-top: var(--space-3) !important;
    margin-bottom: var(--space-3) !important; }
  .sm-mxn3 {
    margin-left: -1.6rem !important;
    margin-right: -1.6rem !important; }
  .sm-m4 {
    margin: var(--space-4) !important; }
  .sm-mt4 {
    margin-top: var(--space-4) !important; }
  .sm-mr4 {
    margin-right: var(--space-4) !important; }
  .sm-mb4 {
    margin-bottom: var(--space-4) !important; }
  .sm-ml4,
  .sm-mx4 {
    margin-left: var(--space-4) !important; }
  .sm-mx4 {
    margin-right: var(--space-4) !important; }
  .sm-my4 {
    margin-top: var(--space-4) !important;
    margin-bottom: var(--space-4) !important; }
  .sm-mxn4 {
    margin-left: -2.4rem !important;
    margin-right: -2.4rem !important; }
  .sm-m5 {
    margin: var(--space-5) !important; }
  .sm-mt5 {
    margin-top: var(--space-5) !important; }
  .sm-mr5 {
    margin-right: var(--space-5) !important; }
  .sm-mb5 {
    margin-bottom: var(--space-5) !important; }
  .sm-ml5,
  .sm-mx5 {
    margin-left: var(--space-5) !important; }
  .sm-mx5 {
    margin-right: var(--space-5) !important; }
  .sm-my5 {
    margin-top: var(--space-5) !important;
    margin-bottom: var(--space-5) !important; }
  .sm-mxn5 {
    margin-left: -3.2rem !important;
    margin-right: -3.2rem !important; }
  .sm-m6 {
    margin: var(--space-6) !important; }
  .sm-mt6 {
    margin-top: var(--space-6) !important; }
  .sm-mr6 {
    margin-right: var(--space-6) !important; }
  .sm-mb6 {
    margin-bottom: var(--space-6) !important; }
  .sm-ml6,
  .sm-mx6 {
    margin-left: var(--space-6) !important; }
  .sm-mx6 {
    margin-right: var(--space-6) !important; }
  .sm-my6 {
    margin-top: var(--space-6) !important;
    margin-bottom: var(--space-6) !important; }
  .sm-mxn6 {
    margin-left: var(--space-1) !important;
    margin-right: var(--space-1) !important; }
  .sm-m7 {
    margin: var(--space-7) !important; }
  .sm-mt7 {
    margin-top: var(--space-7) !important; }
  .sm-mr7 {
    margin-right: var(--space-7) !important; }
  .sm-mb7 {
    margin-bottom: var(--space-7) !important; }
  .sm-ml7,
  .sm-mx7 {
    margin-left: var(--space-7) !important; }
  .sm-mx7 {
    margin-right: var(--space-7) !important; }
  .sm-my7 {
    margin-top: var(--space-7) !important;
    margin-bottom: var(--space-7) !important; }
  .sm-mxn7 {
    margin-left: -4.8rem !important;
    margin-right: -4.8rem !important; }
  .sm-m8 {
    margin: var(--space-8) !important; }
  .sm-mt8 {
    margin-top: var(--space-8) !important; }
  .sm-mr8 {
    margin-right: var(--space-8) !important; }
  .sm-mb8 {
    margin-bottom: var(--space-8) !important; }
  .sm-ml8,
  .sm-mx8 {
    margin-left: var(--space-8) !important; }
  .sm-mx8 {
    margin-right: var(--space-8) !important; }
  .sm-my8 {
    margin-top: var(--space-8) !important;
    margin-bottom: var(--space-8) !important; }
  .sm-mxn8 {
    margin-left: -5.6rem !important;
    margin-right: -5.6rem !important; }
  .sm-m9 {
    margin: var(--space-9) !important; }
  .sm-mt9 {
    margin-top: var(--space-9) !important; }
  .sm-mr9 {
    margin-right: var(--space-9) !important; }
  .sm-mb9 {
    margin-bottom: var(--space-9) !important; }
  .sm-ml9,
  .sm-mx9 {
    margin-left: var(--space-9) !important; }
  .sm-mx9 {
    margin-right: var(--space-9) !important; }
  .sm-my9 {
    margin-top: var(--space-9) !important;
    margin-bottom: var(--space-9) !important; }
  .sm-mxn9 {
    margin-left: -6.4rem !important;
    margin-right: -6.4rem !important; }
  .sm-ml-auto {
    margin-left: auto !important; }
  .sm-mr-auto,
  .sm-mx-auto {
    margin-right: auto !important; }
  .sm-mx-auto {
    margin-left: auto !important; } }

@media (min-width: var(--breakpointMd)) {
  .md-m0 {
    margin: 0 !important; }
  .md-mt0 {
    margin-top: 0 !important; }
  .md-mr0 {
    margin-right: 0 !important; }
  .md-mb0 {
    margin-bottom: 0 !important; }
  .md-ml0,
  .md-mx0 {
    margin-left: 0 !important; }
  .md-mx0 {
    margin-right: 0 !important; }
  .md-my0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-m1 {
    margin: var(--space-1) !important; }
  .md-mt1 {
    margin-top: var(--space-1) !important; }
  .md-mr1 {
    margin-right: var(--space-1) !important; }
  .md-mb1 {
    margin-bottom: var(--space-1) !important; }
  .md-ml1,
  .md-mx1 {
    margin-left: var(--space-1) !important; }
  .md-mx1 {
    margin-right: var(--space-1) !important; }
  .md-my1 {
    margin-top: var(--space-1) !important;
    margin-bottom: var(--space-1) !important; }
  .md-mxn1 {
    margin-left: -0.4rem !important;
    margin-right: -0.4rem !important; }
  .md-m2 {
    margin: var(--space-2) !important; }
  .md-mt2 {
    margin-top: var(--space-2) !important; }
  .md-mr2 {
    margin-right: var(--space-2) !important; }
  .md-mb2 {
    margin-bottom: var(--space-2) !important; }
  .md-ml2,
  .md-mx2 {
    margin-left: var(--space-2) !important; }
  .md-mx2 {
    margin-right: var(--space-2) !important; }
  .md-my2 {
    margin-top: var(--space-2) !important;
    margin-bottom: var(--space-2) !important; }
  .md-mxn2 {
    margin-left: -0.8rem !important;
    margin-right: -0.8rem !important; }
  .md-m3 {
    margin: 1.6rem !important; }
  .md-mt3 {
    margin-top: var(--space-3) !important; }
  .md-mr3 {
    margin-right: var(--space-3) !important; }
  .md-mb3 {
    margin-bottom: var(--space-3) !important; }
  .md-ml3,
  .md-mx3 {
    margin-left: var(--space-3) !important; }
  .md-mx3 {
    margin-right: var(--space-3) !important; }
  .md-my3 {
    margin-top: var(--space-3) !important;
    margin-bottom: var(--space-3) !important; }
  .md-mxn3 {
    margin-left: -1.6rem !important;
    margin-right: -1.6rem !important; }
  .md-m4 {
    margin: var(--space-4) !important; }
  .md-mt4 {
    margin-top: var(--space-4) !important; }
  .md-mr4 {
    margin-right: var(--space-4) !important; }
  .md-mb4 {
    margin-bottom: var(--space-4) !important; }
  .md-ml4,
  .md-mx4 {
    margin-left: var(--space-4) !important; }
  .md-mx4 {
    margin-right: var(--space-4) !important; }
  .md-my4 {
    margin-top: var(--space-4) !important;
    margin-bottom: var(--space-4) !important; }
  .md-mxn4 {
    margin-left: -2.4rem !important;
    margin-right: -2.4rem !important; }
  .md-m5 {
    margin: var(--space-5) !important; }
  .md-mt5 {
    margin-top: var(--space-5) !important; }
  .md-mr5 {
    margin-right: var(--space-5) !important; }
  .md-mb5 {
    margin-bottom: var(--space-5) !important; }
  .md-ml5,
  .md-mx5 {
    margin-left: var(--space-5) !important; }
  .md-mx5 {
    margin-right: var(--space-5) !important; }
  .md-my5 {
    margin-top: var(--space-5) !important;
    margin-bottom: var(--space-5) !important; }
  .md-mxn5 {
    margin-left: -3.2rem !important;
    margin-right: -3.2rem !important; }
  .md-m6 {
    margin: var(--space-6) !important; }
  .md-mt6 {
    margin-top: var(--space-6) !important; }
  .md-mr6 {
    margin-right: var(--space-6) !important; }
  .md-mb6 {
    margin-bottom: var(--space-6) !important; }
  .md-ml6,
  .md-mx6 {
    margin-left: var(--space-6) !important; }
  .md-mx6 {
    margin-right: var(--space-6) !important; }
  .md-my6 {
    margin-top: var(--space-6) !important;
    margin-bottom: var(--space-6) !important; }
  .md-mxn6 {
    margin-left: var(--space-1) !important;
    margin-right: var(--space-1) !important; }
  .md-m7 {
    margin: var(--space-7) !important; }
  .md-mt7 {
    margin-top: var(--space-7) !important; }
  .md-mr7 {
    margin-right: var(--space-7) !important; }
  .md-mb7 {
    margin-bottom: var(--space-7) !important; }
  .md-ml7,
  .md-mx7 {
    margin-left: var(--space-7) !important; }
  .md-mx7 {
    margin-right: var(--space-7) !important; }
  .md-my7 {
    margin-top: var(--space-7) !important;
    margin-bottom: var(--space-7) !important; }
  .md-mxn7 {
    margin-left: -4.8rem !important;
    margin-right: -4.8rem !important; }
  .md-m8 {
    margin: var(--space-8) !important; }
  .md-mt8 {
    margin-top: var(--space-8) !important; }
  .md-mr8 {
    margin-right: var(--space-8) !important; }
  .md-mb8 {
    margin-bottom: var(--space-8) !important; }
  .md-ml8,
  .md-mx8 {
    margin-left: var(--space-8) !important; }
  .md-mx8 {
    margin-right: var(--space-8) !important; }
  .md-my8 {
    margin-top: var(--space-8) !important;
    margin-bottom: var(--space-8) !important; }
  .md-mxn8 {
    margin-left: -5.6rem !important;
    margin-right: -5.6rem !important; }
  .md-m9 {
    margin: var(--space-9) !important; }
  .md-mt9 {
    margin-top: var(--space-9) !important; }
  .md-mr9 {
    margin-right: var(--space-9) !important; }
  .md-mb9 {
    margin-bottom: var(--space-9) !important; }
  .md-ml9,
  .md-mx9 {
    margin-left: var(--space-9) !important; }
  .md-mx9 {
    margin-right: var(--space-9) !important; }
  .md-my9 {
    margin-top: var(--space-9) !important;
    margin-bottom: var(--space-9) !important; }
  .md-mxn9 {
    margin-left: -6.4rem !important;
    margin-right: -6.4rem !important; }
  .md-ml-auto {
    margin-left: auto !important; }
  .md-mr-auto,
  .md-mx-auto {
    margin-right: auto !important; }
  .md-mx-auto {
    margin-left: auto !important; } }

@media (min-width: var(--breakpointLg)) {
  .lg-m0 {
    margin: 0 !important; }
  .lg-mt0 {
    margin-top: 0 !important; }
  .lg-mr0 {
    margin-right: 0 !important; }
  .lg-mb0 {
    margin-bottom: 0 !important; }
  .lg-ml0,
  .lg-mx0 {
    margin-left: 0 !important; }
  .lg-mx0 {
    margin-right: 0 !important; }
  .lg-my0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-m1 {
    margin: var(--space-1) !important; }
  .lg-mt1 {
    margin-top: var(--space-1) !important; }
  .lg-mr1 {
    margin-right: var(--space-1) !important; }
  .lg-mb1 {
    margin-bottom: var(--space-1) !important; }
  .lg-ml1,
  .lg-mx1 {
    margin-left: var(--space-1) !important; }
  .lg-mx1 {
    margin-right: var(--space-1) !important; }
  .lg-my1 {
    margin-top: var(--space-1) !important;
    margin-bottom: var(--space-1) !important; }
  .lg-mxn1 {
    margin-left: -0.4rem !important;
    margin-right: -0.4rem !important; }
  .lg-m2 {
    margin: var(--space-2) !important; }
  .lg-mt2 {
    margin-top: var(--space-2) !important; }
  .lg-mr2 {
    margin-right: var(--space-2) !important; }
  .lg-mb2 {
    margin-bottom: var(--space-2) !important; }
  .lg-ml2,
  .lg-mx2 {
    margin-left: var(--space-2) !important; }
  .lg-mx2 {
    margin-right: var(--space-2) !important; }
  .lg-my2 {
    margin-top: var(--space-2) !important;
    margin-bottom: var(--space-2) !important; }
  .lg-mxn2 {
    margin-left: -0.8rem !important;
    margin-right: -0.8rem !important; }
  .lg-m3 {
    margin: 1.6rem !important; }
  .lg-mt3 {
    margin-top: var(--space-3) !important; }
  .lg-mr3 {
    margin-right: var(--space-3) !important; }
  .lg-mb3 {
    margin-bottom: var(--space-3) !important; }
  .lg-ml3,
  .lg-mx3 {
    margin-left: var(--space-3) !important; }
  .lg-mx3 {
    margin-right: var(--space-3) !important; }
  .lg-my3 {
    margin-top: var(--space-3) !important;
    margin-bottom: var(--space-3) !important; }
  .lg-mxn3 {
    margin-left: -1.6rem !important;
    margin-right: -1.6rem !important; }
  .lg-m4 {
    margin: var(--space-4) !important; }
  .lg-mt4 {
    margin-top: var(--space-4) !important; }
  .lg-mr4 {
    margin-right: var(--space-4) !important; }
  .lg-mb4 {
    margin-bottom: var(--space-4) !important; }
  .lg-ml4,
  .lg-mx4 {
    margin-left: var(--space-4) !important; }
  .lg-mx4 {
    margin-right: var(--space-4) !important; }
  .lg-my4 {
    margin-top: var(--space-4) !important;
    margin-bottom: var(--space-4) !important; }
  .lg-mxn4 {
    margin-left: -2.4rem !important;
    margin-right: -2.4rem !important; }
  .lg-m5 {
    margin: var(--space-5) !important; }
  .lg-mt5 {
    margin-top: var(--space-5) !important; }
  .lg-mr5 {
    margin-right: var(--space-5) !important; }
  .lg-mb5 {
    margin-bottom: var(--space-5) !important; }
  .lg-ml5,
  .lg-mx5 {
    margin-left: var(--space-5) !important; }
  .lg-mx5 {
    margin-right: var(--space-5) !important; }
  .lg-my5 {
    margin-top: var(--space-5) !important;
    margin-bottom: var(--space-5) !important; }
  .lg-mxn5 {
    margin-left: -3.2rem !important;
    margin-right: -3.2rem !important; }
  .lg-m6 {
    margin: var(--space-6) !important; }
  .lg-mt6 {
    margin-top: var(--space-6) !important; }
  .lg-mr6 {
    margin-right: var(--space-6) !important; }
  .lg-mb6 {
    margin-bottom: var(--space-6) !important; }
  .lg-ml6,
  .lg-mx6 {
    margin-left: var(--space-6) !important; }
  .lg-mx6 {
    margin-right: var(--space-6) !important; }
  .lg-my6 {
    margin-top: var(--space-6) !important;
    margin-bottom: var(--space-6) !important; }
  .lg-mxn6 {
    margin-left: var(--space-1) !important;
    margin-right: var(--space-1) !important; }
  .lg-m7 {
    margin: var(--space-7) !important; }
  .lg-mt7 {
    margin-top: var(--space-7) !important; }
  .lg-mr7 {
    margin-right: var(--space-7) !important; }
  .lg-mb7 {
    margin-bottom: var(--space-7) !important; }
  .lg-ml7,
  .lg-mx7 {
    margin-left: var(--space-7) !important; }
  .lg-mx7 {
    margin-right: var(--space-7) !important; }
  .lg-my7 {
    margin-top: var(--space-7) !important;
    margin-bottom: var(--space-7) !important; }
  .lg-mxn7 {
    margin-left: -4.8rem !important;
    margin-right: -4.8rem !important; }
  .lg-m8 {
    margin: var(--space-8) !important; }
  .lg-mt8 {
    margin-top: var(--space-8) !important; }
  .lg-mr8 {
    margin-right: var(--space-8) !important; }
  .lg-mb8 {
    margin-bottom: var(--space-8) !important; }
  .lg-ml8,
  .lg-mx8 {
    margin-left: var(--space-8) !important; }
  .lg-mx8 {
    margin-right: var(--space-8) !important; }
  .lg-my8 {
    margin-top: var(--space-8) !important;
    margin-bottom: var(--space-8) !important; }
  .lg-mxn8 {
    margin-left: -5.6rem !important;
    margin-right: -5.6rem !important; }
  .lg-m9 {
    margin: var(--space-9) !important; }
  .lg-mt9 {
    margin-top: var(--space-9) !important; }
  .lg-mr9 {
    margin-right: var(--space-9) !important; }
  .lg-mb9 {
    margin-bottom: var(--space-9) !important; }
  .lg-ml9,
  .lg-mx9 {
    margin-left: var(--space-9) !important; }
  .lg-mx9 {
    margin-right: var(--space-9) !important; }
  .lg-my9 {
    margin-top: var(--space-9) !important;
    margin-bottom: var(--space-9) !important; }
  .lg-mxn9 {
    margin-left: -6.4rem !important;
    margin-right: -6.4rem !important; }
  .lg-ml-auto {
    margin-left: auto !important; }
  .lg-mr-auto,
  .lg-mx-auto {
    margin-right: auto !important; }
  .lg-mx-auto {
    margin-left: auto !important; } }

@media (min-width: var(--breakpointXl)) {
  .xl-m0 {
    margin: 0 !important; }
  .xl-mt0 {
    margin-top: 0 !important; }
  .xl-mr0 {
    margin-right: 0 !important; }
  .xl-mb0 {
    margin-bottom: 0 !important; }
  .xl-ml0,
  .xl-mx0 {
    margin-left: 0 !important; }
  .xl-mx0 {
    margin-right: 0 !important; }
  .xl-my0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-m1 {
    margin: var(--space-1) !important; }
  .xl-mt1 {
    margin-top: var(--space-1) !important; }
  .xl-mr1 {
    margin-right: var(--space-1) !important; }
  .xl-mb1 {
    margin-bottom: var(--space-1) !important; }
  .xl-ml1,
  .xl-mx1 {
    margin-left: var(--space-1) !important; }
  .xl-mx1 {
    margin-right: var(--space-1) !important; }
  .xl-my1 {
    margin-top: var(--space-1) !important;
    margin-bottom: var(--space-1) !important; }
  .xl-mxn1 {
    margin-left: -0.4rem !important;
    margin-right: -0.4rem !important; }
  .xl-m2 {
    margin: var(--space-2) !important; }
  .xl-mt2 {
    margin-top: var(--space-2) !important; }
  .xl-mr2 {
    margin-right: var(--space-2) !important; }
  .xl-mb2 {
    margin-bottom: var(--space-2) !important; }
  .xl-ml2,
  .xl-mx2 {
    margin-left: var(--space-2) !important; }
  .xl-mx2 {
    margin-right: var(--space-2) !important; }
  .xl-my2 {
    margin-top: var(--space-2) !important;
    margin-bottom: var(--space-2) !important; }
  .xl-mxn2 {
    margin-left: -0.8rem !important;
    margin-right: -0.8rem !important; }
  .xl-m3 {
    margin: 1.6rem !important; }
  .xl-mt3 {
    margin-top: var(--space-3) !important; }
  .xl-mr3 {
    margin-right: var(--space-3) !important; }
  .xl-mb3 {
    margin-bottom: var(--space-3) !important; }
  .xl-ml3,
  .xl-mx3 {
    margin-left: var(--space-3) !important; }
  .xl-mx3 {
    margin-right: var(--space-3) !important; }
  .xl-my3 {
    margin-top: var(--space-3) !important;
    margin-bottom: var(--space-3) !important; }
  .xl-mxn3 {
    margin-left: -1.6rem !important;
    margin-right: -1.6rem !important; }
  .xl-m4 {
    margin: var(--space-4) !important; }
  .xl-mt4 {
    margin-top: var(--space-4) !important; }
  .xl-mr4 {
    margin-right: var(--space-4) !important; }
  .xl-mb4 {
    margin-bottom: var(--space-4) !important; }
  .xl-ml4,
  .xl-mx4 {
    margin-left: var(--space-4) !important; }
  .xl-mx4 {
    margin-right: var(--space-4) !important; }
  .xl-my4 {
    margin-top: var(--space-4) !important;
    margin-bottom: var(--space-4) !important; }
  .xl-mxn4 {
    margin-left: -2.4rem !important;
    margin-right: -2.4rem !important; }
  .xl-m5 {
    margin: var(--space-5) !important; }
  .xl-mt5 {
    margin-top: var(--space-5) !important; }
  .xl-mr5 {
    margin-right: var(--space-5) !important; }
  .xl-mb5 {
    margin-bottom: var(--space-5) !important; }
  .xl-ml5,
  .xl-mx5 {
    margin-left: var(--space-5) !important; }
  .xl-mx5 {
    margin-right: var(--space-5) !important; }
  .xl-my5 {
    margin-top: var(--space-5) !important;
    margin-bottom: var(--space-5) !important; }
  .xl-mxn5 {
    margin-left: -3.2rem !important;
    margin-right: -3.2rem !important; }
  .xl-m6 {
    margin: var(--space-6) !important; }
  .xl-mt6 {
    margin-top: var(--space-6) !important; }
  .xl-mr6 {
    margin-right: var(--space-6) !important; }
  .xl-mb6 {
    margin-bottom: var(--space-6) !important; }
  .xl-ml6,
  .xl-mx6 {
    margin-left: var(--space-6) !important; }
  .xl-mx6 {
    margin-right: var(--space-6) !important; }
  .xl-my6 {
    margin-top: var(--space-6) !important;
    margin-bottom: var(--space-6) !important; }
  .xl-mxn6 {
    margin-left: var(--space-1) !important;
    margin-right: var(--space-1) !important; }
  .xl-m7 {
    margin: var(--space-7) !important; }
  .xl-mt7 {
    margin-top: var(--space-7) !important; }
  .xl-mr7 {
    margin-right: var(--space-7) !important; }
  .xl-mb7 {
    margin-bottom: var(--space-7) !important; }
  .xl-ml7,
  .xl-mx7 {
    margin-left: var(--space-7) !important; }
  .xl-mx7 {
    margin-right: var(--space-7) !important; }
  .xl-my7 {
    margin-top: var(--space-7) !important;
    margin-bottom: var(--space-7) !important; }
  .xl-mxn7 {
    margin-left: -4.8rem !important;
    margin-right: -4.8rem !important; }
  .xl-m8 {
    margin: var(--space-8) !important; }
  .xl-mt8 {
    margin-top: var(--space-8) !important; }
  .xl-mr8 {
    margin-right: var(--space-8) !important; }
  .xl-mb8 {
    margin-bottom: var(--space-8) !important; }
  .xl-ml8,
  .xl-mx8 {
    margin-left: var(--space-8) !important; }
  .xl-mx8 {
    margin-right: var(--space-8) !important; }
  .xl-my8 {
    margin-top: var(--space-8) !important;
    margin-bottom: var(--space-8) !important; }
  .xl-mxn8 {
    margin-left: -5.6rem !important;
    margin-right: -5.6rem !important; }
  .xl-m9 {
    margin: var(--space-9) !important; }
  .xl-mt9 {
    margin-top: var(--space-9) !important; }
  .xl-mr9 {
    margin-right: var(--space-9) !important; }
  .xl-mb9 {
    margin-bottom: var(--space-9) !important; }
  .xl-ml9,
  .xl-mx9 {
    margin-left: var(--space-9) !important; }
  .xl-mx9 {
    margin-right: var(--space-9) !important; }
  .xl-my9 {
    margin-top: var(--space-9) !important;
    margin-bottom: var(--space-9) !important; }
  .xl-mxn9 {
    margin-left: -6.4rem !important;
    margin-right: -6.4rem !important; }
  .xl-ml-auto {
    margin-left: auto !important; }
  .xl-mr-auto,
  .xl-mx-auto {
    margin-right: auto !important; }
  .xl-mx-auto {
    margin-left: auto !important; } }

@media (min-width: var(--breakpointXsl)) {
  .xxl-m0 {
    margin: 0 !important; }
  .xxl-mt0 {
    margin-top: 0 !important; }
  .xxl-mr0 {
    margin-right: 0 !important; }
  .xxl-mb0 {
    margin-bottom: 0 !important; }
  .xxl-ml0,
  .xxl-mx0 {
    margin-left: 0 !important; }
  .xxl-mx0 {
    margin-right: 0 !important; }
  .xxl-my0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xxl-m1 {
    margin: var(--space-1) !important; }
  .xxl-mt1 {
    margin-top: var(--space-1) !important; }
  .xxl-mr1 {
    margin-right: var(--space-1) !important; }
  .xxl-mb1 {
    margin-bottom: var(--space-1) !important; }
  .xxl-ml1,
  .xxl-mx1 {
    margin-left: var(--space-1) !important; }
  .xxl-mx1 {
    margin-right: var(--space-1) !important; }
  .xxl-my1 {
    margin-top: var(--space-1) !important;
    margin-bottom: var(--space-1) !important; }
  .xxl-mxn1 {
    margin-left: -0.4rem !important;
    margin-right: -0.4rem !important; }
  .xxl-m2 {
    margin: var(--space-2) !important; }
  .xxl-mt2 {
    margin-top: var(--space-2) !important; }
  .xxl-mr2 {
    margin-right: var(--space-2) !important; }
  .xxl-mb2 {
    margin-bottom: var(--space-2) !important; }
  .xxl-ml2,
  .xxl-mx2 {
    margin-left: var(--space-2) !important; }
  .xxl-mx2 {
    margin-right: var(--space-2) !important; }
  .xxl-my2 {
    margin-top: var(--space-2) !important;
    margin-bottom: var(--space-2) !important; }
  .xxl-mxn2 {
    margin-left: -0.8rem !important;
    margin-right: -0.8rem !important; }
  .xxl-m3 {
    margin: 1.6rem !important; }
  .xxl-mt3 {
    margin-top: var(--space-3) !important; }
  .xxl-mr3 {
    margin-right: var(--space-3) !important; }
  .xxl-mb3 {
    margin-bottom: var(--space-3) !important; }
  .xxl-ml3,
  .xxl-mx3 {
    margin-left: var(--space-3) !important; }
  .xxl-mx3 {
    margin-right: var(--space-3) !important; }
  .xxl-my3 {
    margin-top: var(--space-3) !important;
    margin-bottom: var(--space-3) !important; }
  .xxl-mxn3 {
    margin-left: -1.6rem !important;
    margin-right: -1.6rem !important; }
  .xxl-m4 {
    margin: var(--space-4) !important; }
  .xxl-mt4 {
    margin-top: var(--space-4) !important; }
  .xxl-mr4 {
    margin-right: var(--space-4) !important; }
  .xxl-mb4 {
    margin-bottom: var(--space-4) !important; }
  .xxl-ml4,
  .xxl-mx4 {
    margin-left: var(--space-4) !important; }
  .xxl-mx4 {
    margin-right: var(--space-4) !important; }
  .xxl-my4 {
    margin-top: var(--space-4) !important;
    margin-bottom: var(--space-4) !important; }
  .xxl-mxn4 {
    margin-left: -2.4rem !important;
    margin-right: -2.4rem !important; }
  .xxl-m5 {
    margin: var(--space-5) !important; }
  .xxl-mt5 {
    margin-top: var(--space-5) !important; }
  .xxl-mr5 {
    margin-right: var(--space-5) !important; }
  .xxl-mb5 {
    margin-bottom: var(--space-5) !important; }
  .xxl-ml5,
  .xxl-mx5 {
    margin-left: var(--space-5) !important; }
  .xxl-mx5 {
    margin-right: var(--space-5) !important; }
  .xxl-my5 {
    margin-top: var(--space-5) !important;
    margin-bottom: var(--space-5) !important; }
  .xxl-mxn5 {
    margin-left: -3.2rem !important;
    margin-right: -3.2rem !important; }
  .xxl-m6 {
    margin: var(--space-6) !important; }
  .xxl-mt6 {
    margin-top: var(--space-6) !important; }
  .xxl-mr6 {
    margin-right: var(--space-6) !important; }
  .xxl-mb6 {
    margin-bottom: var(--space-6) !important; }
  .xxl-ml6,
  .xxl-mx6 {
    margin-left: var(--space-6) !important; }
  .xxl-mx6 {
    margin-right: var(--space-6) !important; }
  .xxl-my6 {
    margin-top: var(--space-6) !important;
    margin-bottom: var(--space-6) !important; }
  .xxl-mxn6 {
    margin-left: var(--space-1) !important;
    margin-right: var(--space-1) !important; }
  .xxl-m7 {
    margin: var(--space-7) !important; }
  .xxl-mt7 {
    margin-top: var(--space-7) !important; }
  .xxl-mr7 {
    margin-right: var(--space-7) !important; }
  .xxl-mb7 {
    margin-bottom: var(--space-7) !important; }
  .xxl-ml7,
  .xxl-mx7 {
    margin-left: var(--space-7) !important; }
  .xxl-mx7 {
    margin-right: var(--space-7) !important; }
  .xxl-my7 {
    margin-top: var(--space-7) !important;
    margin-bottom: var(--space-7) !important; }
  .xxl-mxn7 {
    margin-left: -4.8rem !important;
    margin-right: -4.8rem !important; }
  .xxl-m8 {
    margin: var(--space-8) !important; }
  .xxl-mt8 {
    margin-top: var(--space-8) !important; }
  .xxl-mr8 {
    margin-right: var(--space-8) !important; }
  .xxl-mb8 {
    margin-bottom: var(--space-8) !important; }
  .xxl-ml8,
  .xxl-mx8 {
    margin-left: var(--space-8) !important; }
  .xxl-mx8 {
    margin-right: var(--space-8) !important; }
  .xxl-my8 {
    margin-top: var(--space-8) !important;
    margin-bottom: var(--space-8) !important; }
  .xxl-mxn8 {
    margin-left: -5.6rem !important;
    margin-right: -5.6rem !important; }
  .xxl-m9 {
    margin: var(--space-9) !important; }
  .xxl-mt9 {
    margin-top: var(--space-9) !important; }
  .xxl-mr9 {
    margin-right: var(--space-9) !important; }
  .xxl-mb9 {
    margin-bottom: var(--space-9) !important; }
  .xxl-ml9,
  .xxl-mx9 {
    margin-left: var(--space-9) !important; }
  .xxl-mx9 {
    margin-right: var(--space-9) !important; }
  .xxl-my9 {
    margin-top: var(--space-9) !important;
    margin-bottom: var(--space-9) !important; }
  .xxl-mxn9 {
    margin-left: -6.4rem !important;
    margin-right: -6.4rem !important; }
  .xxl-ml-auto {
    margin-left: auto !important; }
  .xxl-mr-auto,
  .xxl-mx-auto {
    margin-right: auto !important; }
  .xxl-mx-auto {
    margin-left: auto !important; } }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pl0,
.px0 {
  padding-left: 0 !important; }

.px0 {
  padding-right: 0 !important; }

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.frapPadding {
  padding-bottom: 9rem !important; }

.frapPaddingDouble {
  padding-bottom: 14.6rem !important; }

.p1 {
  padding: var(--space-1) !important; }

.pt1 {
  padding-top: var(--space-1) !important; }

.pr1 {
  padding-right: var(--space-1) !important; }

.pb1 {
  padding-bottom: var(--space-1) !important; }

.pl1,
.px1 {
  padding-left: var(--space-1) !important; }

.px1 {
  padding-right: var(--space-1) !important; }

.py1 {
  padding-top: var(--space-1) !important;
  padding-bottom: var(--space-1) !important; }

.p2 {
  padding: var(--space-2) !important; }

.pt2 {
  padding-top: var(--space-2) !important; }

.pr2 {
  padding-right: var(--space-2) !important; }

.pb2 {
  padding-bottom: var(--space-2) !important; }

.pl2,
.px2 {
  padding-left: var(--space-2) !important; }

.px2 {
  padding-right: var(--space-2) !important; }

.py2 {
  padding-top: var(--space-2) !important;
  padding-bottom: var(--space-2) !important; }

.p3 {
  padding: 1.6rem !important; }

.pt3 {
  padding-top: var(--space-3) !important; }

.pr3 {
  padding-right: var(--space-3) !important; }

.pb3 {
  padding-bottom: var(--space-3) !important; }

.pl3,
.px3 {
  padding-left: var(--space-3) !important; }

.px3 {
  padding-right: var(--space-3) !important; }

.py3 {
  padding-top: var(--space-3) !important;
  padding-bottom: var(--space-3) !important; }

.p4 {
  padding: var(--space-4) !important; }

.pt4 {
  padding-top: var(--space-4) !important; }

.pr4 {
  padding-right: var(--space-4) !important; }

.pb4 {
  padding-bottom: var(--space-4) !important; }

.pl4,
.px4 {
  padding-left: var(--space-4) !important; }

.px4 {
  padding-right: var(--space-4) !important; }

.py4 {
  padding-top: var(--space-4) !important;
  padding-bottom: var(--space-4) !important; }

.p5 {
  padding: var(--space-5) !important; }

.pt5 {
  padding-top: var(--space-5) !important; }

.pr5 {
  padding-right: var(--space-5) !important; }

.pb5 {
  padding-bottom: var(--space-5) !important; }

.pl5,
.px5 {
  padding-left: var(--space-5) !important; }

.px5 {
  padding-right: var(--space-5) !important; }

.py5 {
  padding-top: var(--space-5) !important;
  padding-bottom: var(--space-5) !important; }

.p6 {
  padding: var(--space-6) !important; }

.pt6 {
  padding-top: var(--space-6) !important; }

.pr6 {
  padding-right: var(--space-6) !important; }

.pb6 {
  padding-bottom: var(--space-6) !important; }

.pl6,
.px6 {
  padding-left: var(--space-6) !important; }

.px6 {
  padding-right: var(--space-6) !important; }

.py6 {
  padding-top: var(--space-6) !important;
  padding-bottom: var(--space-6) !important; }

.p7 {
  padding: var(--space-7) !important; }

.pt7 {
  padding-top: var(--space-7) !important; }

.pr7 {
  padding-right: var(--space-7) !important; }

.pb7 {
  padding-bottom: var(--space-7) !important; }

.pl7,
.px7 {
  padding-left: var(--space-7) !important; }

.px7 {
  padding-right: var(--space-7) !important; }

.py7 {
  padding-top: var(--space-7) !important;
  padding-bottom: var(--space-7) !important; }

.p8 {
  padding: var(--space-8) !important; }

.pt8 {
  padding-top: var(--space-8) !important; }

.pr8 {
  padding-right: var(--space-8) !important; }

.pb8 {
  padding-bottom: var(--space-8) !important; }

.pl8,
.px8 {
  padding-left: var(--space-8) !important; }

.px8 {
  padding-right: var(--space-8) !important; }

.py8 {
  padding-top: var(--space-8) !important;
  padding-bottom: var(--space-8) !important; }

.p9 {
  padding: var(--space-9) !important; }

.pt9 {
  padding-top: var(--space-9) !important; }

.pr9 {
  padding-right: var(--space-9) !important; }

.pb9 {
  padding-bottom: var(--space-9) !important; }

.pl9,
.px9 {
  padding-left: var(--space-9) !important; }

.px9 {
  padding-right: var(--space-9) !important; }

.py9 {
  padding-top: var(--space-9) !important;
  padding-bottom: var(--space-9) !important; }

@media (min-width: var(--breakpointSm)) {
  .sm-p0 {
    padding: 0 !important; }
  .sm-pt0 {
    padding-top: 0 !important; }
  .sm-pr0 {
    padding-right: 0 !important; }
  .sm-pb0 {
    padding-bottom: 0 !important; }
  .sm-pl0,
  .sm-px0 {
    padding-left: 0 !important; }
  .sm-px0 {
    padding-right: 0 !important; }
  .sm-py0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-p1 {
    padding: var(--space-1) !important; }
  .sm-pt1 {
    padding-top: var(--space-1) !important; }
  .sm-pr1 {
    padding-right: var(--space-1) !important; }
  .sm-pb1 {
    padding-bottom: var(--space-1) !important; }
  .sm-pl1,
  .sm-px1 {
    padding-left: var(--space-1) !important; }
  .sm-px1 {
    padding-right: var(--space-1) !important; }
  .sm-py1 {
    padding-top: var(--space-1) !important;
    padding-bottom: var(--space-1) !important; }
  .sm-p2 {
    padding: var(--space-2) !important; }
  .sm-pt2 {
    padding-top: var(--space-2) !important; }
  .sm-pr2 {
    padding-right: var(--space-2) !important; }
  .sm-pb2 {
    padding-bottom: var(--space-2) !important; }
  .sm-pl2,
  .sm-px2 {
    padding-left: var(--space-2) !important; }
  .sm-px2 {
    padding-right: var(--space-2) !important; }
  .sm-py2 {
    padding-top: var(--space-2) !important;
    padding-bottom: var(--space-2) !important; }
  .sm-p3 {
    padding: 1.6rem !important; }
  .sm-pt3 {
    padding-top: var(--space-3) !important; }
  .sm-pr3 {
    padding-right: var(--space-3) !important; }
  .sm-pb3 {
    padding-bottom: var(--space-3) !important; }
  .sm-pl3,
  .sm-px3 {
    padding-left: var(--space-3) !important; }
  .sm-px3 {
    padding-right: var(--space-3) !important; }
  .sm-py3 {
    padding-top: var(--space-3) !important;
    padding-bottom: var(--space-3) !important; }
  .sm-p4 {
    padding: var(--space-4) !important; }
  .sm-pt4 {
    padding-top: var(--space-4) !important; }
  .sm-pr4 {
    padding-right: var(--space-4) !important; }
  .sm-pb4 {
    padding-bottom: var(--space-4) !important; }
  .sm-pl4,
  .sm-px4 {
    padding-left: var(--space-4) !important; }
  .sm-px4 {
    padding-right: var(--space-4) !important; }
  .sm-py4 {
    padding-top: var(--space-4) !important;
    padding-bottom: var(--space-4) !important; }
  .sm-p5 {
    padding: var(--space-5) !important; }
  .sm-pt5 {
    padding-top: var(--space-5) !important; }
  .sm-pr5 {
    padding-right: var(--space-5) !important; }
  .sm-pb5 {
    padding-bottom: var(--space-5) !important; }
  .sm-pl5,
  .sm-px5 {
    padding-left: var(--space-5) !important; }
  .sm-px5 {
    padding-right: var(--space-5) !important; }
  .sm-py5 {
    padding-top: var(--space-5) !important;
    padding-bottom: var(--space-5) !important; }
  .sm-p6 {
    padding: var(--space-6) !important; }
  .sm-pt6 {
    padding-top: var(--space-6) !important; }
  .sm-pr6 {
    padding-right: var(--space-6) !important; }
  .sm-pb6 {
    padding-bottom: var(--space-6) !important; }
  .sm-pl6,
  .sm-px6 {
    padding-left: var(--space-6) !important; }
  .sm-px6 {
    padding-right: var(--space-6) !important; }
  .sm-py6 {
    padding-top: var(--space-6) !important;
    padding-bottom: var(--space-6) !important; }
  .sm-p7 {
    padding: var(--space-7) !important; }
  .sm-pt7 {
    padding-top: var(--space-7) !important; }
  .sm-pr7 {
    padding-right: var(--space-7) !important; }
  .sm-pb7 {
    padding-bottom: var(--space-7) !important; }
  .sm-pl7,
  .sm-px7 {
    padding-left: var(--space-7) !important; }
  .sm-px7 {
    padding-right: var(--space-7) !important; }
  .sm-py7 {
    padding-top: var(--space-7) !important;
    padding-bottom: var(--space-7) !important; }
  .sm-p8 {
    padding: var(--space-8) !important; }
  .sm-pt8 {
    padding-top: var(--space-8) !important; }
  .sm-pr8 {
    padding-right: var(--space-8) !important; }
  .sm-pb8 {
    padding-bottom: var(--space-8) !important; }
  .sm-pl8,
  .sm-px8 {
    padding-left: var(--space-8) !important; }
  .sm-px8 {
    padding-right: var(--space-8) !important; }
  .sm-py8 {
    padding-top: var(--space-8) !important;
    padding-bottom: var(--space-8) !important; }
  .sm-p9 {
    padding: var(--space-9) !important; }
  .sm-pt9 {
    padding-top: var(--space-9) !important; }
  .sm-pr9 {
    padding-right: var(--space-9) !important; }
  .sm-pb9 {
    padding-bottom: var(--space-9) !important; }
  .sm-pl9,
  .sm-px9 {
    padding-left: var(--space-9) !important; }
  .sm-px9 {
    padding-right: var(--space-9) !important; }
  .sm-py9 {
    padding-top: var(--space-9) !important;
    padding-bottom: var(--space-9) !important; } }

@media (min-width: var(--breakpointMd)) {
  .md-p0 {
    padding: 0 !important; }
  .md-pt0 {
    padding-top: 0 !important; }
  .md-pr0 {
    padding-right: 0 !important; }
  .md-pb0 {
    padding-bottom: 0 !important; }
  .md-pl0,
  .md-px0 {
    padding-left: 0 !important; }
  .md-px0 {
    padding-right: 0 !important; }
  .md-py0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-p1 {
    padding: var(--space-1) !important; }
  .md-pt1 {
    padding-top: var(--space-1) !important; }
  .md-pr1 {
    padding-right: var(--space-1) !important; }
  .md-pb1 {
    padding-bottom: var(--space-1) !important; }
  .md-pl1,
  .md-px1 {
    padding-left: var(--space-1) !important; }
  .md-px1 {
    padding-right: var(--space-1) !important; }
  .md-py1 {
    padding-top: var(--space-1) !important;
    padding-bottom: var(--space-1) !important; }
  .md-p2 {
    padding: var(--space-2) !important; }
  .md-pt2 {
    padding-top: var(--space-2) !important; }
  .md-pr2 {
    padding-right: var(--space-2) !important; }
  .md-pb2 {
    padding-bottom: var(--space-2) !important; }
  .md-pl2,
  .md-px2 {
    padding-left: var(--space-2) !important; }
  .md-px2 {
    padding-right: var(--space-2) !important; }
  .md-py2 {
    padding-top: var(--space-2) !important;
    padding-bottom: var(--space-2) !important; }
  .md-p3 {
    padding: 1.6rem !important; }
  .md-pt3 {
    padding-top: var(--space-3) !important; }
  .md-pr3 {
    padding-right: var(--space-3) !important; }
  .md-pb3 {
    padding-bottom: var(--space-3) !important; }
  .md-pl3,
  .md-px3 {
    padding-left: var(--space-3) !important; }
  .md-px3 {
    padding-right: var(--space-3) !important; }
  .md-py3 {
    padding-top: var(--space-3) !important;
    padding-bottom: var(--space-3) !important; }
  .md-p4 {
    padding: var(--space-4) !important; }
  .md-pt4 {
    padding-top: var(--space-4) !important; }
  .md-pr4 {
    padding-right: var(--space-4) !important; }
  .md-pb4 {
    padding-bottom: var(--space-4) !important; }
  .md-pl4,
  .md-px4 {
    padding-left: var(--space-4) !important; }
  .md-px4 {
    padding-right: var(--space-4) !important; }
  .md-py4 {
    padding-top: var(--space-4) !important;
    padding-bottom: var(--space-4) !important; }
  .md-p5 {
    padding: var(--space-5) !important; }
  .md-pt5 {
    padding-top: var(--space-5) !important; }
  .md-pr5 {
    padding-right: var(--space-5) !important; }
  .md-pb5 {
    padding-bottom: var(--space-5) !important; }
  .md-pl5,
  .md-px5 {
    padding-left: var(--space-5) !important; }
  .md-px5 {
    padding-right: var(--space-5) !important; }
  .md-py5 {
    padding-top: var(--space-5) !important;
    padding-bottom: var(--space-5) !important; }
  .md-p6 {
    padding: var(--space-6) !important; }
  .md-pt6 {
    padding-top: var(--space-6) !important; }
  .md-pr6 {
    padding-right: var(--space-6) !important; }
  .md-pb6 {
    padding-bottom: var(--space-6) !important; }
  .md-pl6,
  .md-px6 {
    padding-left: var(--space-6) !important; }
  .md-px6 {
    padding-right: var(--space-6) !important; }
  .md-py6 {
    padding-top: var(--space-6) !important;
    padding-bottom: var(--space-6) !important; }
  .md-p7 {
    padding: var(--space-7) !important; }
  .md-pt7 {
    padding-top: var(--space-7) !important; }
  .md-pr7 {
    padding-right: var(--space-7) !important; }
  .md-pb7 {
    padding-bottom: var(--space-7) !important; }
  .md-pl7,
  .md-px7 {
    padding-left: var(--space-7) !important; }
  .md-px7 {
    padding-right: var(--space-7) !important; }
  .md-py7 {
    padding-top: var(--space-7) !important;
    padding-bottom: var(--space-7) !important; }
  .md-p8 {
    padding: var(--space-8) !important; }
  .md-pt8 {
    padding-top: var(--space-8) !important; }
  .md-pr8 {
    padding-right: var(--space-8) !important; }
  .md-pb8 {
    padding-bottom: var(--space-8) !important; }
  .md-pl8,
  .md-px8 {
    padding-left: var(--space-8) !important; }
  .md-px8 {
    padding-right: var(--space-8) !important; }
  .md-py8 {
    padding-top: var(--space-8) !important;
    padding-bottom: var(--space-8) !important; }
  .md-p9 {
    padding: var(--space-9) !important; }
  .md-pt9 {
    padding-top: var(--space-9) !important; }
  .md-pr9 {
    padding-right: var(--space-9) !important; }
  .md-pb9 {
    padding-bottom: var(--space-9) !important; }
  .md-pl9,
  .md-px9 {
    padding-left: var(--space-9) !important; }
  .md-px9 {
    padding-right: var(--space-9) !important; }
  .md-py9 {
    padding-top: var(--space-9) !important;
    padding-bottom: var(--space-9) !important; } }

@media (min-width: var(--breakpointLg)) {
  .lg-p0 {
    padding: 0 !important; }
  .lg-pt0 {
    padding-top: 0 !important; }
  .lg-pr0 {
    padding-right: 0 !important; }
  .lg-pb0 {
    padding-bottom: 0 !important; }
  .lg-pl0,
  .lg-px0 {
    padding-left: 0 !important; }
  .lg-px0 {
    padding-right: 0 !important; }
  .lg-py0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-p1 {
    padding: var(--space-1) !important; }
  .lg-pt1 {
    padding-top: var(--space-1) !important; }
  .lg-pr1 {
    padding-right: var(--space-1) !important; }
  .lg-pb1 {
    padding-bottom: var(--space-1) !important; }
  .lg-pl1,
  .lg-px1 {
    padding-left: var(--space-1) !important; }
  .lg-px1 {
    padding-right: var(--space-1) !important; }
  .lg-py1 {
    padding-top: var(--space-1) !important;
    padding-bottom: var(--space-1) !important; }
  .lg-p2 {
    padding: var(--space-2) !important; }
  .lg-pt2 {
    padding-top: var(--space-2) !important; }
  .lg-pr2 {
    padding-right: var(--space-2) !important; }
  .lg-pb2 {
    padding-bottom: var(--space-2) !important; }
  .lg-pl2,
  .lg-px2 {
    padding-left: var(--space-2) !important; }
  .lg-px2 {
    padding-right: var(--space-2) !important; }
  .lg-py2 {
    padding-top: var(--space-2) !important;
    padding-bottom: var(--space-2) !important; }
  .lg-p3 {
    padding: 1.6rem !important; }
  .lg-pt3 {
    padding-top: var(--space-3) !important; }
  .lg-pr3 {
    padding-right: var(--space-3) !important; }
  .lg-pb3 {
    padding-bottom: var(--space-3) !important; }
  .lg-pl3,
  .lg-px3 {
    padding-left: var(--space-3) !important; }
  .lg-px3 {
    padding-right: var(--space-3) !important; }
  .lg-py3 {
    padding-top: var(--space-3) !important;
    padding-bottom: var(--space-3) !important; }
  .lg-p4 {
    padding: var(--space-4) !important; }
  .lg-pt4 {
    padding-top: var(--space-4) !important; }
  .lg-pr4 {
    padding-right: var(--space-4) !important; }
  .lg-pb4 {
    padding-bottom: var(--space-4) !important; }
  .lg-pl4,
  .lg-px4 {
    padding-left: var(--space-4) !important; }
  .lg-px4 {
    padding-right: var(--space-4) !important; }
  .lg-py4 {
    padding-top: var(--space-4) !important;
    padding-bottom: var(--space-4) !important; }
  .lg-p5 {
    padding: var(--space-5) !important; }
  .lg-pt5 {
    padding-top: var(--space-5) !important; }
  .lg-pr5 {
    padding-right: var(--space-5) !important; }
  .lg-pb5 {
    padding-bottom: var(--space-5) !important; }
  .lg-pl5,
  .lg-px5 {
    padding-left: var(--space-5) !important; }
  .lg-px5 {
    padding-right: var(--space-5) !important; }
  .lg-py5 {
    padding-top: var(--space-5) !important;
    padding-bottom: var(--space-5) !important; }
  .lg-p6 {
    padding: var(--space-6) !important; }
  .lg-pt6 {
    padding-top: var(--space-6) !important; }
  .lg-pr6 {
    padding-right: var(--space-6) !important; }
  .lg-pb6 {
    padding-bottom: var(--space-6) !important; }
  .lg-pl6,
  .lg-px6 {
    padding-left: var(--space-6) !important; }
  .lg-px6 {
    padding-right: var(--space-6) !important; }
  .lg-py6 {
    padding-top: var(--space-6) !important;
    padding-bottom: var(--space-6) !important; }
  .lg-p7 {
    padding: var(--space-7) !important; }
  .lg-pt7 {
    padding-top: var(--space-7) !important; }
  .lg-pr7 {
    padding-right: var(--space-7) !important; }
  .lg-pb7 {
    padding-bottom: var(--space-7) !important; }
  .lg-pl7,
  .lg-px7 {
    padding-left: var(--space-7) !important; }
  .lg-px7 {
    padding-right: var(--space-7) !important; }
  .lg-py7 {
    padding-top: var(--space-7) !important;
    padding-bottom: var(--space-7) !important; }
  .lg-p8 {
    padding: var(--space-8) !important; }
  .lg-pt8 {
    padding-top: var(--space-8) !important; }
  .lg-pr8 {
    padding-right: var(--space-8) !important; }
  .lg-pb8 {
    padding-bottom: var(--space-8) !important; }
  .lg-pl8,
  .lg-px8 {
    padding-left: var(--space-8) !important; }
  .lg-px8 {
    padding-right: var(--space-8) !important; }
  .lg-py8 {
    padding-top: var(--space-8) !important;
    padding-bottom: var(--space-8) !important; }
  .lg-p9 {
    padding: var(--space-9) !important; }
  .lg-pt9 {
    padding-top: var(--space-9) !important; }
  .lg-pr9 {
    padding-right: var(--space-9) !important; }
  .lg-pb9 {
    padding-bottom: var(--space-9) !important; }
  .lg-pl9,
  .lg-px9 {
    padding-left: var(--space-9) !important; }
  .lg-px9 {
    padding-right: var(--space-9) !important; }
  .lg-py9 {
    padding-top: var(--space-9) !important;
    padding-bottom: var(--space-9) !important; } }

@media (min-width: var(--breakpointXl)) {
  .xl-p0 {
    padding: 0 !important; }
  .xl-pt0 {
    padding-top: 0 !important; }
  .xl-pr0 {
    padding-right: 0 !important; }
  .xl-pb0 {
    padding-bottom: 0 !important; }
  .xl-pl0,
  .xl-px0 {
    padding-left: 0 !important; }
  .xl-px0 {
    padding-right: 0 !important; }
  .xl-py0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-p1 {
    padding: var(--space-1) !important; }
  .xl-pt1 {
    padding-top: var(--space-1) !important; }
  .xl-pr1 {
    padding-right: var(--space-1) !important; }
  .xl-pb1 {
    padding-bottom: var(--space-1) !important; }
  .xl-pl1,
  .xl-px1 {
    padding-left: var(--space-1) !important; }
  .xl-px1 {
    padding-right: var(--space-1) !important; }
  .xl-py1 {
    padding-top: var(--space-1) !important;
    padding-bottom: var(--space-1) !important; }
  .xl-p2 {
    padding: var(--space-2) !important; }
  .xl-pt2 {
    padding-top: var(--space-2) !important; }
  .xl-pr2 {
    padding-right: var(--space-2) !important; }
  .xl-pb2 {
    padding-bottom: var(--space-2) !important; }
  .xl-pl2,
  .xl-px2 {
    padding-left: var(--space-2) !important; }
  .xl-px2 {
    padding-right: var(--space-2) !important; }
  .xl-py2 {
    padding-top: var(--space-2) !important;
    padding-bottom: var(--space-2) !important; }
  .xl-p3 {
    padding: 1.6rem !important; }
  .xl-pt3 {
    padding-top: var(--space-3) !important; }
  .xl-pr3 {
    padding-right: var(--space-3) !important; }
  .xl-pb3 {
    padding-bottom: var(--space-3) !important; }
  .xl-pl3,
  .xl-px3 {
    padding-left: var(--space-3) !important; }
  .xl-px3 {
    padding-right: var(--space-3) !important; }
  .xl-py3 {
    padding-top: var(--space-3) !important;
    padding-bottom: var(--space-3) !important; }
  .xl-p4 {
    padding: var(--space-4) !important; }
  .xl-pt4 {
    padding-top: var(--space-4) !important; }
  .xl-pr4 {
    padding-right: var(--space-4) !important; }
  .xl-pb4 {
    padding-bottom: var(--space-4) !important; }
  .xl-pl4,
  .xl-px4 {
    padding-left: var(--space-4) !important; }
  .xl-px4 {
    padding-right: var(--space-4) !important; }
  .xl-py4 {
    padding-top: var(--space-4) !important;
    padding-bottom: var(--space-4) !important; }
  .xl-p5 {
    padding: var(--space-5) !important; }
  .xl-pt5 {
    padding-top: var(--space-5) !important; }
  .xl-pr5 {
    padding-right: var(--space-5) !important; }
  .xl-pb5 {
    padding-bottom: var(--space-5) !important; }
  .xl-pl5,
  .xl-px5 {
    padding-left: var(--space-5) !important; }
  .xl-px5 {
    padding-right: var(--space-5) !important; }
  .xl-py5 {
    padding-top: var(--space-5) !important;
    padding-bottom: var(--space-5) !important; }
  .xl-p6 {
    padding: var(--space-6) !important; }
  .xl-pt6 {
    padding-top: var(--space-6) !important; }
  .xl-pr6 {
    padding-right: var(--space-6) !important; }
  .xl-pb6 {
    padding-bottom: var(--space-6) !important; }
  .xl-pl6,
  .xl-px6 {
    padding-left: var(--space-6) !important; }
  .xl-px6 {
    padding-right: var(--space-6) !important; }
  .xl-py6 {
    padding-top: var(--space-6) !important;
    padding-bottom: var(--space-6) !important; }
  .xl-p7 {
    padding: var(--space-7) !important; }
  .xl-pt7 {
    padding-top: var(--space-7) !important; }
  .xl-pr7 {
    padding-right: var(--space-7) !important; }
  .xl-pb7 {
    padding-bottom: var(--space-7) !important; }
  .xl-pl7,
  .xl-px7 {
    padding-left: var(--space-7) !important; }
  .xl-px7 {
    padding-right: var(--space-7) !important; }
  .xl-py7 {
    padding-top: var(--space-7) !important;
    padding-bottom: var(--space-7) !important; }
  .xl-p8 {
    padding: var(--space-8) !important; }
  .xl-pt8 {
    padding-top: var(--space-8) !important; }
  .xl-pr8 {
    padding-right: var(--space-8) !important; }
  .xl-pb8 {
    padding-bottom: var(--space-8) !important; }
  .xl-pl8,
  .xl-px8 {
    padding-left: var(--space-8) !important; }
  .xl-px8 {
    padding-right: var(--space-8) !important; }
  .xl-py8 {
    padding-top: var(--space-8) !important;
    padding-bottom: var(--space-8) !important; }
  .xl-p9 {
    padding: var(--space-9) !important; }
  .xl-pt9 {
    padding-top: var(--space-9) !important; }
  .xl-pr9 {
    padding-right: var(--space-9) !important; }
  .xl-pb9 {
    padding-bottom: var(--space-9) !important; }
  .xl-pl9,
  .xl-px9 {
    padding-left: var(--space-9) !important; }
  .xl-px9 {
    padding-right: var(--space-9) !important; }
  .xl-py9 {
    padding-top: var(--space-9) !important;
    padding-bottom: var(--space-9) !important; } }

@media (min-width: var(--breakpointXsl)) {
  .xxl-p0 {
    padding: 0 !important; }
  .xxl-pt0 {
    padding-top: 0 !important; }
  .xxl-pr0 {
    padding-right: 0 !important; }
  .xxl-pb0 {
    padding-bottom: 0 !important; }
  .xxl-pl0,
  .xxl-px0 {
    padding-left: 0 !important; }
  .xxl-px0 {
    padding-right: 0 !important; }
  .xxl-py0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xxl-p1 {
    padding: var(--space-1) !important; }
  .xxl-pt1 {
    padding-top: var(--space-1) !important; }
  .xxl-pr1 {
    padding-right: var(--space-1) !important; }
  .xxl-pb1 {
    padding-bottom: var(--space-1) !important; }
  .xxl-pl1,
  .xxl-px1 {
    padding-left: var(--space-1) !important; }
  .xxl-px1 {
    padding-right: var(--space-1) !important; }
  .xxl-py1 {
    padding-top: var(--space-1) !important;
    padding-bottom: var(--space-1) !important; }
  .xxl-p2 {
    padding: var(--space-2) !important; }
  .xxl-pt2 {
    padding-top: var(--space-2) !important; }
  .xxl-pr2 {
    padding-right: var(--space-2) !important; }
  .xxl-pb2 {
    padding-bottom: var(--space-2) !important; }
  .xxl-pl2,
  .xxl-px2 {
    padding-left: var(--space-2) !important; }
  .xxl-px2 {
    padding-right: var(--space-2) !important; }
  .xxl-py2 {
    padding-top: var(--space-2) !important;
    padding-bottom: var(--space-2) !important; }
  .xxl-p3 {
    padding: 1.6rem !important; }
  .xxl-pt3 {
    padding-top: var(--space-3) !important; }
  .xxl-pr3 {
    padding-right: var(--space-3) !important; }
  .xxl-pb3 {
    padding-bottom: var(--space-3) !important; }
  .xxl-pl3,
  .xxl-px3 {
    padding-left: var(--space-3) !important; }
  .xxl-px3 {
    padding-right: var(--space-3) !important; }
  .xxl-py3 {
    padding-top: var(--space-3) !important;
    padding-bottom: var(--space-3) !important; }
  .xxl-p4 {
    padding: var(--space-4) !important; }
  .xxl-pt4 {
    padding-top: var(--space-4) !important; }
  .xxl-pr4 {
    padding-right: var(--space-4) !important; }
  .xxl-pb4 {
    padding-bottom: var(--space-4) !important; }
  .xxl-pl4,
  .xxl-px4 {
    padding-left: var(--space-4) !important; }
  .xxl-px4 {
    padding-right: var(--space-4) !important; }
  .xxl-py4 {
    padding-top: var(--space-4) !important;
    padding-bottom: var(--space-4) !important; }
  .xxl-p5 {
    padding: var(--space-5) !important; }
  .xxl-pt5 {
    padding-top: var(--space-5) !important; }
  .xxl-pr5 {
    padding-right: var(--space-5) !important; }
  .xxl-pb5 {
    padding-bottom: var(--space-5) !important; }
  .xxl-pl5,
  .xxl-px5 {
    padding-left: var(--space-5) !important; }
  .xxl-px5 {
    padding-right: var(--space-5) !important; }
  .xxl-py5 {
    padding-top: var(--space-5) !important;
    padding-bottom: var(--space-5) !important; }
  .xxl-p6 {
    padding: var(--space-6) !important; }
  .xxl-pt6 {
    padding-top: var(--space-6) !important; }
  .xxl-pr6 {
    padding-right: var(--space-6) !important; }
  .xxl-pb6 {
    padding-bottom: var(--space-6) !important; }
  .xxl-pl6,
  .xxl-px6 {
    padding-left: var(--space-6) !important; }
  .xxl-px6 {
    padding-right: var(--space-6) !important; }
  .xxl-py6 {
    padding-top: var(--space-6) !important;
    padding-bottom: var(--space-6) !important; }
  .xxl-p7 {
    padding: var(--space-7) !important; }
  .xxl-pt7 {
    padding-top: var(--space-7) !important; }
  .xxl-pr7 {
    padding-right: var(--space-7) !important; }
  .xxl-pb7 {
    padding-bottom: var(--space-7) !important; }
  .xxl-pl7,
  .xxl-px7 {
    padding-left: var(--space-7) !important; }
  .xxl-px7 {
    padding-right: var(--space-7) !important; }
  .xxl-py7 {
    padding-top: var(--space-7) !important;
    padding-bottom: var(--space-7) !important; }
  .xxl-p8 {
    padding: var(--space-8) !important; }
  .xxl-pt8 {
    padding-top: var(--space-8) !important; }
  .xxl-pr8 {
    padding-right: var(--space-8) !important; }
  .xxl-pb8 {
    padding-bottom: var(--space-8) !important; }
  .xxl-pl8,
  .xxl-px8 {
    padding-left: var(--space-8) !important; }
  .xxl-px8 {
    padding-right: var(--space-8) !important; }
  .xxl-py8 {
    padding-top: var(--space-8) !important;
    padding-bottom: var(--space-8) !important; }
  .xxl-p9 {
    padding: var(--space-9) !important; }
  .xxl-pt9 {
    padding-top: var(--space-9) !important; }
  .xxl-pr9 {
    padding-right: var(--space-9) !important; }
  .xxl-pb9 {
    padding-bottom: var(--space-9) !important; }
  .xxl-pl9,
  .xxl-px9 {
    padding-left: var(--space-9) !important; }
  .xxl-px9 {
    padding-right: var(--space-9) !important; }
  .xxl-py9 {
    padding-top: var(--space-9) !important;
    padding-bottom: var(--space-9) !important; } }

.relative {
  position: relative !important; }

.absolute {
  position: absolute !important; }

.fixed {
  position: fixed !important; }

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.absoluteCenter-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.absoluteCenter-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.absoluteSpread {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.size1of12 {
  width: 8.33333%; }

.size2of12 {
  width: 16.66667%; }

.size3of12 {
  width: 25%; }

.size4of12 {
  width: 33.33333%; }

.size5of12 {
  width: 41.66667%; }

.size6of12 {
  width: 50%; }

.size7of12 {
  width: 58.33333%; }

.size8of12 {
  width: 66.66667%; }

.size9of12 {
  width: 75%; }

.size10of12 {
  width: 83.33333%; }

.size11of12 {
  width: 91.66667%; }

.size12of12 {
  width: 100%; }

.size1of5 {
  width: 20%; }

.size2of5 {
  width: 40%; }

.size3of5 {
  width: 60%; }

.size4of5 {
  width: 80%; }

.size5of5 {
  width: 100%; }

.size-auto {
  width: auto; }

.height-100 {
  height: 100% !important; }

@media (min-width: var(--breakpointSm)) {
  .sm-size1of12 {
    width: 8.33333%; }
  .sm-size2of12 {
    width: 16.66667%; }
  .sm-size3of12 {
    width: 25%; }
  .sm-size4of12 {
    width: 33.33333%; }
  .sm-size5of12 {
    width: 41.66667%; }
  .sm-size6of12 {
    width: 50%; }
  .sm-size7of12 {
    width: 58.33333%; }
  .sm-size8of12 {
    width: 66.66667%; }
  .sm-size9of12 {
    width: 75%; }
  .sm-size10of12 {
    width: 83.33333%; }
  .sm-size11of12 {
    width: 91.66667%; }
  .sm-size12of12 {
    width: 100%; }
  .sm-size1of5 {
    width: 20%; }
  .sm-size2of5 {
    width: 40%; }
  .sm-size3of5 {
    width: 60%; }
  .sm-size4of5 {
    width: 80%; }
  .sm-size5of5 {
    width: 100%; }
  .sm-size-auto {
    width: auto; } }

@media (min-width: var(--breakpointMd)) {
  .md-size1of12 {
    width: 8.33333%; }
  .md-size2of12 {
    width: 16.66667%; }
  .md-size3of12 {
    width: 25%; }
  .md-size4of12 {
    width: 33.33333%; }
  .md-size5of12 {
    width: 41.66667%; }
  .md-size6of12 {
    width: 50%; }
  .md-size7of12 {
    width: 58.33333%; }
  .md-size8of12 {
    width: 66.66667%; }
  .md-size9of12 {
    width: 75%; }
  .md-size10of12 {
    width: 83.33333%; }
  .md-size11of12 {
    width: 91.66667%; }
  .md-size12of12 {
    width: 100%; }
  .md-size1of5 {
    width: 20%; }
  .md-size2of5 {
    width: 40%; }
  .md-size3of5 {
    width: 60%; }
  .md-size4of5 {
    width: 80%; }
  .md-size5of5 {
    width: 100%; }
  .md-size-auto {
    width: auto; } }

@media (min-width: var(--breakpointLg)) {
  .lg-size1of12 {
    width: 8.33333%; }
  .lg-size2of12 {
    width: 16.66667%; }
  .lg-size3of12 {
    width: 25%; }
  .lg-size4of12 {
    width: 33.33333%; }
  .lg-size5of12 {
    width: 41.66667%; }
  .lg-size6of12 {
    width: 50%; }
  .lg-size7of12 {
    width: 58.33333%; }
  .lg-size8of12 {
    width: 66.66667%; }
  .lg-size9of12 {
    width: 75%; }
  .lg-size10of12 {
    width: 83.33333%; }
  .lg-size11of12 {
    width: 91.66667%; }
  .lg-size12of12 {
    width: 100%; }
  .lg-size1of5 {
    width: 20%; }
  .lg-size2of5 {
    width: 40%; }
  .lg-size3of5 {
    width: 60%; }
  .lg-size4of5 {
    width: 80%; }
  .lg-size5of5 {
    width: 100%; }
  .lg-size-auto {
    width: auto; } }

@media (min-width: var(--breakpointXl)) {
  .xl-size1of12 {
    width: 8.33333%; }
  .xl-size2of12 {
    width: 16.66667%; }
  .xl-size3of12 {
    width: 25%; }
  .xl-size4of12 {
    width: 33.33333%; }
  .xl-size5of12 {
    width: 41.66667%; }
  .xl-size6of12 {
    width: 50%; }
  .xl-size7of12 {
    width: 58.33333%; }
  .xl-size8of12 {
    width: 66.66667%; }
  .xl-size9of12 {
    width: 75%; }
  .xl-size10of12 {
    width: 83.33333%; }
  .xl-size11of12 {
    width: 91.66667%; }
  .xl-size12of12 {
    width: 100%; }
  .xl-size1of5 {
    width: 20%; }
  .xl-size2of5 {
    width: 40%; }
  .xl-size3of5 {
    width: 60%; }
  .xl-size4of5 {
    width: 80%; }
  .xl-size5of5 {
    width: 100%; }
  .xl-size-auto {
    width: auto; } }

@media (min-width: var(--breakpointXsl)) {
  .xxl-size1of12 {
    width: 8.33333%; }
  .xxl-size2of12 {
    width: 16.66667%; }
  .xxl-size3of12 {
    width: 25%; }
  .xxl-size4of12 {
    width: 33.33333%; }
  .xxl-size5of12 {
    width: 41.66667%; }
  .xxl-size6of12 {
    width: 50%; }
  .xxl-size7of12 {
    width: 58.33333%; }
  .xxl-size8of12 {
    width: 66.66667%; }
  .xxl-size9of12 {
    width: 75%; }
  .xxl-size10of12 {
    width: 83.33333%; }
  .xxl-size11of12 {
    width: 91.66667%; }
  .xxl-size12of12 {
    width: 100%; }
  .xxl-size1of5 {
    width: 20%; }
  .xxl-size2of5 {
    width: 40%; }
  .xxl-size3of5 {
    width: 60%; }
  .xxl-size4of5 {
    width: 80%; }
  .xxl-size5of5 {
    width: 100%; }
  .xxl-size-auto {
    width: auto; } }

.text-xxs {
  font-size: 1.2rem !important; }

.text-xs {
  font-size: 1.3rem !important; }

.text-sm {
  font-size: 1.4rem !important; }

.text-md {
  font-size: 1.7rem !important; }

.text-lg {
  font-size: 1.9rem !important; }

.text-xl {
  font-size: 2.2rem !important; }

.text-xxl,
.text-xxxl {
  font-size: 2.6rem !important; }

.text-bold {
  font-weight: 700 !important; }

.text-bold-menu {
  line-height: 3.7rem !important; }

.text-upper {
  text-transform: uppercase !important;
  letter-spacing: 0.1em !important; }

.text-underline {
  text-decoration: underline !important; }

.text-noUnderline {
  text-decoration: none !important; }

.whiteSpace-normal {
  white-space: normal !important; }

.whiteSpace-noWrap {
  white-space: nowrap !important; }

.whiteSpace-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.letterSpacing-loose {
  letter-spacing: 0.05em !important; }

.letterSpacing-looser {
  letter-spacing: 0.1em !important; }

.hiddenVisually {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; }

.invisible {
  visibility: hidden !important; }

.visible {
  visibility: visible !important; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button {
  background: transparent;
  border: 0;
  padding: 0;
  border-radius: 0; }

button[disabled],
html input[disabled] {
  cursor: not-allowed; }

.sb-button {
  display: inline-block;
  background: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 50px;
  padding: 7px 16px;
  font-size: 1.4rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  font-family: SoDoSans, sans-serif;
  font-weight: 600;
  letter-spacing: -0.014rem; }

.sb-button--default {
  border-color: #008248;
  color: #008248; }

.sb-button--positive {
  background-color: #008248;
  border-color: #008248;
  color: #fff; }

.sb-button--positive:active {
  transform: scale(0.95);
  transition: all 0.2s ease; }

.sb-button--default:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.1); }

body:not([data-focus-source="keyboard"]) :focus {
  outline: none; }

.sb-button--default:active {
  transform: scale(0.95);
  transition: all 0.2s ease; }

.sb-button--disabled {
  cursor: not-allowed;
  opacity: 0.5; }

.sb-button--text {
  border-color: transparent;
  color: #008248; }

.sb-button--large {
  padding: 1.6rem 3.2rem;
  border-radius: 2px; }

.sb-textLink {
  text-align: inherit;
  text-decoration: underline; }

.sb-frap {
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 18px 2.4rem;
  background: #00a862;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.24), 0 8px 12px rgba(0, 0, 0, 0.14);
  border-radius: 500px;
  color: #fff;
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.2;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-property: transform, box-shadow, width, height;
  transition-timing-function: ease; }

.sb-frap:not(.sb-frap--disabled):not(.sb-frap--loading):active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.24), 0 8px 12px transparent;
  transform: translateY(4px); }

.sb-frap--mini {
  padding: 10px 1.6rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 17px;
  font-size: 1.7rem; }

.sb-frap--mini:before {
  content: "";
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  bottom: -0.8rem;
  left: -0.8rem;
  z-index: -1; }

@keyframes ripple {
  0% {
    opacity: 0.5;
    transform: scale(1); }
  to {
    opacity: 0;
    transform: scale(50); } }

.sb-frap--disabled .ripple {
  display: none !important; }

.sb-ripple {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  animation-name: ripple;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  background-color: white; }

.sb-frap--mini.sb-frap--disabled {
  background: #ccc;
  color: rgba(0, 0, 0, 0.56);
  cursor: not-allowed; }

.sb-frap--disabled {
  background: #ccc;
  cursor: not-allowed; }

.sb-frap--loading {
  width: 5.6rem;
  height: 5.6rem;
  padding: 0; }

.sb-frap--loadingCircle {
  margin: auto;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border: 0.3rem solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid #fff;
  border-right: 0.3rem solid #fff;
  width: 75%;
  height: 75%;
  border-radius: 500px;
  animation: spin 0.75s linear infinite, fade 0.1s; }

.sb-button--default {
  border-color: #008248;
  color: #008248; }

.sb-button--default:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.1); }

.sb-button--default:active {
  transform: scale(0.95);
  transition: all 0.2s ease; }

.sb-button--default.sb-button--dark,
.sb-button--default.sb-button--rewardsGreen {
  border-color: #00a862;
  color: #00a862; }

.sb-button--default.sb-button--dark:not(.sb-button--disabled):hover,
.sb-button--default.sb-button--rewardsGreen:not(.sb-button--disabled):hover {
  border-color: #008248;
  background-color: #008248;
  color: #fff; }

.sb-button--default.sb-button--rewardsGold {
  border-color: #c2a661;
  color: #c2a661; }

.sb-button--default.sb-button--rewardsGold:not(.sb-button--disabled):hover {
  background-color: #c2a661;
  border-color: #c2a661;
  color: rgba(0, 0, 0, 0.87); }

.sb-button--positive {
  background-color: #008248;
  border-color: #008248;
  color: #fff; }

.sb-button--positive:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.9);
  border-color: rgba(0, 168, 98, 0.9); }

.sb-button--positive:active {
  transform: scale(0.95);
  transition: all 0.2s ease; }

.sb-button--positive.sb-button--rewardsGold {
  color: rgba(0, 0, 0, 0.87); }

.sb-button--positive.sb-button--rewardsGold,
.sb-button--positive.sb-button--rewardsGold:not(.sb-button--disabled):hover {
  background-color: #c2a661;
  border-color: #c2a661; }

[data-focus-source="keyboard"] .sb-button--positive:focus {
  background-color: #3d3935; }

.sb-button--text {
  border-color: transparent;
  color: #008248; }

.sb-button--text:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.1); }

.sb-button--text.sb-button:active {
  transform: scale(0.95);
  transition: all 0.2s ease; }

.sb-button--text.sb-button--dark,
.sb-button--text.sb-button--rewardsGreen {
  color: #00a862; }

.sb-button--text.sb-button--rewardsGold {
  color: #c2a661; }

.sb-button--text.sb-button--dark:not(.sb-button--disabled):hover,
.sb-button--text.sb-button--rewardsGold:not(.sb-button--disabled):hover,
.sb-button--text.sb-button--rewardsGreen:not(.sb-button--disabled):hover {
  color: #fff;
  background-color: transparent; }

.sb-button--large {
  padding: 1.6rem 3.2rem;
  border-radius: 2px; }

.sb-button--disabled {
  cursor: not-allowed;
  opacity: 0.5; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(270deg); }
  to {
    transform: rotate(1turn); } }

.sb-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  overflow: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.sb-card__image {
  display: block;
  max-width: 100%;
  min-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center; }

.sb-card--imageLeft .sb-card__content,
.sb-card--imageRight .sb-card__content {
  overflow: hidden; }

.sb-card--imageSmall.sb-card--imageLeft {
  -ms-flex-direction: row;
  flex-direction: row; }

.sb-card--imageSmall.sb-card--imageRight {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

@media (min-width: 480px) {
  .sb-card__imageBlockLeft,
  .sb-card__imageBlockRight {
    width: 50%;
    max-width: 300px; } }

.sb-card__imageBlockSmall {
  max-width: 25%;
  min-width: 90px; }

@media (min-width: 768px) {
  .sb-card--imageLeft {
    -ms-flex-direction: row;
    flex-direction: row; }
  .sb-card--imageRight {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .sb-card__imageBlockSmall {
    max-width: 35%; } }

.sb-headerCrate {
  position: relative;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }

.fieldStatus {
  display: block;
  font-size: 13px;
  font-size: 1.3rem;
  position: relative;
  transition: color .2s ease-out; }

.fieldStatus--error .fieldStatus__icon {
  color: #e75b52; }

.fieldStatus--valid .fieldStatus__icon {
  color: #00653e; }

.fieldStatus--indeterminate:before {
  content: "\2022";
  position: absolute;
  left: 3px;
  top: 1px; }

.fieldStatus__icon {
  position: absolute;
  top: -2px;
  left: -4px;
  animation: .2s ease-out field-status-icon-fade-in forwards; }

.fieldStatus__text {
  display: inline-block;
  font-size: 13px;
  font-size: 1.3rem;
  padding-left: 20px;
  vertical-align: middle; }

@keyframes float-label-appear {
  0% {
    font-size: 1.4rem;
    opacity: 1;
    top: 30px; }
  50% {
    font-size: 1.3rem;
    opacity: 0;
    top: 30px; }
  51% {
    font-size: 1.3rem;
    opacity: 0;
    top: 10px; }
  to {
    font-size: 1.3rem;
    opacity: 1;
    top: 10px; } }

.floatLabel {
  cursor: text;
  font-size: 1.4rem;
  position: absolute;
  top: 30px;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0; }

.floatLabel--isActive {
  animation: .3s float-label-appear ease-in-out forwards; }

.field {
  display: block;
  position: relative; }

.fieldInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.9);
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 10px;
  line-height: 1rem;
  padding: 32px 0 6px;
  width: 100%;
  z-index: 1; }

.field--dark .fieldInput {
  color: #fff; }

.fieldInput::-ms-clear,
.fieldInput::-ms-reveal {
  display: none; }

.field__inputWrapper {
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.field--dark .field__inputWrapper {
  border-bottom-color: rgba(255, 255, 255, 0.2); }

.fieldInput:focus {
  outline: none !important; }

.field--isFocused .field__inputWrapper {
  border-color: #00a862; }

.field--isFocused.field--rewardsGold .field__inputWrapper {
  border-color: #c2a661; }

.field--isInvalid.field--rewardsGold .field__inputWrapper,
.field--isInvalid .field__inputWrapper {
  border-color: #e75b52; }

.field__warningIcon {
  color: #e75b52; }

.sb-editField {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.sb-editField__list {
  font-size: 1.6rem;
  line-height: 1.4;
  padding-top: 32px;
  word-wrap: break-word;
  margin: 0; }

.sb-editField__item {
  padding-bottom: 6px; }

.sb-editField__button {
  padding-bottom: 6px;
  font-size: 1.6rem;
  line-height: 1.4; }

.sb-editField__label {
  top: 6px;
  z-index: 1; }

.select {
  display: block;
  position: relative;
  width: 100%; }

.select__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1; }

.select__input,
.select__selectedText {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%; }

.select__selectedText {
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  line-height: 1.25;
  padding-top: 32px;
  padding-bottom: 6px; }

.select__floatLabel {
  pointer-events: none; }

.select__selectedText--noLabel {
  padding-top: 10px;
  padding-bottom: 10px; }

.select__input:focus + .select__selectedText {
  outline: none !important;
  border-color: #00a862; }

.select__selectedText--isInvalid {
  border-color: #e75b52 !important; }

.select__icon {
  color: rgba(0, 0, 0, 0.56);
  right: 0;
  position: absolute;
  bottom: 8px; }

@keyframes option-label-marker-expansion {
  0% {
    transform: scale(0); }
  to {
    transform: scale(1); } }

.option__label {
  cursor: pointer;
  display: block;
  padding: 10px 0;
  position: relative; }

[data-focus-source=keyboard] .option__input:focus + .option__label {
  border-color: transparent;
  outline: 2px solid #008248; }

.option__labelMarker {
  border: 1px solid #008248;
  color: transparent; }

.option__input:checked + .option__label .option__labelMarker {
  background-color: #008248;
  border-color: #008248;
  color: #fff; }

.option--checkbox .option__labelMarker {
  border-radius: 25%; }

.option--radio .option__labelMarker {
  border-radius: 50%; }

.option__labelIcon {
  margin: auto; }

.option__radioIcon {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid #008248;
  position: relative; }

.option__input:checked + .option__label .option__radioIcon:before {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  content: "";
  border-radius: 50%;
  background-color: #008248; }

.option__input:checked + .option__label .option__labelIcon,
.option__input:checked + .option__label .option__radioIcon:before {
  animation: 0.3s option-label-marker-expansion cubic-bezier(0.32, 2.32, 0.61, 0.27) forwards; }

.option__labelText {
  padding-top: 2px;
  padding-left: 10px;
  font-size: 14px;
  font-size: 1.4rem;
  min-width: 0; }

.sb-toggle {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px; }

.sb-toggle__input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer; }

.sb-toggle__slider {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  transition: background-color .4s ease-out; }

.sb-toggle__slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  transition: transform .4s ease-out;
  border-radius: 50%; }

.sb-toggle__input:checked + .sb-toggle__slider {
  background-color: #00a862; }

[data-focus-source=keyboard] .sb-toggle__input:focus + .sb-toggle__slider {
  box-shadow: 0 0 0 3px #00a862; }

.sb-toggle__input:checked + .sb-toggle__slider:before {
  transform: translateX(20px); }

.sb-toggle {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px; }

.sb-toggle__input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer; }

.sb-toggle__slider {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  transition: background-color .4s ease-out; }

.sb-toggle__slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  transition: transform .4s ease-out;
  border-radius: 50%; }

.sb-toggle__input:checked + .sb-toggle__slider {
  background-color: #00a862; }

[data-focus-source=keyboard] .sb-toggle__input:focus + .sb-toggle__slider {
  box-shadow: 0 0 0 3px #00a862; }

.sb-toggle__input:checked + .sb-toggle__slider:before {
  transform: translateX(20px); }

.field--dark .fieldInput {
  color: #fff; }

.fieldInput::-ms-clear,
.fieldInput::-ms-reveal {
  display: none; }

.field__inputWrapper {
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.field--dark .field__inputWrapper {
  border-bottom-color: rgba(255, 255, 255, 0.2); }

.sb-headerCrate {
  position: relative;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }

.sb-heading {
  font-weight: 400; }

.sb-heading-xl {
  font-size: 3.1rem;
  line-height: 1.29; }

.sb-heading-lg {
  font-size: 2.6rem;
  line-height: 1.385; }

.sb-heading-md {
  font-size: 2.2rem;
  line-height: 1.455; }

.sb-heading-sm {
  font-size: 1.9rem;
  line-height: 1.263; }

.sb-heading-xs {
  font-size: 1.7rem;
  line-height: 1.411; }

.sb-heading-xxs {
  font-size: 1.2rem;
  line-height: 1.229; }

.sb-animator-fade-appear,
.sb-animator-fade-enter,
.sb-animator-fade-leave.sb-animator-fade-leave-active {
  opacity: 0 !important;
  transform: scale(0.75) !important;
  transition-timing-function: ease !important;
  transition-property: transform, opacity !important; }

.sb-animator-fade-appear.sb-animator-fade-appear-active,
.sb-animator-fade-enter.sb-animator-fade-enter-active,
.sb-animator-fade-leave {
  opacity: 1 !important;
  transform: scale(1) !important; }

.sb-animator-fade-appear-active,
.sb-animator-fade-enter-active {
  transition-delay: 0.1s !important;
  transition-duration: 0.2s !important; }

.sb-animator-fade-leave-active {
  transition-delay: 0ms !important;
  transition-duration: 0.1s !important; }

.sb-animator-grow-appear,
.sb-animator-grow-enter,
.sb-animator-grow-leave.sb-animator-grow-leave-active {
  opacity: 0 !important;
  transform: scale(0.3) !important;
  transition-timing-function: ease !important;
  transition-property: transform, opacity !important; }

.sb-animator-grow-appear.sb-animator-grow-appear-active,
.sb-animator-grow-enter.sb-animator-grow-enter-active,
.sb-animator-grow-leave {
  opacity: 1 !important;
  transform: scale(1) !important; }

.sb-expander--isAnimating {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.sb-expander__pushdownContent {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0; }

.sb-animator-grow-appear-active,
.sb-animator-grow-enter-active {
  transition-delay: 0.1s !important;
  transition-duration: 0.2s !important; }

.sb-animator-grow-leave-active {
  transition-delay: 0ms !important;
  transition-duration: 0.1s !important; }

.sb-navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 74px; }

.sb-navbar-siren {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px; }

.sb-navbar-button {
  width: 44px; }

.sb-navbar-icon {
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  margin: auto; }

@media (min-width: 375px) {
  .sb-navbar-siren {
    width: 40px;
    height: 40px; } }

@media (min-width: 768px) {
  .sb-navbar-siren {
    width: 50px;
    height: 50px; } }

@media (min-width: 1024px) {
  .sb-navbar {
    position: absolute;
    width: 100%;
    height: 106px; } }

.sb-subnav-activeLink {
  box-shadow: inset 0 -3px #00a862; }

.sb-subnav-heading {
  word-wrap: break-word; }

.sb-subnav-listLink {
  padding-bottom: 6px; }

@media (max-width: 1023px) {
  .sb-subnav-list {
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.6rem;
    padding-right: .8rem;
    padding-left: .8rem;
    margin-right: -1.6rem;
    margin-left: -1.6rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: auto; }
  .sb-subnav-listItem {
    padding-right: 1.6rem; }
  .sb-subnav-listLink {
    display: block;
    padding: .4rem .8rem .8rem; } }

@media (min-width: 1024px) {
  .sb-subnav-listItem {
    margin-top: 1.6rem; }
    .sb-subnav-listItem a {
      cursor: pointer; }
  .sb-subnav-breadcrumbIcon {
    position: absolute;
    top: 0;
    right: 100%; } }

body {
  height: 100%;
  margin: 0;
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -.014rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.globalCursor {
  cursor: pointer; }

.sb-rule {
  border: 0;
  margin: 0; }

.sb-rule--thin:before {
  border-top-width: 1px !important; }

.sb-rule:before {
  content: "";
  display: block;
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.hardDisplayNone {
  display: none !important; }

.sb-loadingCoffeeCup {
  height: 85px;
  width: 73px;
  opacity: 0.7;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.sb-loadingCoffeeSteam {
  opacity: 0.4;
  stroke-dasharray: 25;
  -webkit-animation: dash 2.4s infinite linear;
  animation: dash 2.4s infinite linear; }

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 25; }
  to {
    stroke-dashoffset: -25; } }

@keyframes dash {
  from {
    stroke-dashoffset: 25; }
  to {
    stroke-dashoffset: -25; } }

.gray-deactive-bg-color {
  background-color: #8e8e8e !important;
  cursor: not-allowed !important; }

.whiteBtn {
  color: black !important;
  background-color: none !important;
  border-color: black; }
  .whiteBtn:hover {
    background-color: rgba(156, 156, 156, 0.1) !important; }

.blackBtn {
  color: white !important;
  background-color: black !important;
  border-color: black; }
  .blackBtn:hover {
    background-color: rgba(0, 0, 0, 0.7) !important; }

.globalNav_ {
  position: relative;
  z-index: 2; }
  .globalNav_ .sb-globalNav__logo {
    flex-shrink: 0;
    width: 32px;
    height: 32px; }
  .globalNav_ .sb-globalNav__links {
    display: flex;
    align-items: center;
    flex-grow: 1; }
    .globalNav_ .sb-globalNav__links ul {
      margin-bottom: 0; }
      .globalNav_ .sb-globalNav__links ul li a {
        color: currentColor;
        cursor: pointer;
        letter-spacing: 0.13rem !important;
        line-height: 2.1rem; }
      .globalNav_ .sb-globalNav__links ul li .text-xxs {
        font-size: 1.3rem !important; }
      .globalNav_ .sb-globalNav__links ul li .text-bold {
        font-weight: 700 !important; }
    .globalNav_ .sb-globalNav__links a {
      cursor: pointer; }
  .globalNav_ .sb-globalNav__menuButton {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 44px; }
  .globalNav_ .right-links .inbox-cap {
    display: inline-block;
    position: relative; }
    .globalNav_ .right-links .inbox-cap .count-inbox {
      position: absolute;
      display: inline-block;
      padding: 2px 6px;
      border-radius: 12px;
      background-color: #008247;
      font-size: 10px;
      font-weight: 800;
      text-align: center;
      color: #ffffff;
      top: -6px;
      right: 0px;
      height: 15px;
      width: 15px; }
  .globalNav_ .right-links .findStore {
    color: black;
    font-weight: 600 !important;
    text-decoration: none !important; }
  .globalNav_ .right-links .sb-button--default.sb-button--black,
  .globalNav_ .right-links .sb-button--default.sb-button--rewardsDefault {
    border-color: rgba(0, 0, 0, 0.87);
    color: rgba(0, 0, 0, 0.87); }
    .globalNav_ .right-links .sb-button--default.sb-button--black:hover,
    .globalNav_ .right-links .sb-button--default.sb-button--rewardsDefault:hover {
      background-color: rgba(0, 0, 0, 0.06); }
  .globalNav_ .right-links .sb-button--positive.sb-button--black,
  .globalNav_ .right-links .sb-button--positive.sb-button--rewardsDefault {
    background-color: #000;
    border-color: #000; }
  .globalNav_ .right-links .sb-button--positive.sb-button--black:not(.sb-button--disabled):not(.sb-button--nonInteractive):not(
  .sb-button--loading
):hover,
  .globalNav_ .right-links .sb-button--positive.sb-button--rewardsDefault:not(.sb-button--disabled):not(.sb-button--nonInteractive):not(
  .sb-button--loading
):hover {
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-decoration: none; }

.sb-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .sb-mobile-menu .sb-overlay__container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    .sb-mobile-menu .sb-overlay__container .sb-overlay__content {
      min-height: 100%; }
      .sb-mobile-menu .sb-overlay__container .sb-overlay__content .sb-overlay__closeBtnContainer {
        height: 44px;
        margin: 1.6rem; }
        .sb-mobile-menu .sb-overlay__container .sb-overlay__content .sb-overlay__closeBtnContainer .sb-overlay__closeBtn {
          right: 0;
          transition: all 0.2s ease-out;
          min-width: 44px;
          min-height: 44px; }
      .sb-mobile-menu .sb-overlay__container .sb-overlay__content .sbLoginBtn {
        color: rgba(0, 0, 0, 0.87);
        border-color: rgba(0, 0, 0, 0.87); }
        .sb-mobile-menu .sb-overlay__container .sb-overlay__content .sbLoginBtn:hover {
          background-color: rgba(0, 0, 0, 0.1); }
      .sb-mobile-menu .sb-overlay__container .sb-overlay__content .sbRegisterBtn {
        background-color: rgba(0, 0, 0, 0.87);
        border-color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1024px) {
  .globalNav_ {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%; }
    .globalNav_ .lg-py4 {
      padding-top: 2.4rem !important;
      padding-bottom: 2.4rem !important; }
    .globalNav_ .lg-px7 {
      padding-right: 4.8rem !important; }
    .globalNav_ .lg-pl7,
    .globalNav_ .lg-px7 {
      padding-left: 4.8rem !important; }
    .globalNav_ .sb-globalNav__logo {
      width: 50px !important;
      height: 50px !important; }
  .sb-globalNav {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 2px 2px 0 rgba(0, 0, 0, 0.12); } }

@media (min-width: 375px) {
  .globalNav_ .sb-globalNav__logo {
    width: 40px;
    height: 40px; } }

@media (min-width: 768px) {
  .md-pl4,
  .md-px4 {
    padding-left: 1.2rem !important; }
  .globalNav_ .sb-globalNav__menuButton {
    display: none !important; } }

@media (max-width: 768px) {
  .globalNav_ .sb-globalNav__links {
    display: none !important; } }

.sb-small-footer .container-footer {
  display: -webkit-box;
  display: flex; }
  .sb-small-footer .container-footer .find-store .langBtn {
    margin-left: 0 !important; }
  .sb-small-footer .container-footer .find-store .storeBtn {
    margin-left: 0 !important; }
  .sb-small-footer .container-footer .find-store a {
    text-decoration: none; }
    .sb-small-footer .container-footer .find-store a:hover {
      color: #008248; }
  .sb-small-footer .container-footer .footer-links a {
    color: currentColor; }

.sb-small-footer .container-footer > * {
  padding: 0 6.4rem 0 0; }

@media (max-width: 768px) {
  .sb-small-footer .container-footer > * {
    padding: 0 10px 0 0 !important; } }

.sb-new-footer {
  background: #fff;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 50px; }
  .sb-new-footer .foot-top .menu-list .menu-item {
    padding: 40px 0; }
    .sb-new-footer .foot-top .menu-list .menu-item .menu-head {
      cursor: pointer; }
      .sb-new-footer .foot-top .menu-list .menu-item .menu-head .left {
        display: inline-block;
        width: calc(100% - 50px);
        vertical-align: middle; }
        .sb-new-footer .foot-top .menu-list .menu-item .menu-head .left .title {
          font-size: 2.3rem;
          line-height: 3.1rem;
          letter-spacing: -.015rem;
          font-weight: 400; }
      .sb-new-footer .foot-top .menu-list .menu-item .menu-head .right {
        display: inline-block;
        width: 50px;
        text-align: left;
        vertical-align: middle; }
        .sb-new-footer .foot-top .menu-list .menu-item .menu-head .right .active-arrow {
          transform: rotate(180deg); }
        .sb-new-footer .foot-top .menu-list .menu-item .menu-head .right .icon-wrapper {
          padding: 0.8rem;
          border-radius: 100%;
          height: 4rem;
          width: 4rem;
          transition: white 0.2s cubic-bezier(0.19, 1, 0.22, 1);
          cursor: pointer; }
          .sb-new-footer .foot-top .menu-list .menu-item .menu-head .right .icon-wrapper:hover {
            background: #edebe9; }
    .sb-new-footer .foot-top .menu-list .menu-item .sub-menu {
      display: none; }
      .sb-new-footer .foot-top .menu-list .menu-item .sub-menu .sub-item {
        display: block;
        padding: 20px 0;
        color: #6c6c6c;
        font-weight: 600;
        line-height: 1.2;
        font-size: 1.4rem;
        letter-spacing: -.014rem;
        cursor: pointer; }
  .sb-new-footer .ui-divider {
    border: 0.1rem solid #ccc; }
  .sb-new-footer .foot-bottom {
    padding-top: 20px; }
    .sb-new-footer .foot-bottom .secondary-links .link-item {
      display: block;
      color: #1f1f1f;
      font-size: 1.4rem;
      letter-spacing: -.014rem;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 3.2rem;
      cursor: pointer; }

.sb-cont .sb-global-subnav-containerLight {
  position: relative;
  z-index: inherit;
  width: 100%;
  border-bottom: 1px solid #dbd9d7;
  border: 1px solid #edebe9;
  background-color: #f7f7f7; }
  .sb-cont .sb-global-subnav-containerLight .sb-global-subnav-logoAlign .sb-global-subnav-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: auto;
    margin-bottom: 0px !important; }
    .sb-cont .sb-global-subnav-containerLight .sb-global-subnav-logoAlign .sb-global-subnav-list .sb-global-subnav-listItem {
      padding-right: 1.6rem; }
      .sb-cont .sb-global-subnav-containerLight .sb-global-subnav-logoAlign .sb-global-subnav-list .sb-global-subnav-listItem .text-semibold {
        font-weight: 600 !important; }
      .sb-cont .sb-global-subnav-containerLight .sb-global-subnav-logoAlign .sb-global-subnav-list .sb-global-subnav-listItem a {
        display: block;
        padding: .4rem .8rem .8rem;
        font-size: 1.5rem !important;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.56) !important; }
      .sb-cont .sb-global-subnav-containerLight .sb-global-subnav-logoAlign .sb-global-subnav-list .sb-global-subnav-listItem .activeSubnavLink {
        box-shadow: inset 0 -3px #00a862;
        color: black !important; }

.sb-cont .contentInMenu {
  padding-bottom: 74px; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section .text-semibold {
    font-weight: 600 !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section .text-sm {
    font-size: 1.5rem !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section .mt4 {
    margin-top: 2.4rem !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section ul .text-noUnderline {
    text-decoration: none !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section ul .text-sm {
    font-size: 1.5rem !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section ul .color-textBlackSoft {
    color: rgba(0, 0, 0, 0.56) !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section ul .block {
    display: block !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .sideNav___2uqqD section:first-child h4 {
    margin-top: 5px !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .color-textBlackSoft {
    color: rgba(0, 0, 0, 0.56) !important; }
    .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .color-textBlackSoft a {
      color: rgba(0, 0, 0, 0.56) !important;
      font-weight: bold; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .sb-heading {
    font-size: 2.3rem !important;
    margin-bottom: 0px; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .sb-heading {
    font-size: 2rem !important;
    line-height: 1.263 !important;
    margin-top: 0px;
    padding-bottom: 0 !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .sb-rule {
    border: 0;
    margin: 0; }
    .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .sb-rule:before {
      content: "";
      display: block;
      border-top: 2px solid rgba(0, 0, 0, 0.1); }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .sb-rule--thin:before {
    border-top-width: 1px; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .grid {
    display: block;
    margin: 0;
    padding: 0;
    text-align: left; }
    .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .grid .gridItem {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
      vertical-align: top;
      cursor: pointer; }
      .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .grid .gridItem .thumb {
        width: 100%;
        max-width: 72px; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .grid .gridItem .thumb .block {
          max-width: 112px; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .menu-content .grid .gridItem .thumb .imageBlock {
          border-radius: 50%;
          overflow: hidden;
          width: 100%; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item hr {
    margin-top: 0px !important;
    margin-bottom: 0 !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .size6of12 {
    width: 50%; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid {
    margin: 0 -.4rem; }
    .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      text-align: left;
      vertical-align: top; }
      .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA {
        display: block;
        width: 100%; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA .prodTile__body___cybaW, .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA .prodTileImageBlock___3c9FQ {
          display: block;
          vertical-align: top; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA .prodTileImageBlock___3c9FQ {
          max-width: 148px;
          margin: 0px auto; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA .imageBlock___2DfHk {
          z-index: 0;
          width: 100%;
          padding-bottom: 100%;
          border-radius: 50%;
          overflow: hidden; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA img {
          max-width: 100%;
          height: auto; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA .show___VOoMM {
          opacity: 1;
          -webkit-transition: opacity .15s ease-in;
          transition: opacity .15s ease-in; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .prodTile___3fZDA .imagePositioning___1CXPi {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: inherit; }
      .sb-cont .contentInMenu .menuWithSideNav-3HCqB .baseMenu___2sYeE .child-menu-category-item .grid .gridItem .linkOverlay {
        position: relative; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .active-line {
    font-weight: bold;
    display: inline-block !important; }
  .sb-cont .contentInMenu .menuWithSideNav-3HCqB .nutrition-cta {
    flex: 1 1 0;
    overflow: auto;
    padding-right: 9% !important;
    position: absolute;
    margin-top: 10px;
    right: 0;
    padding: 10px; }
    .sb-cont .contentInMenu .menuWithSideNav-3HCqB .nutrition-cta .nutrition-cta-wrapper {
      background-color: white;
      border-radius: 4rem;
      padding: 0 1.6rem;
      box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.24), 0 0rem 1.2rem rgba(0, 0, 0, 0.14);
      transition-duration: .2s;
      transition-property: background-color,transform,box-shadow,color;
      transition-timing-function: ease-in-out;
      padding: 10px 20px;
      cursor: pointer; }
      .sb-cont .contentInMenu .menuWithSideNav-3HCqB .nutrition-cta .nutrition-cta-wrapper svg {
        width: 18px;
        height: 18px; }
      .sb-cont .contentInMenu .menuWithSideNav-3HCqB .nutrition-cta .nutrition-cta-wrapper span {
        display: inline-block;
        font-weight: bold; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .nutrition-cta .nutrition-cta-wrapper span:first-child {
          margin-right: 10px; }
        .sb-cont .contentInMenu .menuWithSideNav-3HCqB .nutrition-cta .nutrition-cta-wrapper span:last-child {
          margin-left: 10px; }
      .sb-cont .contentInMenu .menuWithSideNav-3HCqB .nutrition-cta .nutrition-cta-wrapper:hover {
        background-color: rgba(128, 128, 128, 0.05);
        opacity: 0.8; }

.sb-cont .content_isAnonFavorites {
  padding-bottom: 74px; }
  .sb-cont .content_isAnonFavorites .size12of12 {
    width: 100%; }
  .sb-cont .content_isAnonFavorites .sb-button--default.sb-button--black, .sb-cont .content_isAnonFavorites .sb-button--default.sb-button--rewardsDefault {
    color: rgba(0, 0, 0, 0.87);
    border-color: rgba(0, 0, 0, 0.87); }
    .sb-cont .content_isAnonFavorites .sb-button--default.sb-button--black:hover, .sb-cont .content_isAnonFavorites .sb-button--default.sb-button--rewardsDefault:hover {
      background-color: rgba(0, 0, 0, 0.06); }
  .sb-cont .content_isAnonFavorites .sb-button--positive.sb-button--black, .sb-cont .content_isAnonFavorites .sb-button--positive.sb-button--rewardsDefault {
    background-color: #000;
    border-color: #000; }
    .sb-cont .content_isAnonFavorites .sb-button--positive.sb-button--black:hover, .sb-cont .content_isAnonFavorites .sb-button--positive.sb-button--rewardsDefault:hover {
      background-color: rgba(0, 0, 0, 0.7);
      border-color: rgba(0, 0, 0, 0.7); }

.sb-cont .content_FavoriteList {
  padding: 15px; }
  .sb-cont .content_FavoriteList .wrapper {
    padding: 16px 16px 24px; }
  .sb-cont .content_FavoriteList .favoriteItem {
    margin-bottom: 20px; }
    .sb-cont .content_FavoriteList .favoriteItem .prodImage {
      border-radius: 50%;
      height: 72px;
      width: 72px; }
    .sb-cont .content_FavoriteList .favoriteItem .sb-imageFade__imagePositioning {
      position: absolute;
      top: 0;
      left: 0; }
    .sb-cont .content_FavoriteList .favoriteItem .sb-imageFade__show {
      opacity: 1;
      transition: opacity .15s ease-in; }
    .sb-cont .content_FavoriteList .favoriteItem .productDetails {
      padding: 0 16px 0 96px; }

@media (min-width: 1280px) {
  .baseMenu___2sYeE {
    padding-right: 18% !important; }
  .grid--compactGutter {
    margin: 0 -.8rem; }
  .xl-size3of12 {
    width: 25% !important; } }

@media (min-width: 1024px) {
  .lg-flex {
    display: flex !important; }
  .sideNav___2uqqD {
    flex-basis: 450px; }
  .baseMenu___2sYeE {
    padding-right: 18% !important;
    -webkit-box-flex: 1;
    flex: 1 1 0;
    overflow: auto;
    padding-right: 9% !important; }
  .lg-pb6 {
    padding-bottom: 4rem !important; }
  .lg-pl7, .lg-px7 {
    padding-left: 4.8rem !important; }
  .lg-mb5 {
    margin-bottom: 3.2rem !important; }
  .thumb {
    max-width: 112px !important; }
  .lg-pl7, .lg-px7 {
    padding-left: 4.8rem !important; }
  .sb-cont {
    padding-top: 82px; }
    .sb-cont .globalSubnav___2CFWp {
      position: fixed;
      z-index: 1; }
    .sb-cont .contentInMenu {
      padding-top: 44px; }
    .sb-cont .content_isAnonFavorites {
      padding-top: 44px; }
      .sb-cont .content_isAnonFavorites .lg-size6of12 {
        width: 50% !important; }
    .sb-cont .content_FavoriteList {
      padding-left: 16px !important;
      padding-right: 16px !important;
      max-width: 600px !important; }
  .menuWithSideNav-3HCqB {
    padding-left: 123px !important; } }

@media (min-width: 768px) {
  .sb-global-subnav-logoAlign {
    margin-left: 50px; }
  .md-size6of12 {
    width: 50% !important; }
  .menuWithSideNav-3HCqB {
    padding-left: 91px; }
  .md-size3of12 {
    width: 25%; }
  .sb-cont .content_isAnonFavorites .lg-size6of12 {
    width: 75%; }
  .sb-cont .content_FavoriteList {
    padding-left: 96px;
    padding-right: 96px; }
    .sb-cont .content_FavoriteList .productDetails {
      padding: 0 72px 0 128px !important; }
    .sb-cont .content_FavoriteList .wrapper {
      padding: 24px 24px 40px !important; }
    .sb-cont .content_FavoriteList .headerContainer {
      -webkit-box-align: center;
      align-items: center; }
      .sb-cont .content_FavoriteList .headerContainer .prodImage {
        height: 104px !important;
        width: 104px !important; } }

@media (min-width: 480px) {
  .sb-cont .content_isAnonFavorites .lg-size6of12 {
    width: 83.33333%; }
  .sb-cont .content_FavoriteList .wrapper {
    padding: 32px; } }

@media (max-width: 768px) {
  .menuWithSideNav-3HCqB .nutrition-cta {
    top: 150px !important;
    zoom: 0.8 !important; } }

.homeCapsul {
  position: relative;
  z-index: 1; }
  .homeCapsul .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .homeCapsul .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
      .homeCapsul .sb-headerCrate .sb-headerCrate--content .sb-heading--large {
        font-size: 2.7rem;
        line-height: 1.385; }
  .homeCapsul .sb-contentCrate {
    overflow: hidden; }
    .homeCapsul .sb-contentCrate .sb-content-capsul .sb-home-rewards .pb4 .carousel {
      z-index: 0;
      touch-action: pan-y pinch-zoom; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .sb-home-rewards .pb4 .carousel .carousel__slideContainer {
        -webkit-transform: translateZ(0) !important;
        transform: translateZ(0) !important;
        overflow: hidden; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .sb-home-rewards .pb4 .carousel .carousel__slideContainer .carousel__slider {
          position: relative;
          z-index: 0;
          white-space: nowrap; }
          .homeCapsul .sb-contentCrate .sb-content-capsul .sb-home-rewards .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
            white-space: normal;
            text-align: center; }
    .homeCapsul .sb-contentCrate .sb-content-capsul .sb-home-rewards .container:before {
      display: unset;
      content: unset; }
    .homeCapsul .sb-contentCrate .sb-content-capsul .sb-home-rewards .container .whiteBtn {
      color: black !important;
      background-color: none !important;
      border-color: black; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .sb-home-rewards .container .whiteBtn:hover {
        background-color: rgba(156, 156, 156, 0.1) !important; }
    .homeCapsul .sb-contentCrate .sb-content-capsul .streamCardsContainer {
      padding-top: 1.6rem;
      padding-left: .8rem;
      padding-right: .8rem; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .streamCardsContainer .sb-card .sb-button:hover {
        text-decoration: none;
        color: white;
        background-color: rgba(0, 130, 72, 0.9);
        border-color: rgba(0, 130, 72, 0.9); }
    .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer {
      padding-top: 1.6rem;
      padding-left: .8rem;
      padding-right: .8rem;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
      max-width: 455px;
      margin-top: 40px; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .cont-title {
        font-size: 14px;
        padding-left: 30px;
        margin-bottom: 20px; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .left-cap {
        position: relative;
        width: 40%;
        display: inline-block;
        vertical-align: top; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .left-cap .rewards-svg path {
          will-change: auto;
          stroke-width: 15px;
          stroke-miterlimit: round;
          transition: stroke-dashoffset 850ms ease-in-out; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .left-cap .digitalRewardsIcon {
          position: absolute;
          top: -25px;
          right: -5px;
          left: 0;
          bottom: 0;
          margin: auto;
          color: #656565; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .left-cap .countRewards {
          position: absolute;
          bottom: 25px;
          left: 8px;
          right: 0;
          font-size: 18px;
          font-weight: 700;
          color: #656565;
          text-align: center; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .left-cap .lastUpdate {
          display: block;
          color: #adadad; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap {
        width: 60%;
        display: inline-block;
        vertical-align: top;
        padding-left: 20px; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top {
          display: block;
          margin-bottom: 15px; }
          .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .left-x {
            display: inline-block;
            margin-right: 50px; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .left-x .z1 {
              vertical-align: middle;
              display: inline-block;
              margin-right: 5px; }
              .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .left-x .z1 .count {
                font-weight: bold;
                font-size: 20px; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .left-x .z2 {
              vertical-align: middle;
              display: inline-block; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .left-x .title {
              display: block;
              font-size: 12px;
              font-weight: bold; }
          .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .right-x {
            display: inline-block; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .right-x .z1 {
              vertical-align: middle;
              display: inline-block;
              margin-right: 5px; }
              .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .right-x .z1 .count {
                font-weight: bold;
                font-size: 20px; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .right-x .z2 {
              vertical-align: middle;
              display: inline-block; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .top .right-x .title {
              display: block;
              font-size: 12px;
              font-weight: bold; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .bottom {
          display: block; }
          .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .bottom .t1 {
            display: block; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .bottom .t1 .icon {
              vertical-align: middle;
              display: inline-block;
              padding-right: 10px;
              width: 30px; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .bottom .t1 .title {
              color: rgba(0, 0, 0, 0.87);
              font-size: 14px;
              font-weight: 600;
              vertical-align: middle; }
          .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .bottom .t2 {
            margin-top: 10px;
            display: block;
            text-align: right;
            padding-right: 30px; }
            .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .bottom .t2 .title {
              display: inline-block;
              width: 150px;
              background: none;
              border-style: solid;
              border-width: 1px;
              border-radius: 50px;
              padding: 7px 16px;
              font-size: 1.4rem;
              font-weight: 700;
              line-height: 1.2;
              text-align: center;
              text-decoration: none;
              cursor: pointer; }
              .homeCapsul .sb-contentCrate .sb-content-capsul .profileStatusContainer .right-cap .bottom .t2 .title:hover {
                background-color: rgba(0, 0, 0, 0.87);
                color: white; }
    .homeCapsul .sb-contentCrate .sb-content-capsul .sb-small-footer .container-footer {
      display: -webkit-box;
      display: flex; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .sb-small-footer .container-footer .find-store a {
        text-decoration: none; }
        .homeCapsul .sb-contentCrate .sb-content-capsul .sb-small-footer .container-footer .find-store a:hover {
          color: #008248; }
      .homeCapsul .sb-contentCrate .sb-content-capsul .sb-small-footer .container-footer .footer-links a {
        color: currentColor; }
    .homeCapsul .sb-contentCrate .sb-content-capsul .sb-small-footer .container-footer > * {
      padding: 0 6.4rem 0 0; }

.sticky-pay-btn {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 10; }

@media (min-width: 1280px) {
  .homeCapsul .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .homeCapsul .sb-headerCrate {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 40vw; }
    .homeCapsul .sb-headerCrate .sb-headerCrate--content {
      padding: 0 3.2rem; }
  .homeCapsul .sb-contentCrate {
    margin-left: 40vw;
    padding-top: 83px; }
    .homeCapsul .sb-contentCrate .sb-home-rewards .title .sb-heading {
      font-size: 1.3rem;
      line-height: 1.229; }
    .homeCapsul .sb-contentCrate .streamCardsContainer {
      max-width: 500px; }
    .homeCapsul .sb-contentCrate .lg-mb6 {
      margin-bottom: 4rem !important; }
    .homeCapsul .sb-contentCrate .lg-px0 {
      padding-right: 0 !important; }
    .homeCapsul .sb-contentCrate .lg-pl0, .homeCapsul .sb-contentCrate .lg-px0 {
      padding-left: 0 !important; }
    .homeCapsul .sb-contentCrate .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
      text-align: center; }
    .homeCapsul .sb-contentCrate .pb4 .carousel .carousel__slideContainer .carousel__slider .lg-size4of12 {
      width: 33.33333% !important; }
    .homeCapsul .sb-contentCrate .container {
      padding: 0 4.8rem; }
      .homeCapsul .sb-contentCrate .container .sb-button--positive.sb-button--black, .homeCapsul .sb-contentCrate .container .sb-button--positive.sb-button--rewardsDefault {
        background-color: #000;
        border-color: #000; }
        .homeCapsul .sb-contentCrate .container .sb-button--positive.sb-button--black:hover, .homeCapsul .sb-contentCrate .container .sb-button--positive.sb-button--rewardsDefault:hover {
          color: white;
          text-decoration: none;
          opacity: 0.7; }
      .homeCapsul .sb-contentCrate .container .sb-button--default.sb-button--black, .homeCapsul .sb-contentCrate .container .sb-button--default.sb-button--rewardsDefault {
        color: rgba(0, 0, 0, 0.87);
        border-color: rgba(0, 0, 0, 0.87); }
        .homeCapsul .sb-contentCrate .container .sb-button--default.sb-button--black:hover, .homeCapsul .sb-contentCrate .container .sb-button--default.sb-button--rewardsDefault:hover {
          text-decoration: none;
          background-color: rgba(0, 0, 0, 0.06); } }

@media (min-width: 768px) {
  .homeCapsul .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
    text-align: center; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .lg-size4of12 {
    width: 41.66667%; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .lg-px6 {
    padding-right: 4rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .lg-pl6, .homeCapsul .sb-contentCrate .sb-content-capsul .lg-px6 {
    padding-left: 4rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-pt7 {
    padding-top: 4.8rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-right: 2.4rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-pl4, .homeCapsul .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-left: 2.4rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-pb4 {
    padding-bottom: 2.4rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-pt2 {
    padding-top: 0.8rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-pt7 {
    padding-top: 4.8rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-right: 2.4rem !important; }
  .homeCapsul .sb-contentCrate .sb-content-capsul .md-pl4, .homeCapsul .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-left: 2.4rem !important; } }

@media (max-width: 480px) {
  .profileStatusContainer {
    padding-bottom: 1.6rem; }
    .profileStatusContainer .left-cap .rewards-svg {
      height: 120px !important;
      width: 150px !important; }
    .profileStatusContainer .left-cap .digitalRewardsIcon {
      width: 40px;
      height: 40px; }
    .profileStatusContainer .right-cap .top .left-x {
      margin-right: 20px !important; }
    .profileStatusContainer .right-cap .bottom .t1 .title {
      font-size: 12px !important; } }

@media (min-width: 480px) {
  .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
    text-align: center; }
  .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .lg-size4of12 {
    width: 50%; }
  .sb-contentCrate .sb-content-capsul .md-pb4 {
    padding-bottom: 2.4rem !important; }
  .sb-contentCrate .sb-content-capsul .md-pt2 {
    padding-top: 0.8rem !important; }
  .sb-contentCrate .sb-content-capsul .md-pt7 {
    padding-top: 4.8rem !important; }
  .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-right: 2.4rem !important; }
  .sb-contentCrate .sb-content-capsul .md-pl4, .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-left: 2.4rem !important; } }

@media (max-width: 768px) {
  .sticky-pay-btn {
    bottom: 15px !important;
    right: 15px !important; }
  .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
    width: 33% !important;
    text-align: center; }
    .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem span {
      font-size: 10px !important; } }

.prodDetail-content {
  position: relative;
  z-index: 1; }
  .prodDetail-content .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .prodDetail-content .sb-headerCrate .flex .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
    .prodDetail-content .sb-headerCrate .flex .size12of12 {
      width: 100%; }
  .prodDetail-content .sb-contentCrate .grid {
    display: block;
    margin: 0;
    padding: 0;
    text-align: left; }
    .prodDetail-content .sb-contentCrate .grid .gridItem {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
      vertical-align: top;
      padding: 0 1.6rem; }
  .prodDetail-content .color-textBlackSoft {
    color: rgba(0, 0, 0, 0.56) !important; }
    .prodDetail-content .color-textBlackSoft a {
      color: rgba(0, 0, 0, 0.56) !important; }

@media (min-width: 1280px) {
  .prodDetail-content .sb-headerCrate .flex .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; }
  .prodDetail-content .sb-contentCrate .grid {
    margin: 0 -2.4rem !important; }
    .prodDetail-content .sb-contentCrate .grid .gridItem {
      padding: 0 2.4rem !important; } }

@media (min-width: 1024px) {
  .prodDetail-content {
    padding-top: 82px; }
    .prodDetail-content .sb-headerCrate {
      position: fixed;
      top: 82px;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .prodDetail-content .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .prodDetail-content .sb-contentCrate {
      margin-left: 40vw; }
      .prodDetail-content .sb-contentCrate .container {
        padding: 0 4.8rem; }
    .prodDetail-content .lg-pb4 {
      padding-bottom: 2.4rem !important; }
    .prodDetail-content .lg-pt5 {
      padding-top: 3.2rem !important; } }

@media (min-width: 768px) {
  .prodDetail-content .sb-contentCrate .grid .gridItem .imageBlock {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1; }
  .prodDetail-content .sb-contentCrate .grid .md-size6of12 {
    width: 50% !important; }
  .prodDetail-content .md-hidden {
    display: none !important; } }

@media (max-width: 768px) {
  .prodDetail-content .sb-headerCrate {
    display: none !important; }
  .prodDetail-content .sb-contentCrate .grid .gridItem .imageBlock {
    border-radius: 50%;
    overflow: hidden;
    margin-top: 20px; } }

.isAnonym {
  position: relative;
  z-index: 1; }
  .isAnonym .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .isAnonym .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .isAnonym .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .isAnonym .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .isAnonym .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }

.sb-home-capsul {
  position: relative;
  z-index: 1; }
  .sb-home-capsul .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-home-capsul .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
      .sb-home-capsul .sb-headerCrate .sb-headerCrate--content .sb-heading--large {
        font-size: 2.7rem;
        line-height: 1.385; }
  .sb-home-capsul .sb-contentCrate {
    overflow: hidden; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards {
      width: 300px;
      margin: 150px auto; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .title {
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #777777;
        margin-bottom: 50px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards {
        margin-top: 20px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item {
          border-bottom: 1px solid #ababab;
          padding-bottom: 10px;
          margin-bottom: 20px;
          position: relative;
          padding-top: 5px;
          padding-left: 5px;
          padding-right: 5px;
          cursor: pointer; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-logo {
            display: inline-block;
            vertical-align: middle; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-logo img {
              width: 64px;
              margin-right: 10px;
              vertical-align: middle; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-inform {
            display: inline-block;
            vertical-align: middle; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-inform .card-title {
              display: block;
              color: #9e9e9e;
              font-size: 15px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-inform .card-number {
              display: block;
              color: #9e9e9e;
              font-size: 15px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-action {
            display: inline-block;
            vertical-align: middle; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-action .card-delete-btn {
              display: none;
              background-color: #e75b52;
              color: white;
              position: absolute;
              right: -90px;
              font-size: 21px;
              padding: 19px;
              bottom: 0px;
              font-weight: 100;
              cursor: pointer; }
              .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-action .card-delete-btn:hover {
                opacity: 0.8;
                -moz-transition: 0.4s;
                -o-transition: 0.4s;
                -webkit-transition: 0.4s;
                transition: 0.4s; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item:hover {
            transform: scale(1.05);
            -moz-transition: 0.3s;
            -o-transition: 0.3s;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            -webkit-box-shadow: 0px 0px 22px -8px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 22px -8px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 0px 22px -8px rgba(0, 0, 0, 0.75); }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item:hover .card-action > .card-delete-btn {
              display: inline-block; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .add-credit-card .add-credit-card-btn {
        font-size: 18px;
        cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .add-credit-card .add-credit-card-btn i {
          border-radius: 5px;
          border: 1px solid #c1c1c1;
          padding: 15px 20px;
          font-size: 14px;
          color: #c1c1c1;
          margin-right: 5px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .my-cards .add-credit-card .masterpass-icon {
        width: 124px;
        display: block;
        margin-left: 55px;
        margin-top: 10px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay {
      text-align: center;
      padding-top: 80px;
      padding-bottom: 120px;
      width: 70%;
      margin: 0 auto;
      position: relative; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .prod-image {
        margin-bottom: 40px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .prod-image img {
          max-width: 100%; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .prod-inform .price {
        display: inline-block;
        font-size: 32px;
        margin-right: 10px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .prod-inform i {
        display: inline-block;
        font-size: 22px;
        color: #d0cccc;
        margin-right: 10px;
        cursor: pointer;
        cursor: pointer; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .prod-inform .inf {
        display: inline-block;
        font-size: 16px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .acc-history-btn {
        border: 1px solid black;
        padding: 10px;
        border-radius: 35px;
        cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .acc-history-btn:hover {
          background-color: black;
          color: white; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .payInStore-old {
        position: absolute;
        display: inline-block;
        z-index: 1;
        padding: 18px 2.4rem;
        background: #00a862;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.24), 0 8px 12px rgba(0, 0, 0, 0.14);
        border-radius: 500px;
        color: #fff;
        font-size: 19px;
        font-size: 1.9rem;
        font-weight: 500;
        line-height: 1.2;
        overflow: hidden;
        text-align: center;
        text-decoration: none;
        transition-duration: .2s;
        transition-property: transform,box-shadow,width,height;
        transition-timing-function: ease;
        cursor: pointer;
        transition: background .8s;
        right: 0;
        margin-top: 100px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .payInStore-old:hover {
          background: #039458 radial-gradient(circle, transparent 1%, #039458 1%) center/15000%; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .payInStore-old:active {
          background-color: #07c274;
          background-size: 100%;
          transition: background 0s; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pay .payInStore {
        position: absolute;
        display: inline-block;
        z-index: 1;
        cursor: pointer;
        right: 0;
        margin-top: 40px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money {
      width: 350px;
      margin: 100px auto;
      position: relative; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .head {
        display: block;
        margin-bottom: 20px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .head .title {
          font-size: 18px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .head .sb-toggle {
          float: right; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .balance-sum {
        display: table;
        margin: 20px 0;
        background-color: #016341;
        color: white;
        padding: 10px;
        border-radius: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .sub-title {
        color: #777777;
        margin-bottom: 15px;
        display: block;
        font-size: 14px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .title-add {
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #777777;
        margin-bottom: 50px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .pb2 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .amountSelect {
        margin-top: 30px;
        margin-bottom: 10px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .amountSelect .title {
          color: #777;
          position: relative;
          top: 15px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input {
        position: relative;
        display: block;
        margin-bottom: 20px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .show-password {
          width: 24px;
          height: 24px;
          fill: rgba(0, 0, 0, 0.56) !important;
          position: absolute;
          right: 0;
          bottom: 14px;
          cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .hide-password {
          width: 24px;
          height: 24px;
          fill: rgba(0, 0, 0, 0.56) !important;
          position: absolute;
          right: 0;
          bottom: 14px;
          cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .form-inpt {
          width: 100%;
          margin-top: 20px;
          height: 45px;
          font-size: 14px;
          font-weight: 500;
          color: #2d3436;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          background-color: transparent;
          border-radius: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-top-width: 0;
          border-left-width: 0;
          border-right-width: 0;
          padding-right: 40px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .floating-label {
          position: absolute;
          pointer-events: none;
          left: 0;
          top: 38px;
          transition: 0.2s ease all;
          color: rgba(0, 0, 0, 0.56) !important;
          font-weight: 500; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input input:focus + .floating-label, .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input input:valid + .floating-label {
          margin-top: -26px;
          font-size: 12px;
          color: #6c7a89;
          padding: 0 0px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input textarea:focus, .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input input:focus {
          outline: none; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards {
        margin-top: 20px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .selected {
          background-color: #d6e1da !important;
          border: 1px solid #016341 !important; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item {
          border-bottom: 1px solid #ababab;
          margin-bottom: 20px;
          position: relative;
          padding: 15px 15px 15px;
          cursor: pointer;
          border: 2px solid #e6e5e5;
          border-radius: 4px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-logo {
            display: inline-block;
            vertical-align: middle; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-logo img {
              width: 64px;
              margin-right: 10px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-inform {
            display: inline-block;
            vertical-align: middle; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-inform .card-title {
              display: block;
              color: #555555;
              font-size: 18px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-inform .card-number {
              display: block;
              color: #9e9e9e;
              font-size: 18px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-action {
            display: inline-block;
            vertical-align: middle; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-action .card-delete-btn {
              display: none;
              background-color: #e75b52;
              color: white;
              position: absolute;
              right: -90px;
              font-size: 21px;
              padding: 19px;
              bottom: 0px;
              font-weight: 100;
              cursor: pointer; }
              .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-action .card-delete-btn:hover {
                opacity: 0.8;
                -moz-transition: 0.4s;
                -o-transition: 0.4s;
                -webkit-transition: 0.4s;
                transition: 0.4s; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item:hover {
            -moz-transition: 0.3s;
            -o-transition: 0.3s;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            background-color: #8080800f; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .deleteIcon {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .add-credit-card .add-credit-card-btn {
        font-size: 18px;
        cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .add-credit-card .add-credit-card-btn i {
          border-radius: 5px;
          border: 1px solid #c1c1c1;
          padding: 15px 20px;
          font-size: 14px;
          color: #c1c1c1;
          margin-right: 5px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .add-credit-card .masterpass-icon {
        width: 124px;
        display: block;
        margin-left: 55px;
        margin-top: 10px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .addMoneyConfirm {
        position: absolute;
        display: inline-block;
        z-index: 1;
        padding: 18px 2.4rem;
        background: #00a862;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.24), 0 8px 12px rgba(0, 0, 0, 0.14);
        border-radius: 500px;
        color: #fff;
        font-size: 19px;
        font-size: 1.9rem;
        font-weight: 500;
        line-height: 1.2;
        overflow: hidden;
        text-align: center;
        text-decoration: none;
        transition-duration: .2s;
        transition-property: transform,box-shadow,width,height;
        transition-timing-function: ease;
        cursor: pointer;
        transition: background .8s;
        right: 0; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .addMoneyConfirm:hover {
          background: #039458 radial-gradient(circle, transparent 1%, #039458 1%) center/15000%; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money .addMoneyConfirm:active {
          background-color: #07c274;
          background-size: 100%;
          transition: background 0s; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .manage .manage-list .manage-item {
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .manage .manage-list .manage-item:hover {
        background-color: #dcdcdc59;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .manage .manage-list .manage-item .title {
        font-size: 22px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .manage .manage-list .manage-item .text {
        color: rgba(0, 0, 0, 0.56) !important;
        line-height: initial; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .manage .manage-list .manage-item .item-icon {
        margin-left: 20px;
        width: 36px;
        height: 36px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory {
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list {
        margin-top: 40px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item {
          box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
          background-color: white;
          border-radius: 2px;
          margin-bottom: 30px;
          padding: 15px;
          cursor: pointer; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .grid {
            margin: 0 !important;
            padding-top: 15px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .icon {
            display: inline-block;
            width: 32px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .inform {
            display: inline-block;
            line-height: 0.8;
            padding-left: 20px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .inform .title {
              font-weight: bold; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail {
            line-height: 1.5;
            margin-top: -5px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail h3 {
              margin: 0; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail .s1 {
              display: block; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail .s2 {
              display: block; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail .s3 {
              display: block; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard {
      width: 320px;
      margin: 150px auto;
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .noActiveCard-img {
        display: block; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .text {
        display: block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .text .detail {
          font-size: 12px;
          color: gray; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .add-credit-card {
        display: block;
        margin-top: 20px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .add-credit-card .add-credit-card-btn {
          font-size: 14px;
          cursor: pointer;
          color: gray; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .add-credit-card .add-credit-card-btn i {
            border-radius: 5px;
            border: 1px solid #c1c1c1;
            padding: 10px 20px;
            font-size: 14px;
            color: #c1c1c1;
            margin-right: 5px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .masterpass {
        text-align: center;
        margin-top: 10px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .masterpass img {
          width: 185px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .head {
      width: 100%;
      background-color: white;
      padding: 25px;
      border-bottom: 1px solid lightgrey; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .head .title {
        font-size: 18px;
        font-weight: bold; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .head .id {
        float: right;
        padding-top: 5px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-list {
      padding: 30px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-list .receipt-item {
        margin-top: 20px;
        border-bottom: 3px dotted #d3d3d3;
        padding-bottom: 10px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-list .receipt-item .receipt-title {
          font-size: 16px;
          font-weight: bold; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-total {
      width: 300px;
      float: right;
      margin-top: 60px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-total .s1 .t1 {
        width: 70px;
        display: inline-block;
        font-weight: bold; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box {
      width: 450px;
      max-width: 100%;
      margin: 10% auto;
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .head {
        display: block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .head .title {
          font-size: 18px;
          font-weight: bold; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .head .sb-toggle {
          float: right; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form {
        margin-top: 40px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .m4m {
          margin-top: 20px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .m4m .field__inputWrapper {
            width: 100%; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .selectAmountList .floatLabel {
          top: 10px !important; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list {
          margin-top: 20px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list .card-item {
            margin-top: 10px;
            border: 2px solid #e6e5e5;
            padding: 10px 15px;
            cursor: pointer; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list .card-item .card-logo {
              display: inline-block;
              vertical-align: middle;
              width: 55px;
              margin-right: 10px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list .card-item .card-detail {
              display: inline-block;
              vertical-align: middle; }
              .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list .card-item .card-detail .card-title {
                display: block;
                color: #636363;
                font-size: 18px;
                font-weight: bold; }
              .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list .card-item .card-detail .card-number {
                display: block;
                color: #636363;
                font-size: 18px;
                font-weight: bold; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list .card-item:hover {
              background-color: #ececec;
              -webkit-transition: all 200ms ease;
              -moz-transition: all 200ms ease;
              -o-transition: all 200ms ease;
              transition: all 200ms ease; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .cc .card-list .selected {
            background-color: #ececec; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .add-credit-card {
          display: block;
          margin-top: 20px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .add-credit-card .add-credit-card-btn {
            font-size: 14px;
            cursor: pointer;
            color: gray; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .add-credit-card .add-credit-card-btn i {
              border-radius: 5px;
              border: 1px solid #c1c1c1;
              padding: 10px 20px;
              font-size: 14px;
              color: #c1c1c1;
              margin-right: 5px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .option .option__labelText {
          padding-top: 0; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .actionBtn {
          margin-top: 20px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .rdt {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 20px;
          margin-top: 10px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .rdt .form-control {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            color: rgba(0, 0, 0, 0.9);
            display: block;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 10px;
            line-height: 1rem;
            padding: 10px 0 6px;
            width: 100%;
            z-index: 1; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .rdt .form-control::-ms-clear,
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .rdt .form-control::-ms-reveal {
            display: none; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .autoreload-box .auto-form .rdt .form-control:focus {
            outline: none !important; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.33);
      height: 100%;
      z-index: 9;
      margin: 0 -7px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal {
        background-color: #fff;
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 100%;
        border: 1px solid #e2e0e0;
        padding: 10px;
        overflow: auto;
        padding: 20px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .close-icon {
          width: 32px;
          height: 32px;
          overflow: visible;
          background: #e9e9e9;
          border-radius: 50%;
          padding: 5px;
          position: initial;
          left: 5%;
          top: 30px;
          cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .content {
          margin: 0 auto;
          width: 450px;
          max-width: 100%;
          padding-top: 20px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .content ul li {
            font-size: 12px;
            margin-bottom: 20px;
            list-style: circle; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .content .actionBtn .mp-logo {
            height: 30px;
            margin-top: 18px;
            margin-right: 20px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .content .actionBtn button {
            background-color: #424242; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .bank-verification .activation-inp {
          height: 45px;
          width: 100%;
          border-radius: 15px;
          border: 2px solid gray;
          padding: 15px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .bank-verification .btns {
          margin-top: 25px;
          margin-bottom: 30px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .bank-verification .btns .resendBtn {
            margin-right: 15px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .bank-verification .btns .resendBtn button {
              background-color: #616161; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal .in-modal .bank-verification .mp-logo {
          height: 40px;
          display: block;
          float: right; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate {
      width: 340px;
      max-width: 100%;
      margin: 10% auto;
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate .text .title {
        color: #414141; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate .text .text {
        color: #818181; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate .actionBtn {
        margin-top: 20px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 {
      width: 320px;
      max-width: 100%;
      margin: 15% auto;
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .title {
        color: #414141;
        font-size: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .codeBox {
        display: block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .codeBox .firstBox {
          border-radius: 15px 0px 0px 15px;
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .codeBox .defaultBox {
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .codeBox .lastBox {
          border-radius: 0px 15px 15px 0px;
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .stepCount .step {
        display: block;
        margin: 20px 0;
        text-align: center; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .stepCount .step .first {
          color: white;
          font-size: 18px;
          background-color: #bdbdbd;
          padding: 10px 15px;
          border-radius: 50%;
          font-family: sans-serif;
          margin-right: 15px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .stepCount .step .right-chev {
          font-size: 30px;
          color: grey; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .stepCount .step .last {
          color: white;
          font-size: 18px;
          background-color: #bdbdbd;
          padding: 10px 15px;
          border-radius: 50%;
          font-family: sans-serif;
          margin-left: 15px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .stepCount .step .active {
          background-color: gray; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .stepCount .text {
        display: block;
        font-size: 15px;
        padding-left: 5px;
        padding-top: 10px;
        color: #414141;
        font-weight: bold; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinCodeGenerate-step1 .actionBtn {
        margin-top: 20px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .changePinCode {
      width: 300px;
      max-width: 100%;
      margin: 10% auto;
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .changePinCode .text .head {
        margin: 20px 0;
        display: block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .changePinCode .text .head .title {
          font-size: 21px;
          font-weight: bold; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .changePinCode .text .head .sb-toggle {
          float: right; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .changePinCode .text .title {
        color: #414141; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .changePinCode .text .text {
        color: #818181; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .changePinCode .actionBtn {
        margin-top: 40px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl {
      width: 320px;
      max-width: 100%;
      margin: 15% auto;
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .title {
        color: #414141;
        font-size: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .codeBox {
        display: block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .codeBox .firstBox {
          border-radius: 15px 0px 0px 15px;
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .codeBox .defaultBox {
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .codeBox .lastBox {
          border-radius: 0px 15px 15px 0px;
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .text {
        margin-top: 15px;
        color: #838383;
        display: block; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .fargot-pin {
        margin-top: 10px;
        display: block;
        color: #747474;
        font-weight: bold;
        text-decoration: underline;
        text-align: right;
        cursor: pointer; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .pinControl .actionBtn {
        margin-top: 40px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode {
      width: 320px;
      max-width: 100%;
      margin: 15% auto;
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .title {
        color: #414141;
        font-size: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .codeBox {
        display: block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .codeBox .firstBox {
          border-radius: 15px 0px 0px 15px;
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .codeBox .defaultBox {
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .codeBox .lastBox {
          border-radius: 0px 15px 15px 0px;
          width: 70px;
          height: 60px;
          border: 1px solid gray;
          padding: 26px;
          font-size: 24px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .stepCount .step {
        display: block;
        margin: 20px 0;
        text-align: center; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .stepCount .step .first {
          color: white;
          font-size: 18px;
          background-color: #bdbdbd;
          padding: 10px 15px;
          border-radius: 50%;
          font-family: sans-serif;
          margin-right: 15px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .stepCount .step .right-chev {
          font-size: 30px;
          color: grey; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .stepCount .step .last {
          color: white;
          font-size: 18px;
          background-color: #bdbdbd;
          padding: 10px 15px;
          border-radius: 50%;
          font-family: sans-serif;
          margin-left: 15px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .stepCount .step .active {
          background-color: gray; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .stepCount .text {
        display: block;
        font-size: 15px;
        padding-left: 5px;
        padding-top: 10px;
        color: #414141;
        font-weight: bold; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .newPinCode .actionBtn {
        margin-top: 20px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal {
      position: fixed;
      top: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.33);
      height: 100%;
      z-index: 9; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal {
        background-color: #fff;
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 100%;
        border: 1px solid #e2e0e0;
        padding: 10px;
        overflow: auto; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .close-icon {
          width: 32px;
          height: 32px;
          overflow: visible;
          background: #e9e9e9;
          border-radius: 50%;
          padding: 5px;
          position: initial;
          left: 5%;
          top: 30px;
          cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .content {
          margin: 0 auto;
          width: 450px;
          max-width: 100%;
          padding-top: 20px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .content ul li {
            font-size: 12px;
            margin-bottom: 20px;
            list-style: circle; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .content .actionBtn .mp-logo {
            height: 30px;
            margin-top: 18px;
            margin-right: 20px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .content .actionBtn button {
            background-color: #424242; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .bank-verification .activation-inp {
          height: 45px;
          width: 100%;
          border-radius: 15px;
          border: 2px solid gray;
          padding: 15px; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .bank-verification .btns {
          margin-top: 25px;
          margin-bottom: 30px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .bank-verification .btns .resendBtn {
            margin-right: 15px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .bank-verification .btns .resendBtn button {
              background-color: #616161; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .pinCode .foot-modal .in-modal .bank-verification .mp-logo {
          height: 40px;
          display: block;
          float: right; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard {
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .renewMenu .renewItem {
        margin-right: 20px;
        padding-bottom: 10px;
        cursor: pointer; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .renewMenu .renewItem:hover {
          color: gray; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .renewMenu .active {
        border-bottom: 3px solid #00a862; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .pyhsicalCard {
        width: 430px;
        max-width: 100%;
        margin: 10% auto; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .pyhsicalCard .title {
          font-size: 21px;
          color: #3a3a3a; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .pyhsicalCard .pyhsicalCard-form .pb4 {
          border: 0;
          padding: 0;
          margin: 0;
          min-width: 0; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .pyhsicalCard .actionBtn {
          margin-top: 30px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .digitalCard {
        width: 430px;
        max-width: 100%;
        margin: 10% auto; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .digitalCard .title {
          font-size: 21px;
          color: #3a3a3a; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .digitalCard .pyhsicalCard-form .pb4 {
          border: 0;
          padding: 0;
          margin: 0;
          min-width: 0; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .renewStarbucksCard .digitalCard .actionBtn {
          margin-top: 30px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard {
      padding: 20px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard {
        width: 350px;
        max-width: 100%;
        margin: 10% auto; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .birthday {
          margin-top: 20px !important;
          margin-bottom: 10px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .birthday .title {
            color: var(--colorTextBlackSoft) !important;
            font-size: 14px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .birthday .rdt {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            margin-bottom: 0;
            margin-top: 0; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .birthday .rdt .form-control {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background-color: transparent;
              border: 0;
              border-radius: 0;
              color: rgba(0, 0, 0, 0.9);
              display: block;
              font-size: 1.6rem;
              font-weight: 700;
              line-height: 10px;
              line-height: 1rem;
              padding: 10px 0 6px;
              width: 100%;
              z-index: 1; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .birthday .rdt .form-control::-ms-clear,
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .birthday .rdt .form-control::-ms-reveal {
              display: none; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .birthday .rdt .form-control:focus {
              outline: none !important; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .title {
          font-size: 21px;
          color: #3a3a3a; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .frm .pb1 {
          border: 0;
          padding: 0;
          margin: 0;
          min-width: 0; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .tcInfoText {
          font-size: 10px;
          color: gray;
          display: block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .createStarbucksCard .createSBcard .actionBtn {
          margin-top: 50px; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard {
      background-color: #2d2926;
      height: calc(100vh - 83px); }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .bgCard {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 14;
        border-radius: 12px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .pb1 {
        position: relative;
        z-index: 15; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk {
        max-width: 365px;
        width: 100%; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .sb-heading--xxsmall {
          font-size: 1.3rem;
          line-height: 1.229;
          padding-bottom: 0px !important;
          color: white; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .headingDetail {
          font-size: 13px;
          color: white; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .formCard {
          border-radius: 12px; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .formCard .fieldGroup {
            padding-bottom: 70px;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px; }
            .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .formCard .fieldGroup .pb1 {
              border: 0;
              padding: 0;
              margin: 0;
              min-width: 0; }
          .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .formCard .goldStripe {
            width: 100%;
            position: absolute;
            bottom: 20px;
            left: 0;
            height: 35px;
            background-color: #cba258 !important; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .base___3dWsJ {
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 10;
          padding: 1.6rem; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .addPyhsicalCard .base___3qZEk .mt3 label {
          color: white; }
    .sb-home-capsul .sb-contentCrate .sb-content-capsul .beforeAddMoneyCard {
      width: 320px;
      margin: 150px auto;
      position: relative;
      text-align: center; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .beforeAddMoneyCard .title {
        display: block;
        text-align: left;
        font-weight: bold;
        margin-bottom: 30px;
        margin-left: 15px; }
      .sb-home-capsul .sb-contentCrate .sb-content-capsul .beforeAddMoneyCard .btns {
        text-align: center; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .beforeAddMoneyCard .btns .mpBtn {
          margin-right: 30px;
          display: inline-block; }
        .sb-home-capsul .sb-contentCrate .sb-content-capsul .beforeAddMoneyCard .btns .akbankBtn {
          display: inline-block; }

.profile-add-new-card {
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #0000009e;
  width: 100%;
  height: 100%;
  left: 0; }
  .profile-add-new-card .capsul {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 483px;
    border-radius: 3px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-bottom: 40px; }
    .profile-add-new-card .capsul .head .title {
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
      color: #424242;
      padding: 30px; }
    .profile-add-new-card .capsul .head .close-icon-popup {
      float: right;
      padding: 30px;
      cursor: pointer; }
    .profile-add-new-card .capsul .tab {
      background-color: #f6f6f6;
      border-top: solid 1px #e1e8ee;
      padding: 20px 30px; }
      .profile-add-new-card .capsul .tab .tab-title {
        font-size: 16px;
        font-weight: 500;
        color: #6c7a89;
        margin-right: 10px;
        padding-bottom: 5px;
        cursor: pointer; }
      .profile-add-new-card .capsul .tab .v-line {
        font-weight: 400;
        color: #6c7a89;
        margin-right: 10px; }
      .profile-add-new-card .capsul .tab .active {
        font-weight: 600;
        color: #4d9c2d; }
    .profile-add-new-card .capsul .add-cc {
      text-align: center; }
      .profile-add-new-card .capsul .add-cc .md-size3of13 {
        width: 31%;
        margin-left: 5px; }
      .profile-add-new-card .capsul .add-cc .ig-card-form {
        text-align: center; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .rules {
          margin-top: 20px;
          margin-bottom: -20px;
          position: relative;
          text-align: left;
          padding-left: 30px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .rules .mc-logo {
            width: 100px;
            vertical-align: bottom; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .row {
          margin-left: 15px;
          margin-right: 25px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .name {
          display: inline-block;
          position: relative; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name .floating-label {
            position: absolute;
            pointer-events: none;
            left: 15px;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name input:focus + .floating-label, .profile-add-new-card .capsul .add-cc .ig-card-form .name input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name input {
            width: 202px;
            height: 55px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .surname {
          display: inline-block;
          position: relative; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname .floating-label {
            position: absolute;
            pointer-events: none;
            left: 15px;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname input:focus + .floating-label, .profile-add-new-card .capsul .add-cc .ig-card-form .surname input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname input {
            width: 202px;
            height: 55px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .sb-style-inp {
          margin-left: 15px !important;
          margin-right: 15px !important;
          margin-bottom: 10px !important; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number {
          position: relative; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number .floating-label {
            position: absolute;
            pointer-events: none;
            left: 15px;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number input:focus + .floating-label, .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number input {
            width: 423px;
            height: 55px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number .camera-icon {
            position: absolute;
            top: 38px;
            right: 40px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .month {
          display: inline-block;
          position: relative;
          /*
                        display:inline-block;
                        position: relative;
                        //margin-right: 10px;

                        .floating-label{
                            position: absolute;
                            pointer-events: none;
                            //left: 15px;
                            left: 30px;
                            top: 38px;
                            transition: 0.2s ease all;

                            width: 202px;
                            margin-top: 20px;
                            height: 54px;
                            border-radius: 4px;
                            border-color: #bdc3c7;
                            font-size: 14px;
                            font-weight: 500;
                            color: #2d3436;
                        }

                        .floating-label{
                            color: #6c7a89;
                        }

                        input:focus + .floating-label, input:valid + .floating-label{                  
                             margin-top: -26px;
                             background-color:white;
                             font-size: 12px;
                             color: #6c7a89;
                             padding:0 4px;
                        }

                        input{
                            width: 131px;
                            height: 54px;
                        }

                        */ }
          .profile-add-new-card .capsul .add-cc .ig-card-form .month .floating-label {
            position: absolute;
            pointer-events: none;
            left: 30px;
            top: 12px;
            transition: 0.2s ease all;
            background-color: white;
            padding: 0px 5px;
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .month input:focus + .floating-label, .profile-add-new-card .capsul .add-cc .ig-card-form .month input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .month select {
            width: 100%;
            margin-top: 20px;
            height: 54px;
            font-weight: 500;
            width: 100%;
            border: 1px solid #d9d9d9;
            padding: 15px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .year {
          display: inline-block;
          position: relative;
          /*
                        display:inline-block;
                        position: relative;
                        //margin-right: 10px;

                        .floating-label{
                            position: absolute;
                            pointer-events: none;
                            //left: 15px;
                            left: 30px;
                            top: 38px;
                            transition: 0.2s ease all;
                        }

                        .floating-label{
                            color: #6c7a89;
                        }

                        input:focus + .floating-label, input:valid + .floating-label{                  
                             margin-top: -26px;
                             background-color:white;
                             font-size: 12px;
                             color: #6c7a89;
                             padding:0 4px;
                        }

                        input{
                            width: 131px;
                            height: 54px;
                        }

                        */ }
          .profile-add-new-card .capsul .add-cc .ig-card-form .year .floating-label {
            position: absolute;
            pointer-events: none;
            left: 30px;
            top: 12px;
            transition: 0.2s ease all;
            background-color: white;
            padding: 0px 5px;
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .year input:focus + .floating-label, .profile-add-new-card .capsul .add-cc .ig-card-form .year input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .year select {
            width: 100%;
            margin-top: 20px;
            height: 54px;
            font-weight: 500;
            width: 100%;
            border: 1px solid #d9d9d9;
            padding: 15px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .cvv {
          display: inline-block;
          position: relative; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv .floating-label {
            position: absolute;
            pointer-events: none;
            left: 30px;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv input:focus + .floating-label, .profile-add-new-card .capsul .add-cc .ig-card-form .cvv input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv input {
            height: 54px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-add-new-cc-btn {
          width: 423px;
          height: 54px;
          border-radius: 3px;
          background-color: #4d9c2d;
          display: block;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 2px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
          transition: 0.2s ease all;
          margin: -10px auto;
          line-height: 54px;
          padding: 0 20px;
          margin-top: 0px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .ig-add-new-cc-btn .ok {
            float: right;
            padding-top: 22px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .ig-add-new-cc-btn:hover {
            background-color: #4c9c2dd5;
            transition: 0.2s ease all; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .error-msg {
          display: block;
          margin-top: 15px;
          font-size: 12px;
          font-weight: 500;
          color: #ed3246;
          text-align: left;
          margin-left: 30px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .error-input {
          border-color: #ed3246 !important; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-small-input {
          margin-top: 20px;
          height: 45px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid gray;
          padding: 15px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-small-input {
          margin-top: 20px;
          height: 45px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid gray;
          padding: 15px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-xsmall-input {
          margin-top: 20px;
          height: 45px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid gray;
          padding: 15px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .normal-input {
          margin-top: 20px;
          height: 45px;
          width: 100%;
          border: none;
          border-bottom: 1px solid #d9d9d9;
          padding: 15px 0 0px 0;
          font-size: 15px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .inform {
          background: url(/assets/img/exclamation.png);
          background-repeat: no-repeat;
          background-position-x: 0px;
          background-position-y: 13px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .inform p {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.43;
            color: #2d3436;
            text-align: left;
            padding: 30px 30px; }

.profile-delete-card-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #0000009e;
  width: 100%;
  height: 100%; }
  .profile-delete-card-modal .capsul {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-bottom: 40px;
    padding: 40px 35px;
    text-align: center; }
    .profile-delete-card-modal .capsul .logo img {
      width: 160px; }
    .profile-delete-card-modal .capsul .text {
      padding: 40px 0; }
      .profile-delete-card-modal .capsul .text p {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #424242; }
    .profile-delete-card-modal .capsul .btns .delete-btn {
      width: 231px;
      height: 54px;
      border-radius: 3px;
      border: solid 1px #181a1a;
      background-color: #ffffff;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
      color: #181a1a;
      display: inline-block;
      line-height: 51px;
      margin-right: 20px;
      cursor: pointer; }
      .profile-delete-card-modal .capsul .btns .delete-btn:hover {
        color: #ffffff;
        background-color: #181a1a; }
    .profile-delete-card-modal .capsul .btns .no-deletion-btn {
      margin-top: -2px;
      width: 231px;
      height: 54px;
      border-radius: 3px;
      background-color: #4d9c2d;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      line-height: 54px;
      cursor: pointer; }
      .profile-delete-card-modal .capsul .btns .no-deletion-btn:hover {
        opacity: 0.8; }

.order-cancel-modal {
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #0000009e;
  width: 100%;
  height: 100%; }
  .order-cancel-modal .capsul {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-bottom: 40px;
    padding: 40px 45px;
    text-align: center; }
    .order-cancel-modal .capsul .logo img {
      width: 90px; }
    .order-cancel-modal .capsul .text {
      padding: 40px 0; }
      .order-cancel-modal .capsul .text p {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #424242; }
    .order-cancel-modal .capsul .btns .delete-btn {
      width: 231px;
      height: 54px;
      border-radius: 3px;
      background-color: #ed3246;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
      color: white;
      display: inline-block;
      line-height: 51px;
      margin-right: 20px;
      cursor: pointer; }
      .order-cancel-modal .capsul .btns .delete-btn:hover {
        color: #ffffff;
        background-color: #181a1a; }
    .order-cancel-modal .capsul .btns .no-deletion-btn {
      margin-top: -2px;
      width: 231px;
      height: 54px;
      border-radius: 3px;
      background-color: #444c53;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      line-height: 54px;
      cursor: pointer; }
      .order-cancel-modal .capsul .btns .no-deletion-btn:hover {
        opacity: 0.8; }

.generic-modal {
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #0000009e;
  width: 100%;
  height: 100%; }
  .generic-modal .capsul {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-bottom: 40px;
    padding: 40px 45px;
    text-align: center; }
    .generic-modal .capsul .pb2 {
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
      width: 90%;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 20px; }
    .generic-modal .capsul .cancelBtn {
      margin-right: 30px;
      margin-left: 30px; }
      .generic-modal .capsul .cancelBtn button {
        background-color: #616161; }
    .generic-modal .capsul .logo img {
      width: 90px; }
    .generic-modal .capsul .text {
      padding: 20px 0; }
      .generic-modal .capsul .text p {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #424242; }
    .generic-modal .capsul .btns .bt {
      margin: 0 10px; }
    .generic-modal .capsul .btns .btn-1 {
      width: 231px;
      height: 54px;
      border-radius: 3px;
      background-color: #607D8B;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
      color: white;
      display: inline-block;
      line-height: 51px;
      position: relative;
      top: -2px;
      cursor: pointer; }
      .generic-modal .capsul .btns .btn-1:hover {
        color: #ffffff;
        background-color: #181a1a; }
    .generic-modal .capsul .btns .btn-2 {
      margin-top: -2px;
      width: 231px;
      height: 54px;
      border-radius: 3px;
      background-color: #444c53;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      line-height: 54px;
      cursor: pointer; }
      .generic-modal .capsul .btns .btn-2:hover {
        opacity: 0.8; }

.order-detail-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #0000009e;
  width: 100%;
  height: 100%; }
  .order-detail-popup .capsul {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 870px;
    border-radius: 3px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-bottom: 40px; }
    .order-detail-popup .capsul .head {
      padding: 30px;
      border-bottom: 1px solid #e1e8ee;
      position: relative; }
      .order-detail-popup .capsul .head .titles .t1 {
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #424242;
        display: block; }
      .order-detail-popup .capsul .head .titles .t2 {
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #424242;
        display: block; }
      .order-detail-popup .capsul .head .close-icon {
        position: absolute;
        right: 20px;
        top: 40px;
        cursor: pointer; }
    .order-detail-popup .capsul .product-list {
      max-height: 385px;
      overflow: auto; }
      .order-detail-popup .capsul .product-list .list tr {
        border-bottom: 1px solid #e1e8ee; }
        .order-detail-popup .capsul .product-list .list tr:last-child {
          border: none; }
      .order-detail-popup .capsul .product-list .list td {
        padding: 10px; }
      .order-detail-popup .capsul .product-list .list .order-image {
        text-align: center; }
        .order-detail-popup .capsul .product-list .list .order-image img {
          max-width: 68px;
          max-height: 68px; }
      .order-detail-popup .capsul .product-list .list .order-title {
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #2d3436; }
      .order-detail-popup .capsul .product-list .list .order-count {
        font-size: 14px;
        font-weight: 500;
        color: #2d3436; }
      .order-detail-popup .capsul .product-list .list .order-price {
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #181a1a; }
    .order-detail-popup .capsul .foot {
      padding: 0;
      border-top: 1px solid #e1e8ee; }
      .order-detail-popup .capsul .foot .btns .t1 {
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2px;
        color: #181a1a;
        cursor: pointer;
        position: relative;
        top: 37px;
        left: 30px; }
      .order-detail-popup .capsul .foot .btns .t2 {
        float: right;
        width: 291px;
        height: 54px;
        border-radius: 3px;
        background-color: #444c53;
        display: block;
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #ffffff;
        line-height: 54px;
        cursor: pointer;
        position: relative;
        right: 20px;
        top: 20px; }
        .order-detail-popup .capsul .foot .btns .t2:hover {
          opacity: 0.8; }

.global-slot-changer-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #0000009e;
  width: 100%;
  height: 100%; }
  .global-slot-changer-popup .capsul {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 474px;
    border-radius: 3px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff; }
    .global-slot-changer-popup .capsul .delivery-list {
      width: 100%;
      background-color: white;
      -webkit-box-shadow: 0px 0px 127px -11px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 0px 127px -11px rgba(0, 0, 0, 0.22);
      box-shadow: 0px 0px 127px -11px rgba(0, 0, 0, 0.2);
      z-index: 9; }
      .global-slot-changer-popup .capsul .delivery-list .inform {
        border-bottom: 2px solid #e1e8ee;
        padding: 10px 0; }
        .global-slot-changer-popup .capsul .delivery-list .inform .icon {
          width: 40px;
          height: 50px;
          display: inline-block; }
          .global-slot-changer-popup .capsul .delivery-list .inform .icon img {
            top: 10px;
            left: 20px;
            position: relative; }
        .global-slot-changer-popup .capsul .delivery-list .inform .close-icon {
          position: absolute;
          cursor: pointer;
          right: 20px;
          top: 22px; }
        .global-slot-changer-popup .capsul .delivery-list .inform .titles {
          display: inline-block;
          position: relative;
          top: 13px;
          left: 20px; }
          .global-slot-changer-popup .capsul .delivery-list .inform .titles .t1 {
            display: block;
            font-size: 18px;
            font-weight: 800;
            color: #2d3436; }
          .global-slot-changer-popup .capsul .delivery-list .inform .titles .t2 {
            font-size: 12px;
            font-weight: 500;
            color: #6c7a89; }
        .global-slot-changer-popup .capsul .delivery-list .inform .up-icon {
          float: right;
          position: relative;
          right: 20px;
          top: 30px;
          cursor: pointer; }
      .global-slot-changer-popup .capsul .delivery-list .time-list {
        width: 475px; }
        .global-slot-changer-popup .capsul .delivery-list .time-list .day-list {
          background-color: #2d3436;
          padding: 10px 0px 0px;
          text-align: center; }
          .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .day-item {
            width: 100px;
            height: 50px;
            padding-top: 5px;
            border: solid 2px #4c555f;
            display: inline-block;
            margin-bottom: 10px;
            margin-right: 30px;
            cursor: pointer; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .day-item:last-child {
              margin-right: 0; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .day-item .t1 {
              font-size: 13px;
              font-weight: 500;
              color: #6c7a89;
              display: block; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .day-item .t2 {
              font-size: 13px;
              font-weight: 800;
              color: #6c7a89;
              display: block; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .day-item .up-icon {
              float: right; }
          .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .active-day {
            background-color: #7dc855;
            border: solid 2px #7dc855; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .active-day .t1 {
              color: white !important; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .day-list .active-day .t2 {
              color: white !important; }
        .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list {
          height: 472px;
          background-color: white;
          -webkit-box-shadow: 0px 0px 127px -11px rgba(0, 0, 0, 0.42);
          -moz-box-shadow: 0px 0px 127px -11px rgba(0, 0, 0, 0.42);
          box-shadow: 0px 0px 127px -11px rgba(0, 0, 0, 0.42); }
          .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .checked-radio {
            position: relative;
            z-index: 10;
            -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);
            -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35);
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.35); }
          .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .inform-warn-color {
            color: #fdcb6e !important; }
          .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .inform-full-color {
            color: #ed3246 !important; }
          .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .disable-hour {
            opacity: 0.6; }
          .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item {
            background-color: white;
            border-bottom: 1px solid #e1e8ee;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            width: 236.6px;
            display: inline-block; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item:last-child {
              border-bottom: 1px solid #e1e8ee; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item input.check[type="radio"]:checked + label:before {
              background-color: #4d9c2d;
              box-shadow: inset 0 0 0 1px #4d9c2d; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item input.check[type="radio"] + label:before {
              top: 10px; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item input.check[type="radio"]:checked + label:after {
              top: 13px !important; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item .hour-hour {
              font-size: 13px;
              font-weight: 500;
              color: #2d3436; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item .hour-text {
              font-size: 11px;
              font-weight: 800;
              color: #6c7a89; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item input.check[type="radio"]:checked + label > .hour-hour {
              color: #181a1a !important; }
            .global-slot-changer-popup .capsul .delivery-list .time-list .hour-list .hour-item input.check[type="radio"]:checked + label > .hour-text {
              color: #181a1a !important; }

@media (min-width: 1280px) {
  .isAnonym .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; }
  .sb-home-capsul .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .isAnonym {
    padding-top: 83px; }
    .isAnonym .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .isAnonym .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .isAnonym .sb-contentCrate {
      margin-left: 40vw; }
      .isAnonym .sb-contentCrate .sb-contentColumn {
        padding-top: 100px;
        padding-bottom: 100px; }
  .sb-home-capsul .sb-headerCrate {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 40vw; }
    .sb-home-capsul .sb-headerCrate .sb-headerCrate--content {
      padding: 0 3.2rem; }
  .sb-home-capsul .sb-contentCrate {
    margin-left: 40vw;
    padding-top: 83px; }
    .sb-home-capsul .sb-contentCrate .manage .manage-list .manage-item {
      padding: 3rem 10rem !important; }
      .sb-home-capsul .sb-contentCrate .manage .manage-list .manage-item .text {
        line-height: 0px !important; }
    .sb-home-capsul .sb-contentCrate .autoReload .foot-modal {
      width: 60% !important; }
      .sb-home-capsul .sb-contentCrate .autoReload .foot-modal .close-icon {
        position: absolute !important; }
    .sb-home-capsul .sb-contentCrate .pinCode .foot-modal {
      width: 60% !important; }
      .sb-home-capsul .sb-contentCrate .pinCode .foot-modal .close-icon {
        position: absolute !important; }
    .sb-home-capsul .sb-contentCrate .addPyhsicalCard .base___3qZEk {
      margin-top: 100px; }
    .sb-home-capsul .sb-contentCrate .addPyhsicalCard .base___3qZEk {
      margin-top: 100px; }
    .sb-home-capsul .sb-contentCrate .addPyhsicalCard .lg-pl0, .sb-home-capsul .sb-contentCrate .addPyhsicalCard .lg-px0 {
      padding-left: 0 !important; }
    .sb-home-capsul .sb-contentCrate .addPyhsicalCard .base___3dWsJ {
      padding: 3.2rem 4.8rem !important; } }

@media (min-width: 375px) {
  .isAnonym .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .profile-add-new-card {
    border-radius: 0 !important; }
    .profile-add-new-card .row {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .profile-add-new-card .capsul {
      width: 100% !important;
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      transform: none !important;
      overflow: auto !important;
      border-radius: 0 !important; }
      .profile-add-new-card .capsul .head .title {
        font-size: 13px !important;
        padding: 15px !important; }
      .profile-add-new-card .capsul .head .close-icon-popup {
        padding: 12px !important; }
      .profile-add-new-card .capsul .add-cc .ig-card-form {
        text-align: center; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .name {
          position: relative;
          display: block !important;
          margin-right: 0 !important; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name .floating-label {
            position: absolute;
            pointer-events: none;
            left: 28px !important;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name input:focus + .floating-label,
          .profile-add-new-card .capsul .add-cc .ig-card-form .name input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .name input {
            width: 92% !important;
            height: 55px !important; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .surname {
          position: relative;
          display: block !important; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname .floating-label {
            position: absolute;
            pointer-events: none;
            left: 28px !important;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname input:focus + .floating-label,
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .surname input {
            width: 92% !important;
            height: 55px !important; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .sb-style-inp {
          margin-left: 15px !important;
          margin-right: 15px !important;
          margin-bottom: 10px !important; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number {
          position: relative; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number .floating-label {
            position: absolute;
            pointer-events: none;
            left: 15px !important;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number input:focus + .floating-label,
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number input {
            width: 92% !important;
            height: 55px !important; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cc-number .camera-icon {
            position: absolute;
            top: 38px;
            right: 40px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .month {
          display: inline-block;
          position: relative;
          margin-right: 0 !important; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .month .floating-label {
            position: absolute;
            pointer-events: none;
            left: 10px !important;
            top: 10px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .month .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .month input:focus + .floating-label,
          .profile-add-new-card .capsul .add-cc .ig-card-form .month input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .month input {
            width: 100% !important;
            height: 54px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .year {
          display: inline-block;
          position: relative;
          margin-right: 0 !important; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .year .floating-label {
            position: absolute;
            pointer-events: none;
            left: 10px !important;
            top: 10px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .year .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .year input:focus + .floating-label,
          .profile-add-new-card .capsul .add-cc .ig-card-form .year input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .year input {
            width: 100% !important;
            height: 54px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .cvv {
          display: inline-block;
          position: relative;
          margin-right: 0 !important; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv .floating-label {
            position: absolute;
            pointer-events: none;
            left: 18px !important;
            top: 38px;
            transition: 0.2s ease all; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv .floating-label {
            color: #6c7a89; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv input:focus + .floating-label,
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv input:valid + .floating-label {
            margin-top: -26px;
            background-color: white;
            font-size: 12px;
            color: #6c7a89;
            padding: 0 4px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .cvv input {
            width: 100% !important;
            height: 54px;
            border: 1px solid grey; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .error-msg {
          display: block;
          margin-top: 15px;
          font-size: 10px;
          font-weight: 500;
          color: #ff0005;
          text-align: left;
          margin-left: 16px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .error-input {
          border-color: #ff0005 !important; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-small-input {
          margin-top: 20px;
          height: 54px;
          border-radius: 4px;
          border-color: #bdc3c7;
          font-size: 14px;
          font-weight: 500;
          color: #2d3436; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-small-input {
          margin-top: 20px;
          height: 54px;
          border-radius: 4px;
          border-color: #bdc3c7;
          font-size: 14px;
          font-weight: 500;
          color: #2d3436; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-xsmall-input {
          margin-top: 20px;
          height: 54px;
          border-radius: 4px;
          border-color: #bdc3c7;
          font-size: 14px;
          font-weight: 500;
          color: #2d3436; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .normal-input {
          margin-top: 20px;
          height: 45px;
          width: 100%;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #cccccc; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .inform {
          background: url(https://s3-eu-west-1.amazonaws.com/alpha-igweb/exclamation.png);
          background-repeat: no-repeat;
          background-position-x: 0px;
          background-position-y: 13px; }
          .profile-add-new-card .capsul .add-cc .ig-card-form .inform p {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.43;
            color: #2d3436;
            text-align: left;
            padding: 30px 15px; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .ig-add-new-cc-btn {
          width: 95% !important; }
        .profile-add-new-card .capsul .add-cc .ig-card-form .cc-detail-box {
          padding: 0 10px 0 5px !important; }
  .profile-delete-card-modal .capsul .btns .delete-btn {
    margin-right: 0 !important;
    margin-bottom: 20px; }
  .sb-home-capsul .sb-contentCrate .sb-content-capsul .add-money {
    margin: 50px auto !important; }
  .sb-home-capsul .sb-contentCrate .sb-content-capsul .autoReload .foot-modal {
    margin: 0 0px !important; }
  .sb-home-capsul .sb-contentCrate .sb-content-capsul .noActiveCard {
    margin: 25px auto !important; }
  .generic-modal .capsul .cancelBtn {
    margin: 0 !important; } }

.sb-stores {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: calc(100% - 72px); }
  .sb-stores .sb-contentCrate {
    -webkit-box-flex: 1;
    flex-grow: 1;
    position: relative;
    z-index: 1;
    overflow: hidden;
    word-wrap: normal !important;
    background-color: #fff;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    flex-shrink: 0; }
    .sb-stores .sb-contentCrate .container--headerCrate {
      flex-shrink: 0;
      position: relative;
      z-index: 2;
      min-height: 50px;
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12); }
      .sb-stores .sb-contentCrate .container--headerCrate .controls {
        display: -webkit-box;
        display: flex;
        position: relative;
        z-index: 2;
        background-color: #fff; }
        .sb-stores .sb-contentCrate .container--headerCrate .controls .form_search {
          -webkit-box-flex: 1;
          flex: 1;
          height: 55px;
          display: -webkit-box;
          display: flex;
          position: relative; }
          .sb-stores .sb-contentCrate .container--headerCrate .controls .form_search input {
            border: 0;
            display: block;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            padding: 10px 0 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
          .sb-stores .sb-contentCrate .container--headerCrate .controls .form_search .buttonContainer {
            display: block;
            width: 4.8rem;
            height: 100%;
            position: absolute;
            right: 0; }
            .sb-stores .sb-contentCrate .container--headerCrate .controls .form_search .buttonContainer button {
              color: rgba(0, 0, 0, 0.56);
              -webkit-transition: color .2s ease-out;
              transition: color .2s ease-out;
              width: 100%;
              height: 100%; }
        .sb-stores .sb-contentCrate .container--headerCrate .controls .filterButton {
          position: relative;
          width: auto;
          color: rgba(0, 0, 0, 0.56);
          font-size: 1.5rem;
          font-weight: 400;
          -webkit-transition: color .2s ease-out;
          transition: color .2s ease-out;
          font-weight: bold; }
        .sb-stores .sb-contentCrate .container--headerCrate .controls .activeCheck {
          background-color: #00a862;
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 8rem;
          top: .8rem;
          left: 100%; }
    .sb-stores .sb-contentCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-stores .sb-contentCrate .locationCardList {
      -webkit-box-flex: 1;
      flex-grow: 1;
      position: relative;
      background: #fff;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      white-space: normal; }
      .sb-stores .sb-contentCrate .locationCardList .locationList {
        padding-top: 10px; }
        .sb-stores .sb-contentCrate .locationCardList .locationList .favorite-title {
          font-size: 1.3rem;
          line-height: 1.229;
          color: rgba(0, 0, 0, 0.56) !important;
          text-transform: uppercase !important;
          letter-spacing: 0.1em !important;
          padding: 0 3.2rem;
          font-weight: bold; }
        .sb-stores .sb-contentCrate .locationCardList .locationList .store-list-title {
          font-size: 1.3rem;
          line-height: 1.229;
          color: rgba(0, 0, 0, 0.56) !important;
          text-transform: uppercase !important;
          letter-spacing: 0.1em !important;
          padding: 0 3.2rem;
          font-weight: bold; }
        .sb-stores .sb-contentCrate .locationCardList .locationList .locationListItem {
          cursor: pointer;
          display: -webkit-box;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-direction: column;
          width: 100%;
          background-color: #fff;
          font-size: 1.3rem;
          pointer-events: all;
          -webkit-transition-duration: .2s;
          transition-duration: .2s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
          -webkit-transition-property: background-color;
          transition-property: background-color; }
          .sb-stores .sb-contentCrate .locationCardList .locationList .locationListItem:hover {
            background-color: rgba(0, 0, 0, 0.15); }
          .sb-stores .sb-contentCrate .locationCardList .locationList .locationListItem .header___3UekJ {
            line-height: 10px; }
        .sb-stores .sb-contentCrate .locationCardList .locationList .isActive {
          position: relative;
          background-color: rgba(0, 0, 0, 0.1); }
      .sb-stores .sb-contentCrate .locationCardList .noStoresCard {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        font-size: 1.3rem;
        pointer-events: all;
        -webkit-transition-duration: .2s;
        transition-duration: .2s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        -webkit-transition-property: background-color;
        transition-property: background-color; }
      .sb-stores .sb-contentCrate .locationCardList .foot-privacy .color-textBlackSoft {
        color: rgba(0, 0, 0, 0.56) !important; }
    .sb-stores .sb-contentCrate .locationDetail {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
      .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        top: 72px; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .sb-overlay__closeBtnContainer {
          height: 44px;
          margin: 1.6rem; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .sb-overlay__closeBtnContainer .sb-overlay__closeBtn {
            right: 0; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .sb-overlay__closeBtnContainer .sb-iconButton {
            transition: all .2s ease-out; }
            .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .sb-overlay__closeBtnContainer .sb-iconButton:hover {
              transition: all .2s ease-out;
              background-color: #f1f1f1;
              border-radius: 50%; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .sb-overlay__closeBtnContainer .color-textBlackSoft {
            color: rgba(0, 0, 0, 0.56) !important; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .pb1 {
          margin-top: 20px; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .pb1 .sb-heading--medium {
            font-size: 2.3rem;
            line-height: 1.455;
            margin-bottom: 0px;
            margin-top: 0; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .pb1 .color-textBlackSoft {
            margin-bottom: 0px;
            margin-top: 5px; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .info {
          display: -webkit-box;
          display: flex;
          line-height: 1.6;
          max-width: 350px;
          margin-top: 20px; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .info .address {
            -webkit-box-flex: 0;
            flex-grow: 0;
            flex-shrink: 0; }
            .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .info .address p {
              margin-top: 0px !important; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .info .links {
            margin-left: 20px !important;
            -webkit-box-flex: 0;
            flex-grow: 0;
            flex-shrink: 1; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .time-detail .sb-heading--xxsmall {
          font-size: 1.3rem;
          line-height: 1.229; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .time-detail .schedule {
          display: table;
          width: 100%;
          max-width: 350px; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .time-detail .schedule .scheduleItemHighlight {
            font-weight: 700;
            display: table-row; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .time-detail .schedule .scheduleItem {
            display: table-row;
            height: 30px; }
            .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .time-detail .schedule .scheduleItem .scheduleDay {
              display: table-cell; }
            .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .time-detail .schedule .scheduleItem .scheduleHours {
              text-align: right;
              display: table-cell; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .sb-heading--xxsmall {
          font-size: 1.3rem;
          line-height: 1.229; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .featureItem {
          margin-top: 16px;
          vertical-align: top;
          padding-top: 8px;
          color: rgba(0, 0, 0, 0.87);
          vertical-align: middle; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .featureItem .icon {
            margin-right: 6px;
            position: relative;
            top: -2px;
            vertical-align: middle; }
          .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .featureItem:first-of-type {
            margin-top: 0; }
    .sb-stores .sb-contentCrate .filters-capsul {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
      .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        top: 72px; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .sb-overlay__closeBtnContainer {
          height: 44px;
          margin: 1.6rem; }
          .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .sb-overlay__closeBtnContainer .sb-overlay__closeBtn {
            right: 0; }
          .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .sb-overlay__closeBtnContainer .sb-iconButton {
            transition: all .2s ease-out; }
          .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .sb-overlay__closeBtnContainer .color-textBlackSoft {
            color: rgba(0, 0, 0, 0.56) !important; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .title {
          padding: 0px 40px;
          font-weight: bold;
          font-size: 2.7rem;
          padding-bottom: 30px;
          margin-bottom: 0px; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list {
          padding-bottom: 9rem !important; }
          .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item .isActive {
            color: rgba(0, 0, 0, 0.87); }
          .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item button {
            color: rgba(0, 0, 0, 0.56);
            display: block;
            font-size: 1.4rem;
            position: relative;
            padding-top: 2rem;
            padding-bottom: 2rem;
            text-align: left;
            -webkit-transition: background-color .2s ease-out,color .2s ease-out;
            transition: background-color .2s ease-out,color .2s ease-out;
            width: 100%; }
            .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item button:hover {
              background-color: rgba(0, 0, 0, 0.03);
              outline: none; }
            .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item button .icon {
              margin-right: 18px;
              position: relative;
              top: -1px;
              left: -2px; }
            .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item button .activeIconWrapper {
              right: 12px; }
            .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item button .activeIcon {
              opacity: 0;
              -webkit-transition: opacity .2s ease-out;
              transition: opacity .2s ease-out;
              display: block; }
          .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item .flex-grow {
            font-weight: 500; }
          .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .filter-list .filter-item .showOpac {
            opacity: 1 !important; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .apply-btn-filter {
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 10;
          padding: 1.6rem; }
  .sb-stores .sb-headerCrate {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    flex-grow: 0;
    padding-top: 0;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }

@media (min-width: 1280px) {
  .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .info {
    -webkit-box-pack: justify;
    justify-content: space-between; } }

@media (min-width: 1024px) {
  .sb-stores {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    height: 100vh !important;
    padding-top: 83px; }
    .sb-stores .sb-contentCrate {
      -webkit-box-flex: 0;
      flex-grow: 0;
      flex-basis: 40vw;
      -webkit-box-ordinal-group: 2;
      order: 1;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12); }
      .sb-stores .sb-contentCrate .container--headerCrate {
        margin-bottom: 1.6rem;
        padding-bottom: .8rem;
        box-shadow: none; }
        .sb-stores .sb-contentCrate .container--headerCrate .lg-pl5, .sb-stores .sb-contentCrate .container--headerCrate .lg-px5 {
          padding-left: 3.2rem !important; }
        .sb-stores .sb-contentCrate .container--headerCrate .controls {
          padding-top: 1.6rem; }
      .sb-stores .sb-contentCrate .locationCardList .lg-pl6, .sb-stores .sb-contentCrate .locationCardList .lg-px6 {
        padding-left: 4rem !important; }
      .sb-stores .sb-contentCrate .locationCardList .lg-pb4 {
        padding-bottom: 2.4rem !important; }
      .sb-stores .sb-contentCrate .container--headerCrate {
        padding: 0 3.2rem; }
      .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay {
        right: 60vw !important;
        top: 83px !important; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .sb-overlay__closeBtnContainer {
          margin-right: 3.2rem;
          margin-left: 3.2rem;
          margin-bottom: 1.6rem;
          margin-bottom: 0px; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .lg-pb6 {
          padding-bottom: 4rem !important; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .lg-pl6, .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .lg-px6 {
          padding-left: 4rem !important; }
        .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .lg-px6 {
          padding-right: 4rem !important; }
      .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay {
        right: 60vw !important;
        top: 83px !important; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .sb-overlay__closeBtnContainer {
          margin-right: 3.2rem;
          margin-left: 3.2rem;
          margin-bottom: 1.6rem;
          margin-bottom: 0px; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .lg-pb6 {
          padding-bottom: 4rem !important; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .lg-pl6, .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .lg-px6 {
          padding-left: 4rem !important; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .lg-px6 {
          padding-right: 4rem !important; }
        .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .apply-btn-filter {
          right: auto !important;
          left: 40vw;
          padding-left: 3.2rem;
          padding-right: 3.2rem;
          -webkit-transform: translateX(-100%) !important;
          transform: translateX(-100%) !important;
          padding: 3.2rem 4.8rem !important; }
    .sb-stores .sb-headerCrate {
      flex-basis: 60vw;
      -webkit-box-ordinal-group: 3;
      order: 2;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; } }

@media (min-width: 768px) {
  .sb-stores {
    height: calc(100% - 82px); } }

@media (min-width: 480px) {
  .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .sm-px6 {
    padding-right: 4rem !important; }
  .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .sm-pl6, .sb-stores .sb-contentCrate .filters-capsul .expandedLocationOverlay .Filters .sm-px6 {
    padding-left: 4rem !important; } }

@media (max-width: 768px) {
  .sb-stores .sb-contentCrate {
    overflow: unset !important;
    height: 50vh !important; }
  .sb-stores .sb-headerCrate .container___2qSwp .mapCont {
    height: 40vh !important; } }

@media (max-width: 1023px) and (min-width: 480px), (min-width: 1280px) {
  .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .info {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
    -webkit-box-align: start !important;
    align-items: start !important; } }

@media (max-width: 1023px) and (min-width: 600px), (min-width: 1600px) {
  .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .sb-heading--xxsmall {
    font-size: 1.3rem;
    line-height: 1.229; }
  .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .featureItem {
    display: inline-block;
    width: 33%;
    padding-right: 3%;
    vertical-align: middle; }
    .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .featureItem:nth-of-type(-n+3) {
      margin-top: 0; } }

@media (max-width: 599px) and (min-width: 420px), (max-width: 1599px) and (min-width: 1024px) {
  .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .sb-heading--xxsmall {
    font-size: 1.3rem;
    line-height: 1.229; }
  .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .featureList .featureItem {
    display: inline-block;
    width: 50%;
    padding-right: 2%;
    vertical-align: middle; } }

@media (max-width: 479px), (min-width: 1024px) {
  .sb-stores .sb-contentCrate .locationDetail .expandedLocationOverlay .expandedLocationCardLabel .address-detail .info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; } }

.sb-corporate {
  position: relative;
  z-index: 1; }
  .sb-corporate .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-corporate .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-corporate .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-corporate .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-corporate .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }

@media (min-width: 1280px) {
  .sb-corporate .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-corporate {
    padding-top: 83px; }
    .sb-corporate .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-corporate .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-corporate .sb-contentCrate {
      margin-left: 40vw; }
      .sb-corporate .sb-contentCrate .sb-contentColumn {
        padding-top: 100px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-corporate .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-corporate .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-corporate .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

.sb-join-main {
  position: relative;
  z-index: 1; }
  .sb-join-main .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-join-main .sb-headerCrate .base___46s3P {
      height: 100%; }
      .sb-join-main .sb-headerCrate .base___46s3P .register-logo {
        width: 48px !important;
        height: 48px !important; }
      .sb-join-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content {
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 2.4rem;
        display: block;
        width: 100%;
        padding: 0 1.6rem; }
        .sb-join-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content .sb-heading {
          margin: 0;
          font-size: 2.3rem;
          line-height: 1.385; }
  .sb-join-main .sb-contentCrate {
    /*
        .sb-small-footer{
            .container-footer{
                display: -webkit-box;
                display: flex;

                .find-store{
                    a{
                        text-decoration: none;
                        &:hover{
                            color: #008248;
                        }
                    }
                }

                .footer-links{
                    a{
                        color: currentColor;
                    }
                }

            }

            .container-footer>* {
                padding: 0 6.4rem 0 0;
            }
        }
        */ }
    .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn {
      padding: 2.4rem 1.6rem 6.4rem; }
      .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .pb6 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .phone-set {
        padding-bottom: 0 !important; }
        .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .phone-set .areaCode {
          width: 80px;
          display: inline-block; }
        .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .phone-set .phoneNumber {
          width: 260px;
          display: inline-block;
          position: relative;
          top: 1px;
          left: 3px; }
      .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .password-targets {
        margin-top: 20px; }
        .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .password-targets .target-item {
          display: block;
          position: relative; }
          .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .password-targets .target-item .title {
            padding-left: 20px; }
          .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .password-targets .target-item .fieldStatus__icon {
            top: 0 !important; }
          .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .password-targets .target-item .check {
            color: #00653e; }
          .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .password-targets .target-item .close {
            color: #e75b52; }
      .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .select-first-name {
        padding: 15px !important;
        background-color: #fff0;
        border-radius: 2px;
        border: 1px solid #c6c6c6;
        margin-left: 0px;
        margin-right: 0px; }
        .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .select-first-name .s-title {
          display: block;
          margin-bottom: 10px;
          font-weight: bold;
          color: #6c6c6c; }
        .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn .select-first-name .option--radio {
          display: inline-block;
          margin-right: 15px; }
    .sb-join-main .sb-contentCrate .activation-modal {
      position: fixed;
      top: 0;
      width: 100%;
      background-color: #00000054;
      height: 100%;
      z-index: 9; }
      .sb-join-main .sb-contentCrate .activation-modal .in-modal {
        background-color: white;
        position: absolute;
        bottom: 0;
        height: auto;
        width: 100%;
        border: 1px solid #e2e0e0;
        padding: 10px; }
        .sb-join-main .sb-contentCrate .activation-modal .in-modal .close-icon {
          position: absolute;
          right: 40px;
          top: 40px; }
        .sb-join-main .sb-contentCrate .activation-modal .in-modal .active-form .activation-inp {
          height: 45px;
          width: 100%;
          border-radius: 15px;
          border: 2px solid gray;
          padding: 15px; }
        .sb-join-main .sb-contentCrate .activation-modal .in-modal .active-form .btns {
          margin-top: 25px;
          margin-bottom: 100px; }
          .sb-join-main .sb-contentCrate .activation-modal .in-modal .active-form .btns .resendBtn {
            margin-right: 15px; }
            .sb-join-main .sb-contentCrate .activation-modal .in-modal .active-form .btns .resendBtn button {
              background-color: #616161; }

@media (min-width: 1280px) {
  .sb-join-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-join-main .sb-headerCrate {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 40vw; }
    .sb-join-main .sb-headerCrate .lg-flex {
      display: flex !important; }
    .sb-join-main .sb-headerCrate .lg-m4 {
      margin: 2.4rem !important; }
    .sb-join-main .sb-headerCrate .base___46s3P {
      height: 100%; }
      .sb-join-main .sb-headerCrate .base___46s3P .register-logo {
        width: 40px;
        height: 40px; }
      .sb-join-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content {
        padding: 0 3.2rem; }
  .sb-join-main .sb-contentCrate {
    margin-left: 40vw; }
    .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn {
      padding-top: 150px;
      padding-bottom: 100px; }
    .sb-join-main .sb-contentCrate .activation-modal .in-modal {
      width: 60% !important; } }

@media (min-width: 768px) {
  .sb-join-main .sb-headerCrate .base___46s3P .register-logo {
    width: 40px;
    height: 40px; } }

@media (min-width: 375px) {
  .sb-join-main .sb-contentCrate .sb-register-form .flex-auto .sb-contentColumn {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; }
  .sb-join-main .sb-contentCrate .active-form {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

.sb-login-main {
  position: relative;
  z-index: 1; }
  .sb-login-main .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-login-main .sb-headerCrate .base___46s3P {
      height: 100%; }
      .sb-login-main .sb-headerCrate .base___46s3P .register-logo {
        width: 48px !important;
        height: 48px !important; }
      .sb-login-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content {
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 2.4rem;
        display: block;
        width: 100%;
        padding: 0 1.6rem; }
        .sb-login-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content .sb-heading {
          margin: 0;
          font-size: 2.3rem;
          line-height: 1.385; }
  .sb-login-main .sb-contentCrate {
    /*
        .sb-small-footer{
            .container-footer{
                display: -webkit-box;
                display: flex;

                .find-store{
                    a{
                        text-decoration: none;
                        &:hover{
                            color: #008248;
                        }
                    }
                }

                .footer-links{
                    a{
                        color: currentColor;
                    }
                }

            }

            .container-footer>* {
                padding: 0 6.4rem 0 0;
            }
        }
        */ }
    .sb-login-main .sb-contentCrate .send-again--btn- {
      display: block;
      margin: 10px 0;
      font-weight: bold;
      color: #5a9c7f; }
    .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .newPassword .pb3 {
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0; }
    .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .newPassword .password-targets {
      margin-top: 20px; }
      .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .newPassword .password-targets .target-item {
        display: block;
        position: relative; }
        .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .newPassword .password-targets .target-item .title {
          padding-left: 20px; }
        .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .newPassword .password-targets .target-item .fieldStatus__icon {
          top: 0 !important; }
        .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .newPassword .password-targets .target-item .check {
          color: #00653e; }
        .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .newPassword .password-targets .target-item .close {
          color: #e75b52; }
    .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .sb-contentColumn {
      padding: 2.4rem 1.6rem 6.4rem; }
      .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .sb-contentColumn .pb6 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
        .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .sb-contentColumn .pb6 .rememberMe {
          margin-top: 20px; }
      .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .sb-contentColumn .cancelBtn {
        margin-right: 30px; }
        .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .sb-contentColumn .cancelBtn button {
          background-color: #616161; }

@media (min-width: 1280px) {
  .sb-login-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-login-main .sb-headerCrate {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 40vw; }
    .sb-login-main .sb-headerCrate .lg-flex {
      display: flex !important; }
    .sb-login-main .sb-headerCrate .lg-m4 {
      margin: 2.4rem !important; }
    .sb-login-main .sb-headerCrate .base___46s3P {
      height: 100%; }
      .sb-login-main .sb-headerCrate .base___46s3P .register-logo {
        width: 40px;
        height: 40px; }
      .sb-login-main .sb-headerCrate .base___46s3P .flex .sb-headerCrate--content {
        padding: 0 3.2rem; }
  .sb-login-main .sb-contentCrate {
    margin-left: 40vw; }
    .sb-login-main .sb-contentCrate .sb-login-form {
      margin-top: 100px; }
      .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .sb-contentColumn {
        padding-top: 100px;
        padding-bottom: 100px; } }

@media (min-width: 768px) {
  .sb-login-main .sb-headerCrate .base___46s3P .register-logo {
    width: 40px;
    height: 40px; } }

@media (min-width: 375px) {
  .sb-login-main .sb-contentCrate .sb-login-form .flex-auto .sb-contentColumn {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

.sb-account-capsul {
  position: relative;
  z-index: 1; }
  .sb-account-capsul .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-account-capsul .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
      .sb-account-capsul .sb-headerCrate .sb-headerCrate--content .sb-heading--large {
        font-size: 2.7rem;
        line-height: 1.385; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards {
    width: 300px;
    margin: 150px auto; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .title {
      display: block;
      font-size: 18px;
      font-weight: 500;
      color: #777777;
      margin-bottom: 50px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards {
      margin-top: 20px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .selected {
        background-color: #d6e1da !important;
        border: 1px solid #016341 !important; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item {
        border-bottom: 1px solid #ababab;
        margin-bottom: 20px;
        position: relative;
        padding: 15px 15px 10px;
        cursor: pointer;
        border: 2px solid #e6e5e5; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-logo {
          display: inline-block;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-logo img {
            width: 64px;
            margin-right: 10px;
            vertical-align: middle; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-inform {
          display: inline-block;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-inform .card-title {
            display: block;
            color: #555555;
            font-size: 15px; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-inform .card-number {
            display: block;
            color: #9e9e9e;
            font-size: 15px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-action {
          display: inline-block;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-action .card-delete-btn {
            display: none;
            background-color: #e75b52;
            color: white;
            position: absolute;
            right: -90px;
            font-size: 21px;
            padding: 19px;
            bottom: 0px;
            font-weight: 100;
            cursor: pointer; }
            .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .card-action .card-delete-btn:hover {
              opacity: 0.8;
              -moz-transition: 0.4s;
              -o-transition: 0.4s;
              -webkit-transition: 0.4s;
              transition: 0.4s; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item:hover {
          -moz-transition: 0.3s;
          -o-transition: 0.3s;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          background-color: #8080800f; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .credit-cards .card-item .deleteIcon {
          position: absolute;
          right: 5px;
          top: 5px;
          cursor: pointer; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .add-credit-card .add-credit-card-btn {
      font-size: 18px;
      cursor: pointer; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .add-credit-card .add-credit-card-btn i {
        border-radius: 5px;
        border: 1px solid #c1c1c1;
        padding: 15px 20px;
        font-size: 14px;
        color: #c1c1c1;
        margin-right: 5px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .my-cards .add-credit-card .masterpass-icon {
      width: 124px;
      display: block;
      margin-left: 55px;
      margin-top: 10px; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money {
    width: 350px;
    margin: 150px auto;
    position: relative; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .title {
      display: block;
      font-size: 18px;
      color: #777777;
      margin-bottom: 50px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .balance-sum {
      display: table;
      margin: 20px 0;
      background-color: #016341;
      color: white;
      padding: 10px;
      border-radius: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input {
      position: relative;
      display: block;
      margin-bottom: 20px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .show-password {
        width: 24px;
        height: 24px;
        fill: rgba(0, 0, 0, 0.56) !important;
        position: absolute;
        right: 0;
        bottom: 14px;
        cursor: pointer; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .hide-password {
        width: 24px;
        height: 24px;
        fill: rgba(0, 0, 0, 0.56) !important;
        position: absolute;
        right: 0;
        bottom: 14px;
        cursor: pointer; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .form-inpt {
        width: 100%;
        margin-top: 20px;
        height: 45px;
        font-size: 14px;
        font-weight: 500;
        color: #2d3436;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        background-color: transparent;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        padding-right: 40px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input .floating-label {
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 38px;
        transition: 0.2s ease all;
        color: rgba(0, 0, 0, 0.56) !important;
        font-weight: 500; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input input:focus + .floating-label, .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input input:valid + .floating-label {
        margin-top: -26px;
        font-size: 12px;
        color: #6c7a89;
        padding: 0 0px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input textarea:focus, .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .blocking-input input:focus {
        outline: none; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards {
      margin-top: 20px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .selected {
        background-color: #d6e1da !important;
        border: 1px solid #016341 !important; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item {
        border-bottom: 1px solid #ababab;
        margin-bottom: 20px;
        position: relative;
        padding: 15px 15px 10px;
        cursor: pointer;
        border: 2px solid #e6e5e5;
        border: 2px solid #e6e5e5;
        border-radius: 4px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-logo {
          display: inline-block;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-logo img {
            width: 64px;
            margin-right: 10px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-inform {
          display: inline-block;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-inform .card-title {
            display: block;
            color: #555555;
            font-size: 18px; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-inform .card-number {
            display: block;
            color: #9e9e9e;
            font-size: 18px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-action {
          display: inline-block;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-action .card-delete-btn {
            display: none;
            background-color: #e75b52;
            color: white;
            position: absolute;
            right: -90px;
            font-size: 21px;
            padding: 19px;
            bottom: 0px;
            font-weight: 100;
            cursor: pointer; }
            .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .card-action .card-delete-btn:hover {
              opacity: 0.8;
              -moz-transition: 0.4s;
              -o-transition: 0.4s;
              -webkit-transition: 0.4s;
              transition: 0.4s; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item:hover {
          -moz-transition: 0.3s;
          -o-transition: 0.3s;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          background-color: #8080800f; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .credit-cards .card-item .deleteIcon {
          position: absolute;
          right: 5px;
          top: 5px;
          cursor: pointer; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .add-credit-card .add-credit-card-btn {
      font-size: 18px;
      cursor: pointer; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .add-credit-card .add-credit-card-btn i {
        border-radius: 5px;
        border: 1px solid #c1c1c1;
        padding: 15px 20px;
        font-size: 14px;
        color: #c1c1c1;
        margin-right: 5px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .add-credit-card .masterpass-icon {
      width: 124px;
      display: block;
      margin-left: 55px;
      margin-top: 10px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .addMoneyConfirm {
      position: absolute;
      display: inline-block;
      z-index: 1;
      padding: 18px 2.4rem;
      background: #00a862;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.24), 0 8px 12px rgba(0, 0, 0, 0.14);
      border-radius: 500px;
      color: #fff;
      font-size: 19px;
      font-size: 1.9rem;
      font-weight: 500;
      line-height: 1.2;
      overflow: hidden;
      text-align: center;
      text-decoration: none;
      transition-duration: .2s;
      transition-property: transform,box-shadow,width,height;
      transition-timing-function: ease;
      cursor: pointer;
      transition: background .8s;
      right: 0; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .addMoneyConfirm:hover {
        background: #039458 radial-gradient(circle, transparent 1%, #039458 1%) center/15000%; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .add-money .addMoneyConfirm:active {
        background-color: #07c274;
        background-size: 100%;
        transition: background 0s; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountIndex .account-list .account-item {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountIndex .account-list .account-item:hover {
      background-color: #dcdcdc59;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountIndex .account-list .account-item .title {
      font-size: 22px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountIndex .account-list .account-item .text {
      color: rgba(0, 0, 0, 0.56) !important;
      line-height: initial; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountIndex .account-list .account-item .item-icon {
      margin-left: 20px;
      width: 36px;
      height: 36px; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory {
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list {
      margin-top: 40px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item {
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        background-color: white;
        border-radius: 2px;
        margin-bottom: 30px;
        padding: 15px;
        cursor: pointer; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .grid {
          margin: 0 !important;
          padding-top: 15px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .icon {
          display: inline-block;
          width: 32px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .inform {
          display: inline-block;
          line-height: 0.8;
          padding-left: 20px; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .inform .title {
            font-weight: bold; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail {
          line-height: 1.5;
          margin-top: -5px; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail h3 {
            margin: 0; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail .s1 {
            display: block; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail .s2 {
            display: block; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .accountHistory .history-list .history-item .detail .s3 {
            display: block; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation {
    padding: 20px;
    height: calc(100vh - 120px); }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-code {
      box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
      background-color: #fff;
      border-radius: 2px;
      margin-bottom: 30px;
      padding: 15px;
      text-align: center; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-code .code {
        display: block;
        font-size: 28px;
        font-weight: bold;
        color: #00a862; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list {
      margin-top: 40px;
      box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
      background-color: #fff;
      border-radius: 2px;
      margin-bottom: 30px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .head {
        background-color: #c4c4c4;
        padding: 10px;
        color: #000000;
        border-bottom: 3px solid #afafaf;
        font-size: 15px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item {
        border-bottom: 1px solid #e8e5e5;
        padding: 30px 20px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .icon {
          display: inline-block;
          width: 32px;
          vertical-align: middle; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .inform {
          display: inline-block;
          padding-left: 20px;
          width: calc(100% - 100px);
          line-height: 18px;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .inform .title {
            font-weight: bold; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .detail {
          line-height: 1.5;
          margin-top: -5px;
          width: 80%;
          display: inline-block;
          vertical-align: middle; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .detail h3 {
            margin: 0; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .detail .s1 {
            display: block; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .detail .s2 {
            display: block; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .detail .s3 {
            display: block; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item .date-box {
          vertical-align: middle;
          display: inline-block;
          text-align: right;
          width: 20%; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .invitation-item:last-child {
          border: none; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .invitation-list .non-invitation {
        text-align: center;
        width: 460px;
        max-width: 100%;
        margin: 0 auto;
        padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .land-capsul .content {
      max-width: 300px;
      margin: 60px auto; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-right: 40px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box .left {
        width: 50%;
        display: inline-block;
        vertical-align: middle; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box .left .more-inform-btn {
          display: inline-block;
          border: 1px solid black;
          border-radius: 25px;
          padding: 5px 10px;
          cursor: pointer; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box .left .more-inform-btn:hover {
            background-color: black;
            color: white; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box .right {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: right; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard {
    width: 320px;
    margin: 150px auto;
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .noActiveCard-img {
      display: block; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .text {
      display: block; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .text .detail {
        font-size: 12px;
        color: gray; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .add-credit-card {
      display: block;
      margin-top: 20px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .add-credit-card .add-credit-card-btn {
        font-size: 14px;
        cursor: pointer;
        color: gray; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .add-credit-card .add-credit-card-btn i {
          border-radius: 5px;
          border: 1px solid #c1c1c1;
          padding: 10px 20px;
          font-size: 14px;
          color: #c1c1c1;
          margin-right: 5px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .masterpass {
      text-align: center;
      margin-top: 10px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard .masterpass img {
        width: 185px; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .head {
    width: 100%;
    background-color: white;
    padding: 25px;
    border-bottom: 1px solid lightgrey; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .head .title {
      font-size: 18px;
      font-weight: bold; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .head .id {
      float: right;
      padding-top: 5px; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-list {
    padding: 30px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-list .receipt-item {
      margin-top: 20px;
      border-bottom: 3px dotted #d3d3d3;
      padding-bottom: 10px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-list .receipt-item .receipt-title {
        font-size: 16px;
        font-weight: bold; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-total {
    width: 300px;
    float: right;
    margin-top: 60px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .receipt .receipt-total .s1 .t1 {
      width: 70px;
      display: inline-block;
      font-weight: bold; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards {
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards {
      width: 500px;
      max-width: 100%;
      margin: 2% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .reTitle {
        font-size: 1.3rem;
        line-height: 1.229; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards {
        text-align: center;
        background-color: white;
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        border-radius: 2px;
        padding-bottom: 20px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .svgBox {
          position: relative; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .svgBox .rewards-svg path {
            will-change: auto;
            stroke-width: 15px;
            stroke-miterlimit: round;
            transition: stroke-dashoffset 850ms ease-in-out; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .svgBox .digitalRewardsIcon {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            color: #656565; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .svgBox .countRewards {
            position: absolute;
            bottom: 50px;
            left: 0;
            right: 0;
            font-size: 28px;
            font-weight: bold;
            color: #656565; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .svgBox .lastUpdate {
            display: block;
            color: #adadad; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .hasBeenRewards {
          margin-top: 10px; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .hasBeenRewards .earnedRewardsCount .count {
            font-size: 39px;
            font-weight: bold;
            vertical-align: middle;
            color: #3e3e3e; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .hasBeenRewards .earnedRewardsCount .bardakIcon {
            width: 40px;
            height: 40px;
            vertical-align: middle;
            color: #3e3e3e; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .earnedRewards .hasBeenRewards .earnedRewardsCount .title {
            display: block;
            color: #3e3e3e;
            font-size: 18px;
            font-weight: bold; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .whenWillEnd {
        margin-top: 100px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .whenWillEnd .titles {
          font-size: 1.3rem;
          line-height: 1.229; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .rules .titles {
        font-size: 1.3rem;
        line-height: 1.229; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .rules .benefitContainer .benefitImage {
        width: 140px;
        min-width: 140px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .myRewards .rules .benefitContainer:nth-of-type(2n) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        flex-direction: row-reverse; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .howItWork {
      width: 500px;
      max-width: 100%;
      margin: 6% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .howItWork .title {
        font-size: 2.3rem;
        line-height: 1.455; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .howItWork .pb5 .imgWrapper {
        min-width: 100px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .howItWork .pb5 .sb-heading {
        margin-bottom: 0px !important;
        margin-top: 0px !important; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .rewards .howItWork .pb5 .sb-heading--medium {
        font-size: 2.3rem;
        line-height: 1.455; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo {
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm {
      width: 350px;
      max-width: 100%;
      margin: 8% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .pb1 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .saveBtn {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        padding: 1.6rem; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .birthday {
        margin-top: 15px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .birthday .title {
          color: var(--colorTextBlackSoft) !important; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .birthday .rdt {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 0;
          margin-top: 0; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .birthday .rdt .form-control {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            color: rgba(0, 0, 0, 0.9);
            display: block;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 10px;
            line-height: 1rem;
            padding: 10px 0 6px;
            width: 100%;
            z-index: 1; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .birthday .rdt .form-control::-ms-clear,
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .birthday .rdt .form-control::-ms-reveal {
            display: none; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .personalInfo .informForm .birthday .rdt .form-control:focus {
            outline: none !important; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings {
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm {
      width: 340px;
      max-width: 100%;
      margin: 8% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .titles {
        font-size: 1.8rem;
        line-height: 1.411;
        margin-bottom: 50px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .pb1 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0;
        margin-bottom: 20px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .pb1 .mb9 {
          margin-top: var(--space-5) !important;
          margin-bottom: var(--space-2) !important; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .pb1 .title {
          color: #808080;
          font-size: 12px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .pb1 .sb-editField {
          margin-top: 5px !important; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .pb1 .sb-editField .floatLabel {
            font-size: 16px;
            font-weight: bold;
            color: #404040 !important; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .err-msg {
        color: #e75b52;
        font-size: 13px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .settings .settingsForm .help-item {
        padding: 1.6rem 0 !important; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail {
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail .editEmailForm {
      width: 355px;
      max-width: 100%;
      margin: 10% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail .editEmailForm .pb2 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail .editEmailForm .informNote {
        font-size: 13px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail .digitVerfiy {
      width: 355px;
      max-width: 100%;
      margin: 10% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail .digitVerfiy .title {
        font-size: 1.8rem;
        line-height: 1.411; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail .digitVerfiy .pb2 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editEmail .digitVerfiy .informNote {
        font-size: 13px; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone {
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .editPhoneForm {
      width: 355px;
      max-width: 100%;
      margin: 10% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .editPhoneForm .pb2 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .editPhoneForm .cancelBtn {
        margin-right: 30px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .editPhoneForm .cancelBtn button {
          background-color: #616161; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .phoneVerificationCode {
      width: 355px;
      max-width: 100%;
      margin: 10% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .phoneVerificationCode .title {
        font-size: 14px;
        margin-bottom: 15px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .phoneVerificationCode .deepNote {
        font-size: 13px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .phoneVerificationCode .pb2 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .phoneVerificationCode .cancelBtn {
        margin-right: 30px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPhone .phoneVerificationCode .cancelBtn button {
          background-color: #616161; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword {
    padding: 20px; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm {
      width: 355px;
      max-width: 100%;
      margin: 10% auto; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm .pb3 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm .password-targets {
        margin-top: 20px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm .password-targets .target-item {
          display: block;
          position: relative; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm .password-targets .target-item .title {
            padding-left: 20px; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm .password-targets .target-item .fieldStatus__icon {
            top: 0 !important; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm .password-targets .target-item .check {
            color: #00653e; }
          .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .editPasswordForm .password-targets .target-item .close {
            color: #e75b52; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .sb-contentColumn {
      padding: 2.4rem 1.6rem 6.4rem; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .sb-contentColumn .pb6 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .sb-contentColumn .pb6 .rememberMe {
          margin-top: 20px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .sb-contentColumn .cancelBtn {
        margin-right: 30px; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .editPassword .sb-contentColumn .cancelBtn button {
          background-color: #616161; }

@media (min-width: 1280px) {
  .sb-account-capsul .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-account-capsul .sb-headerCrate {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 40vw; }
    .sb-account-capsul .sb-headerCrate .sb-headerCrate--content {
      padding: 0 3.2rem; }
  .sb-account-capsul .sb-contentCrate {
    margin-left: 40vw;
    padding-top: 83px; }
    .sb-account-capsul .sb-contentCrate .accountIndex .account-list .account-item {
      padding: 3rem 10rem !important; }
      .sb-account-capsul .sb-contentCrate .accountIndex .account-list .account-item .text {
        line-height: 0px !important; }
    .sb-account-capsul .sb-contentCrate .autoReload .foot-modal {
      width: 60% !important; }
      .sb-account-capsul .sb-contentCrate .autoReload .foot-modal .close-icon {
        position: absolute !important; }
    .sb-account-capsul .sb-contentCrate .pinCode .foot-modal {
      width: 60% !important; }
      .sb-account-capsul .sb-contentCrate .pinCode .foot-modal .close-icon {
        position: absolute !important; }
    .sb-account-capsul .sb-contentCrate .addPyhsicalCard .base___3qZEk {
      margin-top: 100px; }
    .sb-account-capsul .sb-contentCrate .addPyhsicalCard .base___3qZEk {
      margin-top: 100px; }
    .sb-account-capsul .sb-contentCrate .addPyhsicalCard .lg-pl0, .sb-account-capsul .sb-contentCrate .addPyhsicalCard .lg-px0 {
      padding-left: 0 !important; }
    .sb-account-capsul .sb-contentCrate .addPyhsicalCard .base___3dWsJ {
      padding: 3.2rem 4.8rem !important; }
    .sb-account-capsul .sb-contentCrate .personalInfo .informForm .saveBtn {
      padding: 3.2rem 4.8rem !important; } }

@media (min-width: 768px) {
  .rewards .myRewards .rules .md-py3 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; } }

@media (max-width: 768px) {
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .paymentMethods .add-money {
    margin: 25px auto !important; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .noActiveCard {
    margin: 25px auto !important; }
  .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation {
    height: auto !important; }
    .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box {
      position: unset !important; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box .left {
        width: 100% !important;
        text-align: left; }
        .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box .left .more-inform-btn {
          margin-bottom: 20px; }
      .sb-account-capsul .sb-contentCrate .sb-content-capsul .invitation .sticky-box .right {
        width: 100% !important;
        text-align: left; }
  .invitation {
    padding: 20px 10px 120px !important; }
    .invitation .history-item {
      padding: 10px 5px !important; }
      .invitation .history-item .inform {
        padding-left: 0 !important; }
        .invitation .history-item .inform .title {
          display: block;
          line-height: normal;
          margin-bottom: 10px;
          font-size: 12px; }
        .invitation .history-item .inform .text {
          line-height: normal;
          font-size: 12px; }
        .invitation .history-item .inform p {
          padding: 0;
          margin: 0;
          font-size: 12px; }
      .invitation .history-item .detail {
        width: 85%;
        display: inline-block;
        vertical-align: middle; }
      .invitation .history-item .detail-full {
        width: 100%;
        display: block; }
      .invitation .history-item .empt {
        display: none; }
      .invitation .history-item .sendBtn {
        text-align: right !important;
        width: 15%;
        display: inline-block;
        vertical-align: middle; }
        .invitation .history-item .sendBtn svg {
          width: 24px; }
  .sticky-actions {
    bottom: 15px !important;
    right: 15px !important; }
    .sticky-actions .sb-frap {
      zoom: 0.8; } }

.sticky-actions {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 10; }
  .sticky-actions .sb-frap {
    display: block;
    margin-top: 10px; }

.sb-inbox-capsul {
  position: relative;
  z-index: 1; }
  .sb-inbox-capsul .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-inbox-capsul .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
      .sb-inbox-capsul .sb-headerCrate .sb-headerCrate--content .sb-heading--large {
        font-size: 2.7rem;
        line-height: 1.385; }
  .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-head {
    border-bottom: 1px solid #e1e1e1; }
    .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-head .message-menu {
      padding: 15px 30px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-head .message-menu li {
        display: inline-block;
        margin-right: 20px;
        font-weight: bold;
        cursor: pointer;
        position: relative; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-head .message-menu .count-inbox {
        position: absolute;
        display: inline-block;
        padding: 2px 6px;
        border-radius: 12px;
        background-color: #008247;
        font-size: 10px;
        font-weight: 800;
        text-align: center;
        color: #ffffff;
        top: -12px;
        right: -10px;
        height: 12px;
        width: 10px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-head .message-menu .active {
        border-bottom: 4px solid #007d40; }
  .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content {
    padding: 15px; }
    .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .no-message {
      width: 300px;
      max-width: 100%;
      margin: 5% auto; }
    .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item {
      box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
      background-color: white;
      padding: 25px 50px;
      margin-bottom: 20px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item .mess-left {
        width: 50%;
        display: inline-block;
        vertical-align: middle; }
        .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item .mess-left .new-msg {
          display: block;
          color: #696969;
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 10px; }
        .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item .mess-left .title {
          display: block;
          font-size: 28px;
          margin-bottom: 10px;
          color: #232323; }
        .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item .mess-left .date {
          display: block;
          color: #b4b4b4;
          margin-bottom: 10px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item .mess-right {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: right; }
        .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item .mess-right .msg-img {
          width: 150px;
          border-radius: 50%; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-list .message-item:hover {
        background-color: #e9e9e9;
        transition: all .2s ease-out; }
    .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-detail {
      width: 600px;
      max-width: 100%;
      margin: 5% auto; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-detail .cover-img {
        display: block;
        border-radius: 4px;
        margin-bottom: 15px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-detail .title {
        display: block;
        color: #151515;
        font-size: 28px;
        margin-bottom: 15px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-detail .sub-title {
        display: block;
        color: #151515;
        font-size: 18px;
        margin-bottom: 10px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-detail .date {
        display: block;
        color: #a8a8a8;
        font-size: 14px;
        margin-bottom: 10px; }
      .sb-inbox-capsul .sb-contentCrate .sb-content-capsul .message-capsul .message-content .message-detail .text {
        display: block; }

@media (min-width: 1280px) {
  .sb-inbox-capsul .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-inbox-capsul .sb-headerCrate {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 40vw; }
    .sb-inbox-capsul .sb-headerCrate .sb-headerCrate--content {
      padding: 0 3.2rem; }
  .sb-inbox-capsul .sb-contentCrate {
    margin-left: 40vw;
    padding-top: 83px; } }

@media (min-width: 1024px) {
  .emailVerifiedCapsul .sb-verfied-cap {
    height: calc(100vh - 400px) !important; } }

.sb-contact {
  position: relative;
  z-index: 1; }
  .sb-contact .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-contact .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-contact .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-contact .sb-contentCrate .help-capsul .help-head {
    background-color: #d4e9e2;
    padding: 60px;
    height: 120px;
    background-size: cover;
    position: relative; }
    .sb-contact .sb-contentCrate .help-capsul .help-head .help-bar {
      position: absolute;
      bottom: -35px;
      width: calc(100% - 120px); }
      .sb-contact .sb-contentCrate .help-capsul .help-head .help-bar .search-icon {
        position: absolute;
        left: 20px;
        top: 20px; }
        .sb-contact .sb-contentCrate .help-capsul .help-head .help-bar .search-icon svg {
          width: 28px;
          height: 28px; }
          .sb-contact .sb-contentCrate .help-capsul .help-head .help-bar .search-icon svg path {
            fill: #848484 !important; }
      .sb-contact .sb-contentCrate .help-capsul .help-head .help-bar input {
        width: 100%;
        height: 50px;
        border: 1px solid #f7f7f7;
        padding: 35px 35px 35px 60px;
        font-size: 16px;
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        font-weight: 500;
        border: none;
        border-radius: 2px; }
        .sb-contact .sb-contentCrate .help-capsul .help-head .help-bar input:focus {
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
          box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.24), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.34); }
  .sb-contact .sb-contentCrate .help-capsul .help-container {
    padding: 80px 40px 0px 40px; }
    .sb-contact .sb-contentCrate .help-capsul .help-container .help-category {
      text-align: center; }
      .sb-contact .sb-contentCrate .help-capsul .help-container .help-category .help-category-item {
        /*display: inline-block;
                        vertical-align: middle;
                        width: calc(33% - 10px);
                        margin-right: 10px;
                        margin-bottom: 20px;
                        height: 100px;
                        line-height: 100px;
                        border-radius: 2px;
                        background-color: #1f6c4e;
                        text-align: center;
                        color: white;
                        box-shadow: 0 0 .5px 0 rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .24);
                        cursor: pointer;
                        position: relative;
                        text-align:  -webkit-center;*/
        display: inline-block;
        vertical-align: middle;
        width: calc(33% - 10px);
        margin-right: 10px;
        margin-bottom: 20px;
        height: 100px;
        border-radius: 2px;
        background-color: #1f6c4e;
        color: #fff;
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
        position: relative; }
        .sb-contact .sb-contentCrate .help-capsul .help-container .help-category .help-category-item span {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%); }
        .sb-contact .sb-contentCrate .help-capsul .help-container .help-category .help-category-item:nth-child(3n) {
          margin-right: 0; }
        .sb-contact .sb-contentCrate .help-capsul .help-container .help-category .help-category-item:hover {
          opacity: 0.9;
          -webkit-transition: all .2s ease;
          transition: all .2s ease; }
        .sb-contact .sb-contentCrate .help-capsul .help-container .help-category .help-category-item svg {
          width: 50px;
          display: block; }
      .sb-contact .sb-contentCrate .help-capsul .help-container .help-category .active {
        opacity: 0.8; }
    .sb-contact .sb-contentCrate .help-capsul .help-container .help-list {
      margin-top: 10px; }
      .sb-contact .sb-contentCrate .help-capsul .help-container .help-list .help-list-item {
        background-color: white;
        border-radius: 2px;
        border: 1px solid #3d3d3d36;
        margin-bottom: 20px; }
        .sb-contact .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head {
          cursor: pointer;
          display: block;
          padding: 15px; }
          .sb-contact .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head span {
            font-weight: bold; }
          .sb-contact .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head svg {
            float: right; }
          .sb-contact .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head .active {
            transform: rotate(180deg); }
        .sb-contact .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-content {
          border-top: 1px solid #d6d6d6;
          padding: 0px 20px 0px 20px; }
        .sb-contact .sb-contentCrate .help-capsul .help-container .help-list .help-list-item:hover {
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
          border: 1px solid rgba(61, 61, 61, 0.876); }
  .sb-contact .sb-contentCrate .inform-head .detail-bg {
    border-radius: 5px; }
  .sb-contact .sb-contentCrate .contact-form {
    width: 500px;
    max-width: 100%; }
    .sb-contact .sb-contentCrate .contact-form .title {
      margin-bottom: 60px; }
    .sb-contact .sb-contentCrate .contact-form .pb6 {
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
      padding-bottom: 40px !important; }
      .sb-contact .sb-contentCrate .contact-form .pb6 .rememberMe {
        margin-top: 20px; }
    .sb-contact .sb-contentCrate .contact-form .select label {
      top: 5px; }
    .sb-contact .sb-contentCrate .contact-form .msgBox textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-family: unset; }
  .sb-contact .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-contact .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-contact .sb-contentCrate .help-list {
    margin-top: 10px; }
    .sb-contact .sb-contentCrate .help-list .help-list-item {
      background-color: white;
      border-radius: 2px;
      border: 1px solid #3d3d3d36;
      margin-bottom: 20px; }
      .sb-contact .sb-contentCrate .help-list .help-list-item .help-list-item-head {
        cursor: pointer;
        display: block;
        padding: 15px; }
        .sb-contact .sb-contentCrate .help-list .help-list-item .help-list-item-head span {
          font-weight: bold; }
        .sb-contact .sb-contentCrate .help-list .help-list-item .help-list-item-head svg {
          float: right; }
        .sb-contact .sb-contentCrate .help-list .help-list-item .help-list-item-head .active {
          transform: rotate(180deg); }
      .sb-contact .sb-contentCrate .help-list .help-list-item .help-list-item-content {
        border-top: 1px solid #d6d6d6;
        padding: 0px 20px 0px 20px; }
      .sb-contact .sb-contentCrate .help-list .help-list-item:hover {
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        border: 1px solid rgba(61, 61, 61, 0.876); }

@media (min-width: 1280px) {
  .sb-contact .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-contact {
    padding-top: 10px; }
    .sb-contact .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-contact .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-contact .sb-contentCrate {
      margin-left: 40vw; }
      .sb-contact .sb-contentCrate .sb-contentColumn {
        padding-top: 100px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-contact .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-contact .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-contact .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

.sb-gift {
  position: relative;
  z-index: 1;
  display: none; }
  .sb-gift .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-gift .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-gift .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-gift .sb-contentCrate .card-list {
    padding: 80px 80px; }
    .sb-gift .sb-contentCrate .card-list .card-item {
      width: 25%;
      display: inline-block;
      vertical-align: middle;
      padding: 10px; }
      .sb-gift .sb-contentCrate .card-list .card-item img {
        border-radius: 3.3333%/5.298%;
        box-shadow: 0 2px 3px rgba(7, 7, 7, 0.24); }
  .sb-gift .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-gift .sb-contentCrate .sb-contentColumn .giftcard-form {
      width: 100%; }
      .sb-gift .sb-contentCrate .sb-contentColumn .giftcard-form .pb6 {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0;
        padding-bottom: 20px !important; }
      .sb-gift .sb-contentCrate .sb-contentColumn .giftcard-form .import-credit {
        margin-top: 20px; }
        .sb-gift .sb-contentCrate .sb-contentColumn .giftcard-form .import-credit .sb-frap {
          background-color: white;
          color: black;
          zoom: 0.75; }
      .sb-gift .sb-contentCrate .sb-contentColumn .giftcard-form .number-box {
        position: relative; }
        .sb-gift .sb-contentCrate .sb-contentColumn .giftcard-form .number-box .icon {
          position: absolute;
          right: 0px;
          bottom: 22px;
          cursor: pointer;
          z-index: 2; }
          .sb-gift .sb-contentCrate .sb-contentColumn .giftcard-form .number-box .icon svg {
            width: 18px;
            height: 18px;
            fill: rgba(0, 0, 0, 0.6); }
    .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-gift .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }

@media (min-width: 1280px) {
  .sb-gift .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-gift {
    padding-top: 83px; }
    .sb-gift .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-gift .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-gift .sb-contentCrate {
      margin-left: 40vw; }
      .sb-gift .sb-contentCrate .sb-contentColumn {
        padding-top: 50px;
        padding-bottom: 100px;
        max-width: 440px !important; }
        .sb-gift .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-gift .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-gift .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

.sb-survey {
  position: relative;
  z-index: 1; }
  .sb-survey .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-survey .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-survey .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-survey .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-survey .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-survey .sb-contentCrate .survey-box {
    box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    background-color: white; }
    .sb-survey .sb-contentCrate .survey-box .head {
      background-color: #006341;
      padding: 30px 50px;
      color: white; }
      .sb-survey .sb-contentCrate .survey-box .head .top .text {
        text-align: center; }
        .sb-survey .sb-contentCrate .survey-box .head .top .text .logo {
          width: 100px;
          margin: 0 auto; }
    .sb-survey .sb-contentCrate .survey-box .survey-form {
      padding: 30px 50px; }
      .sb-survey .sb-contentCrate .survey-box .survey-form .req {
        display: block;
        margin-bottom: 20px; }
      .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item {
        margin-bottom: 55px; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .survey-label {
          display: block;
          margin-bottom: 15px;
          resize: none;
          font-weight: 600; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .survey-p {
          font-size: 13px;
          color: #595a5a; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item input {
          font-family: SodoSans,sans-serif;
          height: 45px;
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          background-color: transparent !important;
          border: 0 !important;
          border-radius: 0 !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item select {
          font-family: SodoSans,sans-serif;
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          background-color: transparent !important;
          border: 0 !important;
          border-radius: 0 !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
          height: 45px;
          width: 100%; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item textarea {
          font-family: SodoSans,sans-serif;
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          background-color: transparent !important;
          border: 0 !important;
          border-radius: 0 !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
          resize: none; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .survey-inp {
          width: 100%;
          padding: 10px 0; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .select-rate {
          border: 1px solid black; }
          .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .select-rate .rate-item {
            display: inline-block;
            border-left: 1px solid black;
            padding: 10px;
            width: 9.09%;
            text-align: center;
            cursor: pointer; }
            .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .select-rate .rate-item:hover {
              background-color: black;
              color: white; }
            .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .select-rate .rate-item:first-child {
              border-left: none !important; }
          .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .select-rate .active {
            background-color: black;
            color: white; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .rate-inform {
          margin-top: 10px;
          height: 30px; }
          .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .rate-inform .left {
            float: left; }
          .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .rate-inform .right {
            float: right; }
        .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .arrow {
          position: relative;
          height: 15px;
          width: 15px;
          float: right;
          right: 20px;
          top: 25px;
          transform: rotate(180deg);
          zoom: 0.8; }
          .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .arrow::before {
            content: "";
            position: absolute;
            bottom: 0px;
            width: 0.15rem;
            height: 100%;
            transition: all 0.5s;
            left: -5px;
            transform: rotate(45deg);
            background-color: #394a6d; }
          .sb-survey .sb-contentCrate .survey-box .survey-form .survey-item .arrow::after {
            content: "";
            position: absolute;
            bottom: 0px;
            width: 0.15rem;
            height: 100%;
            transition: all 0.5s;
            left: 5px;
            transform: rotate(-45deg);
            background-color: #394a6d; }

@media (min-width: 1280px) {
  .sb-survey .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-survey {
    padding-top: 83px; }
    .sb-survey .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-survey .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-survey .sb-contentCrate {
      margin-left: 40vw; }
      .sb-survey .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-survey .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-survey .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-survey .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; }
  .sb-survey .sb-contentCrate .survey-box .head {
    padding: 20px 20px !important; }
  .sb-survey .sb-contentCrate .survey-box .survey-form {
    padding: 20px 20px !important; } }

.egift-land-cap {
  margin-top: 50px;
  margin-bottom: 30px; }
  .egift-land-cap .card-list-part {
    margin-bottom: 50px; }
    .egift-land-cap .card-list-part .sb-heading {
      margin-left: 20px; }
  .egift-land-cap .egift-card-list {
    padding: 50px 50px; }
    .egift-land-cap .egift-card-list .egift-cart-item {
      width: 25%;
      display: inline-block;
      vertical-align: middle;
      padding: 10px;
      cursor: pointer; }
      .egift-land-cap .egift-card-list .egift-cart-item img {
        border-radius: 3.3333%/5.298%;
        box-shadow: 0 2px 3px rgba(7, 7, 7, 0.24); }
        .egift-land-cap .egift-card-list .egift-cart-item img:hover {
          box-shadow: 0 4px 5px rgba(7, 7, 7, 0.64);
          -webkit-transition: all .2s ease;
          transition: all .2s ease; }
    .egift-land-cap .egift-card-list .slick-slider .slick-list {
      margin-left: 8px;
      margin-right: 8px; }
    .egift-land-cap .egift-card-list .slick-prev {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.24), 0 8px 12px rgba(0, 0, 0, 0.14); }
  .egift-land-cap .egift-band {
    padding: 25px 30px;
    background-color: #d4e9e1;
    width: calc(100% - 70px);
    margin: 0 auto;
    border-radius: 8px; }
    .egift-land-cap .egift-band .title {
      display: inline-block;
      margin-right: 15px; }

.egift-capsul {
  position: relative;
  z-index: 1; }
  .egift-capsul .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .egift-capsul .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .egift-capsul .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .egift-capsul .sb-contentCrate .inform-head .detail-bg {
    border-radius: 3.3333%/5.298%;
    box-shadow: 0 2px 3px rgba(7, 7, 7, 0.24); }
  .egift-capsul .sb-contentCrate .gift-form {
    width: 500px;
    max-width: 100%; }
    .egift-capsul .sb-contentCrate .gift-form .form-item {
      margin-bottom: 50px; }
      .egift-capsul .sb-contentCrate .gift-form .form-item .form-item-label {
        color: rgba(0, 0, 0, 0.56) !important;
        font-weight: 700;
        margin-bottom: 15px;
        display: block; }
    .egift-capsul .sb-contentCrate .gift-form .title {
      margin-bottom: 60px; }
    .egift-capsul .sb-contentCrate .gift-form .pb6 {
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
      padding-bottom: 15px !important; }
      .egift-capsul .sb-contentCrate .gift-form .pb6 .rememberMe {
        margin-top: 20px; }
    .egift-capsul .sb-contentCrate .gift-form .select label {
      top: 5px; }
    .egift-capsul .sb-contentCrate .gift-form .msgBox textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-family: unset; }
  .egift-capsul .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .egift-capsul .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .egift-capsul .sb-contentCrate .saveBtn {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 1.6rem; }

.sb-egift-balance-check .giftcard-form {
  width: 100%; }
  .sb-egift-balance-check .giftcard-form .pb6 {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
    padding-bottom: 20px !important; }
  .sb-egift-balance-check .giftcard-form .import-credit {
    margin-top: 20px; }
    .sb-egift-balance-check .giftcard-form .import-credit .sb-frap {
      background-color: white;
      color: black;
      zoom: 0.75; }
  .sb-egift-balance-check .giftcard-form .number-box {
    position: relative; }
    .sb-egift-balance-check .giftcard-form .number-box .icon {
      position: absolute;
      right: 0px;
      bottom: 22px;
      cursor: pointer;
      z-index: 2; }
      .sb-egift-balance-check .giftcard-form .number-box .icon svg {
        width: 18px;
        height: 18px;
        fill: rgba(0, 0, 0, 0.6); }

@media (min-width: 1280px) {
  .egift-capsul .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .egift-capsul {
    padding-top: 10px; }
    .egift-capsul .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .egift-capsul .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .egift-capsul .sb-contentCrate {
      margin-left: 40vw; }
      .egift-capsul .sb-contentCrate .sb-contentColumn {
        padding-top: 100px;
        padding-bottom: 100px;
        max-width: 420px !important; }
        .egift-capsul .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .egift-capsul .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .egift-capsul .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .egift-land-cap {
    margin-top: 0 !important; }
    .egift-land-cap .egift-card-list {
      padding: 20px 20px !important; }
    .egift-land-cap .egift-band {
      width: calc(100% - 35px) !important; } }

.sb-nutrition {
  position: relative;
  z-index: 1; }
  .sb-nutrition .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-nutrition .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-nutrition .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-nutrition .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-nutrition .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-nutrition .sb-contentCrate .nutrition-cover-img img {
    width: 100%; }
  .sb-nutrition .sb-contentCrate .nutrition-big-with-img {
    padding: 30px; }
    .sb-nutrition .sb-contentCrate .nutrition-big-with-img .item-text {
      padding-left: 20px;
      width: 50%;
      display: inline-block;
      vertical-align: top; }
      .sb-nutrition .sb-contentCrate .nutrition-big-with-img .item-text .title {
        font-weight: 900;
        font-size: 15px;
        margin-bottom: 20px; }
    .sb-nutrition .sb-contentCrate .nutrition-big-with-img .item-img {
      padding-right: 20px;
      width: 50%;
      display: inline-block;
      vertical-align: top; }
  .sb-nutrition .sb-contentCrate .nutrition-big-with-text .item-text {
    padding-left: 20px; }
    .sb-nutrition .sb-contentCrate .nutrition-big-with-text .item-text .title {
      font-weight: 900;
      font-size: 15px;
      margin-bottom: 20px; }
  .sb-nutrition .sb-contentCrate .nutrition-content {
    padding: 30px; }
    .sb-nutrition .sb-contentCrate .nutrition-content .content-type-text-with-btn {
      margin-bottom: 70px; }
      .sb-nutrition .sb-contentCrate .nutrition-content .content-type-text-with-btn .title {
        font-weight: 900;
        font-size: 15px;
        margin-bottom: 20px; }

@media (min-width: 1280px) {
  .sb-nutrition .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-nutrition {
    padding-top: 83px; }
    .sb-nutrition .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-nutrition .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-nutrition .sb-contentCrate {
      margin-left: 40vw; }
      .sb-nutrition .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-nutrition .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-nutrition .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-nutrition .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; }
  .sb-nutrition .sb-contentCrate .nutrition-box .head {
    padding: 20px 20px !important; }
  .sb-nutrition .sb-contentCrate .nutrition-box .nutrition-form {
    padding: 20px 20px !important; } }

@media (max-width: 768px) {
  .sb-nutrition .sb-contentCrate .nutrition-big-with-img .item-img {
    width: 100%;
    padding: 0; }
  .sb-nutrition .sb-contentCrate .nutrition-big-with-img .item-text {
    width: 100%;
    padding: 0; } }

.sb-rewards {
  position: relative;
  z-index: 1; }
  .sb-rewards .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-rewards .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-rewards .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-rewards .sb-contentCrate .egift2-content {
    padding: 20px; }
    .sb-rewards .sb-contentCrate .egift2-content .top-inform {
      margin-bottom: 40px; }
      .sb-rewards .sb-contentCrate .egift2-content .top-inform .title {
        font-weight: bold;
        font-size: 24px; }
    .sb-rewards .sb-contentCrate .egift2-content .top-list .inform .title {
      font-weight: bold;
      font-size: 24px; }
    .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul {
      margin: auto -20px;
      background-color: white; }
      .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        background-color: #fff;
        padding: 25px;
        width: 33.33%;
        opacity: .3; }
        .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-img {
          width: 140px;
          display: inline-block;
          vertical-align: top;
          text-align: center; }
          .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-img img {
            max-height: 100%;
            vertical-align: middle;
            max-width: 140px; }
          .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-img .title {
            vertical-align: middle;
            font-weight: bold;
            font-size: 16px; }
        .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-detail {
          width: 100%;
          display: block;
          white-space: normal;
          vertical-align: top; }
      .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .active {
        opacity: 1 !important; }
    .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content {
      background-color: rgba(193, 193, 193, 0.14902);
      padding: 30px;
      white-space: nowrap;
      margin: auto -20px; }
      .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content .area-img {
        vertical-align: middle;
        display: inline-block;
        height: 180px;
        width: 180px;
        text-align: center; }
        .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content .area-img img {
          max-height: 100%; }
      .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content .area-content {
        width: calc(100% - 180px);
        vertical-align: middle;
        display: inline-block;
        white-space: normal;
        padding-left: 50px; }
        .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content .area-content .area-title {
          font-weight: bold;
          font-size: 18px; }
    .sb-rewards .sb-contentCrate .egift2-content .rewards-gift-promo-code .title {
      display: block;
      font-weight: bold;
      font-size: 24px;
      margin-top: 15px; }
    .sb-rewards .sb-contentCrate .egift2-content .rewards-gift-promo-code .text {
      margin-bottom: 5px; }
    .sb-rewards .sb-contentCrate .egift2-content .rewards-gift-promo-code #star-code {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
      display: block;
      padding: 12px 16px;
      position: relative;
      transition: border .2s linear .2s;
      border: none;
      color: rgba(0, 0, 0, 0.87);
      font-size: 1.6rem;
      width: 300px;
      max-width: 100%; }
    .sb-rewards .sb-contentCrate .egift2-content .rewards-gift-promo-code #rewards-action-btn {
      color: rgba(0, 0, 0, 0.8);
      border-color: rgba(0, 0, 0, 0.7); }
      .sb-rewards .sb-contentCrate .egift2-content .rewards-gift-promo-code #rewards-action-btn:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-rewards .sb-contentCrate .egift2-content .download-area {
      margin: 20px -20px;
      background-color: rgba(128, 128, 128, 0.0784314);
      padding: 30px; }
      .sb-rewards .sb-contentCrate .egift2-content .download-area .title {
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px; }
      .sb-rewards .sb-contentCrate .egift2-content .download-area .area-capsul {
        white-space: nowrap; }
        .sb-rewards .sb-contentCrate .egift2-content .download-area .area-capsul .area-img {
          vertical-align: middle;
          display: inline-block;
          height: 180px;
          width: 180px;
          text-align: center; }
          .sb-rewards .sb-contentCrate .egift2-content .download-area .area-capsul .area-img img {
            max-height: 100%; }
        .sb-rewards .sb-contentCrate .egift2-content .download-area .area-capsul .area-content {
          width: calc(100% - 180px);
          vertical-align: middle;
          display: inline-block;
          white-space: normal;
          padding-left: 50px; }
          .sb-rewards .sb-contentCrate .egift2-content .download-area .area-capsul .area-content .area-title {
            font-weight: bold;
            font-size: 18px; }
    .sb-rewards .sb-contentCrate .egift2-content .tabs {
      margin-top: 40px; }
      .sb-rewards .sb-contentCrate .egift2-content .tabs .title {
        font-weight: 700;
        font-size: 24px;
        display: block; }
      .sb-rewards .sb-contentCrate .egift2-content .tabs .sub-title {
        font-weight: 600;
        font-size: 17px;
        display: block; }
      .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items {
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        background: white;
        border-radius: 2px;
        padding: 20px;
        cursor: pointer;
        margin: auto -20px; }
        .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .active {
          opacity: 1 !important; }
        .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item {
          width: 33.3%;
          display: inline-block;
          vertical-align: top;
          padding: 15px;
          text-align: center;
          opacity: 0.3; }
          .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item .tab-img {
            height: 160px;
            width: 160px;
            line-height: 160px;
            text-align: center;
            margin: 0 auto; }
            .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item .tab-img img {
              max-height: 100%; }
          .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item .tab-detail .tab-text {
            display: block;
            margin-top: 20px;
            font-weight: bold; }
          .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item:hover {
            opacity: 0.6; }
      .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content {
        background-color: rgba(193, 193, 193, 0.14902);
        padding: 30px;
        white-space: nowrap;
        margin: auto -20px; }
        .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content .area-img {
          vertical-align: middle;
          display: inline-block;
          height: 180px;
          width: 180px;
          text-align: center; }
          .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content .area-img img {
            max-height: 100%; }
        .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content .area-content {
          width: calc(100% - 180px);
          vertical-align: middle;
          display: inline-block;
          white-space: normal;
          padding-left: 50px; }
          .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content .area-content .area-title {
            font-weight: bold;
            font-size: 18px; }
    .sb-rewards .sb-contentCrate .egift2-content .tips {
      margin-top: 40px; }
      .sb-rewards .sb-contentCrate .egift2-content .tips .tips-detail {
        display: block;
        width: 100%;
        clear: both;
        margin-bottom: 20px; }
        .sb-rewards .sb-contentCrate .egift2-content .tips .tips-detail .title {
          display: block;
          font-weight: 700;
          font-size: 24px;
          display: block; }
      .sb-rewards .sb-contentCrate .egift2-content .tips .tip {
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        width: calc(50% - 20px);
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        margin-bottom: 20px;
        margin-right: 40px; }
        .sb-rewards .sb-contentCrate .egift2-content .tips .tip .tip-img img {
          width: 100%; }
        .sb-rewards .sb-contentCrate .egift2-content .tips .tip .tip-detail {
          padding: 20px; }
        .sb-rewards .sb-contentCrate .egift2-content .tips .tip:nth-child(2n) {
          margin-right: 0; }
    .sb-rewards .sb-contentCrate .egift2-content .status-inform {
      margin-top: 40px;
      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      /* Chrome, Edge, and Safari */ }
      .sb-rewards .sb-contentCrate .egift2-content .status-inform .left {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding-right: 2px; }
        .sb-rewards .sb-contentCrate .egift2-content .status-inform .left .cap .top {
          background-color: #016440;
          padding: 10px;
          color: white;
          font-weight: bold;
          text-align: center;
          font-size: 22px;
          margin-bottom: 15px;
          position: relative; }
          .sb-rewards .sb-contentCrate .egift2-content .status-inform .left .cap .top::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-color: #036340 transparent transparent;
            border-style: solid;
            border-width: 10px 10px 0;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 10px); }
        .sb-rewards .sb-contentCrate .egift2-content .status-inform .left .cap .mid {
          background-color: #016440;
          padding: 15px;
          min-height: 365px; }
          .sb-rewards .sb-contentCrate .egift2-content .status-inform .left .cap .mid .star-item {
            display: block;
            color: white;
            margin-bottom: 10px; }
      .sb-rewards .sb-contentCrate .egift2-content .status-inform .right {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding-left: 2px; }
        .sb-rewards .sb-contentCrate .egift2-content .status-inform .right .cap .top {
          background-color: #927e4c;
          padding: 10px;
          color: white;
          font-weight: bold;
          text-align: center;
          font-size: 22px;
          margin-bottom: 15px;
          position: relative; }
          .sb-rewards .sb-contentCrate .egift2-content .status-inform .right .cap .top::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-color: #927e4c transparent transparent;
            border-style: solid;
            border-width: 10px 10px 0;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 10px); }
        .sb-rewards .sb-contentCrate .egift2-content .status-inform .right .cap .mid {
          background-color: #927e4c;
          padding: 15px;
          min-height: 365px; }
          .sb-rewards .sb-contentCrate .egift2-content .status-inform .right .cap .mid .star-item {
            display: block;
            color: white;
            margin-bottom: 10px; }
      .sb-rewards .sb-contentCrate .egift2-content .status-inform * {
        scrollbar-width: auto;
        scrollbar-color: #303030 #ffffff; }
      .sb-rewards .sb-contentCrate .egift2-content .status-inform *::-webkit-scrollbar {
        width: 12px; }
      .sb-rewards .sb-contentCrate .egift2-content .status-inform *::-webkit-scrollbar-track {
        background: #ffffff; }
      .sb-rewards .sb-contentCrate .egift2-content .status-inform *::-webkit-scrollbar-thumb {
        background-color: #303030;
        border-radius: 10px;
        border: 3px solid #ffffff; }
    .sb-rewards .sb-contentCrate .egift2-content .egift2-foot {
      margin-top: 40px; }
      .sb-rewards .sb-contentCrate .egift2-content .egift2-foot .foot-title {
        font-weight: bold; }
  .sb-rewards .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-rewards .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }

@media (min-width: 1280px) {
  .sb-rewards .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-rewards {
    padding-top: 83px; }
    .sb-rewards .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-rewards .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-rewards .sb-contentCrate {
      margin-left: 40vw; }
      .sb-rewards .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-rewards .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-rewards .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-rewards .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; }
  .sb-rewards .sb-contentCrate .nutrition-box .head {
    padding: 20px 20px !important; }
  .sb-rewards .sb-contentCrate .nutrition-box .nutrition-form {
    padding: 20px 20px !important; } }

@media (max-width: 768px) {
  .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-img {
    display: block !important;
    margin: 0 auto !important;
    width: auto !important;
    height: auto !important; }
    .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-img img {
      max-width: 100px !important; }
    .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-img .title {
      display: block;
      font-size: 12px !important; }
  .sb-rewards .sb-contentCrate .egift2-content .top-list .top-list-capsul .list-item .list-detail {
    display: block !important;
    width: 100% !important;
    padding-left: 0 !important; }
  .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content .area-img {
    display: block !important;
    margin: 0 auto !important;
    width: auto !important;
    height: auto !important; }
    .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content .area-img img {
      width: 180px !important; }
  .sb-rewards .sb-contentCrate .egift2-content .top-list .tab-item-content .area-content {
    display: block !important;
    width: 100% !important;
    padding-left: 0 !important; }
  .sb-rewards .sb-contentCrate .egift2-content .download-area .area-capsul .area-img {
    display: block !important;
    margin: 0 auto !important; }
  .sb-rewards .sb-contentCrate .egift2-content .download-area .area-capsul .area-content {
    width: 100% !important;
    display: block !important;
    padding-left: 0 !important; }
  .sb-rewards .sb-contentCrate .egift2-content .status-inform .left {
    width: 100% !important;
    padding: 0 !important; }
  .sb-rewards .sb-contentCrate .egift2-content .status-inform .right {
    width: 100% !important;
    padding: 0 !important; }
  .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item .tab-img {
    display: block !important;
    margin: 0 auto !important;
    width: auto !important;
    height: auto !important;
    line-height: 0 !important; }
    .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item .tab-img img {
      max-width: 100px !important; }
    .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item .tab-img .title {
      display: block;
      font-size: 12px !important; }
  .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-items .tab-item .tab-detail {
    display: block !important;
    width: 100% !important;
    padding-left: 0 !important; }
  .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content .area-img {
    display: block !important;
    margin: 0 auto !important;
    width: auto !important;
    height: auto !important; }
    .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content .area-img img {
      width: 180px !important; }
  .sb-rewards .sb-contentCrate .egift2-content .tabs .tab-item-content .area-content {
    display: block !important;
    width: 100% !important;
    padding-left: 0 !important; }
  .sb-rewards .sb-contentCrate .egift2-content .tips .tips-capsul .tip {
    width: 100% !important;
    margin-right: 0 !important; } }

.sb-our-heritage {
  position: relative;
  z-index: 1; }
  .sb-our-heritage .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-our-heritage .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-our-heritage .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-our-heritage .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-our-heritage .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-our-heritage .sb-contentCrate .our-heritage-cover {
    text-align: center;
    padding-top: 20px; }
  .sb-our-heritage .sb-contentCrate .our-heritage-content {
    padding: 40px;
    color: #6c6c6c; }

@media (min-width: 1280px) {
  .sb-our-heritage .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-our-heritage {
    padding-top: 83px; }
    .sb-our-heritage .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-our-heritage .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-our-heritage .sb-contentCrate {
      margin-left: 40vw; }
      .sb-our-heritage .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-our-heritage .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-our-heritage .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-our-heritage .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-company-information {
  position: relative;
  z-index: 1; }
  .sb-company-information .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-company-information .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-company-information .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-company-information .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-company-information .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-company-information .sb-contentCrate .our-heritage-cover {
    text-align: center;
    padding-top: 20px; }
  .sb-company-information .sb-contentCrate .our-heritage-content {
    padding: 40px;
    color: #6c6c6c; }
    .sb-company-information .sb-contentCrate .our-heritage-content b {
      color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1280px) {
  .sb-company-information .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-company-information {
    padding-top: 83px; }
    .sb-company-information .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-company-information .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-company-information .sb-contentCrate {
      margin-left: 40vw; }
      .sb-company-information .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-company-information .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-company-information .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-company-information .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-store-static {
  position: relative;
  z-index: 1; }
  .sb-store-static .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-store-static .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-store-static .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-store-static .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-store-static .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-store-static .sb-contentCrate .our-heritage-cover {
    text-align: center;
    padding-top: 20px; }
  .sb-store-static .sb-contentCrate .our-heritage-content {
    padding: 40px;
    color: #6c6c6c; }
    .sb-store-static .sb-contentCrate .our-heritage-content h2 {
      color: rgba(0, 0, 0, 0.87); }
    .sb-store-static .sb-contentCrate .our-heritage-content b {
      color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1280px) {
  .sb-store-static .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-store-static {
    padding-top: 83px; }
    .sb-store-static .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-store-static .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-store-static .sb-contentCrate {
      margin-left: 40vw; }
      .sb-store-static .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-store-static .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-store-static .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-store-static .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-reserve {
  position: relative;
  z-index: 1; }
  .sb-reserve .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-reserve .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-reserve .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-reserve .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-reserve .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-reserve .sb-contentCrate .our-heritage-cover {
    text-align: center;
    padding-top: 20px; }
  .sb-reserve .sb-contentCrate .reserve-content {
    padding: 40px; }
    .sb-reserve .sb-contentCrate .reserve-content .resever-item {
      margin-bottom: 50px;
      display: flex; }
      .sb-reserve .sb-contentCrate .reserve-content .resever-item .reverse-cover {
        width: 50%;
        display: inline-block;
        vertical-align: top; }
      .sb-reserve .sb-contentCrate .reserve-content .resever-item .reverse-detail {
        width: 50%;
        display: inline-block;
        vertical-align: top; }
        .sb-reserve .sb-contentCrate .reserve-content .resever-item .reverse-detail .reverse-text {
          color: #6c6c6c; }
      .sb-reserve .sb-contentCrate .reserve-content .resever-item:nth-child(2) {
        flex-direction: row-reverse; }
  .sb-reserve .sb-contentCrate .dual-content {
    /*.dual-item{
                width: 100%;
                display: inline-block;
                margin-bottom: 50px;
                padding:40px;
                vertical-align: top;

                .item-cover{
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                }

                .item-detail{
                    width: 50%;
                    vertical-align: top;
                    display: inline-block;
                    padding-left: 15px;
                    .item-title{
                        margin-top: 0 !important;
                    }

                    .item-sub-title{
                        font-weight: bold;
                    }
                }
            }*/ }
    .sb-reserve .sb-contentCrate .dual-content .dual-item {
      width: 50%;
      display: inline-block;
      margin-bottom: 50px;
      padding: 40px;
      vertical-align: top; }
      .sb-reserve .sb-contentCrate .dual-content .dual-item .item-detail .item-sub-title {
        font-weight: bold; }
      .sb-reserve .sb-contentCrate .dual-content .dual-item .item-detail p {
        color: #6c6c6c; }
  .sb-reserve .sb-contentCrate .roasteries-content .roasteries-title {
    padding: 0 50px; }
  .sb-reserve .sb-contentCrate .roasteries-content .roasterie-item {
    width: 33%;
    display: inline-block;
    vertical-align: top;
    padding: 30px 50px;
    text-align: center; }
    .sb-reserve .sb-contentCrate .roasteries-content .roasterie-item .roasterie-detail .roasterie-title {
      display: block;
      font-weight: bold;
      margin-top: 15px; }

@media (min-width: 1280px) {
  .sb-reserve .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-reserve {
    padding-top: 83px; }
    .sb-reserve .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-reserve .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-reserve .sb-contentCrate {
      margin-left: 40vw; }
      .sb-reserve .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-reserve .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-reserve .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-reserve .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .sb-reserve .sb-contentCrate .dual-content .dual-item {
    width: 100% !important; }
    .sb-reserve .sb-contentCrate .dual-content .dual-item .item-cover {
      width: 100%; }
    .sb-reserve .sb-contentCrate .dual-content .dual-item .item-detail {
      width: 100%; }
  .sb-reserve .sb-contentCrate .roasteries-content .roasterie-item {
    width: 50% !important; } }

.sb-supply-roastry-blending {
  position: relative;
  z-index: 1; }
  .sb-supply-roastry-blending .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-supply-roastry-blending .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-supply-roastry-blending .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-supply-roastry-blending .sb-contentCrate .supply-roastry-blending {
    padding: 20px 40px; }

@media (min-width: 1280px) {
  .sb-supply-roastry-blending .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; }
  .sb-supply-roastry-blending .sb-contentCrate {
    color: #6c6c6c; }
    .sb-supply-roastry-blending .sb-contentCrate b {
      color: rgba(0, 0, 0, 0.87); } }

@media (min-width: 1024px) {
  .sb-supply-roastry-blending {
    padding-top: 83px; }
    .sb-supply-roastry-blending .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-supply-roastry-blending .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-supply-roastry-blending .sb-contentCrate {
      margin-left: 40vw; }
      .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-supply-roastry-blending .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .sb-supply-roastry-blending .sb-contentCrate .nutrition-big-with-img .item-img {
    width: 100%;
    padding: 0; }
  .sb-supply-roastry-blending .sb-contentCrate .nutrition-big-with-img .item-text {
    width: 100%;
    padding: 0; } }

.sb-roast {
  position: relative;
  z-index: 1; }
  .sb-roast .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-roast .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-roast .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-roast .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-roast .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-roast .sb-contentCrate .roast-content {
    padding: 20px 40px; }
    .sb-roast .sb-contentCrate .roast-content .roast-cover {
      margin-bottom: 20px; }
    .sb-roast .sb-contentCrate .roast-content .roast-detail {
      color: #6c6c6c; }
      .sb-roast .sb-contentCrate .roast-content .roast-detail b {
        color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1280px) {
  .sb-roast .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-roast {
    padding-top: 83px; }
    .sb-roast .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-roast .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-roast .sb-contentCrate {
      margin-left: 40vw; }
      .sb-roast .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-roast .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-roast .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-roast .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-how-to-brew {
  position: relative;
  z-index: 1; }
  .sb-how-to-brew .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-how-to-brew .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-how-to-brew .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-how-to-brew .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-how-to-brew .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-menu {
    background-color: white; }
    .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-menu .active span {
      color: black !important; }
    .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-menu .menu-item {
      width: 25%;
      display: inline-block;
      vertical-align: bottom;
      padding: 25px;
      text-align: center; }
      .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-menu .menu-item span {
        font-weight: bold;
        font-size: 24px;
        color: #777;
        cursor: pointer; }
        .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-menu .menu-item span:hover {
          color: black !important;
          transition: all 0.2s ease; }
  .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-detail {
    padding: 20px 40px; }
    .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-detail .detail-item .detail-text {
      margin-bottom: 20px; }
    .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-detail .detail-item .detail-step {
      margin-bottom: 30px; }
      .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-detail .detail-item .detail-step .step-cover {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding-right: 20px; }
      .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-detail .detail-item .detail-step .step-text {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding-left: 20px; }
        .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-detail .detail-item .detail-step .step-text .title {
          display: block;
          font-weight: bold; }
    .sb-how-to-brew .sb-contentCrate .how-to-brew-content .how-to-brew-detail .detail-item p {
      color: #6c6c6c; }

@media (min-width: 1280px) {
  .sb-how-to-brew .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-how-to-brew {
    padding-top: 83px; }
    .sb-how-to-brew .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-how-to-brew .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-how-to-brew .sb-contentCrate {
      margin-left: 40vw; }
      .sb-how-to-brew .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-how-to-brew .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-how-to-brew .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-how-to-brew .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-faq {
  position: relative;
  z-index: 1; }
  .sb-faq .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-faq .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-faq .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-faq .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-faq .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-faq .sb-contentCrate .faq-content {
    padding: 20px 40px; }
    .sb-faq .sb-contentCrate .faq-content p {
      color: #6c6c6c; }
    .sb-faq .sb-contentCrate .faq-content b {
      color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1280px) {
  .sb-faq .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-faq {
    padding-top: 83px; }
    .sb-faq .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-faq .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-faq .sb-contentCrate {
      margin-left: 40vw; }
      .sb-faq .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-faq .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-faq .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-faq .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-sourcing {
  position: relative;
  z-index: 1; }
  .sb-sourcing .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-sourcing .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-sourcing .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-sourcing .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-sourcing .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-sourcing .sb-contentCrate .sourcing-content {
    padding: 20px 40px; }
    .sb-sourcing .sb-contentCrate .sourcing-content .sourcing-box .box-item {
      width: 50%;
      display: inline-block;
      padding: 10px 20px;
      margin-bottom: 40px;
      vertical-align: top; }
      .sb-sourcing .sb-contentCrate .sourcing-content .sourcing-box .box-item .item-cover img {
        width: 100%; }
    .sb-sourcing .sb-contentCrate .sourcing-content p {
      color: #6c6c6c; }

@media (min-width: 1280px) {
  .sb-sourcing .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-sourcing {
    padding-top: 83px; }
    .sb-sourcing .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-sourcing .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-sourcing .sb-contentCrate {
      margin-left: 40vw; }
      .sb-sourcing .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-sourcing .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-sourcing .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-sourcing .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-community {
  position: relative;
  z-index: 1; }
  .sb-community .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-community .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-community .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-community .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-community .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-community .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-community .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-community .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-community .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-community .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-community .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-community .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-community .sb-contentCrate .community-content {
    padding: 40px; }
    .sb-community .sb-contentCrate .community-content p {
      color: #6c6c6c; }
    .sb-community .sb-contentCrate .community-content b {
      color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1280px) {
  .sb-community .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-community {
    padding-top: 83px; }
    .sb-community .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-community .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-community .sb-contentCrate {
      margin-left: 40vw; }
      .sb-community .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-community .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-community .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-community .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-environment {
  position: relative;
  z-index: 1; }
  .sb-environment .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-environment .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-environment .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-environment .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-environment .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-environment .sb-contentCrate .environment-content {
    padding: 20px 40px; }

@media (min-width: 1280px) {
  .sb-environment .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-environment {
    padding-top: 83px; }
    .sb-environment .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-environment .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-environment .sb-contentCrate {
      margin-left: 40vw; }
      .sb-environment .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-environment .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-environment .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-environment .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-diversity {
  position: relative;
  z-index: 1; }
  .sb-diversity .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-diversity .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-diversity .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-diversity .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-diversity .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-diversity .sb-contentCrate .diversity-content {
    padding: 20px 40px;
    color: #6c6c6c; }
    .sb-diversity .sb-contentCrate .diversity-content b {
      color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1280px) {
  .sb-diversity .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-diversity {
    padding-top: 83px; }
    .sb-diversity .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-diversity .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-diversity .sb-contentCrate {
      margin-left: 40vw; }
      .sb-diversity .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-diversity .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-diversity .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-diversity .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-sustainability {
  position: relative;
  z-index: 1; }
  .sb-sustainability .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-sustainability .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-sustainability .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-sustainability .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black,
    .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover,
      .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black,
    .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover,
      .sb-sustainability .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-sustainability .sb-contentCrate .sustainability-content {
    padding: 20px 40px;
    color: #6c6c6c; }
    .sb-sustainability .sb-contentCrate .sustainability-content b {
      color: rgba(0, 0, 0, 0.87); }

@media (min-width: 1280px) {
  .sb-sustainability .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-sustainability {
    padding-top: 83px; }
    .sb-sustainability .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-sustainability .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-sustainability .sb-contentCrate {
      margin-left: 40vw; }
      .sb-sustainability .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-sustainability .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-sustainability .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-sustainability .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .sb-sustainability .sb-contentCrate .nutrition-big-with-img .item-img {
    width: 100%;
    padding: 0; }
  .sb-sustainability .sb-contentCrate .nutrition-big-with-img .item-text {
    width: 100%;
    padding: 0; } }

.sb-delivery-yemeksepeti {
  position: relative;
  z-index: 1; }
  .sb-delivery-yemeksepeti .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-delivery-yemeksepeti .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-delivery-yemeksepeti .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-delivery-yemeksepeti .sb-contentCrate .yemeksepeti-content {
    padding: 20px 40px; }

@media (min-width: 1280px) {
  .sb-delivery-yemeksepeti .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-delivery-yemeksepeti {
    padding-top: 83px; }
    .sb-delivery-yemeksepeti .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-delivery-yemeksepeti .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-delivery-yemeksepeti .sb-contentCrate {
      margin-left: 40vw; }
      .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-delivery-yemeksepeti .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.sb-delivery-getir {
  position: relative;
  z-index: 1; }
  .sb-delivery-getir .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-delivery-getir .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-delivery-getir .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-delivery-getir .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-delivery-getir .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-delivery-getir .sb-contentCrate .getir-content {
    padding: 20px 40px; }

@media (min-width: 1280px) {
  .sb-delivery-getir .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-delivery-getir {
    padding-top: 83px; }
    .sb-delivery-getir .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-delivery-getir .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-delivery-getir .sb-contentCrate {
      margin-left: 40vw; }
      .sb-delivery-getir .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-delivery-getir .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-delivery-getir .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-delivery-getir .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

.homeCapsulv2 {
  position: relative;
  z-index: 1; }
  .homeCapsulv2 .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .homeCapsulv2 .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
      .homeCapsulv2 .sb-headerCrate .sb-headerCrate--content .sb-heading--large {
        font-size: 2.7rem;
        line-height: 1.385; }
  .homeCapsulv2 .sb-contentCrate {
    overflow: hidden; }
    .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .top-banner .top-banner-detail {
      padding: 20px 40px; }
      .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .top-banner .top-banner-detail .title {
        font-weight: 400;
        font-size: 2.7rem;
        line-height: 3.7rem;
        letter-spacing: -0.015rem; }
    .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list {
      padding: 20px 40px; }
      .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list .campaign-item {
        margin-bottom: 50px; }
        .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list .campaign-item .left {
          width: 50%;
          display: inline-block;
          vertical-align: top; }
        .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list .campaign-item .right {
          width: 50%;
          display: inline-block;
          vertical-align: top;
          padding-left: 20px; }
          .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list .campaign-item .right .title {
            font-weight: 700;
            font-size: 2rem;
            letter-spacing: -0.015rem;
            line-height: 2.7rem; }
      .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list p {
        color: #6c6c6c; }
    .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .bottom-banner .bottom-detail {
      padding: 20px 40px; }
      .homeCapsulv2 .sb-contentCrate .sb-content-capsul .main-v2 .bottom-banner .bottom-detail .text {
        font-size: 18px;
        font-weight: bold; }

.sticky-pay-btn {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 10; }

@media (min-width: 1280px) {
  .homeCapsulv2 .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .homeCapsulv2 .sb-headerCrate {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: 40vw; }
    .homeCapsulv2 .sb-headerCrate .sb-headerCrate--content {
      padding: 0 3.2rem; }
  .homeCapsulv2 .sb-contentCrate {
    margin-left: 40vw;
    padding-top: 83px; }
    .homeCapsulv2 .sb-contentCrate .sb-home-rewards .title .sb-heading {
      font-size: 1.3rem;
      line-height: 1.229; }
    .homeCapsulv2 .sb-contentCrate .streamCardsContainer {
      max-width: 500px; }
    .homeCapsulv2 .sb-contentCrate .lg-mb6 {
      margin-bottom: 4rem !important; }
    .homeCapsulv2 .sb-contentCrate .lg-px0 {
      padding-right: 0 !important; }
    .homeCapsulv2 .sb-contentCrate .lg-pl0,
    .homeCapsulv2 .sb-contentCrate .lg-px0 {
      padding-left: 0 !important; }
    .homeCapsulv2 .sb-contentCrate .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
      text-align: center; }
    .homeCapsulv2 .sb-contentCrate .pb4 .carousel .carousel__slideContainer .carousel__slider .lg-size4of12 {
      width: 33.33333% !important; }
    .homeCapsulv2 .sb-contentCrate .container {
      padding: 0 4.8rem; }
      .homeCapsulv2 .sb-contentCrate .container .sb-button--positive.sb-button--black,
      .homeCapsulv2 .sb-contentCrate .container .sb-button--positive.sb-button--rewardsDefault {
        background-color: #000;
        border-color: #000; }
        .homeCapsulv2 .sb-contentCrate .container .sb-button--positive.sb-button--black:hover,
        .homeCapsulv2 .sb-contentCrate .container .sb-button--positive.sb-button--rewardsDefault:hover {
          color: white;
          text-decoration: none;
          opacity: 0.7; }
      .homeCapsulv2 .sb-contentCrate .container .sb-button--default.sb-button--black,
      .homeCapsulv2 .sb-contentCrate .container .sb-button--default.sb-button--rewardsDefault {
        color: rgba(0, 0, 0, 0.87);
        border-color: rgba(0, 0, 0, 0.87); }
        .homeCapsulv2 .sb-contentCrate .container .sb-button--default.sb-button--black:hover,
        .homeCapsulv2 .sb-contentCrate .container .sb-button--default.sb-button--rewardsDefault:hover {
          text-decoration: none;
          background-color: rgba(0, 0, 0, 0.06); } }

@media (min-width: 768px) {
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
    text-align: center; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .lg-size4of12 {
    width: 41.66667%; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .lg-px6 {
    padding-right: 4rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .lg-pl6,
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .lg-px6 {
    padding-left: 4rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-pt7 {
    padding-top: 4.8rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-right: 2.4rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-pl4,
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-left: 2.4rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-pb4 {
    padding-bottom: 2.4rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-pt2 {
    padding-top: 0.8rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-pt7 {
    padding-top: 4.8rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-right: 2.4rem !important; }
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-pl4,
  .homeCapsulv2 .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-left: 2.4rem !important; } }

@media (max-width: 480px) {
  .profileStatusContainer {
    padding-bottom: 1.6rem; }
    .profileStatusContainer .left-cap .rewards-svg {
      height: 120px !important;
      width: 150px !important; }
    .profileStatusContainer .left-cap .digitalRewardsIcon {
      width: 40px;
      height: 40px; }
    .profileStatusContainer .right-cap .top .left-x {
      margin-right: 20px !important; }
    .profileStatusContainer .right-cap .bottom .t1 .title {
      font-size: 12px !important; } }

@media (min-width: 480px) {
  .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
    text-align: center; }
  .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .lg-size4of12 {
    width: 50%; }
  .sb-contentCrate .sb-content-capsul .md-pb4 {
    padding-bottom: 2.4rem !important; }
  .sb-contentCrate .sb-content-capsul .md-pt2 {
    padding-top: 0.8rem !important; }
  .sb-contentCrate .sb-content-capsul .md-pt7 {
    padding-top: 4.8rem !important; }
  .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-right: 2.4rem !important; }
  .sb-contentCrate .sb-content-capsul .md-pl4,
  .sb-contentCrate .sb-content-capsul .md-px4 {
    padding-left: 2.4rem !important; } }

@media (max-width: 768px) {
  .sticky-pay-btn {
    bottom: 15px !important;
    right: 15px !important; }
  .sb-contentCrate .sb-content-capsul .main-v2 .top-banner .top-banner-detail {
    padding: 10px !important; }
  .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list {
    padding: 10px !important; }
    .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list .campaign-item .left {
      width: 100% !important; }
    .sb-contentCrate .sb-content-capsul .main-v2 .campaign-list .campaign-item .right {
      width: 100% !important;
      padding-left: 0 !important; }
  .sb-contentCrate .sb-content-capsul .main-v2 .bottom-banner .bottom-detail {
    padding: 10px !important; }
  .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem {
    width: 33% !important;
    text-align: center; }
    .sb-contentCrate .sb-content-capsul .pb4 .carousel .carousel__slideContainer .carousel__slider .carousel__sliderItem span {
      font-size: 10px !important; } }

.sb-come-stores {
  position: relative;
  z-index: 1; }
  .sb-come-stores .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-come-stores .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-come-stores .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-come-stores .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-come-stores .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-come-stores .sb-contentCrate .come-stores-content .p1 .p1-head-title {
    padding: 25px; }
  .sb-come-stores .sb-contentCrate .come-stores-content .p1 .p1-list .p1-item {
    width: 50%;
    display: inline-block;
    text-align: center;
    padding: 25px;
    vertical-align: top; }
    .sb-come-stores .sb-contentCrate .come-stores-content .p1 .p1-list .p1-item img {
      max-width: 250px; }
    .sb-come-stores .sb-contentCrate .come-stores-content .p1 .p1-list .p1-item span {
      display: block;
      font-weight: bold;
      margin-top: 20px; }
  .sb-come-stores .sb-contentCrate .come-stores-content .p2 .p2-head-title {
    padding: 25px; }
  .sb-come-stores .sb-contentCrate .come-stores-content .p2 .p2-list .p2-item {
    width: 50%;
    display: inline-block;
    text-align: center;
    padding: 25px;
    vertical-align: top; }
    .sb-come-stores .sb-contentCrate .come-stores-content .p2 .p2-list .p2-item img {
      max-width: 250px; }
    .sb-come-stores .sb-contentCrate .come-stores-content .p2 .p2-list .p2-item span {
      display: block;
      font-weight: bold;
      margin-top: 20px; }

@media (min-width: 1280px) {
  .sb-come-stores .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-come-stores {
    padding-top: 83px; }
    .sb-come-stores .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-come-stores .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-come-stores .sb-contentCrate {
      margin-left: 40vw; }
      .sb-come-stores .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-come-stores .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-come-stores .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-come-stores .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .sb-come-stores .sb-contentCrate .come-stores-content .p1 .p1-list .p1-item img {
    width: 100%; }
  .sb-come-stores .sb-contentCrate .come-stores-content .p2 .p2-list .p2-item img {
    width: 100%; } }

.sb-survey-form {
  position: relative;
  z-index: 1; }
  .sb-survey-form .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-survey-form .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-survey-form .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-survey-form .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
    .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-head {
      padding: 0 20px 20px; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-head svg {
        width: 60px;
        display: inline-block !important;
        vertical-align: middle; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-head .sb-survey-form-title {
        vertical-align: middle;
        display: inline-block;
        font-size: 24px;
        margin-left: 20px;
        font-weight: 500; }
    .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content {
      background-color: white;
      border: 4px solid #016241;
      width: 95% !important;
      margin: 30px auto;
      padding: 0 20px 20px 20px; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .sb-survey-form-content-title {
        color: #016241; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-single {
        border: 2px solid black;
        padding: 15px;
        margin-bottom: 20px; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-single .survey-detail .question-title {
          display: block;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-single .survey-action {
          text-align: right; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-single .get-user-action .action-item {
          display: inline-block;
          vertical-align: top;
          margin-right: 30px; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-multiple {
        border: 2px solid #000;
        padding: 15px;
        margin-bottom: 20px; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-multiple .userLocaleTxt {
          margin-bottom: 40px;
          margin-top: 10px; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-multiple .matrix-table .survey-table-header {
          vertical-align: bottom;
          text-align: center; }
          .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-multiple .matrix-table .survey-table-header td {
            font-weight: bold; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content .survey-item-multiple .matrix-table .AltAnswerText:nth-child(2n+1) {
          background-color: #f1f1f1; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn .sb-survey-form-content textarea {
        width: 100%;
        margin: 20px 0;
        resize: none; }

@media (min-width: 1280px) {
  .sb-survey-form .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-survey-form {
    padding-top: 83px; }
    .sb-survey-form .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-survey-form .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-survey-form .sb-contentCrate {
      margin-left: 40vw; }
      .sb-survey-form .sb-contentCrate .sb-contentColumn {
        padding-top: 4px;
        padding-bottom: 40px; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-survey-form .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-survey-form .sb-contentCrate .sb-contentColumn--narrow {
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .sb-survey-form .sb-headerCrate {
    text-align: center; }
    .sb-survey-form .sb-headerCrate .survey-img {
      display: none; } }

.sb-help {
  position: relative;
  z-index: 1; }
  .sb-help .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-help .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-help .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-help .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-help .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-help .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-help .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-help .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-help .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-help .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-help .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-help .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-help .sb-contentCrate .help-capsul .help-head {
    background-color: #d4e9e2;
    padding: 60px;
    height: 120px;
    background-size: cover;
    position: relative; }
    .sb-help .sb-contentCrate .help-capsul .help-head .help-bar {
      position: absolute;
      bottom: -35px;
      width: calc(100% - 120px); }
      .sb-help .sb-contentCrate .help-capsul .help-head .help-bar .search-icon {
        position: absolute;
        left: 20px;
        top: 20px; }
        .sb-help .sb-contentCrate .help-capsul .help-head .help-bar .search-icon svg {
          width: 28px;
          height: 28px; }
          .sb-help .sb-contentCrate .help-capsul .help-head .help-bar .search-icon svg path {
            fill: #848484 !important; }
      .sb-help .sb-contentCrate .help-capsul .help-head .help-bar input {
        width: 100%;
        height: 50px;
        border: 1px solid #f7f7f7;
        padding: 35px 35px 35px 60px;
        font-size: 16px;
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        font-weight: 500;
        border: none;
        border-radius: 2px; }
        .sb-help .sb-contentCrate .help-capsul .help-head .help-bar input:focus {
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
          box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.24), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.34); }
  .sb-help .sb-contentCrate .help-capsul .help-container {
    padding: 80px 40px; }
    .sb-help .sb-contentCrate .help-capsul .help-container .help-category {
      text-align: center; }
      .sb-help .sb-contentCrate .help-capsul .help-container .help-category .help-category-item {
        display: inline-block;
        vertical-align: middle;
        width: calc(25% - 10px);
        margin-right: 10px;
        margin-bottom: 20px;
        height: 100px;
        border-radius: 2px;
        background-color: #1f6c4e;
        text-align: center;
        color: white;
        box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
        position: relative;
        text-align: -webkit-center; }
        .sb-help .sb-contentCrate .help-capsul .help-container .help-category .help-category-item span {
          /*position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);*/
          display: block;
          text-align: center;
          left: 0;
          right: 0;
          padding: 0 20px; }
        .sb-help .sb-contentCrate .help-capsul .help-container .help-category .help-category-item:nth-child(4n) {
          margin-right: 0; }
        .sb-help .sb-contentCrate .help-capsul .help-container .help-category .help-category-item:hover {
          opacity: 0.9;
          -webkit-transition: all .2s ease;
          transition: all .2s ease; }
        .sb-help .sb-contentCrate .help-capsul .help-container .help-category .help-category-item svg {
          width: 50px;
          display: block; }
      .sb-help .sb-contentCrate .help-capsul .help-container .help-category .active {
        opacity: 0.8; }
    .sb-help .sb-contentCrate .help-capsul .help-container .help-list {
      margin-top: 10px; }
      .sb-help .sb-contentCrate .help-capsul .help-container .help-list .help-list-item {
        background-color: white;
        border-radius: 2px;
        border: 1px solid #3d3d3d36;
        margin-bottom: 20px; }
        .sb-help .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head {
          cursor: pointer;
          display: block;
          padding: 15px; }
          .sb-help .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head span {
            font-weight: bold; }
          .sb-help .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head svg {
            float: right; }
          .sb-help .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-head .active {
            transform: rotate(180deg); }
        .sb-help .sb-contentCrate .help-capsul .help-container .help-list .help-list-item .help-list-item-content {
          border-top: 1px solid #d6d6d6;
          padding: 0px 20px 0px 20px; }
        .sb-help .sb-contentCrate .help-capsul .help-container .help-list .help-list-item:hover {
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
          border: 1px solid rgba(61, 61, 61, 0.876); }

@media (min-width: 1280px) {
  .sb-help .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-help {
    padding-top: 83px; }
    .sb-help .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-help .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-help .sb-contentCrate {
      margin-left: 40vw; }
      .sb-help .sb-contentCrate .sb-contentColumn {
        padding-top: 100px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-help .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-help .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-help .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 343px;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 768px) {
  .sb-help .sb-contentCrate .help-container {
    padding: 40px 15px !important; }
    .sb-help .sb-contentCrate .help-container .help-category-item {
      width: calc(50% - 10px) !important;
      margin-right: 0 !important; }
      .sb-help .sb-contentCrate .help-container .help-category-item span {
        font-size: 12px !important; }
      .sb-help .sb-contentCrate .help-container .help-category-item:nth-child(1n) {
        margin-right: 10px !important; } }

.sb-delivery {
  position: relative;
  z-index: 1; }
  .sb-delivery .sb-headerCrate {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); }
    .sb-delivery .sb-headerCrate .container--headerCrate {
      display: block;
      width: 100%;
      padding: 0 1.6rem; }
    .sb-delivery .sb-headerCrate .sb-headerCrate--content {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 2.4rem; }
  .sb-delivery .sb-contentCrate .sb-contentColumn {
    padding: 2.4rem 1.6rem 6.4rem; }
    .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black, .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault {
      color: rgba(0, 0, 0, 0.87);
      border-color: rgba(0, 0, 0, 0.87); }
      .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--black:hover, .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--default.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.06); }
    .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black, .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault {
      background-color: #000;
      border-color: #000; }
      .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--black:hover, .sb-delivery .sb-contentCrate .sb-contentColumn .sb-button--positive.sb-button--rewardsDefault:hover {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7); }
  .sb-delivery .sb-contentCrate .delivers-content .top-content {
    background-color: #1d3e38;
    margin: 20px 20px;
    height: 600px; }
    .sb-delivery .sb-contentCrate .delivers-content .top-content .top-logo {
      text-align: center;
      padding-top: 30px; }
      .sb-delivery .sb-contentCrate .delivers-content .top-content .top-logo .sb-logo-reserve {
        width: 200px; }
      .sb-delivery .sb-contentCrate .delivers-content .top-content .top-logo .sb-logo-pin {
        width: 90px;
        display: block;
        margin: 10px auto; }
      .sb-delivery .sb-contentCrate .delivers-content .top-content .top-logo .sb-bot-txt {
        color: white;
        font-weight: bold; }
      .sb-delivery .sb-contentCrate .delivers-content .top-content .top-logo .sb-top-bg {
        width: 65%; }
  .sb-delivery .sb-contentCrate .delivers-content .mid-content {
    text-align: center;
    margin-top: 150px; }
    .sb-delivery .sb-contentCrate .delivers-content .mid-content .comp-logo {
      background-color: #f5f0eb;
      width: 100%;
      padding: 20px;
      text-align: center; }
      .sb-delivery .sb-contentCrate .delivers-content .mid-content .comp-logo img {
        vertical-align: middle; }
      .sb-delivery .sb-contentCrate .delivers-content .mid-content .comp-logo .ys-logo {
        background-color: #f80a4f;
        width: 115px;
        height: 45px;
        line-height: 45px;
        border-radius: 4px;
        text-align: center;
        display: inline-block;
        margin-right: 15px; }
        .sb-delivery .sb-contentCrate .delivers-content .mid-content .comp-logo .ys-logo img {
          padding: 10px; }
      .sb-delivery .sb-contentCrate .delivers-content .mid-content .comp-logo .gy-logo {
        background-color: #5d3ebc;
        width: 115px;
        height: 45px;
        line-height: 45px;
        border-radius: 4px;
        text-align: center;
        display: inline-block; }
        .sb-delivery .sb-contentCrate .delivers-content .mid-content .comp-logo .gy-logo img {
          padding: 10px; }
    .sb-delivery .sb-contentCrate .delivers-content .mid-content .mid-title {
      color: #1d6243; }
    .sb-delivery .sb-contentCrate .delivers-content .mid-content p {
      color: #585a5a;
      padding: 0 20px; }
  .sb-delivery .sb-contentCrate .delivers-content .foot-content {
    text-align: center; }
    .sb-delivery .sb-contentCrate .delivers-content .foot-content p {
      color: #585a5a;
      padding: 0 20px; }

@media (min-width: 1280px) {
  .sb-delivery .sb-headerCrate .sb-headerCrate--content {
    padding-left: 11.2rem !important;
    padding-right: 11.2rem !important; } }

@media (min-width: 1024px) {
  .sb-delivery {
    padding-top: 83px; }
    .sb-delivery .sb-headerCrate {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      width: 40vw; }
      .sb-delivery .sb-headerCrate .container--headerCrate {
        padding: 0 3.2rem; }
    .sb-delivery .sb-contentCrate {
      margin-left: 40vw; }
      .sb-delivery .sb-contentCrate .sb-contentColumn {
        padding-top: 60px;
        padding-bottom: 100px;
        max-width: 670px !important; }
        .sb-delivery .sb-contentCrate .sb-contentColumn .content___LdxU3 img {
          display: inline-block;
          margin-right: 50px;
          vertical-align: middle; }
        .sb-delivery .sb-contentCrate .sb-contentColumn .content___LdxU3 .py33 {
          display: inline-block;
          width: 310px;
          vertical-align: middle;
          margin-top: 0px;
          padding: 0;
          margin-bottom: 0px; } }

@media (min-width: 375px) {
  .sb-delivery .sb-contentCrate .sb-contentColumn--narrow {
    max-width: 95%;
    padding-left: 0;
    padding-right: 0; } }

/*# sourceMappingURL=slick.min.css.map */
@font-face {
  font-family: slick;
  font-weight: 400;
  font-style: normal;
  src: url(fonts/slick.eot);
  src: url(fonts/slick.eot?#iefix) format("embedded-opentype"), url(fonts/slick.woff) format("woff"), url(fonts/slick.ttf) format("truetype"), url(fonts/slick.svg#slick) format("svg"); }

/*# sourceMappingURL=slick-theme.min.css.map */
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: 0; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  display: block;
  top: 0;
  left: 0; }
  .slick-track:after {
    display: table;
    content: '';
    clear: both; }
  .slick-track:before {
    display: table;
    content: ''; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-loading .slick-list {
  background: url(ajax-loader.gif) center center no-repeat #fff; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }
  .slick-slide img {
    display: block; }

[dir=rtl] .slick-slide {
  float: right; }

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto; }
  [dir=rtl] .slick-prev:before {
    content: '→'; }

[dir=rtl] .slick-next {
  right: auto;
  left: -25px; }
  [dir=rtl] .slick-next:before {
    content: '←'; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  position: absolute;
  display: block;
  padding: 0;
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: 0;
      background: 0 0; }
      .slick-dots li button:before {
        font-family: slick;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        opacity: .25;
        color: #000; }
      .slick-dots li button:focus {
        outline: 0; }
        .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:hover {
        outline: 0; }
        .slick-dots li button:hover:before {
          opacity: 1; }
  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #000; }

.slick-next {
  position: absolute;
  display: block;
  padding: 0;
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  right: -25px; }
  .slick-next:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #fff;
    content: '→'; }
  .slick-next:focus {
    color: transparent;
    outline: 0;
    background: 0 0; }
    .slick-next:focus:before {
      opacity: 1; }
  .slick-next:hover {
    color: transparent;
    outline: 0;
    background: 0 0; }
    .slick-next:hover:before {
      opacity: 1; }

.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  left: -25px; }
  .slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #fff;
    content: '←'; }
  .slick-prev:focus {
    color: transparent;
    outline: 0;
    background: 0 0; }
    .slick-prev:focus:before {
      opacity: 1; }
  .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0; }
    .slick-prev:hover:before {
      opacity: 1; }

.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev.slick-disabled:before {
  opacity: .25; }

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.sb-white-dialog-box {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.36); }
  .sb-white-dialog-box .in-modal {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    align-self: center;
    max-width: 420px;
    opacity: 1;
    visibility: visible;
    max-height: 97%;
    overflow: auto;
    padding: 3rem; }
  .sb-white-dialog-box .modal-just-text .text .title {
    font-size: 2.7rem;
    line-height: 1.385;
    display: block; }
  .sb-white-dialog-box .modal-just-text .text .content {
    display: block; }
  .sb-white-dialog-box .modal-media-text .text .title {
    font-size: 2.7rem;
    line-height: 1.385;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block; }
  .sb-white-dialog-box .modal-media-text .text .content {
    display: block; }
  .sb-white-dialog-box .modal-media-text .actions {
    margin-top: 40px; }

.egift-inform-modal .in-modal {
  width: 600px;
  max-width: 100%; }
  .egift-inform-modal .in-modal .text .title {
    color: #008248;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 25px; }
  .egift-inform-modal .in-modal .order-summary .summary-left {
    width: 50%;
    display: inline-block;
    vertical-align: top; }
    .egift-inform-modal .in-modal .order-summary .summary-left .summary-title {
      display: block;
      margin-bottom: 15px;
      text-decoration: underline;
      font-weight: bold; }
    .egift-inform-modal .in-modal .order-summary .summary-left .summary-name {
      display: block; }
    .egift-inform-modal .in-modal .order-summary .summary-left .summary-mail {
      display: block; }
    .egift-inform-modal .in-modal .order-summary .summary-left .summary-phone {
      display: block; }
  .egift-inform-modal .in-modal .order-summary .summary-right {
    width: 50%;
    display: inline-block;
    vertical-align: top; }
    .egift-inform-modal .in-modal .order-summary .summary-right .summary-title {
      display: block;
      margin-bottom: 15px;
      text-decoration: underline;
      font-weight: bold; }
    .egift-inform-modal .in-modal .order-summary .summary-right .summary-name {
      display: block; }
    .egift-inform-modal .in-modal .order-summary .summary-right .summary-mail {
      display: block; }
    .egift-inform-modal .in-modal .order-summary .summary-right .summary-phone {
      display: block; }
  .egift-inform-modal .in-modal .egift-detail .egift-box {
    margin-top: 30px; }
    .egift-inform-modal .in-modal .egift-detail .egift-box .egift-img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px; }
      .egift-inform-modal .in-modal .egift-detail .egift-box .egift-img img {
        width: 100px;
        border-radius: 5px; }
    .egift-inform-modal .in-modal .egift-detail .egift-box .egift-text {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 120px); }
  .egift-inform-modal .in-modal .egift-detail .egift-inform {
    margin-top: 10px; }
    .egift-inform-modal .in-modal .egift-detail .egift-inform .t1 {
      display: block; }
    .egift-inform-modal .in-modal .egift-detail .egift-inform .t2 {
      display: block;
      font-weight: bold; }

@media (max-width: 768px) {
  .egift-inform-modal .in-modal .order-summary .summary-left {
    width: 100%;
    margin-bottom: 15px; }
  .egift-inform-modal .in-modal .order-summary .summary-right {
    width: 100%; } }

@media (min-width: 1024px) {
  .lg-mx0 {
    margin-right: 0 !important; }
  .lg-ml0, .lg-mx0 {
    margin-left: 0 !important; } }

.sb-agreement-modal {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.36);
  padding: 20px; }
  .sb-agreement-modal .capsul {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    align-self: center;
    max-width: 100%;
    opacity: 1;
    visibility: visible;
    max-height: 97%;
    overflow: auto;
    padding: 3rem; }
    .sb-agreement-modal .capsul .close-icon {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer; }
    .sb-agreement-modal .capsul .content {
      width: 500px;
      height: 400px;
      max-width: 100%;
      margin-top: 50px;
      overflow: auto; }

.isAnonym .anonim-cont {
  max-width: 300px;
  margin: 40px auto; }

.v2-sb-add-new-cart {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.36);
  padding: 20px; }
  .v2-sb-add-new-cart .capsul {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    align-self: center;
    width: 500px;
    max-width: 100%;
    opacity: 1;
    visibility: visible;
    max-height: 97%;
    overflow: auto;
    padding: 4rem; }
    .v2-sb-add-new-cart .capsul .head {
      white-space: nowrap; }
      .v2-sb-add-new-cart .capsul .head .left {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        white-space: normal; }
        .v2-sb-add-new-cart .capsul .head .left .title {
          font-size: 16px;
          font-weight: bold;
          border-bottom: 2px solid #00a862;
          padding-bottom: 5px; }
      .v2-sb-add-new-cart .capsul .head .right {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        text-align: right; }
        .v2-sb-add-new-cart .capsul .head .right .close-icon {
          cursor: pointer; }
    .v2-sb-add-new-cart .capsul .detail-head {
      margin-top: 15px; }
      .v2-sb-add-new-cart .capsul .detail-head p {
        color: gray; }
    .v2-sb-add-new-cart .capsul .form-capsul {
      margin-top: 35px; }
      .v2-sb-add-new-cart .capsul .form-capsul .form-item {
        margin-bottom: 20px;
        white-space: nowrap; }
        .v2-sb-add-new-cart .capsul .form-capsul .form-item .col-50 {
          display: inline-block;
          width: 50%;
          vertical-align: top; }
        .v2-sb-add-new-cart .capsul .form-capsul .form-item .form-title {
          display: block;
          font-size: 12px;
          font-weight: 600;
          color: #0f1f2c;
          margin-bottom: 10px; }
        .v2-sb-add-new-cart .capsul .form-capsul .form-item label {
          display: block;
          font-size: 12px;
          font-weight: 600;
          color: #0f1f2c;
          margin-bottom: 10px; }
        .v2-sb-add-new-cart .capsul .form-capsul .form-item .form-inp {
          width: 100%;
          height: 47px;
          border-radius: 4px;
          border: solid 1px #e1e8ee;
          padding: 15px; }
        .v2-sb-add-new-cart .capsul .form-capsul .form-item .form-select {
          width: 100%;
          height: 47px;
          border-radius: 4px;
          border: 1px solid #e1e8ee;
          padding: 15px; }
        .v2-sb-add-new-cart .capsul .form-capsul .form-item .month-cap {
          padding-right: 10px; }
        .v2-sb-add-new-cart .capsul .form-capsul .form-item .year-cap {
          padding-left: 10px; }
      .v2-sb-add-new-cart .capsul .form-capsul .aggr-mp {
        white-space: normal; }
        .v2-sb-add-new-cart .capsul .form-capsul .aggr-mp .option__labelText {
          font-weight: 400 !important;
          font-size: 12px;
          padding-top: 0 !important;
          position: relative;
          top: -2px; }
        .v2-sb-add-new-cart .capsul .form-capsul .aggr-mp .txt {
          font-size: 12px;
          color: gray; }
      .v2-sb-add-new-cart .capsul .form-capsul .action {
        white-space: normal;
        margin-top: 40px; }
        .v2-sb-add-new-cart .capsul .form-capsul .action .left {
          display: inline-block;
          width: 40%;
          vertical-align: middle; }
          .v2-sb-add-new-cart .capsul .form-capsul .action .left .mp-icon {
            width: 140px; }
        .v2-sb-add-new-cart .capsul .form-capsul .action .right {
          display: inline-block;
          width: 60%;
          vertical-align: middle;
          text-align: right; }

.v3-sb-generic-modal {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.36);
  padding: 20px; }
  .v3-sb-generic-modal .capsul {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    align-self: center;
    width: 600px;
    max-width: 100%;
    opacity: 1;
    visibility: visible;
    max-height: 97%;
    overflow: auto;
    padding: 4rem; }
    .v3-sb-generic-modal .capsul .head {
      white-space: nowrap; }
      .v3-sb-generic-modal .capsul .head .left {
        width: 80%;
        display: inline-block;
        vertical-align: middle;
        white-space: normal; }
        .v3-sb-generic-modal .capsul .head .left .title {
          color: #3d3a35;
          font-size: 16px;
          font-weight: bold;
          border-bottom: 2px solid #00a862;
          padding-bottom: 5px; }
      .v3-sb-generic-modal .capsul .head .right {
        width: 20%;
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        text-align: right; }
        .v3-sb-generic-modal .capsul .head .right .close-icon {
          cursor: pointer; }
    .v3-sb-generic-modal .capsul .content {
      margin-top: 30px; }
      .v3-sb-generic-modal .capsul .content .text {
        font-size: 12px; }
    .v3-sb-generic-modal .capsul .action {
      margin-top: 15px; }
      .v3-sb-generic-modal .capsul .action .sb-button {
        margin-bottom: 10px; }

.sb-term-modal {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100 !important;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.36); }
  .sb-term-modal .in-modal {
    position: relative;
    z-index: 3;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    align-self: center;
    width: 60vw;
    opacity: 1;
    visibility: visible;
    max-height: 97%;
    overflow: auto;
    padding: 3rem; }
    .sb-term-modal .in-modal object {
      width: 100%;
      height: 50vh; }

@media (max-width: 768px) {
  .sb-agreement-modal .capsul {
    padding: 15px !important; }
  .v2-sb-add-new-cart {
    padding: 0 !important; }
    .v2-sb-add-new-cart .capsul {
      max-height: 100% !important;
      padding: 20px;
      border-radius: 0 !important;
      align-self: unset !important;
      width: 100% !important; }
      .v2-sb-add-new-cart .capsul .action .right {
        zoom: 0.75; }
  .v3-sb-generic-modal {
    padding: 0 !important; }
    .v3-sb-generic-modal .capsul {
      max-height: 100% !important;
      padding: 20px;
      border-radius: 0 !important;
      align-self: unset !important;
      width: 100% !important; }
      .v3-sb-generic-modal .capsul .head .left .title {
        border: none; }
      .v3-sb-generic-modal .capsul .action .right {
        zoom: 0.75; }
  .sb-term-modal .in-modal {
    width: 90%; } }
