.sb-come-stores{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }
    
    .sb-contentCrate{
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }
        }

     
       .come-stores-content{
           .p1{

              .p1-head-title{
                    padding: 25px;
               }
            
               .p1-list{
                   .p1-item{
                       width: 50%;
                       display: inline-block;
                       text-align: center;
                       padding: 25px;
                       vertical-align: top;

                       img{
                           max-width: 250px;
                       }

                       span{
                           display: block;
                           font-weight: bold;
                           margin-top: 20px;
                       }
                   }
               }
           }

           .p2{
                .p2-head-title{
                    padding: 25px;
                }
            
                .p2-list{
                    .p2-item{
                        width: 50%;
                        display: inline-block;
                        text-align: center;
                        padding: 25px;
                        vertical-align: top;

                        img{
                            max-width: 250px;
                        }

                        span{
                            display: block;
                            font-weight: bold;
                            margin-top: 20px;
                            
                        }
                    }
                }
            }
       }




        

    }
}


@media (min-width: 1280px){
   .sb-come-stores{
       
        .sb-headerCrate{
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }
        
        .sb-contentCrate{
            
        }
    }
}

@media (min-width: 1024px){
    .sb-come-stores{
        padding-top: 83px;

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }
        
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-contentColumn {
                //padding-top: 100px;
                padding-top: 60px;
                padding-bottom: 100px;
                max-width: 670px !important;

                .content___LdxU3{
                    img{
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33{
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .sb-come-stores{
        
        .sb-headerCrate{

        }
        
        .sb-contentCrate{
           
        }
    
    }
}

@media (min-width: 480px){
    .sb-come-stores{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 375px){
    .sb-come-stores{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .sb-contentColumn--narrow {
                max-width: 95%;
                padding-left: 0;
                padding-right: 0;
            }


        }
    }
}

@media (max-width: 768px){
    .sb-come-stores{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
           .come-stores-content{
               .p1{
                .p1-list{
                    .p1-item{
                        img{
                            width: 100%;
                        }
                    }
                }
               }

               .p2{
                .p2-list{
                    .p2-item{
                        img{
                            width: 100%;
                        }
                    }
                }
               }
           }
        }
    
    }
}