.prodDetail-content{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .flex{

            .sb-headerCrate--content {
                margin-top: auto;
                margin-bottom: auto;
                padding-bottom: 2.4rem;
            }

            .size12of12 {
                width: 100%;
            }

        }
    }

    .sb-contentCrate{
       .grid{
            display: block;
            margin: 0;
            padding: 0;
            text-align: left;

            .gridItem{
                position: relative;
                display: inline-block;
                width: 100%;
                margin: 0;
                padding: 0;
                text-align: left;
                vertical-align: top;
                padding: 0 1.6rem;

            }
       }
    }

    
    .color-textBlackSoft {
        color: rgba(0,0,0,.56) !important;

        a{
            color: rgba(0,0,0,.56) !important;
        }
    }
}


@media (min-width: 1280px){
    .prodDetail-content{

        .sb-headerCrate{
         
    
            .flex{
                .sb-headerCrate--content {
                    padding-left: 11.2rem !important;
                    padding-right: 11.2rem !important;
                }
    
                .size12of12 {
                  
                }
            }
        }
    
        .sb-contentCrate{
             .grid{
                margin: 0 -2.4rem !important;
                .gridItem{
                    padding: 0 2.4rem !important;
                }
            }
        }
    }
}

@media (min-width: 1024px){

    .prodDetail-content{
        padding-top: 82px;

        .sb-headerCrate{
            position: fixed;
            top: 82px;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }
        }
    
        .sb-contentCrate{
            margin-left: 40vw;

            .container {
                padding: 0 4.8rem;
            }
        }

        .lg-pb4 {
            padding-bottom: 2.4rem!important;
        }

        .lg-pt5 {
            padding-top: 3.2rem!important;
            //padding-top: 5rem!important;
        }

    }
 
}

@media (min-width: 768px){
    .prodDetail-content{
        
        .sb-headerCrate{
           
            .flex{
    
                .sb-headerCrate--content {
                  
                }
    
                .size12of12 {
                  
                }
    
            }
        }
    
        .sb-contentCrate{
           .grid{
               .gridItem{
                    .imageBlock{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        overflow: hidden;
                        z-index: 1;
                    }
               }

               .md-size6of12 {
                    width: 50% !important;
                }
           }
        }
    
        
        .color-textBlackSoft {
        
            a{
            
            }
        }

        .md-hidden {
            display: none!important;
        }
    }
}

@media (min-width: 480px){
   
}

@media (min-width: 375px){
 
}

@media (max-width: 768px){

    .prodDetail-content{
        
        .sb-headerCrate{
            display:none !important;
            .flex{
    
                .sb-headerCrate--content {
                  
                }
    
                .size12of12 {
                  
                }
    
            }
        }
    
        .sb-contentCrate{
           .grid{
               .gridItem{
                    .imageBlock{
                        border-radius: 50%;
                        overflow: hidden;
                        margin-top: 20px;
                        
                    }
               }

               .md-size6of12 {
                   
                }
           }
        }
    
        
        .color-textBlackSoft {
        
            a{
            
            }
        }

        .md-hidden {
           
        }
    }
}