.sb-new-footer{
    background: #fff;
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 50px;
    .foot-top{
        .menu-list{
            .menu-item{
                padding:40px 0;
                
                .menu-head{
                    cursor: pointer;
                    .left{
                        display: inline-block;
                        width: calc(100% - 50px);
                        vertical-align: middle;
                        .title{
                            font-size: 2.3rem;
                            line-height: 3.1rem;
                            letter-spacing: -.015rem;
                            font-weight: 400;
                        }
                    }

                    .right{ 
                        display: inline-block;
                        width: 50px;
                        text-align: left;
                        vertical-align: middle;

                        .active-arrow{
                            transform: rotate(180deg);
                        }

                        .icon-wrapper{
                            padding: 0.8rem;
                            border-radius: 100%;
                            height: 4rem;
                            width: 4rem;
                            transition: white .2s cubic-bezier(.19,1,.22,1);
                            cursor: pointer;

                            &:hover{
                                background: #edebe9;
                            }
                        }
                    }
                }

                .sub-menu{
                    display: none;
                    .sub-item{
                        display: block;
                        padding:20px 0;
                        color: #6c6c6c;
                        font-weight: 600;
                        line-height: 1.2;
                        font-size: 1.4rem;
                        letter-spacing: -.014rem;
                        cursor: pointer;
                    }
                }
            }
        }   
    }

    .ui-divider{
        border: 0.1rem solid #ccc;
    }

    .foot-bottom{
    padding-top: 20px;
      .secondary-links{
          .link-item{
            display: block;
            color: #1f1f1f;
            font-size: 1.4rem;
            letter-spacing: -.014rem;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 3.2rem;
            cursor: pointer;
          }
      }
    }
}