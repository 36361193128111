.fieldStatus {
  display: block;
  font-size: 13px;
  font-size: 1.3rem;
  position: relative;
  transition: color .2s ease-out
}

.fieldStatus--error .fieldStatus__icon {
  color: #e75b52
}

.fieldStatus--valid .fieldStatus__icon {
  color: #00653e
}

.fieldStatus--indeterminate:before {
  content: "\2022";
  position: absolute;
  left: 3px;
  top: 1px
}

.fieldStatus__icon {
  position: absolute;
  top: -2px;
  left: -4px;
  animation: .2s ease-out field-status-icon-fade-in forwards
}

.fieldStatus__text {
  display: inline-block;
  font-size: 13px;
  font-size: 1.3rem;
  padding-left: 20px;
  vertical-align: middle
}

@keyframes float-label-appear {
  0% {
    font-size: 1.4rem;
    opacity: 1;
    top: 30px
  }
  50% {
    font-size: 1.3rem;
    opacity: 0;
    top: 30px
  }
  51% {
    font-size: 1.3rem;
    opacity: 0;
    top: 10px
  }
  to {
    font-size: 1.3rem;
    opacity: 1;
    top: 10px
  }
}

.floatLabel {
  cursor: text;  
  font-size: 1.4rem;  
  position: absolute;  
  top: 30px;  
  left: 0;  
  -webkit-user-select: none; 
  -moz-user-select: none;  
  -ms-user-select: none;  
  user-select: none;  
  z-index: 0
}

.floatLabel--isActive {
  animation: .3s float-label-appear ease-in-out forwards
}

.field {
  display: block;
  position: relative
}

.fieldInput {
  -webkit-appearance: none;  
  -moz-appearance: none;  
  appearance: none;  
  background-color: transparent;  
  border: 0;  
  border-radius: 0;  
  color: rgba(0, 0, 0, .9);  
  display: block;  
  font-size: 1.6rem;  
  font-weight: 700;  
  line-height: 10px;  
  line-height: 1rem;  
  padding: 32px 0 6px;  
  width: 100%;  
  z-index: 1
}

.field--dark .fieldInput {
  color: #fff
}

.fieldInput::-ms-clear,
.fieldInput::-ms-reveal {
  display: none
}

.field__inputWrapper {
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, .2)
}

.field--dark .field__inputWrapper {
  border-bottom-color: hsla(0, 0%, 100%, .2)
}

.fieldInput:focus {
  outline: none!important
}

.field--isFocused .field__inputWrapper {
  border-color: #00a862
}

.field--isFocused.field--rewardsGold .field__inputWrapper {
  border-color: #c2a661
}

.field--isInvalid.field--rewardsGold .field__inputWrapper,
.field--isInvalid .field__inputWrapper {
  border-color: #e75b52
}

.field__warningIcon {
  color: #e75b52
}
.sb-editField {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, .2)
}

.sb-editField__list {
  font-size: 1.6rem;
  line-height: 1.4;
  padding-top: 32px;
  word-wrap: break-word;
  margin: 0
}

.sb-editField__item {
  padding-bottom: 6px
}

.sb-editField__button {
  padding-bottom: 6px;
  font-size: 1.6rem;
  line-height: 1.4
}

.sb-editField__label {
  top: 6px;
  z-index: 1
}
.select {
  display: block;
  position: relative;
  width: 100%
}

.select__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1
}

.select__input,
.select__selectedText {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%
}

.select__selectedText {
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  display: block;
  line-height: 1.25;
  padding-top: 32px;
  padding-bottom: 6px
}

.select__floatLabel {
  pointer-events: none
}

.select__selectedText--noLabel {
  padding-top: 10px;
  padding-bottom: 10px
}

.select__input:focus+.select__selectedText {
  outline: none!important;
  border-color: #00a862
}

.select__selectedText--isInvalid {
  border-color: #e75b52!important
}

.select__icon {
  color: rgba(0, 0, 0, .56);
  right: 0;
  position: absolute;
  bottom: 8px
}
@keyframes option-label-marker-expansion {
  0% {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

.option__label {
  cursor: pointer;
  display: block;
  padding: 10px 0;
  position: relative
}

[data-focus-source=keyboard] .option__input:focus+.option__label {
  border-color: transparent;
  outline: 2px solid #008248
}

.option__labelMarker {
  border: 1px solid #008248;
  color: transparent
}

.option__input:checked+.option__label .option__labelMarker {
  background-color: #008248;
  border-color: #008248;
  color: #fff
}

.option--checkbox .option__labelMarker {
  border-radius: 25%
}

.option--radio .option__labelMarker {
  border-radius: 50%
}

.option__labelIcon {
  margin: auto
}

.option__radioIcon {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid #008248;
  position: relative
}

.option__input:checked+.option__label .option__radioIcon:before {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  content: "";
  border-radius: 50%;
  background-color: #008248
}

.option__input:checked+.option__label .option__labelIcon,
.option__input:checked+.option__label .option__radioIcon:before {
  animation: .3s option-label-marker-expansion cubic-bezier(.32, 2.32, .61, .27) forwards
}

.option__labelText {
  padding-top: 2px;
  padding-left: 10px;
  font-size: 14px;
  font-size: 1.4rem;
  min-width: 0
}
.sb-toggle {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px
}

.sb-toggle__input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer
}

.sb-toggle__slider {
  background-color: rgba(0, 0, 0, .2);
  border-radius: 50px;
  transition: background-color .4s ease-out
}

.sb-toggle__slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  transition: transform .4s ease-out;
  border-radius: 50%
}

.sb-toggle__input:checked+.sb-toggle__slider {
  background-color: #00a862
}

[data-focus-source=keyboard] .sb-toggle__input:focus+.sb-toggle__slider {
  box-shadow: 0 0 0 3px #00a862
}

.sb-toggle__input:checked+.sb-toggle__slider:before {
  transform: translateX(20px)
}.sb-toggle {
   position: relative;
   display: inline-block;
   width: 52px;
   height: 32px
 }

.sb-toggle__input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer
}

.sb-toggle__slider {
  background-color: rgba(0, 0, 0, .2);
  border-radius: 50px;
  transition: background-color .4s ease-out
}

.sb-toggle__slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  transition: transform .4s ease-out;
  border-radius: 50%
}

.sb-toggle__input:checked+.sb-toggle__slider {
  background-color: #00a862
}

[data-focus-source=keyboard] .sb-toggle__input:focus+.sb-toggle__slider {
  box-shadow: 0 0 0 3px #00a862
}

.sb-toggle__input:checked+.sb-toggle__slider:before {
  transform: translateX(20px)
}
.field--dark .fieldInput {
  color: #fff
}

.fieldInput::-ms-clear,
.fieldInput::-ms-reveal {
  display: none
}

.field__inputWrapper {
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, .2)
}

.field--dark .field__inputWrapper {
  border-bottom-color: hsla(0, 0%, 100%, .2)
}