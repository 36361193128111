.sb-small-footer{
    .container-footer{
        display: -webkit-box;
        display: flex;

        .find-store{

            .langBtn{
                margin-left: 0 !important;
            }

            .storeBtn{
                margin-left: 0 !important;
            }

            a{
                text-decoration: none;
                &:hover{
                    color: #008248;
                }
            }
        }

        .footer-links{
            a{
                color: currentColor;
            }
        }

    }

    .container-footer>* {
        padding: 0 6.4rem 0 0;
    }
}

 
@media (max-width: 768px){
    .sb-small-footer{
        .container-footer{

            .find-store{
                a{
    
                    &:hover{
                      
                    }
                }
            }
    
            .footer-links{
                a{
                }
            }
    
        }
    
        .container-footer> * {
            padding: 0 10px 0 0 !important;
        }
    }
}