.sb-gift{
    position: relative;
    z-index: 1;
    display: none;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }
    
    .sb-contentCrate{

        .card-list{
            padding: 80px 80px;
            .card-item{
                width: 25%;
                display: inline-block;
                vertical-align: middle;
                padding: 10px;

                img{
                    border-radius: 3.3333%/5.298%;
                    box-shadow: 0 2px 3px rgba(7, 7, 7, 0.24);
                }
            }
        }
        
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .giftcard-form{
                width: 100%;

                .pb6{
                    border: 0;
                    padding: 0;
                    margin: 0;
                    min-width: 0;
                    padding-bottom: 20px !important;
                }

                .import-credit{
                    margin-top: 20px;
                    .sb-frap{
                        background-color: white;
                        color: black;
                        zoom: 0.75;
                    }
                }

                .number-box{
                    position: relative;

                    .icon{
                        position: absolute;
                        right: 0px;
                        bottom: 22px;
                        cursor: pointer;
                        z-index: 2;

                        svg{
                            width: 18px;
                            height: 18px;
                            fill: rgba(0,0,0,.6);
                        }
                    }
                }
            }

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }
        }
    }

}


@media (min-width: 1280px){
   .sb-gift{
       
        .sb-headerCrate{
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }
        
        .sb-contentCrate{
            
        }
    }
}

@media (min-width: 1024px){
    .sb-gift{
        padding-top: 83px;

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }
        
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-contentColumn {
                padding-top: 50px;
                padding-bottom: 100px;
                max-width: 440px !important;

                .content___LdxU3{
                    img{
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33{
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .sb-gift{
        
        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 480px){
    .sb-gift{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 375px){
    .sb-gift{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .sb-contentColumn--narrow {
                max-width: 343px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    
    }
}

@media (max-width: 768px){
    .sb-gift{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}