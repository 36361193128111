$color_1: transparent;
$color_2: #fff;
$color_3: #000;
$font-family_1: slick;

/*# sourceMappingURL=slick.min.css.map */

@font-face {
	font-family: slick;
	font-weight: 400;
	font-style: normal;
	src: url(fonts/slick.eot);
	src: url(fonts/slick.eot?#iefix) format('embedded-opentype'),url(fonts/slick.woff) format('woff'),url(fonts/slick.ttf) format('truetype'),url(fonts/slick.svg#slick) format('svg');
}
/*# sourceMappingURL=slick-theme.min.css.map */
.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
	&:focus {
		outline: 0;
	}
}
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	.slick-list {
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
		-o-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
	.slick-track {
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
		-o-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
	}
}
.slick-track {
	position: relative;
	display: block;
	top: 0;
	left: 0;
	&:after {
		display: table;
		content: '';
		clear: both;
	}
	&:before {
		display: table;
		content: '';
	}
}
.slick-loading {
	.slick-slide {
		visibility: hidden;
	}
	.slick-track {
		visibility: hidden;
	}
	.slick-list {
		background: url(ajax-loader.gif) center center no-repeat #fff;
	}
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	img {
		display: block;
	}
}
[dir=rtl] {
	.slick-slide {
		float: right;
	}
	.slick-prev {
		right: -25px;
		left: auto;
		&:before {
			content: '→';
		}
	}
	.slick-next {
		right: auto;
		left: -25px;
		&:before {
			content: '←';
		}
	}
}
.slick-slide.slick-loading {
	img {
		display: none;
	}
}
.slick-slide.dragging {
	img {
		pointer-events: none;
	}
}
.slick-initialized {
	.slick-slide {
		display: block;
	}
}
.slick-vertical {
	.slick-slide {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}
.slick-arrow.slick-hidden {
	display: none;
}
.slick-dots {
	position: absolute;
	display: block;
	padding: 0;
	bottom: -25px;
	width: 100%;
	margin: 0;
	list-style: none;
	text-align: center;
	li {
		button {
			&:before {
				font-family: $font-family_1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				font-size: 6px;
				line-height: 20px;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				content: '•';
				text-align: center;
				opacity: .25;
				color: $color_3;
			}
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 20px;
			padding: 5px;
			cursor: pointer;
			color: $color_1;
			border: 0;
			outline: 0;
			background: 0 0;
			&:focus {
				outline: 0;
				&:before {
					opacity: 1;
				}
			}
			&:hover {
				outline: 0;
				&:before {
					opacity: 1;
				}
			}
		}
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
	}
	li.slick-active {
		button {
			&:before {
				opacity: .75;
				color: $color_3;
			}
		}
	}
}
.slick-next {
	position: absolute;
	display: block;
	padding: 0;
	font-size: 0;
	line-height: 0;
	top: 50%;
	width: 20px;
	height: 20px;
	-webkit-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	transform: translate(0,-50%);
	cursor: pointer;
	color: $color_1;
	border: none;
	outline: 0;
	background: 0 0;
	right: -25px;
	&:before {
		font-family: $font-family_1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 20px;
		line-height: 1;
		opacity: .75;
		color: $color_2;
		content: '→';
	}
	&:focus {
		color: $color_1;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
	&:hover {
		color: $color_1;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
}
.slick-prev {
	position: absolute;
	display: block;
	padding: 0;
	font-size: 0;
	line-height: 0;
	top: 50%;
	width: 20px;
	height: 20px;
	-webkit-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	transform: translate(0,-50%);
	cursor: pointer;
	color: $color_1;
	border: none;
	outline: 0;
	background: 0 0;
	left: -25px;
	&:before {
		font-family: $font-family_1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 20px;
		line-height: 1;
		opacity: .75;
		color: $color_2;
		content: '←';
	}
	&:focus {
		color: $color_1;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
	&:hover {
		color: $color_1;
		outline: 0;
		background: 0 0;
		&:before {
			opacity: 1;
		}
	}
}
.slick-next.slick-disabled {
	&:before {
		opacity: .25;
	}
}
.slick-prev.slick-disabled {
	&:before {
		opacity: .25;
	}
}
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}
