button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button {
  background: transparent;
  border: 0;
  padding: 0;
  border-radius: 0;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}

.sb-button {
  display: inline-block;
  background: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 50px;
  padding: 7px 16px;
  font-size: 1.4rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  font-family: SoDoSans, sans-serif;
  font-weight: 600;
  letter-spacing: -0.014rem;
}
.sb-button--default {
  border-color: #008248;
  color: #008248;
}

.sb-button--positive {
  background-color: #008248;
  border-color: #008248;
  color: #fff;
}

.sb-button--positive:active {
  transform: scale(0.95);
  transition: all 0.2s ease;
}

.sb-button--default:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.1);
}

body:not([data-focus-source="keyboard"]) :focus {
  outline: none;
}

.sb-button--default:active {
  transform: scale(0.95);
  transition: all 0.2s ease;
}
.sb-button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.sb-button--text {
  border-color: transparent;
  color: #008248;
}
.sb-button--large {
  padding: 1.6rem 3.2rem;
  border-radius: 2px;
}
.sb-textLink {
  text-align: inherit;
  text-decoration: underline;
}
.sb-frap {
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 18px 2.4rem;
  background: #00a862;
  box-shadow:
    0 0 6px rgba(0, 0, 0, 0.24),
    0 8px 12px rgba(0, 0, 0, 0.14);
  border-radius: 500px;
  color: #fff;
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.2;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-property: transform, box-shadow, width, height;
  transition-timing-function: ease;
}
.sb-frap:not(.sb-frap--disabled):not(.sb-frap--loading):active {
  box-shadow:
    0 0 6px rgba(0, 0, 0, 0.24),
    0 8px 12px transparent;
  transform: translateY(4px);
}
.sb-frap--mini {
  padding: 10px 1.6rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 17px;
  font-size: 1.7rem;
}
.sb-frap--mini:before {
  content: "";
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  bottom: -0.8rem;
  left: -0.8rem;
  z-index: -1;
}
@keyframes ripple {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(50);
  }
}
.sb-frap--disabled .ripple {
  display: none !important;
}
.sb-ripple {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  animation-name: ripple;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  background-color: white;
}
.sb-frap--mini.sb-frap--disabled {
  background: #ccc;
  color: rgba(0, 0, 0, 0.56);
  cursor: not-allowed;
}
.sb-frap--disabled {
  background: #ccc;
  cursor: not-allowed;
}
.sb-frap--loading {
  width: 5.6rem;
  height: 5.6rem;
  padding: 0;
}
.sb-frap--loadingCircle {
  margin: auto;
  border: 3px solid hsla(0, 0%, 100%, 0.2);
  border: 0.3rem solid hsla(0, 0%, 100%, 0.2);
  border-right: 3px solid #fff;
  border-right: 0.3rem solid #fff;
  width: 75%;
  height: 75%;
  border-radius: 500px;
  animation:
    spin 0.75s linear infinite,
    fade 0.1s;
}

.sb-button--default {
  border-color: #008248;
  color: #008248;
}

.sb-button--default:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.1);
}

.sb-button--default:active {
  transform: scale(0.95);
  transition: all 0.2s ease;
}

.sb-button--default.sb-button--dark,
.sb-button--default.sb-button--rewardsGreen {
  border-color: #00a862;
  color: #00a862;
}

.sb-button--default.sb-button--dark:not(.sb-button--disabled):hover,
.sb-button--default.sb-button--rewardsGreen:not(.sb-button--disabled):hover {
  border-color: #008248;
  background-color: #008248;
  color: #fff;
}

.sb-button--default.sb-button--rewardsGold {
  border-color: #c2a661;
  color: #c2a661;
}

.sb-button--default.sb-button--rewardsGold:not(.sb-button--disabled):hover {
  background-color: #c2a661;
  border-color: #c2a661;
  color: rgba(0, 0, 0, 0.87);
}

.sb-button--positive {
  background-color: #008248;
  border-color: #008248;
  color: #fff;
}

.sb-button--positive:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.9);
  border-color: rgba(0, 168, 98, 0.9);
}

.sb-button--positive:active {
  transform: scale(0.95);
  transition: all 0.2s ease;
}

.sb-button--positive.sb-button--rewardsGold {
  color: rgba(0, 0, 0, 0.87);
}

.sb-button--positive.sb-button--rewardsGold,
.sb-button--positive.sb-button--rewardsGold:not(.sb-button--disabled):hover {
  background-color: #c2a661;
  border-color: #c2a661;
}

[data-focus-source="keyboard"] .sb-button--positive:focus {
  background-color: #3d3935;
}

.sb-button--text {
  border-color: transparent;
  color: #008248;
}

.sb-button--text:not(.sb-button--disabled):hover {
  background-color: rgba(0, 168, 98, 0.1);
}

.sb-button--text.sb-button:active {
  transform: scale(0.95);
  transition: all 0.2s ease;
}

.sb-button--text.sb-button--dark,
.sb-button--text.sb-button--rewardsGreen {
  color: #00a862;
}

.sb-button--text.sb-button--rewardsGold {
  color: #c2a661;
}

.sb-button--text.sb-button--dark:not(.sb-button--disabled):hover,
.sb-button--text.sb-button--rewardsGold:not(.sb-button--disabled):hover,
.sb-button--text.sb-button--rewardsGreen:not(.sb-button--disabled):hover {
  color: #fff;
  background-color: transparent;
}

.sb-button--large {
  padding: 1.6rem 3.2rem;
  border-radius: 2px;
}

.sb-button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(270deg);
  }
  to {
    transform: rotate(1turn);
  }
}
