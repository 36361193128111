.isAnonym{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }
    
    .sb-contentCrate{
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }
        }
    }
}

.sb-home-capsul{
    position: relative;
    z-index: 1;

    .sb-headerCrate {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;

            .sb-heading--large {
                font-size: 2.7rem;
                line-height: 1.385;
            }
        }
    }

    .sb-contentCrate{

        overflow: hidden;

        .sb-content-capsul{

  
            .my-cards {
                width: 300px;
                margin: 150px auto;

                .title {
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                    color: #777777;
                    margin-bottom: 50px;
                }

                .credit-cards {
                    margin-top: 20px;
                    .card-item {
                        border-bottom: 1px solid #ababab;
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                        position: relative;
                        padding-top: 5px;
                        padding-left: 5px;
                        padding-right: 5px;
                        cursor: pointer;

                        .card-logo {
                            display: inline-block;
                            vertical-align: middle;

                            img {
                                width: 64px;
                                margin-right: 10px;
                                vertical-align: middle;
                            }
                        }

                        .card-inform {
                            display: inline-block;
                            vertical-align: middle;

                            .card-title {
                                display: block;
                                color: #9e9e9e;
                                font-size: 15px;
                            }

                            .card-number {
                                display: block;
                                color: #9e9e9e;
                                font-size: 15px;
                            }
                        }

                        .card-action {
                            display: inline-block;
                            vertical-align: middle;

                            .card-delete-btn {
                                display: none;
                                background-color: #e75b52;
                                color: white;
                                position: absolute;
                                right: -90px;
                                font-size: 21px;
                                padding: 19px;
                                bottom: 0px;
                                font-weight: 100;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.8;
                                    -moz-transition: 0.4s;
                                    -o-transition: 0.4s;
                                    -webkit-transition: 0.4s;
                                    transition: 0.4s;
                                }
                            }
                        }

                        &:hover {
                            transform: scale(1.05);
                            -moz-transition: 0.3s;
                            -o-transition: 0.3s;
                            -webkit-transition: 0.3s;
                            transition: 0.3s;
                            -webkit-box-shadow: 0px 0px 22px -8px rgba(0,0,0,0.75);
                            -moz-box-shadow: 0px 0px 22px -8px rgba(0,0,0,0.75);
                            box-shadow: 0px 0px 22px -8px rgba(0,0,0,0.75);



                            .card-action > .card-delete-btn {
                                display: inline-block;
                            }
                        }
                    }
                }

                .add-credit-card {
                    .add-credit-card-btn {
                        font-size: 18px;
                        cursor: pointer;

                        i {
                            border-radius: 5px;
                            border: 1px solid #c1c1c1;
                            padding: 15px 20px;
                            font-size: 14px;
                            color: #c1c1c1;
                            margin-right: 5px;
                        }
                    }

                    .masterpass-icon {
                        width: 124px;
                        display: block;
                        margin-left: 55px;
                        margin-top: 10px;
                    }
                }
            }

            .pay {
                text-align: center;
                padding-top: 80px;
                padding-bottom: 120px;
                width: 70%;
                margin: 0 auto;
                position: relative;

                .prod-image {
                    margin-bottom: 40px;

                    img {
                        //width: 70%;
                        max-width: 100%;
                    }
                }

                .prod-inform {
                    .price {
                        display: inline-block;
                        font-size: 32px;
                        margin-right: 10px;
                    }

                    i {
                        display: inline-block;
                        font-size: 22px;
                        color: #d0cccc;
                        margin-right: 10px;
                        cursor: pointer;
                        cursor: pointer;
                    }

                    .inf {
                        display: inline-block;
                        font-size: 16px;
                    }
                }

                .acc-history-btn{
                    border: 1px solid black;
                    padding: 10px;
                    border-radius: 35px;
                    cursor: pointer;

                    &:hover{
                        background-color: black;
                        color: white;
                    }
                }

                .payInStore-old {
                    position: absolute;
                    display: inline-block;
                    z-index: 1;
                    padding: 18px 2.4rem;
                    background: #00a862;
                    box-shadow: 0 0 6px rgba(0,0,0,.24), 0 8px 12px rgba(0,0,0,.14);
                    border-radius: 500px;
                    color: #fff;
                    font-size: 19px;
                    font-size: 1.9rem;
                    font-weight: 500;
                    line-height: 1.2;
                    overflow: hidden;
                    text-align: center;
                    text-decoration: none;
                    transition-duration: .2s;
                    transition-property: transform,box-shadow,width,height;
                    transition-timing-function: ease;
                    cursor: pointer;
                    transition: background .8s;
                    right: 0;
                    margin-top: 100px;

                    &:hover {
                        background: #039458 radial-gradient(circle, transparent 1%, #039458 1%) center/15000%;
                    }

                    &:active {
                        background-color: #07c274;
                        background-size: 100%;
                        transition: background 0s;
                    }
                }

                .payInStore {
                    position: absolute;
                    display: inline-block;
                    z-index: 1;
                    cursor: pointer;
                    right: 0;
                    margin-top: 40px;
                }
            }

            .add-money {
                width: 350px;
                //margin: 150px auto;
                margin: 100px auto;
                position: relative;

                .head{                    
                    display:block;
                    margin-bottom: 20px;

                    .title{
                        font-size: 18px;
                        //font-weight: bold;
                    }

                    .sb-toggle{
                        float: right;

                    }
                }

                .balance-sum{
                    display: table;
                    margin: 20px 0;
                    background-color: #016341;
                    color: white;
                    padding: 10px;
                    border-radius: 20px;
                }

                .sub-title{
                    color: #777777;
                    margin-bottom: 15px;
                    display: block;
                    font-size: 14px;
                }

                .title-add {
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                    color: #777777;
                    margin-bottom: 50px;
                }

                .pb2{
                    border: 0;
                    padding: 0;
                    margin: 0;
                    min-width: 0;
                }

                .amountSelect{
                    margin-top: 30px;
                    margin-bottom: 10px;
                    //margin-bottom: 40px;

                    .title{
                        color: #777;
                        position: relative;
                        top: 15px; 
                    }
                }

                .blocking-input {
                    position: relative;
                    display: block;
                    margin-bottom: 20px;

                    .show-password {
                        width: 24px;
                        height: 24px;
                        fill: rgba(0,0,0,.56) !important;
                        position: absolute;
                        right: 0;
                        bottom: 14px;
                        cursor: pointer;
                    }

                    .hide-password {
                        width: 24px;
                        height: 24px;
                        fill: rgba(0,0,0,.56) !important;
                        position: absolute;
                        right: 0;
                        bottom: 14px;
                        cursor: pointer;
                    }

                    .form-inpt {
                        width: 100%;
                        margin-top: 20px;
                        height: 45px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d3436;
                        border-bottom: 1px solid rgba(0,0,0,.2);
                        background-color: transparent;
                        border-radius: 0;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        border-top-width: 0;
                        border-left-width: 0;
                        border-right-width: 0;
                        padding-right: 40px;
                    }

                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        left: 0;
                        top: 38px;
                        transition: 0.2s ease all;
                        color: rgba(0,0,0,.56) !important;
                        font-weight: 500;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0 0px;
                    }

                    textarea:focus, input:focus {
                        outline: none;
                    }
                }


                .credit-cards {
                    margin-top: 20px;
                    .selected {
                        background-color: #d6e1da !important;
                        border: 1px solid #016341 !important;
                    }

                    .card-item {
                        border-bottom: 1px solid #ababab;
                        margin-bottom: 20px;
                        position: relative;
                        padding: 15px 15px 15px;
                        cursor: pointer;
                        border: 2px solid #e6e5e5;
                        border-radius: 4px;

                        .card-logo {
                            display: inline-block;
                            vertical-align: middle;

                            img {
                                width: 64px;
                                margin-right: 10px;
                            }
                        }

                        .card-inform {
                            display: inline-block;
                            vertical-align: middle;

                            .card-title {
                                display: block;
                                color: #555555;
                                font-size: 18px;
                            }

                            .card-number {
                                display: block;
                                color: #9e9e9e;
                                font-size: 18px;
                            }
                        }

                        .card-action {
                            display: inline-block;
                            vertical-align: middle;

                            .card-delete-btn {
                                display: none;
                                background-color: #e75b52;
                                color: white;
                                position: absolute;
                                right: -90px;
                                font-size: 21px;
                                padding: 19px;
                                bottom: 0px;
                                font-weight: 100;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.8;
                                    -moz-transition: 0.4s;
                                    -o-transition: 0.4s;
                                    -webkit-transition: 0.4s;
                                    transition: 0.4s;
                                }
                            }
                        }

                        &:hover {
                            -moz-transition: 0.3s;
                            -o-transition: 0.3s;
                            -webkit-transition: 0.3s;
                            transition: 0.3s;
                            background-color: #8080800f;


                            .card-action > .card-delete-btn {
                                //display: inline-block;
                            }
                        }

                        .deleteIcon{
                            position: absolute;
                            right: 5px;
                            top: 5px;
                            cursor: pointer;
                        }
                    }
                }

                .add-credit-card {
                    .add-credit-card-btn {
                        font-size: 18px;
                        cursor: pointer;

                        i {
                            border-radius: 5px;
                            border: 1px solid #c1c1c1;
                            padding: 15px 20px;
                            font-size: 14px;
                            color: #c1c1c1;
                            margin-right: 5px;
                        }
                    }

                    .masterpass-icon {
                        width: 124px;
                        display: block;
                        margin-left: 55px;
                        margin-top: 10px;
                    }
                }

                .addMoneyConfirm {
                    position: absolute;
                    display: inline-block;
                    z-index: 1;
                    padding: 18px 2.4rem;
                    background: #00a862;
                    box-shadow: 0 0 6px rgba(0,0,0,.24), 0 8px 12px rgba(0,0,0,.14);
                    border-radius: 500px;
                    color: #fff;
                    font-size: 19px;
                    font-size: 1.9rem;
                    font-weight: 500;
                    line-height: 1.2;
                    overflow: hidden;
                    text-align: center;
                    text-decoration: none;
                    transition-duration: .2s;
                    transition-property: transform,box-shadow,width,height;
                    transition-timing-function: ease;
                    cursor: pointer;
                    transition: background .8s;
                    right: 0;


                    &:hover {
                        background: #039458 radial-gradient(circle, transparent 1%, #039458 1%) center/15000%;
                    }

                    &:active {
                        background-color: #07c274;
                        background-size: 100%;
                        transition: background 0s;
                    }
                }
            }
            
            .manage{
                .manage-list{
                    .manage-item{
                        padding: 20px;
                        border-bottom: 1px solid rgba(0,0,0,.1);
                        cursor: pointer;
                        

                        &:hover{
                            //background-color: gainsboro;
                            background-color: #dcdcdc59;
                            -webkit-transition: all 0.5s ease-in-out;
                            -moz-transition: all 0.5s ease-in-out;
                            -ms-transition: all 0.5s ease-in-out;
                            -o-transition: all 0.5s ease-in-out;
                            transition: all 0.5s ease-in-out;
                        }

                        .title{
                            font-size: 22px;

                        }

                        .text{
                            color:rgba(0,0,0,.56) !important;
                            line-height: initial;
                        }

                        &:last-child{
                            //border:none;
                        }

                        .item-icon{
                            margin-left: 20px;
                            width:36px;
                            height: 36px;
                        }
                    }
                }
            }

            .accountHistory{
                padding: 20px;
                .history-list{
                    margin-top: 40px;
                    .history-item{
                        box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
                        background-color: white;
                        border-radius: 2px;
                        margin-bottom: 30px;
                        padding: 15px;
                        cursor: pointer;

                        .grid{
                            margin:0 !important; 
                            padding-top: 15px;
                        }

                        .icon{
                            display: inline-block;
                            width: 32px;
                        }
                        .inform{
                            display: inline-block;
                            line-height: 0.8;
                            padding-left: 20px;
                            
                            .title{
                                font-weight: bold;
                            }

                        }

                        .detail{
                            line-height: 1.5;
                            margin-top: -5px;
                            
                            h3{
                                margin: 0;
                            }

                            .s1{
                                display: block;
                            }
                            .s2{
                                display: block;
                            }
                            .s3{
                                display: block;
                            }
                        }
                    }
                }
            }

            .noActiveCard{
                width: 320px;
                margin: 150px auto;
                padding: 20px;
                
                .noActiveCard-img{
                    display:block;
                }

                .text{
                    display:block;
                    .title{

                    }
                    .detail{
                        font-size: 12px;
                        color: gray;
                    }
                }

                .add-credit-card{
                    display:block;
                    margin-top: 20px;

                    .add-credit-card-btn{
                        font-size: 14px;
                        cursor: pointer;
                        color: gray;

                        i{
                            border-radius: 5px;
                            border: 1px solid #c1c1c1;
                            padding: 10px 20px;
                            font-size: 14px;
                            color: #c1c1c1;
                            margin-right: 5px;
                        }
                    }
                }

                .masterpass{
                    text-align: center;
                    margin-top: 10px;
                    img{
                        width: 185px;
                    }
                }
            }

            .receipt{
 
                .head{
                    width: 100%;
                    background-color: white;
                    padding: 25px;
                    border-bottom: 1px solid lightgrey;

                    .title{
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .id{
                        float: right;
                        padding-top: 5px;
                    }
                }

                .receipt-list{
                    padding: 30px;
                    .receipt-date{
                        
                    }

                    .receipt-item{
                        margin-top: 20px;
                        border-bottom: 3px dotted #d3d3d3;
                        padding-bottom: 10px;

                        .receipt-title{
                            font-size: 16px;
                            font-weight: bold;
                        }
                        
                    }

                }

                .receipt-total{
                    width: 300px;
                    float: right;
                    margin-top: 60px;

                    .s1{
                        .t1{
                            width: 70px;
                            display: inline-block;
                            font-weight: bold;
                        }

                        .t2{

                        }
                    }
                }
            }

            .autoReload{
                //padding: 20px;

                .autoreload-box{
                    width: 450px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;
                    padding: 20px;

                    .head{
                        display:block;
                        .title{
                            font-size: 18px;
                            font-weight: bold;
                        }

                        .sb-toggle{
                            float: right;
                        }
                    }

                    .auto-form{
                        margin-top: 40px;

                        .m4m{
                            margin-top: 20px;
                            .field__inputWrapper{
                                width: 100%;
                            }
                        }

                        .selectAmountList{
                            .floatLabel{
                                top: 10px !important;
                            }
                        }

                        .cc{
                            .title{

                            }

                            .card-list{
                                margin-top: 20px;
                                .card-item{
                                    margin-top: 10px;
                                    border: 2px solid #e6e5e5;
                                    padding: 10px 15px;
                                    cursor: pointer;
                                    
                                    .card-logo{
                                        display: inline-block;
                                        vertical-align: middle;
                                        width: 55px;
                                        margin-right: 10px;
                                    }

                                    .card-detail{
                                        display: inline-block;
                                        vertical-align: middle;

                                        .card-title{
                                            display: block;
                                            color: #636363;
                                            font-size: 18px;
                                            font-weight: bold;
                                        }

                                        .card-number{
                                            display: block;
                                            color: #636363;
                                            font-size: 18px;
                                            font-weight: bold;
                                        }
                                    }
               

                                    &:hover{
                                        background-color: #ececec;
                                        -webkit-transition: all 200ms ease;
                                        -moz-transition: all 200ms ease;
                                        -o-transition: all 200ms ease;
                                        transition: all 200ms ease;
                                    }
                                }

                                                     
                                .selected{
                                    background-color: #ececec;
                                }
                            }
                        }

                        .add-credit-card{
                            display:block;
                            margin-top: 20px;
        
                            .add-credit-card-btn{
                                font-size: 14px;
                                cursor: pointer;
                                color: gray;
        
                                i{
                                    border-radius: 5px;
                                    border: 1px solid #c1c1c1;
                                    padding: 10px 20px;
                                    font-size: 14px;
                                    color: #c1c1c1;
                                    margin-right: 5px;
                                }
                            }
                        }

                        .option{
                            .option__labelText{
                                padding-top: 0;
                            }
                        }

                        .actionBtn{
                            margin-top: 20px;
                        }

                        .rdt{
                            border-bottom: 1px solid rgba(0,0,0,.2);
                            margin-bottom: 20px;
                            margin-top: 10px;
                            .form-control{
                                -webkit-appearance: none;  
                                -moz-appearance: none;  
                                appearance: none;  
                                background-color: transparent;  
                                border: 0;  
                                border-radius: 0;  
                                color: rgba(0, 0, 0, .9);  
                                display: block;  
                                font-size: 1.6rem;  
                                font-weight: 700;  
                                line-height: 10px;  
                                line-height: 1rem;  
                                //padding: 32px 0 6px;
                                padding: 10px 0 6px;
                                width: 100%;  
                                z-index: 1
                                
                                
                            }

                            .form-control::-ms-clear,
                            .form-control::-ms-reveal {
                              display: none
                            }

                            .form-control:focus {
                                outline: none!important
                              }
                        }

                    }
                }

                .foot-modal{
                    position: fixed;
                    top: 0;
                    right: 0;
                    width: 100%;
                    background-color: rgba(0,0,0,.33);
                    height: 100%;
                    z-index: 9;
                    //margin: 0 -10px;
                    margin: 0 -7px;
                    
                    .in-modal{
                        background-color: #fff;
                        position: absolute;
                        bottom: 0;
                        height: 50%;
                        width: 100%;
                        border: 1px solid #e2e0e0;
                        padding: 10px;
                        overflow: auto;
                        padding: 20px;
                        .close-icon{
                            width: 32px;
                            height: 32px;
                            overflow: visible;
                            background: #e9e9e9;
                            border-radius: 50%;
                            padding: 5px;
                            position: initial;
                            left: 5%;
                            top: 30px;
                            cursor: pointer;
                        }

                        .content{
                            margin:0 auto;
                            width: 450px;
                            max-width: 100%;
                            padding-top: 20px;
                            ul{
                                li{
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    list-style: circle;
                                }
                            }

                            .actionBtn{

                                .mp-logo{
                                    height: 30px;
                                    margin-top: 18px;
                                    margin-right: 20px;
                                }

                                button{
                                    background-color: #424242;
                                }
                            }
                        }

                        .bank-verification{

                            .title{
    
                            }
                            
                            .text{
    
                            }
    
                            .activation-inp{
                                height: 45px;
                                width: 100%;
                                border-radius: 15px;
                                border: 2px solid gray;
                                padding: 15px;
                            }
    
                            .btns{
                                margin-top: 25px;
                                margin-bottom: 30px;
                                .resendBtn{
                                    margin-right: 15px;
    
                                    button{
                                        background-color: #616161;
                                    }
                                }
                            }
                            .mp-logo{
                                height: 40px;
                                display: block;
                                float: right;
                            }
                        }
                    }
                }
            }

            .pinCode{
                //padding: 20px;

                .pinCodeGenerate{
                    width: 340px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;
                    padding: 20px;

                    .text{
                        .title{
                            color:#414141;
                        }
                        .text{
                            color:#818181;
                        }
                    }

                    .actionBtn{
                        margin-top:20px;
                    }

                }

                .pinCodeGenerate-step1{
                    width: 320px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 15% auto;
                    padding: 20px;

                    .title{
                        color:#414141;
                        font-size: 20px;
                    }

                    .codeBox{
                        display:block;
                        
                        .firstBox{
                            border-radius: 15px 0px 0px 15px;
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }

                        .defaultBox{
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }

                        .lastBox{
                            border-radius: 0px 15px 15px 0px;
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }
                    }

                    .stepCount{

                        .step{
                            display:block;
                            margin: 20px 0;
                            text-align: center;

                            .first{
                                color: white;
                                font-size: 18px;
                                background-color: #bdbdbd;
                                padding: 10px 15px;
                                border-radius: 50%;
                                font-family: sans-serif;
                                margin-right:15px;
                            }

                            .right-chev{
                                font-size: 30px;
                                color: grey;
                            }

                            .last{
                                color: white;
                                font-size: 18px;
                                background-color: #bdbdbd;
                                padding: 10px 15px;
                                border-radius: 50%;
                                font-family: sans-serif;
                                margin-left: 15px;
                            }

                            .active{
                                background-color:gray;
                            }
                        }

                        .text{
                            display: block;
                            font-size: 15px;
                            padding-left: 5px;
                            padding-top: 10px;
                            color:#414141;
                            font-weight: bold;
                        }
                    }

                    .actionBtn{
                        margin-top:20px;
                    }
                }

                .changePinCode{
                    width: 300px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 10% auto;
                    padding: 20px;

                    .text{

                        .head{
                            margin:20px 0;
                            display:block;
                            .title{
                                font-size: 21px;
                                font-weight: bold;
                            }
    
                            .sb-toggle{
                                float: right;
                            }
                        }

                        .title{
                            color:#414141;
                        }
                        .text{
                            color:#818181;
                        }
                    }

                    .actionBtn{
                        margin-top:40px;
                    }

                }

                .pinControl{
                    width: 320px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 15% auto;
                    padding: 20px;

                    .title{
                        color:#414141;
                        font-size: 20px;
                    }

                    .codeBox{
                        display:block;
                        
                        .firstBox{
                            border-radius: 15px 0px 0px 15px;
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }

                        .defaultBox{
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }

                        .lastBox{
                            border-radius: 0px 15px 15px 0px;
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }
                    }

                    .text{
                        margin-top: 15px;
                        color:#838383; 
                        display: block;                       
                    }

                    .fargot-pin{
                        margin-top: 10px;
                        display: block; 
                        color:#747474;
                        font-weight: bold;
                        text-decoration: underline;
                        text-align: right;
                        cursor: pointer;
                    }

                    .actionBtn{
                        margin-top:40px;
                    }

                }

                .newPinCode{
                    width: 320px;
                    max-width: 100%;
                    //background-color: white;
                    margin: 15% auto;
                    padding: 20px;

                    .title{
                        color:#414141;
                        font-size: 20px;
                    }

                    .codeBox{
                        display:block;
                        
                        .firstBox{
                            border-radius: 15px 0px 0px 15px;
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }

                        .defaultBox{
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }

                        .lastBox{
                            border-radius: 0px 15px 15px 0px;
                            width: 70px;
                            height: 60px;
                            border: 1px solid gray;
                            padding: 26px;
                            font-size: 24px;
                        }
                    }

                    .stepCount{

                        .step{
                            display:block;
                            margin: 20px 0;
                            text-align: center;

                            .first{
                                color: white;
                                font-size: 18px;
                                background-color: #bdbdbd;
                                padding: 10px 15px;
                                border-radius: 50%;
                                font-family: sans-serif;
                                margin-right:15px;
                            }

                            .right-chev{
                                font-size: 30px;
                                color: grey;
                            }

                            .last{
                                color: white;
                                font-size: 18px;
                                background-color: #bdbdbd;
                                padding: 10px 15px;
                                border-radius: 50%;
                                font-family: sans-serif;
                                margin-left: 15px;
                            }

                            .active{
                                background-color:gray;
                            }
                        }

                        .text{
                            display: block;
                            font-size: 15px;
                            padding-left: 5px;
                            padding-top: 10px;
                            color:#414141;
                            font-weight: bold;
                        }
                    }

                    .actionBtn{
                        margin-top:20px;
                    }
                }

                .foot-modal{
                    position: fixed;
                    top: 0;
                    width: 100%;
                    background-color: rgba(0,0,0,.33);
                    height: 100%;
                    z-index: 9;
                    //margin: 0 -10px;

                    .in-modal{
                        background-color: #fff;
                        position: absolute;
                        bottom: 0;
                        height: 50%;
                        width: 100%;
                        border: 1px solid #e2e0e0;
                        padding: 10px;
                        overflow: auto;

                        .close-icon{
                            width: 32px;
                            height: 32px;
                            overflow: visible;
                            background: #e9e9e9;
                            border-radius: 50%;
                            padding: 5px;
                            position: initial;
                            left: 5%;
                            top: 30px;
                            cursor: pointer;
                        }

                        .content{
                            margin:0 auto;
                            width: 450px;
                            max-width: 100%;
                            padding-top: 20px;
                            ul{
                                li{
                                    font-size: 12px;
                                    margin-bottom: 20px;
                                    list-style: circle;
                                }
                            }

                            .actionBtn{

                                .mp-logo{
                                    height: 30px;
                                    margin-top: 18px;
                                    margin-right: 20px;
                                }

                                button{
                                    background-color: #424242;
                                }
                            }
                        }

                        .bank-verification{

                            .title{
    
                            }
                            
                            .text{
    
                            }
    
                            .activation-inp{
                                height: 45px;
                                width: 100%;
                                border-radius: 15px;
                                border: 2px solid gray;
                                padding: 15px;
                            }
    
                            .btns{
                                margin-top: 25px;
                                margin-bottom: 30px;
                                .resendBtn{
                                    margin-right: 15px;
    
                                    button{
                                        background-color: #616161;
                                    }
                                }
                            }
                            .mp-logo{
                                height: 40px;
                                display: block;
                                float: right;
                            }
                        }
                    }
                }
            }

            .renewStarbucksCard{

                padding: 20px;

                .renewMenu{
                    .renewItem{
                        margin-right:20px;
                        padding-bottom: 10px;
                        cursor: pointer;

                        &:hover{
                            color: gray;
                        }
                    }

                    .active{
                        border-bottom:3px solid #00a862;
                    }
                }

                .pyhsicalCard{
                    width: 430px;
                    max-width: 100%;
                    margin: 10% auto;

                    .title{
                        font-size: 21px;
                        color: #3a3a3a;
                    }

                    .pyhsicalCard-form{
                        .pb4{
                            border: 0;
                            padding: 0;
                            margin: 0;
                            min-width: 0;
                        }
                    }

                    .actionBtn{
                        margin-top: 30px;
                    }

                }

                .digitalCard{
                    width: 430px;
                    max-width: 100%;
                    margin: 10% auto;

                    .title{
                        font-size: 21px;
                        color: #3a3a3a;
                    }

                    .pyhsicalCard-form{
                        .pb4{
                            border: 0;
                            padding: 0;
                            margin: 0;
                            min-width: 0;
    
                        }
                    }

                    .actionBtn{
                        margin-top: 30px;
                    }
                }

            }

            .createStarbucksCard{
                padding: 20px;

                .createSBcard{
                    width: 350px;
                    max-width: 100%;
                    margin: 10% auto;

                    .birthday{
                        margin-top: 20px !important;
                        margin-bottom: 10px;

                        .title{
                            color: var(--colorTextBlackSoft)!important;
                            font-size: 14px;
                        }

                        .rdt{
                            border-bottom: 1px solid rgba(0,0,0,.2);
                            margin-bottom: 0;
                            margin-top: 0;
                            .form-control{
                                -webkit-appearance: none;  
                                -moz-appearance: none;  
                                appearance: none;  
                                background-color: transparent;  
                                border: 0;  
                                border-radius: 0;  
                                color: rgba(0, 0, 0, .9);  
                                display: block;  
                                font-size: 1.6rem;  
                                font-weight: 700;  
                                line-height: 10px;  
                                line-height: 1rem;  
                                //padding: 32px 0 6px;
                                padding: 10px 0 6px;
                                width: 100%;  
                                z-index: 1
                                
                                
                            }
    
                            .form-control::-ms-clear,
                            .form-control::-ms-reveal {
                              display: none
                            }
    
                            .form-control:focus {
                                outline: none!important
                              }
                        }
                    }

                    .title{
                        font-size: 21px;
                        color: #3a3a3a;
                    }

                    .frm{
                        .pb1{
                            border: 0;
                            padding: 0;
                            margin: 0;
                            min-width: 0;
                        }
                    }

                    .tcInfoText {
                        font-size: 10px;
                        color: gray;
                        display:block;
                    }

                    .actionBtn{
                        margin-top: 50px;
                    }

                }
            }
            
            .addPyhsicalCard{
                background-color: #2d2926;
                height: calc(100vh - 83px);

                .bgCard{
                    //background: url(https://content.sbuxtr.com/images/coffe-bg-2.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 14;
                    border-radius: 12px;
                }

                .pb1{
                    position: relative;
                    z-index: 15;
                }

                .base___3qZEk{
                    max-width: 365px;
                    width: 100%;

                    .sb-heading--xxsmall {
                        font-size: 1.3rem;
                        line-height: 1.229;
                        padding-bottom: 0px !important;
                        color:white;
                    }
                    
                    .headingDetail{
                        font-size: 13px;
                        color:white;
                    }

                    .formCard{
                        border-radius: 12px;
                        
                        .fieldGroup{
                            padding-bottom: 70px;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;

                            .pb1{
                                border: 0;
                                padding: 0;
                                margin: 0;
                                min-width: 0;
                            }
                        }

                        .goldStripe{
                            width: 100%;
                            position: absolute;
                            bottom: 20px;
                            left: 0;
                            height: 35px;
                            background-color: #cba258!important;
                        }

                    }
                    
                    .base___3dWsJ{
                        position: fixed;
                        bottom: 0;
                        right: 0;
                        z-index: 10;
                        padding: 1.6rem;
                    }

                    .mt3{
                        label{
                            color:white;
                        }
                    }
                }
            }

            .beforeAddMoneyCard{
                width: 320px;
                margin: 150px auto;
                position: relative;
                text-align: center;
                
                .title{
                    display: block;
                    text-align: left;
                    font-weight: bold;
                    margin-bottom: 30px;
                    margin-left: 15px;
                }

                .btns{
                    text-align: center;
                    
                    .mpBtn{
                        margin-right: 30px;
                        display: inline-block;
                    }
    
                    .akbankBtn{
                        display: inline-block;
                    }

                }
            }
        }
    }


}


//Sonradan Kesinlikle Kaldırılması gerekiyor.

.profile-add-new-card {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: #0000009e;
    width: 100%;
    height: 100%;
    left: 0;

    .capsul {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 483px;
        //height: 573px;
        border-radius: 3px;
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        padding-bottom: 40px;

        .head {

            .title {
                display: inline-block;
                font-size: 20px;
                font-weight: 500;
                color: #424242;
                padding: 30px;
            }

            .close-icon-popup {
                float: right;
                padding: 30px;
                cursor: pointer;
            }
        }

        .tab {
            background-color: #f6f6f6;
            border-top: solid 1px #e1e8ee;
            padding: 20px 30px;

            .tab-title {
                font-size: 16px;
                font-weight: 500;
                color: #6c7a89;
                //border-bottom: solid 1px #bdc3c7;
                margin-right: 10px;
                padding-bottom: 5px;
                cursor: pointer;
            }

            .v-line {
                font-weight: 400;
                color: #6c7a89;
                margin-right: 10px;
            }

            .active {
                font-weight: 600;
                color: #4d9c2d;
                //kalkması gerekti
                //border-bottom: solid 3px #4d9c2d;
            }
        }

        .add-cc {
            text-align: center;
            
            .md-size3of13{
                width: 31%;
                margin-left: 5px;
            }

            .ig-card-form {
                text-align: center;

                .rules {
                    margin-top: 20px;
                    margin-bottom: -20px;
                    position: relative;
                    text-align: left;
                    padding-left: 30px;

                    .mc-logo {
                        width: 100px;
                        vertical-align: bottom;
                    }
                }

                .row {
                    margin-left: 15px;
                    margin-right: 25px;
                }


                .name {
                    display: inline-block;
                    position: relative;
                    //margin-right: 10px;
                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        left: 15px;
                        top: 38px;
                        transition: 0.2s ease all;
                    }

                    .floating-label {
                        color: #6c7a89;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        background-color: white;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0 4px;
                    }

                    input {
                        width: 202px;
                        height: 55px;
                    }
                }

                .surname {
                    display: inline-block;
                    position: relative;


                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        left: 15px;
                        top: 38px;
                        transition: 0.2s ease all;
                    }


                    .floating-label {
                        color: #6c7a89;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        background-color: white;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0 4px;
                    }

                    input {
                        width: 202px;
                        height: 55px;
                    }
                }

                .sb-style-inp{
                    margin-left: 15px !important;
                    margin-right: 15px !important;
                    margin-bottom: 10px !important;
                }

                .cc-number {
                    position: relative;

                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        left: 15px;
                        top: 38px;
                        transition: 0.2s ease all;
                    }


                    .floating-label {
                        color: #6c7a89;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        background-color: white;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0;
                    }

                    input {
                        width: 423px;
                        height: 55px;
                    }

                    .camera-icon {
                        position: absolute;
                        top: 38px;
                        right: 40px;
                    }
                }

                .month {
                    display: inline-block;
                    position: relative;
                    //margin-right: 10px;
                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        //left: 15px;
                        left: 30px;
                        top: 12px;
                        transition: 0.2s ease all;
                        background-color: white;
                        padding: 0px 5px;
                        color: #6c7a89;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        background-color: white;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0 4px;
                    }

                    select {
                        //width: 131px;
                        width: 100%;
                        margin-top: 20px;
                        height: 54px;
                        font-weight: 500;
                        width: 100%;
                        //border-radius: 5px;
                        border: 1px solid #d9d9d9;
                        padding: 15px;
                    }
                    /*
                        display:inline-block;
                        position: relative;
                        //margin-right: 10px;

                        .floating-label{
                            position: absolute;
                            pointer-events: none;
                            //left: 15px;
                            left: 30px;
                            top: 38px;
                            transition: 0.2s ease all;

                            width: 202px;
                            margin-top: 20px;
                            height: 54px;
                            border-radius: 4px;
                            border-color: #bdc3c7;
                            font-size: 14px;
                            font-weight: 500;
                            color: #2d3436;
                        }

                        .floating-label{
                            color: #6c7a89;
                        }

                        input:focus + .floating-label, input:valid + .floating-label{                  
                             margin-top: -26px;
                             background-color:white;
                             font-size: 12px;
                             color: #6c7a89;
                             padding:0 4px;
                        }

                        input{
                            width: 131px;
                            height: 54px;
                        }

                        */
                }

                .year {
                    display: inline-block;
                    position: relative;
                    //margin-right: 10px;
                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        //left: 15px;
                        left: 30px;
                        top: 12px;
                        transition: 0.2s ease all;
                        background-color: white;
                        padding: 0px 5px;
                        color: #6c7a89;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        background-color: white;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0 4px;
                    }

                    select {
                        //width: 131px;
                        width: 100%;
                        margin-top: 20px;
                        height: 54px;
                        font-weight: 500;
                        width: 100%;
                        //border-radius: 5px;
                        border: 1px solid #d9d9d9;
                        padding: 15px;
                    }
                    /*
                        display:inline-block;
                        position: relative;
                        //margin-right: 10px;

                        .floating-label{
                            position: absolute;
                            pointer-events: none;
                            //left: 15px;
                            left: 30px;
                            top: 38px;
                            transition: 0.2s ease all;
                        }

                        .floating-label{
                            color: #6c7a89;
                        }

                        input:focus + .floating-label, input:valid + .floating-label{                  
                             margin-top: -26px;
                             background-color:white;
                             font-size: 12px;
                             color: #6c7a89;
                             padding:0 4px;
                        }

                        input{
                            width: 131px;
                            height: 54px;
                        }

                        */
                }

                .cvv {
                    display: inline-block;
                    position: relative;
                    //margin-right: 10px;
                    .floating-label {
                        position: absolute;
                        pointer-events: none;
                        //left: 15px;
                        left: 30px;
                        top: 38px;
                        transition: 0.2s ease all;
                    }

                    .floating-label {
                        color: #6c7a89;
                    }

                    input:focus + .floating-label, input:valid + .floating-label {
                        margin-top: -26px;
                        background-color: white;
                        font-size: 12px;
                        color: #6c7a89;
                        padding: 0 4px;
                    }

                    input {
                        //width: 131px;
                        height: 54px;
                    }
                }

                .ig-add-new-cc-btn {
                    width: 423px;
                    height: 54px;
                    border-radius: 3px;
                    background-color: #4d9c2d;
                    display: block;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 2px;
                    text-align: center;
                    color: #ffffff;
                    cursor: pointer;
                    transition: 0.2s ease all;
                    margin: -10px auto;
                    line-height: 54px;
                    padding: 0 20px;
                    margin-top: 0px;


                    .ok {
                        float: right;
                        padding-top: 22px;
                    }

                    &:hover {
                        background-color: #4c9c2dd5;
                        transition: 0.2s ease all;
                    }
                }

                .error-msg {
                    display: block;
                    margin-top: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #ed3246;
                    text-align: left;
                    margin-left: 30px;
                }

                .error-input {
                    border-color: #ed3246 !important;
                }

                .ig-small-input {
                    margin-top: 20px;
                    height: 45px;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid gray;
                    padding: 15px;
                }


                .ig-small-input {
                    margin-top: 20px;
                    height: 45px;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid gray;
                    padding: 15px;
                }

                .ig-xsmall-input {
                    margin-top: 20px;
                    height: 45px;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid gray;
                    padding: 15px;
                }

                .normal-input {
                    margin-top: 20px;
                    height: 45px;
                    width: 100%;
                    border:none;
                    border-bottom: 1px solid #d9d9d9;
                    padding: 15px 0 0px 0;
                    font-size: 15px;
                }

                .inform {
                    background: url(/assets/img/exclamation.png);
                    background-repeat: no-repeat;
                    background-position-x: 0px;
                    background-position-y: 13px;

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.43;
                        color: #2d3436;
                        text-align: left;
                        padding: 30px 30px;
                    }
                }
            }
        }
    }
}

.profile-delete-card-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #0000009e;
    width: 100%;
    height: 100%;

    .capsul {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //width: 483px;
        border-radius: 3px;
        box-shadow: 0 20px 40px 0 rgba(0,0,0,0.06);
        background-color: #ffffff;
        padding-bottom: 40px;
        //padding: 40px 45px;
        padding: 40px 35px;
        text-align: center;

        .logo {
            img {
                width: 160px;
            }
        }


        .text {
            padding: 40px 0;

            p {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                color: #424242;
            }
        }

        .btns {

            .delete-btn {
                width: 231px;
                height: 54px;
                border-radius: 3px;
                border: solid 1px #181a1a;
                background-color: #ffffff;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 2px;
                text-align: center;
                color: #181a1a;
                display: inline-block;
                line-height: 51px;
                margin-right: 20px;
                cursor: pointer;

                &:hover {
                    color: #ffffff;
                    background-color: #181a1a;
                }
            }

            .no-deletion-btn {
                margin-top: -2px;
                width: 231px;
                height: 54px;
                border-radius: 3px;
                background-color: #4d9c2d;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 2px;
                text-align: center;
                color: #ffffff;
                display: inline-block;
                line-height: 54px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.order-cancel-modal {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: #0000009e;
    width: 100%;
    height: 100%;

    .capsul {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //width: 483px;
        border-radius: 3px;
        box-shadow: 0 20px 40px 0 rgba(0,0,0,0.06);
        background-color: #ffffff;
        padding-bottom: 40px;
        padding: 40px 45px;
        text-align: center;

        .logo {
            img {
                width: 90px;
            }
        }


        .text {
            padding: 40px 0;

            p {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                color: #424242;
            }
        }

        .btns {

            .delete-btn {
                width: 231px;
                height: 54px;
                border-radius: 3px;
                background-color: #ed3246;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 2px;
                text-align: center;
                color: white;
                display: inline-block;
                line-height: 51px;
                margin-right: 20px;
                cursor: pointer;

                &:hover {
                    color: #ffffff;
                    background-color: #181a1a;
                }
            }

            .no-deletion-btn {
                margin-top: -2px;
                width: 231px;
                height: 54px;
                border-radius: 3px;
                background-color: #444c53;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 2px;
                text-align: center;
                color: #ffffff;
                display: inline-block;
                line-height: 54px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.generic-modal {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: #0000009e;
    width: 100%;
    height: 100%;

    .capsul {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //width: 483px;
        border-radius: 3px;
        box-shadow: 0 20px 40px 0 rgba(0,0,0,0.06);
        background-color: #ffffff;
        padding-bottom: 40px;
        padding: 40px 45px;
        text-align: center;

        .pb2{
            border: 0;
            padding: 0;
            margin: 0;
            min-width: 0;
            width: 90%;
            max-width: 100%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        
        .cancelBtn{
            margin-right: 30px;
            margin-left: 30px;

            button{
                background-color:#616161;
            }
        }

        .logo {
            img {
                width: 90px;
            }
        }


        .text {
            //padding: 40px 0;
            padding: 20px 0;
            p {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                color: #424242;
            }
        }

        .btns {

            .bt {
                margin: 0 10px;
            }

            .btn-1 {
                width: 231px;
                height: 54px;
                border-radius: 3px;
                background-color: #607D8B;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 2px;
                text-align: center;
                color: white;
                display: inline-block;
                line-height: 51px;
                position: relative;
                top: -2px;
                cursor: pointer;

                &:hover {
                    color: #ffffff;
                    background-color: #181a1a;
                }
            }

            .btn-2 {
                margin-top: -2px;
                width: 231px;
                height: 54px;
                border-radius: 3px;
                background-color: #444c53;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 2px;
                text-align: center;
                color: #ffffff;
                display: inline-block;
                line-height: 54px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.order-detail-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #0000009e;
    width: 100%;
    height: 100%;

    .capsul {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 870px;
        border-radius: 3px;
        box-shadow: 0 20px 40px 0 rgba(0,0,0,0.06);
        background-color: #ffffff;
        padding-bottom: 40px;

        .head {
            padding: 30px;
            border-bottom: 1px solid #e1e8ee;
            position: relative;

            .titles {
                .t1 {
                    font-size: 20px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #424242;
                    display: block;
                }

                .t2 {
                    font-size: 13px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #424242;
                    display: block;
                }
            }

            .close-icon {
                position: absolute;
                right: 20px;
                top: 40px;
                cursor: pointer;
            }
        }

        .product-list {
            max-height: 385px;
            overflow: auto;

            .list {

                tr {
                    border-bottom: 1px solid #e1e8ee;

                    td {
                    }

                    &:last-child {
                        border: none;
                    }
                }

                td {
                    padding: 10px;
                }

                .order-image {
                    text-align: center;

                    img {
                        max-width: 68px;
                        max-height: 68px;
                    }
                }

                .order-title {
                    font-size: 13px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    color: #2d3436;
                }

                .order-count {
                    font-size: 14px;
                    font-weight: 500;
                    color: #2d3436;
                }

                .order-price {
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #181a1a;
                }
            }
        }

        .foot {
            padding: 0;
            border-top: 1px solid #e1e8ee;

            .btns {
                .t1 {
                    font-size: 13px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 2px;
                    color: #181a1a;
                    cursor: pointer;
                    position: relative;
                    top: 37px;
                    left: 30px;
                }

                .t2 {
                    float: right;
                    width: 291px;
                    height: 54px;
                    border-radius: 3px;
                    background-color: #444c53;
                    display: block;
                    font-size: 13px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 2px;
                    text-align: center;
                    color: #ffffff;
                    line-height: 54px;
                    cursor: pointer;
                    position: relative;
                    right: 20px;
                    top: 20px;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

.global-slot-changer-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #0000009e;
    width: 100%;
    height: 100%;

    .capsul {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 474px;
        border-radius: 3px;
        box-shadow: 0 20px 40px 0 rgba(0,0,0,0.06);
        background-color: #ffffff;

        .delivery-list {
            width: 100%;
            background-color: white;
            -webkit-box-shadow: 0px 0px 127px -11px rgba(0,0,0,0.22);
            -moz-box-shadow: 0px 0px 127px -11px rgba(0,0,0,0.22);
            box-shadow: 0px 0px 127px -11px rgba(0,0,0,0.2s2);
            z-index: 9;

            .inform {
                border-bottom: 2px solid #e1e8ee;
                padding: 10px 0;

                .icon {
                    width: 40px;
                    height: 50px;
                    display: inline-block;

                    img {
                        top: 10px;
                        left: 20px;
                        position: relative;
                    }
                }

                .close-icon {
                    position: absolute;
                    cursor: pointer;
                    right: 20px;
                    top: 22px;
                }

                .titles {
                    display: inline-block;
                    position: relative;
                    top: 13px;
                    left: 20px;

                    .t1 {
                        display: block;
                        font-size: 18px;
                        font-weight: 800;
                        color: #2d3436;
                    }

                    .t2 {
                        font-size: 12px;
                        font-weight: 500;
                        color: #6c7a89;
                    }
                }

                .up-icon {
                    float: right;
                    position: relative;
                    right: 20px;
                    top: 30px;
                    cursor: pointer;
                }
            }

            .time-list {
                width: 475px;

                .day-list {
                    background-color: #2d3436;
                    padding: 10px 0px 0px;
                    text-align: center;

                    .day-item {
                        width: 100px;
                        height: 50px;
                        padding-top: 5px;
                        border: solid 2px #4c555f;
                        display: inline-block;
                        margin-bottom: 10px;
                        margin-right: 30px;

                        &:last-child {
                            margin-right: 0;
                        }

                        cursor: pointer;

                        .t1 {
                            font-size: 13px;
                            font-weight: 500;
                            color: #6c7a89;
                            display: block;
                        }

                        .t2 {
                            font-size: 13px;
                            font-weight: 800;
                            color: #6c7a89;
                            display: block;
                        }

                        .up-icon {
                            float: right;
                        }
                    }

                    .active-day {
                        background-color: #7dc855;
                        border: solid 2px #7dc855;

                        .t1 {
                            color: white !important;
                        }

                        .t2 {
                            color: white !important;
                        }
                    }
                }

                .hour-list {
                    height: 472px;
                    background-color: white;
                    -webkit-box-shadow: 0px 0px 127px -11px rgba(0,0,0,0.42);
                    -moz-box-shadow: 0px 0px 127px -11px rgba(0,0,0,0.42);
                    box-shadow: 0px 0px 127px -11px rgba(0,0,0,0.42);


                    .checked-radio {
                        position: relative;
                        z-index: 10;
                        -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.35);
                        -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.35);
                        box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.35);
                    }

                    .inform-warn-color {
                        color: #fdcb6e !important;
                    }

                    .inform-full-color {
                        color: #ed3246 !important;
                    }

                    .disable-hour {
                        opacity: 0.6;
                    }

                    .hour-item {
                        background-color: white;
                        border-bottom: 1px solid #e1e8ee;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-left: 20px;
                        width: 236.6px;
                        display: inline-block;

                        &:last-child {
                            border-bottom: 1px solid #e1e8ee;
                        }

                        input.check[type="radio"]:checked + label:before {
                            background-color: #4d9c2d;
                            box-shadow: inset 0 0 0 1px #4d9c2d;
                        }

                        input.check[type="radio"] + label:before {
                            top: 10px;
                        }

                        input.check[type="radio"]:checked + label:after {
                            top: 13px !important;
                        }

                        .hour-hour {
                            font-size: 13px;
                            font-weight: 500;
                            color: #2d3436;
                        }

                        .hour-text {
                            font-size: 11px;
                            font-weight: 800;
                            color: #6c7a89;
                        }


                        input.check[type="radio"]:checked + label > .hour-hour {
                            color: #181a1a !important;
                        }

                        input.check[type="radio"]:checked + label > .hour-text {
                            color: #181a1a !important;
                        }
                    }
                }
            }
        }
    }
}



@media (min-width: 1280px){
   .isAnonym{
       
        .sb-headerCrate{
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }
        
        .sb-contentCrate{
            
        }
    }

    .sb-home-capsul{
        .sb-headerCrate{
    
            .sb-headerCrate--content{
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }

        .sb-contentCrate {
            
        }
    }
}

@media (min-width: 1024px){
    .isAnonym{
        padding-top: 83px;

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }
        
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-contentColumn {
                padding-top: 100px;
                padding-bottom: 100px;
            }
        }
    }

    .sb-home-capsul{
        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .sb-headerCrate--content{
                padding: 0 3.2rem;
            }
        }

        .sb-contentCrate {
            margin-left: 40vw;
            padding-top: 83px;


            .manage{
                .manage-list{
                    .manage-item{
                        padding:3rem 10rem !important;

                        .text{
                            line-height: 0px !important; 
                        }
                    }
                }
            }

            .autoReload{
     
                .foot-modal{

                    width: 60% !important;

                    .in-modal{

                    }

                    .close-icon{
                        position: absolute !important;
                    }
                }
            }

            .pinCode{
     
                .foot-modal{

                    width: 60% !important;

                    .in-modal{

                    }

                    .close-icon{
                        position: absolute !important;
                    }
                }
            }

            .addPyhsicalCard{
                .base___3qZEk {
                    margin-top: 100px;
                }

                .base___3qZEk {
                    margin-top: 100px;
                }

                .lg-pl0, .lg-px0 {
                    padding-left: 0!important;
                }

                .base___3dWsJ{
                    padding: 3.2rem 4.8rem !important; 
                }
            }
        
        }
    }
}

@media (min-width: 768px){
    .isAnonym{
        
        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 480px){
    .isAnonym{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }

}

@media (min-width: 375px){
    .isAnonym{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .sb-contentColumn--narrow {
                max-width: 343px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    
    }
}

@media (max-width: 768px){
    .isAnonym{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }


    .profile-add-new-card{
        border-radius: 0 !important;

        .row{
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .capsul{
            width: 100% !important;
            position: absolute;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            height: 100% !important;
            transform: none !important;
            overflow: auto !important;
            border-radius: 0 !important;
            .head{
                .title{
                    font-size: 13px !important;
                    padding: 15px !important;
                }
                .close-icon-popup{
                    padding: 12px !important;
                }
            }

            .tab{

            }

            .add-cc{

                .ig-card-form{
                    text-align: center;
                    
                    .name {
                        position: relative;
                        display: block !important;
                        margin-right:0 !important;

                        .floating-label {
                            position: absolute;
                            pointer-events: none;
                            //left: 35px;
                            left: 28px !important;
                            top: 38px;
                            transition: 0.2s ease all;
                        }

                        .floating-label {
                            color: #6c7a89;
                        }

                        input:focus+.floating-label,
                        input:valid+.floating-label {
                            margin-top: -26px;
                            background-color: white;
                            font-size: 12px;
                            color: #6c7a89;
                            padding: 0 4px;
                        }

                        input {
                            width: 92% !important;
                            height: 55px !important;
                        }
                    }

                    .surname {
                        position: relative;
                        display: block !important;

                        .floating-label {
                            position: absolute;
                            pointer-events: none;
                            //left: 35px;
                            left: 28px !important;
                            top: 38px;
                            transition: 0.2s ease all;
                        }


                        .floating-label {
                            color: #6c7a89;
                        }

                        input:focus+.floating-label,
                        input:valid+.floating-label {
                            margin-top: -26px;
                            background-color: white;
                            font-size: 12px;
                            color: #6c7a89;
                            padding: 0 4px;
                        }

                        input {
                            width: 92% !important;
                            height: 55px !important;
                        }
                    }

                    .sb-style-inp{
                        margin-left: 15px !important;
                        margin-right: 15px !important;
                        margin-bottom: 10px !important;
                    }

                    .cc-number {
                        position: relative;
              
                        .floating-label {
                            position: absolute;
                            pointer-events: none;
                            //left: 35px;
                            left: 15px !important; 
                            top: 38px;
                            transition: 0.2s ease all;
                        }


                        .floating-label {
                            color: #6c7a89;
                        }

                        input:focus+.floating-label,
                        input:valid+.floating-label {
                            margin-top: -26px;
                            background-color: white;
                            font-size: 12px;
                            color: #6c7a89;
                            padding: 0 4px;
                        }

                        input {
                            width: 92% !important;
                            height: 55px !important;
               
                        }

                        .camera-icon {
                            position: absolute;
                            top: 38px;
                            right: 40px;
                        }
                    }

                    .month {
                        display: inline-block;
                        position: relative;
                        margin-right: 0 !important;

                        .floating-label {
                            position: absolute;
                            pointer-events: none;
                            //left: 35px;
                            left: 10px!important;
                            top: 10px;
                            transition: 0.2s ease all;
                        }

                        .floating-label {
                            color: #6c7a89;
                        }

                        input:focus+.floating-label,
                        input:valid+.floating-label {
                            margin-top: -26px;
                            background-color: white;
                            font-size: 12px;
                            color: #6c7a89;
                            padding: 0 4px;
                        }

                        input {
                            width: 100% !important;
                            height: 54px;
                        }
                    }

                    .year {
                        display: inline-block;
                        position: relative;
                        margin-right: 0 !important;

                        .floating-label {
                            position: absolute;
                            pointer-events: none;
                            //left: 35px;
                            left: 10px!important;
                            top: 10px;
                            transition: 0.2s ease all;
                        }

                        .floating-label {
                            color: #6c7a89;
                        }

                        input:focus+.floating-label,
                        input:valid+.floating-label {
                            margin-top: -26px;
                            background-color: white;
                            font-size: 12px;
                            color: #6c7a89;
                            padding: 0 4px;
                        }

                        input {
                            width: 100% !important;
                            height: 54px;
                        }
                    }

                    .cvv {
                        display: inline-block;
                        position: relative;
                        margin-right: 0 !important;

                        .floating-label {
                            position: absolute;
                            pointer-events: none;
                            //left: 35px;
                            left: 18px !important;
                            top: 38px;
                            transition: 0.2s ease all;
                        }

                        .floating-label {
                            color: #6c7a89;
                        }

                        input:focus+.floating-label,
                        input:valid+.floating-label {
                            margin-top: -26px;
                            background-color: white;
                            font-size: 12px;
                            color: #6c7a89;
                            padding: 0 4px;
                        }

                        input {
                            width: 100% !important;
                            height: 54px;
                            border: 1px solid grey;
                        }
                    }

                    .error-msg {
                        display: block;
                        margin-top: 15px;
                        font-size: 10px;
                        font-weight: 500;
                        color: #ff0005;
                        text-align: left;
                        margin-left: 16px;
                    }

                    .error-input {
                        border-color: #ff0005 !important;
                    }

                    .ig-small-input {
                        margin-top: 20px;
                        height: 54px;
                        border-radius: 4px;
                        border-color: #bdc3c7;
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d3436;

                    }


                    .ig-small-input {
                        margin-top: 20px;
                        height: 54px;
                        border-radius: 4px;
                        border-color: #bdc3c7;
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d3436;
                    }

                    .ig-xsmall-input {
                        margin-top: 20px;
                        height: 54px;
                        border-radius: 4px;
                        border-color: #bdc3c7;
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d3436;
                    }

                    .normal-input {
                        margin-top: 20px;
                        height: 45px;
                        width: 100%;
                        border: none;
                        border-radius: 0;
                        border-bottom: 1px solid #cccccc;
                        //padding: 15px;
                    }

                    .inform {
                        background: url(https://s3-eu-west-1.amazonaws.com/alpha-igweb/exclamation.png);
                        background-repeat: no-repeat;
                        background-position-x: 0px;
                        background-position-y: 13px;

                        p {
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 1.43;
                            color: #2d3436;
                            text-align: left;
                            padding: 30px 15px;
                        }
                    }

                    .ig-add-new-cc-btn{
                        width: 95% !important;
                    }

                    .cc-detail-box{
                        padding: 0 10px 0 5px !important;
                    }
                }
            }
        }
    }

    .profile-delete-card-modal{
        .capsul{
            .btns{
                .delete-btn{
                    margin-right: 0 !important;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .sb-home-capsul{

        .sb-contentCrate{

            .sb-content-capsul{
                
                .add-money{
                    margin: 50px auto !important;
                }

                .autoReload{
                    .foot-modal{
                        margin: 0 0px !important;
                    }
                }

                .noActiveCard{
                    margin: 25px auto !important;
                }
            }   

        }
    }

    .generic-modal {
        .capsul{
            .cancelBtn{
                margin: 0 !important;
            }
        }
    }
}