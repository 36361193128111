.sb-help{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }
    
    .sb-contentCrate{
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }
        }

        .help-capsul{
            .help-head{
                //background-image: url("https://content.sbuxtr.com/images/ui/1700x560-gorsel-store-green.jpg");
                background-color: #d4e9e2;
                padding: 60px;
                //height: 200px;
                height: 120px;
                background-size: cover;
                position: relative;

                .help-bar{
                    position: absolute;
                    bottom: -35px;
                    width: calc(100% - 120px);

                    .search-icon{
                        position: absolute;
                        left: 20px;
                        top: 20px;

                        svg{
                            width: 28px;
                            height: 28px;
                            path{
                                fill: #848484 !important;
                            }
                        }
                    }

                    input{
                        width: 100%;
                        height: 50px;
                        border: 1px solid #f7f7f7;
                        padding: 35px 35px 35px 60px;
                        font-size: 16px;
                        box-shadow: 0 0 .5px 0 rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .24);
                        font-weight: 500;
                        border: none;
                        border-radius: 2px;

                        &:focus{
                            -webkit-transition: all .2s ease;
                            transition: all .2s ease;
                            box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, .24), 0 1.5px 1.5px 0 rgba(0, 0, 0, .34);
                        }
                        
                    }
                }
            }

            .help-container{
                padding: 80px 40px;

                .help-category{
                    text-align: center;
                    .help-category-item{
                        display: inline-block;
                        vertical-align: middle;
                        width: calc(25% - 10px);
                        margin-right: 10px;
                        margin-bottom: 20px;
                        height: 100px;
                        border-radius: 2px;
                        background-color: #1f6c4e;
                        text-align: center;
                        color: white;
                        box-shadow: 0 0 .5px 0 rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .24);
                        cursor: pointer;
                        position: relative;
                        text-align:  -webkit-center;

                        span{
                            /*position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);*/
                            display: block;
                            text-align: center;
                            left: 0;
                            right: 0;
                            padding: 0 20px;
                        }

                        &:nth-child(4n){
                            margin-right: 0;
                        }

                        &:hover{
                            opacity: 0.9;
                            -webkit-transition: all .2s ease;
                            transition: all .2s ease;
                        
                        }

                        svg{
                            width: 50px;
                            display: block;
                        }
                    }

                    .active{
                        opacity: 0.8;
                    }
                }

                .help-list{
                    margin-top: 10px;

                    .help-list-item{
                        background-color: white;
                        border-radius: 2px;
                        border: 1px solid #3d3d3d36;
                        margin-bottom: 20px;
                        

                        .help-list-item-head{
                            cursor: pointer;
                            display: block;
                            padding: 15px;

                            span{
                                font-weight: bold;
                            }

                            svg{
                                float: right;
                            }

                            &:hover{

                            }

                            .active{
                                transform: rotate(180deg);
                            }
                        }

                        .help-list-item-content{
                            border-top: 1px solid #d6d6d6;
                            padding: 0px 20px 0px 20px;
                        }

                        &:hover{
                            -webkit-transition: all .2s ease;
                            transition: all .2s ease;
                            border: 1px solid rgba(61, 61, 61, 0.876);
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 1280px){
   .sb-help{
       
        .sb-headerCrate{
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }
        
        .sb-contentCrate{
            
        }
    }
}

@media (min-width: 1024px){
    .sb-help{
        padding-top: 83px;

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }
        
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-contentColumn {
                padding-top: 100px;
                padding-bottom: 100px;
                max-width: 670px !important;

                .content___LdxU3{
                    img{
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33{
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .sb-help{
        
        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 480px){
    .sb-help{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 375px){
    .sb-help{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .sb-contentColumn--narrow {
                max-width: 343px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    
    }
}

@media (max-width: 768px){
    .sb-help{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .help-container{
                padding: 40px 15px !important;

                .help-category-item{
                    width: calc(50% - 10px) !important;
                    margin-right: 0 !important; 
                    span{
                        font-size: 12px !important;
                    }

                    &:nth-child(1n) {
                        margin-right: 10px !important;
                    }
                }
            }
        }
    
    }
}