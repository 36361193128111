.sb-animator-fade-appear,
.sb-animator-fade-enter,
.sb-animator-fade-leave.sb-animator-fade-leave-active {
  opacity: 0!important;
  transform: scale(.75)!important;
  transition-timing-function: ease!important;
  transition-property: transform, opacity!important
}

.sb-animator-fade-appear.sb-animator-fade-appear-active,
.sb-animator-fade-enter.sb-animator-fade-enter-active,
.sb-animator-fade-leave {
  opacity: 1!important;
  transform: scale(1)!important
}

.sb-animator-fade-appear-active,
.sb-animator-fade-enter-active {
  transition-delay: .1s!important;
  transition-duration: .2s!important
}

.sb-animator-fade-leave-active {
  transition-delay: 0ms!important;
  transition-duration: .1s!important
}

.sb-animator-grow-appear,
.sb-animator-grow-enter,
.sb-animator-grow-leave.sb-animator-grow-leave-active {
  opacity: 0!important;
  transform: scale(.3)!important;
  transition-timing-function: ease!important;
  transition-property: transform, opacity!important
}

.sb-animator-grow-appear.sb-animator-grow-appear-active,
.sb-animator-grow-enter.sb-animator-grow-enter-active,
.sb-animator-grow-leave {
  opacity: 1!important;
  transform: scale(1)!important
}
.sb-expander--isAnimating {
  overflow: hidden;
  transition: max-height .3s cubic-bezier(.25, .46, .45, .94);
}

.sb-expander__pushdownContent {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0
}

.sb-animator-grow-appear-active,
.sb-animator-grow-enter-active {
  transition-delay: .1s!important;
  transition-duration: .2s!important
}

.sb-animator-grow-leave-active {
  transition-delay: 0ms!important;
  transition-duration: .1s!important
}