.sb-navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 74px
}

.sb-navbar-siren {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px
}

.sb-navbar-button {
  width: 44px
}

.sb-navbar-icon {
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  margin: auto
}

@media (min-width:375px) {
  .sb-navbar-siren {
    width: 40px;
    height: 40px
  }
}

@media(min-width:768px) {
  .sb-navbar-siren {
    width: 50px;
    height: 50px
  }
}

@media (min-width:1024px) {
  .sb-navbar {
    position: absolute;
    width: 100%;
    height: 106px;
  }
}

.sb-subnav-activeLink {
  box-shadow: inset 0 -3px #00a862
}

.sb-subnav-heading {
  word-wrap: break-word
}

.sb-subnav-listLink {
  padding-bottom: 6px
}

@media (max-width:1023px) {
  .sb-subnav-list {
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.6rem;
    padding-right: .8rem;
    padding-left: .8rem;
    margin-right: -1.6rem;
    margin-left: -1.6rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: auto
  }
  .sb-subnav-listItem {
    padding-right: 1.6rem;
    
  }
  .sb-subnav-listLink {
    display: block;
    padding: .4rem .8rem .8rem
  }
}

@media (min-width:1024px) {
  .sb-subnav-listItem {
    margin-top: 1.6rem;
    //cursor:pointer;
    a{
      cursor: pointer;
    }
  }
  .sb-subnav-breadcrumbIcon {
    position: absolute;
    top: 0;
    right: 100%
  }
}