.globalNav_ {
    position: relative;
    z-index: 2;

    .sb-globalNav__logo {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
    }

    .sb-globalNav__links {
        display: flex;
        align-items: center;
        flex-grow: 1;

        ul {
            margin-bottom: 0;

            li {
                a {
                    color: currentColor;
                    cursor: pointer;
                    letter-spacing: 0.13rem !important;
                    line-height: 2.1rem;
                }

                .text-xxs {
                    font-size: 1.3rem !important;
                }

                .text-bold {
                    font-weight: 700 !important;
                }
            }
        }

        a {
            cursor: pointer;
        }
    }

    .sb-globalNav__menuButton {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 44px;
    }

    .right-links {
        .inbox-cap {
            display: inline-block;
            position: relative;

            .count-inbox {
                position: absolute;
                display: inline-block;
                padding: 2px 6px;
                border-radius: 12px;
                background-color: #008247;
                font-size: 10px;
                font-weight: 800;
                text-align: center;
                color: #ffffff;
                top: -6px;
                right: 0px;
                height: 15px;
                width: 15px;
            }
        }

        .findStore {
            color: black;
            font-weight: 600 !important;
            text-decoration: none !important;
        }

        .sb-button--default.sb-button--black,
        .sb-button--default.sb-button--rewardsDefault {
            border-color: rgba(0, 0, 0, 0.87);
            color: rgba(0, 0, 0, 0.87);

            &:hover {
                background-color: rgba(0, 0, 0, 0.06);
            }
        }

        .sb-button--positive.sb-button--black,
        .sb-button--positive.sb-button--rewardsDefault {
            background-color: #000;
            border-color: #000;
        }

        .sb-button--positive.sb-button--black:not(.sb-button--disabled):not(.sb-button--nonInteractive):not(
                .sb-button--loading
            ):hover,
        .sb-button--positive.sb-button--rewardsDefault:not(.sb-button--disabled):not(.sb-button--nonInteractive):not(
                .sb-button--loading
            ):hover {
            background-color: rgba(0, 0, 0, 0.7);
            border-color: rgba(0, 0, 0, 0.7);
            color: white;
            text-decoration: none;
        }
    }
}

.sb-mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .sb-overlay__container {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .sb-overlay__content {
            min-height: 100%;

            .sb-overlay__closeBtnContainer {
                height: 44px;
                margin: 1.6rem;

                .sb-overlay__closeBtn {
                    right: 0;
                    transition: all 0.2s ease-out;
                    min-width: 44px;
                    min-height: 44px;
                }
            }

            .sbLoginBtn {
                color: rgba(0, 0, 0, 0.87);
                border-color: rgba(0, 0, 0, 0.87);

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            .sbRegisterBtn {
                background-color: rgba(0, 0, 0, 0.87);
                border-color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}

@media (min-width: 1024px) {
    .globalNav_ {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;

        .lg-py4 {
            padding-top: 2.4rem !important;
            padding-bottom: 2.4rem !important;
        }

        .lg-px7 {
            padding-right: 4.8rem !important;
        }

        .lg-pl7,
        .lg-px7 {
            padding-left: 4.8rem !important;
        }

        .sb-globalNav__logo {
            width: 50px !important;
            height: 50px !important;
        }
    }

    .sb-globalNav {
        box-shadow:
            0 0 2px 0 rgba(0, 0, 0, 0.24),
            0 2px 2px 0 rgba(0, 0, 0, 0.12);
    }
}

@media (min-width: 375px) {
    .globalNav_ {
        .sb-globalNav__logo {
            width: 40px;
            height: 40px;
        }
    }
}

@media (min-width: 768px) {
    .md-pl4,
    .md-px4 {
        padding-left: 1.2rem !important;
    }

    .globalNav_ {
        .sb-globalNav__logo {
        }

        .sb-globalNav__links {
            ul {
                li {
                    a {
                    }

                    .text-xxs {
                    }

                    .text-bold {
                    }
                }
            }
        }

        .right-links {
        }

        .sb-globalNav__menuButton {
            display: none !important;
        }
    }
}

@media (max-width: 768px) {
    .globalNav_ {
        .sb-globalNav__logo {
        }

        .sb-globalNav__links {
            display: none !important;

            ul {
                li {
                    a {
                    }

                    .text-xxs {
                    }

                    .text-bold {
                    }
                }
            }
        }

        .right-links {
        }

        .sb-globalNav__menuButton {
        }
    }
}
