* {
  box-sizing: border-box;
}

a {
  color: currentColor;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  /* Now 10px = 1rem! */
}

body {
  font-size: 1.3rem;
  font-family: SoDoSans, sans-serif;
  font-feature-settings: "calt";
}

img {
  max-width: 100%;
}

header.sb-components-header {
  background: #edebe9;
  padding: 1rem;
}

header.sb-components-header ul {
  list-style: none;
  padding: 0;
  margin: 0 -2rem;
}

header.sb-components-header ul li {
  display: inline;
  margin: 0 2rem;
}

header.sb-components-header ul li a {
  font-weight: 600;
  color: rgba(0, 0, 0, .56);
  text-decoration: none;
  font-size: 1.2rem;
}

.code-description p {
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: .1rem;
  margin-bottom: .8rem;
}

.code-example {
  padding: 2rem;
  border: 1px solid #ccc;
  margin: 2rem 0;
}

.sb-components-container {
  padding: 2rem;
}

.text-bold {
  font-weight: 700 !important;
}

.text-upper {
  text-transform: uppercase !important;
  letter-spacing: .1em !important;
}

svg {
  fill: currentColor;
}