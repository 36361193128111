.sb-survey{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }
    
    .sb-contentCrate{
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }
        }

        .survey-box{
            box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
            background-color: white;
            .head{
                background-color: rgb(0, 99, 65);
                padding: 30px 50px;
                color: white;

                .top{
                    .text{
                        text-align: center;

                        .logo{
                            width: 100px;
                            margin: 0 auto
                        }
                    }
                }
            }

            .survey-form{
                padding: 30px 50px;

                .req{
                    display: block;
                    margin-bottom: 20px;
                }

                .survey-item{
                    margin-bottom: 55px;
                    .survey-label{
                        display: block;
                        margin-bottom: 15px;
                        resize: none;
                        font-weight: 600;
                    }

                    .survey-p{
                        font-size: 13px;
                        color: #595a5a;
                    }

                    input{
                        font-family: SodoSans,sans-serif;
                        height: 45px;
                        -webkit-appearance: none !important;
                        -moz-appearance: none !important;
                        appearance: none !important;
                        background-color: transparent !important;
                        border: 0 !important;
                        border-radius: 0 !important;
                        border-bottom: 1px solid rgba(0,0,0,.2) !important;
                    }
                    
                    select{
                        font-family: SodoSans,sans-serif;
                        -webkit-appearance: none !important;
                        -moz-appearance: none !important;
                        appearance: none !important;
                        background-color: transparent !important;
                        border: 0 !important;
                        border-radius: 0 !important;
                        border-bottom: 1px solid rgba(0,0,0,.2) !important;
                        height: 45px;
                        width: 100%;
                    }

                    textarea{
                        font-family: SodoSans,sans-serif;
                        -webkit-appearance: none !important;
                        -moz-appearance: none !important;
                        appearance: none !important;
                        background-color: transparent !important;
                        border: 0 !important;
                        border-radius: 0 !important;
                        border-bottom: 1px solid rgba(0,0,0,.2) !important;
                        resize: none;
                    }

                    .survey-inp{
                        width: 100%;
                        padding: 10px 0 ;
                    }

                    .select-rate{
                        border: 1px solid black;
                        .rate-item{
                            display: inline-block;
                            border-left: 1px solid black;
                            padding: 10px;
                            width: 9.09%;
                            text-align: center;
                            cursor: pointer;

                            &:hover{
                                background-color: black;
                                color: white;
                            }

                            &:first-child{
                                border-left: none !important;
                            }
                        }

                        .active{
                            background-color: black;
                            color: white;
                        }
                    }

                    .rate-inform{
                        margin-top: 10px;
                        height: 30px;
                        .left{
                            float: left;
                        }

                        .right{
                            float: right;
                        }
                    }
    
                    .arrow {
                        position: relative;
                        height: 15px;
                        width: 15px;
                        float: right;
                        right: 20px;
                        top: 25px;
                        transform: rotate(180deg);
                        zoom: 0.8;
                        &::before {
                            content: "";
                            position: absolute;
                            bottom: 0px;
                            width: 0.15rem;
                            height: 100%;
                            transition: all 0.5s;
                            left: -5px;
                            transform: rotate(45deg);
                            background-color: #394a6d;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0px;
                            width: 0.15rem;
                            height: 100%;
                            transition: all 0.5s;
                            left: 5px;
                            transform: rotate(-45deg);
                            background-color: #394a6d;
                        }
                    }
                    

                }
            }
        }
    }
}


@media (min-width: 1280px){
   .sb-survey{
       
        .sb-headerCrate{
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }
        
        .sb-contentCrate{
            
        }
    }
}

@media (min-width: 1024px){
    .sb-survey{
        padding-top: 83px;

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }
        
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-contentColumn {
                //padding-top: 100px;
                padding-top: 60px;
                padding-bottom: 100px;
                max-width: 670px !important;

                .content___LdxU3{
                    img{
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33{
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .sb-survey{
        
        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 480px){
    .sb-survey{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}

@media (min-width: 375px){
    .sb-survey{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            .sb-contentColumn--narrow {
                max-width: 95%;
                padding-left: 0;
                padding-right: 0;
            }

            .survey-box {
                .head{
                    padding: 20px 20px !important;
                }

                .survey-form{
                    padding: 20px 20px !important;
                }
            }

        }
    }
}

@media (max-width: 768px){
    .sb-survey{

        .sb-headerCrate{

        }
        
        .sb-contentCrate{
            
        }
    
    }
}