.sb-sourcing {
    position: relative;
    z-index: 1;

    .sb-headerCrate {
        position: relative;
        z-index: 1;
        box-shadow:
            0 0 2px 0 rgba(0, 0, 0, 0.24),
            0 4px 4px 0 rgba(0, 0, 0, 0.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content {
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }

    .sb-contentCrate {
        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black,
            .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0, 0, 0, 0.87);
                border-color: rgba(0, 0, 0, 0.87);
                &:hover {
                    background-color: rgba(0, 0, 0, 0.06);
                }
            }

            .sb-button--positive.sb-button--black,
            .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.7);
                    border-color: rgba(0, 0, 0, 0.7);
                }
            }
        }

        .sourcing-content {
            padding: 20px 40px;

            .sourcing-box {
                .box-item {
                    width: 50%;
                    display: inline-block;
                    padding: 10px 20px;
                    margin-bottom: 40px;
                    vertical-align: top;

                    .item-cover {
                        img {
                            width: 100%;
                        }
                    }
                }
            }

            p {
                color: #6c6c6c;
            }
        }
    }
}

@media (min-width: 1280px) {
    .sb-sourcing {
        .sb-headerCrate {
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }

        .sb-contentCrate {
        }
    }
}

@media (min-width: 1024px) {
    .sb-sourcing {
        padding-top: 83px;

        .sb-headerCrate {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }
        }

        .sb-contentCrate {
            margin-left: 40vw;

            .sb-contentColumn {
                //padding-top: 100px;
                padding-top: 60px;
                padding-bottom: 100px;
                max-width: 670px !important;

                .content___LdxU3 {
                    img {
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33 {
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .sb-sourcing {
        .sb-headerCrate {
        }

        .sb-contentCrate {
        }
    }
}

@media (min-width: 480px) {
    .sb-sourcing {
        .sb-headerCrate {
        }

        .sb-contentCrate {
        }
    }
}

@media (min-width: 375px) {
    .sb-sourcing {
        .sb-headerCrate {
        }

        .sb-contentCrate {
            .sb-contentColumn--narrow {
                max-width: 95%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

@media (max-width: 768px) {
    .sb-sourcing {
        .sb-headerCrate {
        }

        .sb-contentCrate {
        }
    }
}
