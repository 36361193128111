.egift-land-cap{
    margin-top: 50px;
    margin-bottom: 30px;

    .card-list-part{
        margin-bottom: 50px;
        .sb-heading{
            margin-left: 20px;
        }
    }

    .egift-card-list{
        padding: 50px 50px;
        .egift-cart-item{
            width: 25%;
            display: inline-block;
            vertical-align: middle;
            padding: 10px;
            cursor: pointer;

            img{
                border-radius: 3.3333%/5.298%;
                box-shadow: 0 2px 3px rgba(7, 7, 7, 0.24);

                &:hover{
                    box-shadow: 0 4px 5px rgba(7, 7, 7, 0.64);
                    -webkit-transition: all .2s ease;
                    transition: all .2s ease;
                }
            }
        }

        .slick-slider{
            .slick-list{
                margin-left: 8px;
                margin-right: 8px;
            }
        }

        .slick-prev{
            box-shadow: 0 0 6px rgba(0,0,0,.24), 0 8px 12px rgba(0,0,0,.14);
        }
    }

    .egift-band{
        padding: 25px 30px;
        background-color: #d4e9e1;
        width: calc(100% - 70px);
        margin: 0 auto;
        border-radius: 8px;

        .title{
            display: inline-block;
            margin-right: 15px;
        }
    }
}


.egift-capsul{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }
    
    .sb-contentCrate{
        .inform-head{
            .detail-bg{
                border-radius: 3.3333%/5.298%;
                box-shadow: 0 2px 3px rgba(7, 7, 7, 0.24);
            }
        }

        .gift-form{
            width: 500px;
            max-width: 100%;

            .form-item{
                margin-bottom: 50px;
                .form-item-label{
                    color: rgba(0,0,0,.56)!important;
                    font-weight: 700;
                    margin-bottom: 15px;
                    display: block;
                }
            }

            .title{
                margin-bottom: 60px;
            }

            .pb6{
                border: 0;
                padding: 0;
                margin: 0;
                min-width: 0;
                padding-bottom: 15px !important;
                .rememberMe{
                    margin-top: 20px;
                }
            }

            .select{
                label{
                    top: 5px;
                }
            }

            .msgBox{
                textarea{
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    border-bottom: 1px solid rgba(0,0,0,.2);
                    font-family: unset;
                }
            }
        }

        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0,0,0,.87);
                border-color:rgba(0,0,0,.87);
                &:hover{
                    background-color: rgba(0,0,0,.06);
                }
            }

            .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;
                &:hover{
                    background-color: rgba(0,0,0,.7);
                    border-color: rgba(0,0,0,.7);
                }
            }
        }

        .saveBtn{
            position: fixed;
            bottom: 0;
            right: 0;
            z-index: 10;
            padding: 1.6rem;
        }
    }
}


.sb-egift-balance-check{
    .giftcard-form{
        width: 100%;

        .pb6{
            border: 0;
            padding: 0;
            margin: 0;
            min-width: 0;
            padding-bottom: 20px !important;
        }

        .import-credit{
            margin-top: 20px;
            .sb-frap{
                background-color: white;
                color: black;
                zoom: 0.75;
            }
        }

        .number-box{
            position: relative;

            .icon{
                position: absolute;
                right: 0px;
                bottom: 22px;
                cursor: pointer;
                z-index: 2;

                svg{
                    width: 18px;
                    height: 18px;
                    fill: rgba(0,0,0,.6);
                }
            }
        }
    }

}

@media (min-width: 1280px){
    .egift-capsul{
        
         .sb-headerCrate{
             .sb-headerCrate--content {
                 padding-left: 11.2rem !important;
                 padding-right: 11.2rem !important;
             }
         }
         
         .sb-contentCrate{
             
         }
     }
 }
 
 @media (min-width: 1024px){
     .egift-capsul{
         padding-top: 10px;
 
         .sb-headerCrate{
             position: fixed;
             top: 0;
             bottom: 0;
             left: 0;
             overflow: auto;
             width: 40vw;
 
             .container--headerCrate {
                 padding: 0 3.2rem;
             }
 
         }
         
         .sb-contentCrate{
             margin-left: 40vw;
 
             .sb-contentColumn {
                 padding-top: 100px;
                 padding-bottom: 100px;
                 max-width: 420px !important;
 
                 .content___LdxU3{
                     img{
                         display: inline-block;
                         margin-right: 50px;
                         vertical-align: middle;
                     }
 
                     .py33{
                         display: inline-block;
                         width: 310px;
                         vertical-align: middle;
                         margin-top: 0px;
                         padding: 0;
                         margin-bottom: 0px;
                     }
                 }
             }
         }
     }
 }
 
 @media (min-width: 768px){
     .egift-capsul{
         
         .sb-headerCrate{
 
         }
         
         .sb-contentCrate{
             
         }
     
     }
 }
 
 @media (min-width: 480px){
     .egift-capsul{
 
         .sb-headerCrate{
 
         }
         
         .sb-contentCrate{
             
         }
     
     }
 }
 
 @media (min-width: 375px){
     .egift-capsul{
 
         .sb-headerCrate{
 
         }
         
         .sb-contentCrate{
             .sb-contentColumn--narrow {
                 max-width: 343px;
                 padding-left: 0;
                 padding-right: 0;
             }
         }
     
     }
 }
 
 @media (max-width: 768px){
     .egift-capsul{
 
         .sb-headerCrate{
 
         }
         
         .sb-contentCrate{
             
         }
     
     }
 }

@media (max-width: 768px){
    .egift-land-cap{
        margin-top: 0 !important;
        .egift-card-list{
            padding: 20px 20px !important; 
            .egift-cart-item{
                //width: 50% !important;
            }
        }

        .egift-band{
            width: calc(100% - 35px) !important;
        }
    }
}