
.sb-join-main{
    position: relative;
    z-index: 1;

    .sb-headerCrate{
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .base___46s3P{
            height: 100%;

            .register-logo{
                width: 48px !important;
                height: 48px !important;
            }

            .flex{
                .sb-headerCrate--content{
                    margin-top: auto;
                    margin-bottom: auto;
                    padding-bottom: 2.4rem;
                    display: block;
                    width: 100%;
                    padding: 0 1.6rem;

                    .sb-heading {
                        margin:0;
                        font-size: 2.3rem;
                        line-height: 1.385;
                    }
                }
            }
        }
    }

    .sb-contentCrate{

        .sb-register-form{
            .flex-auto{
                .sb-contentColumn{
                    padding: 2.4rem 1.6rem 6.4rem;

                    .pb6{
                        border: 0;
                        padding: 0;
                        margin: 0;
                        min-width: 0;
                    }

                    .phone-set{
                        padding-bottom: 0 !important;
                        .areaCode{
                            width: 80px;
                            display: inline-block;
                        }

                        .phoneNumber{
                            width: 260px;
                            display: inline-block;
                            position: relative;
                            top: 1px;
                            left: 3px;
                        }
                    }

                    .password-targets{

                        margin-top: 20px;

                        .target-item{
                            display:block;
                            position: relative;

                            .title{
                                padding-left:20px;
                            }

                            .fieldStatus__icon{
                                top: 0 !important;
                            }

                            .check{
                                color: #00653e;
                            }
    
                            .close{
                                color: #e75b52;
                            }
                        }

                    }

                    .select-first-name{
                        padding: 15px!important;
                        background-color: #fff0;
                        border-radius: 2px;
                        border: 1px solid #c6c6c6;
                        margin-left: 0px;
                        margin-right: 0px;

                        .s-title{
                            display: block;
                            margin-bottom: 10px;
                            font-weight: bold;
                            color: #6c6c6c;
                        }

                        .option--radio{
                            display: inline-block;
                            margin-right: 15px;
    
                        }
                    }
                }
                
            }
        }


        /*
        .sb-small-footer{
            .container-footer{
                display: -webkit-box;
                display: flex;

                .find-store{
                    a{
                        text-decoration: none;
                        &:hover{
                            color: #008248;
                        }
                    }
                }

                .footer-links{
                    a{
                        color: currentColor;
                    }
                }

            }

            .container-footer>* {
                padding: 0 6.4rem 0 0;
            }
        }
        */

        .activation-modal{
            position: fixed;
            top: 0;
            width: 100%;
            background-color: #00000054;
            height: 100%;
            z-index: 9;

            .in-modal{
                    background-color: white;
                    position: absolute;
                    bottom: 0;
                    height: auto;
                    width: 100%;
                    border: 1px solid #e2e0e0;
                    padding: 10px;

                    .close-icon{
                        position: absolute;
                        right: 40px;
                        top: 40px;
                    }

                    .active-form{

                        .title{

                        }
                        
                        .text{

                        }

                        .activation-inp{
                            height: 45px;
                            width: 100%;
                            border-radius: 15px;
                            border: 2px solid gray;
                            padding: 15px;
                        }

                        .btns{
                            margin-top: 25px;
                            margin-bottom: 100px;
                            .resendBtn{
                                margin-right: 15px;

                                button{
                                    background-color: #616161;
                                }
                            }
                        }

                }
            }
        }
    }
}



@media (min-width: 1280px){
    .sb-join-main{

        .sb-headerCrate{

            .lg-flex {
             
            }
            
            .lg-m4 {
             
            }

            .base___46s3P{
           

                .register-logo{
                
                }

                .flex{
                    .sb-headerCrate--content{
                        padding-left: 11.2rem !important;
                        padding-right: 11.2rem !important;

                        .sb-heading {

                        }
                    }
                }
            }
        }
    
        .sb-contentCrate{
         
        }
    }
 }
 
 @media (min-width: 1024px){
    .sb-join-main{

        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .lg-flex {
                display: flex!important;
            }
            
            .lg-m4 {
                margin: 2.4rem!important;
            }

            .base___46s3P{
                height: 100%;

                .register-logo{
                    width: 40px;
                    height: 40px;
                }

                .flex{
                    .sb-headerCrate--content{
                        padding: 0 3.2rem;
                        .sb-heading {

                        }
                    }
                }
            }
        }
    
        .sb-contentCrate{
            margin-left: 40vw;

            .sb-register-form{
                .flex-auto{
                    .sb-contentColumn{
                        padding-top: 150px;
                        padding-bottom: 100px;
                    }
                }
            }

            .activation-modal{
  
                .in-modal{
                    width: 60% !important;
                        .active-form{
    
                            .title{
    
                            }
                            
                            .text{
                                
                            }
    
                            .activation-inp{
                               
                            }
    
                    }
                }
            }
        }
    }
 }
 
 @media (min-width: 768px){
    .sb-join-main{

        .sb-headerCrate{
         
            .lg-flex {
              
            }

            .base___46s3P{
         
                .register-logo{
                    width: 40px;
                    height: 40px;
                }

                .flex{
                    .sb-headerCrate--content{
                        .sb-heading {
                            
                        }
                    }
                }
            }
        }
    
        .sb-contentCrate{
      
        }
    }
 }
 
 @media (min-width: 480px){
  
  
 }
 
 @media (min-width: 375px){
    
    .sb-join-main{

        .sb-headerCrate{
            

            .lg-flex {
               
            }
            
            .lg-m4 {
              
            }

            .base___46s3P{
               

                .register-logo{
                    
                }

                .flex{
                    .sb-headerCrate--content{
                       
                        .sb-heading {

                        }
                    }
                }
            }
        }
    
        .sb-contentCrate{
            .sb-register-form{
                .flex-auto{
                    .sb-contentColumn{
                        max-width: 343px;
                        padding-left: 0;
                        padding-right: 0;
                    }

                }
            }

            
            .active-form{
                max-width: 343px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

 }
 
 @media (max-width: 768px){
    
 }




 @media (max-width: 1023px) and (min-width: 480px), (min-width: 1280px){
 
 }

 @media (max-width: 479px), (min-width: 1024px){
    
 }