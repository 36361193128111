.sb-inbox-capsul{
    position: relative;
    z-index: 1;

    .sb-headerCrate {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);

        .sb-headerCrate--content{
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;

            .sb-heading--large {
                font-size: 2.7rem;
                line-height: 1.385;
            }
        }
    }


    .sb-contentCrate{

        .sb-content-capsul{

            .message-capsul{

                .message-head{
                    border-bottom: 1px solid #e1e1e1;

                    .message-menu{
                        padding: 15px 30px;
                        
                        li{
                           display:inline-block;
                           margin-right: 20px;
                           font-weight: bold; 
                           cursor: pointer;
                           position: relative;
                        }

                        .count-inbox{
                            position: absolute;
                            display: inline-block;
                            padding: 2px 6px;
                            border-radius: 12px;
                            background-color: #008247;
                            font-size: 10px;
                            font-weight: 800;
                            text-align: center;
                            color: #ffffff;
                            top: -12px;
                            right: -10px;
                            height: 12px;
                            width: 10px;
                        }

                        .active{
                            border-bottom: 4px solid #007d40;
                        }
                    }
                }


                .message-content{
                    padding:15px;

                    .no-message{
                        width: 300px;
                        max-width: 100%;
                        //background-color: white;
                        margin: 5% auto;
                    }

                    .message-list{
                        .message-item{
                            box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
                            background-color: white;
                            padding: 25px 50px;
                            margin-bottom: 20px;

                            .mess-left{
                                width: 50%;
                                display:inline-block;
                                vertical-align: middle;
                                .new-msg{
                                    display:block;
                                    color:#696969;
                                    font-weight: bold;
                                    font-size: 14px;
                                    margin-bottom: 10px;
                                }

                                .title{
                                    display:block;
                                    font-size: 28px;
                                    margin-bottom: 10px;
                                    color:#232323;
                                }

                                .date{
                                    display:block;
                                    color:#b4b4b4;
                                    margin-bottom: 10px;
                                }
                            }

                            .mess-right{
                                width: 50%;
                                display:inline-block;
                                vertical-align: middle;
                                text-align: right;

                                .msg-img{
                                    width: 150px;
                                    border-radius: 50%;
                                    
                                }
                            }

                            &:hover{
                                background-color: rgb(233, 233, 233);
                                transition: all .2s ease-out;
                            }
                        }
                    }

                    .message-detail{
                        width: 600px;
                        max-width: 100%;
                        //background-color: white;
                        margin: 5% auto;

                        .cover-img{
                            display:block;
                            border-radius: 4px;
                            margin-bottom: 15px;
                        }

                        .title{
                            display:block;
                            color:#151515;
                            font-size: 28px;
                            margin-bottom: 15px;
                        }

                        .sub-title{
                            display:block;
                            color:#151515;
                            font-size: 18px;
                            margin-bottom: 10px;
                        }

                        .date{
                            display:block;
                            color:#a8a8a8;
                            font-size: 14px;
                            margin-bottom: 10px;
                        }

                        .text{
                            display:block;
                        }
                    }

                }

            }

        }
    }

}


@media (min-width: 1280px){
 
    .sb-inbox-capsul{
        .sb-headerCrate{
    
            .sb-headerCrate--content{
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }

        .sb-contentCrate {
            
        }
    }
}

@media (min-width: 1024px){
 

    .sb-inbox-capsul{
        .sb-headerCrate{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .sb-headerCrate--content{
                padding: 0 3.2rem;
            }
        }

        .sb-contentCrate {
            margin-left: 40vw;
            padding-top: 83px;

        
        }
    }
}

@media (min-width: 768px){
   

}

@media (min-width: 480px){

}

@media (min-width: 375px){
    
}

@media (max-width: 768px){

}