.sb-white-dialog-box{
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 25;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.36);

    .in-modal{
        position: relative;
        z-index: 2;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 0 0.5px 0 rgba(0,0,0,.14), 0 1px 1px 0 rgba(0,0,0,.24);
        align-self: center;
        max-width: 420px;
        opacity: 1;
        visibility: visible;
        max-height: 97%;
        overflow: auto;
        padding: 3rem;

    }

    .modal-just-text{
        .text{
            .title{
                font-size: 2.7rem;
                line-height: 1.385;
                display: block;
            }
            .content{
                display: block;
            }
        }

        .actions{

        }  
    }


    .modal-media-text{
        .text{
            .title{
                font-size: 2.7rem;
                line-height: 1.385;
                margin-top: 10px;
                margin-bottom: 10px;
                display: block;
            }

            .content{
                display: block;
            }
        }

        .actions{
            margin-top: 40px;
        }  
    }

}

.egift-inform-modal{
   
    .in-modal{
        width: 600px;
        max-width: 100%;

        .text{
            .title{
                color: #008248;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 25px;
            }
        }

        .order-summary{
            .summary-left{
                width: 50%;
                display: inline-block;
                vertical-align: top;
                .summary-title{
                    display: block;
                    margin-bottom: 15px;
                    text-decoration: underline;
                    font-weight: bold;
                }

                .summary-name{
                    display: block;
                }

                .summary-mail{
                    display: block;
                }

                .summary-phone{
                    display: block;
                }
            }
    
            .summary-right{
                width: 50%;
                display: inline-block;
                vertical-align: top;

                .summary-title{
                    display: block;
                    margin-bottom: 15px;
                    text-decoration: underline;
                    font-weight: bold;
                }

                .summary-name{
                    display: block;
                }

                .summary-mail{
                    display: block;
                }

                .summary-phone{
                    display: block;
                }
            }
        }

        .egift-detail{
            .egift-box{
                margin-top: 30px;
                .egift-img{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                    img{
                        width:100px;
                        border-radius: 5px;
                    }
                }

                .egift-text{
                    display: inline-block;
                    vertical-align: middle;
                    width: calc(100% - 120px);
                }
            }

            .egift-inform{
                margin-top: 10px;
                .t1{
                    display: block;
                }
                .t2{
                    display: block;
                    font-weight: bold;
                }
            }
        }
    }

}

@media (max-width:768px) {
    .egift-inform-modal{
        .in-modal{
            .order-summary{
                .summary-left{
                    width: 100%;
                    margin-bottom: 15px;
                }
                .summary-right{
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: 1024px){
    .lg-mx0 {
        margin-right: 0!important;
    }

    .lg-ml0, .lg-mx0 {
        margin-left: 0!important;
    }
}