.sb-cont{
    
    .sb-global-subnav-containerLight{
        position: relative;
        z-index: inherit;
        width: 100%;
        border-bottom: 1px solid #dbd9d7;
        border: 1px solid #edebe9;
        background-color: #f7f7f7;

        .sb-global-subnav-logoAlign{

            .sb-global-subnav-list{
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
                -webkit-overflow-scrolling: auto;
                margin-bottom: 0px !important;

                .sb-global-subnav-listItem{
                    padding-right: 1.6rem;
                    
                    .text-semibold{
                        font-weight: 600!important;
                    }

                    a{
                        display: block;
                        padding: .4rem .8rem .8rem;
                        font-size: 1.5rem!important;
                        font-weight: 600;
                        color: rgba(0,0,0,.56)!important;
                    }

                    .activeSubnavLink {
                        box-shadow: inset 0 -3px #00a862;
                        color: black !important;
                    }
                }
            }
        }
    }

    .contentInMenu{
        padding-bottom: 74px;

        .menuWithSideNav-3HCqB{
            //padding-left: 123px;

            .sideNav___2uqqD{
                section{

                    .text-semibold {
                        font-weight: 600!important;
                    }
                    .text-sm {
                        font-size: 1.5rem!important;
                    }
                    .mt4 {
                        margin-top: 2.4rem!important;
                    }

                    ul{
 
                        .text-noUnderline {
                            text-decoration: none!important;
                        }

                        .text-sm {
                            font-size: 1.5rem!important;
                        }

                        .color-textBlackSoft {
                            color: rgba(0,0,0,.56)!important;
                        }

                        .block {
                            display: block!important;
                        }
                    }

                    &:first-child{

                        h4{
                            margin-top: 5px !important;
                        }
                    }

                }
            }

            .baseMenu___2sYeE{

                .color-textBlackSoft {
                    color: rgba(0,0,0,.56)!important;
                    a{
                        color: rgba(0,0,0,.56)!important;
                        font-weight: bold;
                    }
                }

               

                .sb-heading{
                    font-size: 2.3rem !important;
                    margin-bottom: 0px;
                }

                .menu-content{
                    .sb-heading{
                        font-size: 2rem !important;
                        line-height: 1.263 !important;
                        margin-top: 0px;
                        padding-bottom: 0 !important;
                    }

                    .sb-rule {
                        border: 0;
                        margin: 0;

                        &:before {
                            content: "";
                            display: block;
                            border-top: 2px solid rgba(0,0,0,.1);
                        }
                    }   

                    .sb-rule--thin:before {
                        border-top-width: 1px;
                    }

                    .grid {
                        display: block;
                        margin: 0;
                        padding: 0;
                        text-align: left;

                        .gridItem {
                            position: relative;
                            display: inline-block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            text-align: left;
                            vertical-align: top;
                            cursor: pointer;

                            .thumb {
                                width: 100%;
                                max-width: 72px;

                                .block{
                                    max-width: 112px;
                                }

                                .imageBlock {
                                    border-radius: 50%;
                                    overflow: hidden;
                                    width: 100%;
                                }
                            }

                        }
                    }
                }

                .child-menu-category-item{

                    hr{
                        margin-top: 0px !important;
                        margin-bottom: 0 !important;
                    }

                    .size6of12 {
                        width: 50%;
                    }

                    .grid{
                        margin: 0 -.4rem;

                        .gridItem{
                            position: relative;
                            display: inline-block;
                            //width: 100%;
                            margin: 0;
                            padding: 0;
                            text-align: left;
                            vertical-align: top;

                            .prodTile___3fZDA {
                                display: block;
                                width: 100%;

                                .prodTile__body___cybaW, .prodTileImageBlock___3c9FQ {
                                    display: block;
                                    vertical-align: top;
                                }

                                .prodTileImageBlock___3c9FQ {
                                    max-width: 148px;
                                    margin: 0px auto;
                                }

                                .imageBlock___2DfHk {
                                    z-index: 0;
                                    width: 100%;
                                    padding-bottom: 100%;
                                    border-radius: 50%;
                                    overflow: hidden;
                                }

                                img {
                                    max-width: 100%;
                                    height: auto;
                                }

                                .show___VOoMM {
                                    opacity: 1;
                                    -webkit-transition: opacity .15s ease-in;
                                    transition: opacity .15s ease-in;
                                }


                                .imagePositioning___1CXPi {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    border-radius: inherit;
                                }
                                
                            }

                            .linkOverlay {
                                position: relative;
                            }
                        }
                    }
                }
            }

            .active-line{
                //border-bottom: 2px solid green;
                font-weight: bold;
                display: inline-block !important;
            }

            .nutrition-cta{
                flex: 1 1 0;
                overflow: auto;
                padding-right: 9%!important;
                position: absolute;
                margin-top:10px;
                right: 0;
                padding: 10px;
                .nutrition-cta-wrapper{
                    background-color: white;
                    border-radius: 4rem;
                    padding: 0 1.6rem;
                    box-shadow: 0 0 .6rem rgba(0,0,0,.24),0 0rem 1.2rem rgba(0,0,0,.14);
                    transition-duration: .2s;
                    transition-property: background-color,transform,box-shadow,color;
                    transition-timing-function: ease-in-out;
                    padding: 10px 20px;
                    cursor: pointer;

                    svg{
                        width: 18px;
                        height: 18px;
                    }

                    span{
                        display: inline-block;
                        font-weight: bold;   

                        &:first-child{
                            margin-right: 10px;
                        }

                        &:last-child{
                            margin-left: 10px;
                        }
                    }

                    &:hover{
                        background-color: rgba(128, 128, 128, 0.050);
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .content_isAnonFavorites{
        padding-bottom: 74px;

        .size12of12 {
            width: 100%;
        }

        .sb-button--default.sb-button--black, .sb-button--default.sb-button--rewardsDefault {
            color: rgba(0,0,0,.87);
            border-color: rgba(0,0,0,.87);

            &:hover{
                background-color: rgba(0,0,0,.06);
            }
        }

        .sb-button--positive.sb-button--black, .sb-button--positive.sb-button--rewardsDefault {
            background-color: #000;
            border-color: #000;

            &:hover{
                background-color: rgba(0,0,0,.7);
                border-color: rgba(0,0,0,.7);
            }
        }
    }

    .content_FavoriteList{
        padding: 15px;

        .wrapper{
            padding: 16px 16px 24px;
        }

        .favoriteItem{
            margin-bottom: 20px;

            .prodImage {
                border-radius: 50%;
                height: 72px;
                width: 72px;
            }

            .sb-imageFade__imagePositioning {
                position: absolute;
                top: 0;
                left: 0;
              
            }

            .sb-imageFade__show {
                opacity: 1;
                transition: opacity .15s ease-in;
            }

            .productDetails{
                padding: 0 16px 0 96px;
            }
        }
    }
}




@media (min-width: 1280px){
    .baseMenu___2sYeE {
        padding-right: 18% !important;
    }

    .grid--compactGutter {
        margin: 0 -.8rem;
    }
    
    .xl-size3of12 {
        width: 25% !important;
    }
}


@media (min-width: 1024px){
    .lg-flex {
        display: flex!important;
    }

    .sideNav___2uqqD {
        //flex-basis: 150px;
        flex-basis: 450px;
    }

    .baseMenu___2sYeE {
        padding-right: 18% !important;
        -webkit-box-flex: 1;
        flex: 1 1 0;
        overflow: auto;
        //padding-left: 127px !important;
        padding-right: 9% !important;
    }

    .lg-pb6 {
        padding-bottom: 4rem!important;
    }

    .lg-pl7, .lg-px7 {
        padding-left: 4.8rem!important;
    }

    .lg-mb5 {
        margin-bottom: 3.2rem!important;
    }

    .thumb {
        max-width: 112px !important;
    }
    
    .lg-pl7, .lg-px7 {
        padding-left: 4.8rem!important;
    }

    .sb-cont{
        padding-top: 82px;

        .globalSubnav___2CFWp {
            position: fixed;
            z-index: 1;
        }

        .contentInMenu{
            padding-top: 44px;  
        }

        .content_isAnonFavorites{
            padding-top: 44px;

            .lg-size6of12 {
                width: 50% !important;
            }
        }

        
        .content_FavoriteList{
            padding-left: 16px !important;
            padding-right: 16px !important;
            max-width: 600px !important;
        }

    }

    .menuWithSideNav-3HCqB {
        padding-left: 123px !important;
    }

    

}


@media (min-width: 768px) {

    .sb-global-subnav-logoAlign {
        margin-left: 50px;
    }

    .md-size6of12 {
        width: 50% !important;
    }

    .menuWithSideNav-3HCqB {
        padding-left: 91px;
    }

    .md-size3of12 {
        width: 25%;
    }


    .sb-cont{

        .globalSubnav___2CFWp {
        
        }

        .contentInMenu{
   
        }

        .content_isAnonFavorites{
        

            .lg-size6of12 {
                width: 75%;
            }
        }

        .content_FavoriteList{
            padding-left: 96px;
            padding-right: 96px;

            .productDetails {
                padding: 0 72px 0 128px !important;
            }

            .wrapper {
                padding: 24px 24px 40px !important; 
            }
            
            .headerContainer{
                -webkit-box-align: center;
                align-items: center;

                .prodImage {
                    height: 104px !important;
                    width: 104px !important;
                }
            }
        }

    }
}

@media (min-width: 480px) {

    .sb-cont{

        .globalSubnav___2CFWp {
        
        }

        .contentInMenu{
   
        }

        .content_isAnonFavorites{
        

            .lg-size6of12 {
                width: 83.33333%;
            }
        }

        .content_FavoriteList{
            .wrapper {
                 padding: 32px;
            }
        }

    }


}

@media (max-width: 768px) {

    .menuWithSideNav-3HCqB {
        .nutrition-cta{
            top: 150px !important;
            zoom: 0.8 !important;
        }
    }
}