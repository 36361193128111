.sb-stores{
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: calc(100% - 72px);

    .sb-contentCrate{
        -webkit-box-flex: 1;
        flex-grow: 1;
        //flex-basis: 0;
        position: relative;
        z-index: 1;
        overflow: hidden;
        word-wrap: normal!important;
        background-color: #fff;
        box-shadow: 0 -2px 4px 0 rgba(0,0,0,.12);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        flex-shrink: 0;

        
        .container--headerCrate{
            flex-shrink: 0;
            position: relative;
            z-index: 2;
            min-height: 50px;
            background-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.12);

            .controls{
                display: -webkit-box;
                display: flex;
                position: relative;
                z-index: 2;
                background-color: #fff;

                .form_search{
                    -webkit-box-flex: 1;
                    flex: 1;
                    height: 55px;
                    display: -webkit-box;
                    display: flex;
                    position: relative;

                    
                    input{
                        border: 0;
                        display: block;
                        height: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        white-space: nowrap;
                        padding: 10px 0 0;
                        border-bottom: 1px solid rgba(0,0,0,.12);
                    }

                    .buttonContainer{
                        display: block;
                        width: 4.8rem;
                        height: 100%;
                        position: absolute;
                        right: 0;

                        button{
                            color: rgba(0,0,0,.56);
                            -webkit-transition: color .2s ease-out;
                            transition: color .2s ease-out;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .filterButton{
                    position: relative;
                    width: auto;
                    color: rgba(0,0,0,.56);
                    font-size: 1.5rem;
                    font-weight: 400;
                    -webkit-transition: color .2s ease-out;
                    transition: color .2s ease-out;
                    font-weight: bold;
                }

                .activeCheck{
                    background-color: #00a862;
                    width: 1.6rem;
                    height: 1.6rem;
                    border-radius: 8rem;
                    top: .8rem;
                    left: 100%;
                }
            }
        }

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .locationCardList{
            -webkit-box-flex: 1;
            flex-grow: 1;
            position: relative;
            background: #fff;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            white-space: normal;

            .locationList{
                padding-top: 10px;

                .favorite-title{
                    font-size: 1.3rem;
                    line-height: 1.229;
                    color: rgba(0,0,0,.56)!important;
                    text-transform: uppercase!important;
                    letter-spacing: 0.1em!important;
                    padding: 0 3.2rem;
                    font-weight: bold;
                }

                .store-list-title{
                    font-size: 1.3rem;
                    line-height: 1.229;
                    color: rgba(0,0,0,.56)!important;
                    text-transform: uppercase!important;
                    letter-spacing: 0.1em!important;
                    padding: 0 3.2rem;
                    font-weight: bold;
                }

                .locationListItem{
                    cursor: pointer;
                    display: -webkit-box;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    flex-direction: column;
                    width: 100%;
                    background-color: #fff;
                    font-size: 1.3rem;
                    pointer-events: all;
                    -webkit-transition-duration: .2s;
                    transition-duration: .2s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                    -webkit-transition-property: background-color;
                    transition-property: background-color;

                    &:hover{
                        background-color: rgba(0,0,0,.15)
                    }

                    .header___3UekJ{
                        line-height: 10px;
                    }
                }

                .isActive {
                    position: relative;
                    background-color: rgba(0,0,0,.1);
                }
            }

            .noStoresCard{
                display: -webkit-box;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;
                width: 100%;
                background-color: #fff;
                font-size: 1.3rem;
                pointer-events: all;
                -webkit-transition-duration: .2s;
                transition-duration: .2s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
                -webkit-transition-property: background-color;
                transition-property: background-color;
            }

            .foot-privacy{
                .color-textBlackSoft {
                    color: rgba(0,0,0,.56)!important;
                }
            }
        }

        .locationDetail{
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            .expandedLocationOverlay{
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                top: 72px;

                .sb-overlay__closeBtnContainer{
                    height: 44px;
                    margin: 1.6rem;

                    .sb-overlay__closeBtn {
                        right: 0;
                    }

                    .sb-iconButton {
                        transition: all .2s ease-out;

                        &:hover{
                            transition: all .2s ease-out;
                            background-color: #f1f1f1;
                            border-radius: 50%;
                        }
                    }
                    
                    .color-textBlackSoft {
                        color: rgba(0,0,0,.56)!important;
                    }
                }

                .expandedLocationCardLabel{

                    .address-detail{

                        .pb1{
                            margin-top: 20px;
                            .sb-heading--medium {
                                font-size: 2.3rem;
                                line-height: 1.455;
                                margin-bottom: 0px;
                                margin-top: 0;
                            }
    
                            .color-textBlackSoft{
                                margin-bottom: 0px;
                                margin-top: 5px;
                            }
                        }

                        .info{
                            display: -webkit-box;
                            display: flex;
                            line-height: 1.6;
                            max-width: 350px;
                            margin-top: 20px;

                            .address{
                                -webkit-box-flex: 0;
                                flex-grow: 0;
                                flex-shrink: 0;

                                p{
                                    margin-top: 0px !important;
                                }
                            }

                            .links{
                                margin-left: 20px !important;
                                -webkit-box-flex: 0;
                                flex-grow: 0;
                                flex-shrink: 1;
                            }
                        }
                        
                    }

                    .time-detail{
                        .sb-heading--xxsmall {
                            font-size: 1.3rem;
                            line-height: 1.229;
                        }

                        .schedule{

                            display: table;
                            width: 100%;
                            max-width: 350px;
                            
                            .scheduleItemHighlight{
                                font-weight: 700;
                                display: table-row;
                            }

                            .scheduleItem{
                                display: table-row;
                                height: 30px;

                                .scheduleDay{
                                    display: table-cell;
                                }

                                .scheduleHours{
                                    text-align: right;
                                    display: table-cell;
                                }

                            }
                        }
                    }

                    .featureList{
                        .sb-heading--xxsmall {
                            font-size: 1.3rem;
                            line-height: 1.229;
                        }

                        .featureItem{
                            margin-top: 16px;
                            vertical-align: top;
                            padding-top: 8px;
                            color: rgba(0,0,0,.87);
                            vertical-align: middle;
                            
                            .icon{
                                margin-right: 6px;
                                position: relative;
                                top: -2px;
                                vertical-align: middle;
                            }

                            &:first-of-type {
                                margin-top: 0;
                            }
                        }
                    }

                }
            }
        }

        .filters-capsul{
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            .expandedLocationOverlay{
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                top: 72px;

                .sb-overlay__closeBtnContainer{
                    height: 44px;
                    margin: 1.6rem;

                    .sb-overlay__closeBtn {
                        right: 0;
                    }

                    .sb-iconButton {
                        transition: all .2s ease-out;
                    }
                    
                    .color-textBlackSoft {
                        color: rgba(0,0,0,.56)!important;
                    }
                }

                .Filters{
                    .title{
                        padding: 0px 40px;
                        font-weight: bold;
                        font-size: 2.7rem;
                        //border-bottom: 1px solid rgba(0,0,0,.2);
                        padding-bottom: 30px;
                        margin-bottom: 0px;
                    }

                    .filter-list{
                        padding-bottom: 9rem!important;
                        .filter-item{

                            .isActive {
                                color: rgba(0,0,0,.87);
                            }

                            button{
                                color: rgba(0,0,0,.56);
                                display: block;
                                font-size: 1.4rem;
                                position: relative;
                                padding-top: 2rem;
                                padding-bottom: 2rem;
                                text-align: left;
                                -webkit-transition: background-color .2s ease-out,color .2s ease-out;
                                transition: background-color .2s ease-out,color .2s ease-out;
                                width: 100%;

                                &:hover{
                                    background-color: rgba(0,0,0,.03);
                                    outline: none;
                                }

                                .icon{
                                    margin-right: 18px;
                                    position: relative;
                                    top: -1px;
                                    left: -2px;
                                }

                                .activeIconWrapper{
                                    right: 12px;
                                }

                                .activeIcon{
                                    opacity: 0;
                                    -webkit-transition: opacity .2s ease-out;
                                    transition: opacity .2s ease-out;
                                    display: block;
                                }

                               
                            }

                            .flex-grow{
                                font-weight: 500;
                            }

                            .showOpac{
                                opacity: 1 !important;
                            }

                        }
                    }
                    
                    .apply-btn-filter{
                        position: fixed;
                        bottom: 0;
                        right: 0;
                        z-index: 10;
                        padding: 1.6rem;
                    }
                }

            }
        }
    }

    .sb-headerCrate{
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        flex-shrink: 0;
        -webkit-box-flex: 0;
        flex-grow: 0;
        padding-top: 0;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.24), 0 4px 4px 0 rgba(0,0,0,.12);
    }
}

@media (min-width: 1280px){
    .sb-stores{

        .sb-contentCrate{
        
    
            .container--headerCrate{
  
    
                .controls{

    
                    .form_search{

    
                        
                        input{

                        }
    
                        .buttonContainer{

    
                            button{
       
                            }
                        }
                    }
    
                    .filterButton{

                    }
                }
            }
    
            .container--headerCrate {
   
            }
    
            .locationCardList{
    
                .locationList{
      
                    .locationListItem{
                    
                        .header___3UekJ{
                      
                        }
                    }
    
                    .isActive {
              
                    }
                }
    
                .noStoresCard{
          
                }
    
                .foot-privacy{
                    .color-textBlackSoft {
                      
                    }
                }
            }
    
            .locationDetail{
             
                .expandedLocationOverlay{
                  
    
                    .sb-overlay__closeBtnContainer{
                        
    
                        .sb-overlay__closeBtn {
                     
                        }
    
                        .sb-iconButton {
                          
                        }
                        
                        .color-textBlackSoft {
                       
                        }
                    }
    
                    .expandedLocationCardLabel{
                        .address-detail{
                           
                                .pb1{
                                    .sb-heading--medium {
                            
                                    }
        
                                    .color-textBlackSoft{
                                
                                    }
                                }
    
                                .info{

                                    -webkit-box-pack: justify;
                                    justify-content: space-between;


                                    .address{
                                  
                                    }
    
                                    .links{
                                      
                                    }
                                }
                            
                        }
    
                        .time-detail{
    
                        }
                    }
                }
            }

        }
    
    }
 }
 
 @media (min-width: 1024px){
    .sb-stores{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        //height: 100% !important;
        height: 100vh !important;
        padding-top: 83px;

        .sb-contentCrate{
            -webkit-box-flex: 0;
            flex-grow: 0;
            flex-basis: 40vw;
            -webkit-box-ordinal-group: 2;
            order: 1;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.12);

            .container--headerCrate{
                margin-bottom: 1.6rem;
                padding-bottom: .8rem;
                box-shadow: none;
                
                .lg-pl5, .lg-px5 {
                    padding-left: 3.2rem!important;
                }
                
                .controls{
                    padding-top: 1.6rem;

                    .form_search{

                    }
                }

            }

             
            .locationCardList{
                
                .locationList{

                    .locationListItem{
                        
                    }

                    .container--headerCrate {
                        
                    }
                }

                .noStoresCard{
         
                }

                .foot-privacy{
                   
                }

                .lg-pl6, .lg-px6 {
                    padding-left: 4rem!important;
                }
                .lg-pb4 {
                    padding-bottom: 2.4rem!important;
                }
            }

            .container--headerCrate {
                padding: 0 3.2rem;
            }   

            .locationDetail{
                .expandedLocationOverlay{
                    right: 60vw !important;
                    top: 83px !important;

                    .sb-overlay__closeBtnContainer{
                        margin-right: 3.2rem;
                        margin-left: 3.2rem;
                        margin-bottom: 1.6rem;
                        margin-bottom: 0px;
                    }

                    .expandedLocationCardLabel{
                     
                    }

                       
                    .lg-pb6 {
                        padding-bottom: 4rem!important;
                    }

                    .lg-pl6, .lg-px6 {
                        padding-left: 4rem!important;
                    }

                    .lg-px6 {
                        padding-right: 4rem!important;
                    }
                }
            }

            .filters-capsul{
                .expandedLocationOverlay{
                    right: 60vw !important;
                    top: 83px !important;

                    .sb-overlay__closeBtnContainer{
                        margin-right: 3.2rem;
                        margin-left: 3.2rem;
                        margin-bottom: 1.6rem;
                        margin-bottom: 0px;
                    }

                    .expandedLocationCardLabel{
                     
                    }

                       
                    .lg-pb6 {
                        padding-bottom: 4rem!important;
                    }

                    .lg-pl6, .lg-px6 {
                        padding-left: 4rem!important;
                    }

                    .lg-px6 {
                        padding-right: 4rem!important;
                    }

                    .apply-btn-filter{
                        right: auto !important;
                        left: 40vw;
                        padding-left: 3.2rem;
                        padding-right: 3.2rem;
                        -webkit-transform: translateX(-100%) !important;
                        transform: translateX(-100%) !important;
                        padding: 3.2rem 4.8rem !important;
                    }
                }
            }
        }

        .sb-headerCrate{
            flex-basis: 60vw;
            -webkit-box-ordinal-group: 3;
            order: 2;
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;
        }

    }
 }
 
 @media (min-width: 768px){
    .sb-stores{
        height: calc(100% - 82px);
    }
 }
 
 @media (min-width: 480px){
  
    .sb-stores{

        .sb-contentCrate{
        
    
            .container--headerCrate{
  
    
                .controls{

    
                    .form_search{

    
                        
                        input{

                        }
    
                        .buttonContainer{

    
                            button{
       
                            }
                        }
                    }
    
                    .filterButton{

                    }
                }
            }
    
            .container--headerCrate {
   
            }
    
            .locationCardList{
    
                .locationList{
      
                    .locationListItem{
                    
                        .header___3UekJ{
                      
                        }
                    }
    
                    .isActive {
              
                    }
                }
    
                .noStoresCard{
          
                }
    
                .foot-privacy{
                    .color-textBlackSoft {
                      
                    }
                }
            }
    
            .locationDetail{
             
                .expandedLocationOverlay{
                  
    
                    .sb-overlay__closeBtnContainer{
                        
    
                        .sb-overlay__closeBtn {
                     
                        }
    
                        .sb-iconButton {
                          
                        }
                        
                        .color-textBlackSoft {
                       
                        }
                    }
    
                    .expandedLocationCardLabel{
                        .address-detail{
                           
                                .pb1{
                                    .sb-heading--medium {
                            
                                    }
        
                                    .color-textBlackSoft{
                                
                                    }
                                }
    
                                .info{

                        

                                    .address{
                                  
                                    }
    
                                    .links{
                                      
                                    }
                                }
                            
                        }
    
                        .time-detail{
    
                        }
                    }
                }
            }

            .filters-capsul{
               
                .expandedLocationOverlay{
                 
    
                    .sb-overlay__closeBtnContainer{
                
    
                        .sb-overlay__closeBtn {
                      
                        }
    
                        .sb-iconButton {
               
                        }
                        
                        .color-textBlackSoft {
                 
                        }
                    }
    
                    .Filters{
                        .title{
                    
                        }
    
                        .filter-list{
                            .filter-item{
                                button{
                                
                                }
                            }
                        }

                        .sm-px6 {
                            padding-right: 4rem!important;
                        }

                        .sm-pl6, .sm-px6 {
                            padding-left: 4rem!important;
                        }
                    }
    
                }
            }

        }
    
    }
  
 }
 
 @media (min-width: 375px){
 
 }
 
 @media (max-width: 768px){
    .sb-stores{

        .sb-contentCrate{
            overflow: unset !important;
            height: 50vh !important;
            .container--headerCrate{
                //display: none !important;
                
                .lg-pl5, .lg-px5 {
                 
                }
                
                .controls{
                    

                    .form_search{

                    }
                }

            }

             
            .locationCardList{
                
                .locationList{

                    .locationListItem{
                        
                    }

                    .container--headerCrate {
                        
                    }
                }

                .noStoresCard{
         
                }

                .foot-privacy{
                   
                }

                .lg-pl6, .lg-px6 {
                  
                }
                .lg-pb4 {
                 
                }
            }

            .container--headerCrate {
                
            }

            .locationDetail{
                .expandedLocationOverlay{
             
                }
            }
        }

        .sb-headerCrate{
          .container___2qSwp{
            .mapCont{
                height: 40vh !important;
            } 
          }
        }

    }
 }




 @media (max-width: 1023px) and (min-width: 480px), (min-width: 1280px){
    .sb-stores{

        .sb-contentCrate{
        
    
            .container--headerCrate{
  
    
                .controls{

    
                    .form_search{

    
                        
                        input{

                        }
    
                        .buttonContainer{

    
                            button{
       
                            }
                        }
                    }
    
                    .filterButton{

                    }
                }
            }
    
            .container--headerCrate {
   
            }
    
            .locationCardList{
    
                .locationList{
      
                    .locationListItem{
                    
                        .header___3UekJ{
                      
                        }
                    }
    
                    .isActive {
              
                    }
                }
    
                .noStoresCard{
          
                }
    
                .foot-privacy{
                    .color-textBlackSoft {
                      
                    }
                }
            }
    
            .locationDetail{
             
                .expandedLocationOverlay{
                  
    
                    .sb-overlay__closeBtnContainer{
                        
    
                        .sb-overlay__closeBtn {
                     
                        }
    
                        .sb-iconButton {
                          
                        }
                        
                        .color-textBlackSoft {
                       
                        }
                    }
    
                    .expandedLocationCardLabel{
                        .address-detail{
                           
                                .pb1{
                                    .sb-heading--medium {
                            
                                    }
        
                                    .color-textBlackSoft{
                                
                                    }
                                }
    
                                .info{

                                    -webkit-box-orient: horizontal !important;
                                    -webkit-box-direction: normal !important;
                                    flex-direction: row !important;
                                    -webkit-box-align: start !important;
                                    align-items: start !important;

                                    .address{
                                  
                                    }
    
                                    .links{
                                      
                                    }
                                }
                            
                        }
    
                        .time-detail{
    
                        }
                    }
                }
            }
        }
    
    }
 }

 @media (max-width: 1023px) and (min-width: 600px), (min-width: 1600px){

    .sb-stores{
        .sb-contentCrate{
    
            .locationDetail{

                .expandedLocationOverlay{

    
                    .sb-overlay__closeBtnContainer{

    
                        .sb-overlay__closeBtn {
        
                        }
    
                        .sb-iconButton {
                 
                        }
                        
                        .color-textBlackSoft {
                     
                        }
                    }
    
                    .expandedLocationCardLabel{
    
                        .featureList{
                            .sb-heading--xxsmall {
                                font-size: 1.3rem;
                                line-height: 1.229;
                            }
    
                            .featureItem{
                                display: inline-block;
                                width: 33%;
                                padding-right: 3%;
                                vertical-align: middle;

                                &:nth-of-type(-n+3) {
                                    margin-top: 0;
                                }
                            }
                        }
    
                    }
                }
            }
    
        }
    
        .sb-headerCrate{

        }
    }

 }

 @media (max-width: 599px) and (min-width: 420px), (max-width: 1599px) and (min-width: 1024px){
    .sb-stores{
        .sb-contentCrate{
    
            .locationDetail{

                .expandedLocationOverlay{

    
                    .sb-overlay__closeBtnContainer{

    
                        .sb-overlay__closeBtn {
        
                        }
    
                        .sb-iconButton {
                 
                        }
                        
                        .color-textBlackSoft {
                     
                        }
                    }
    
                    .expandedLocationCardLabel{
    
                        .featureList{
                            .sb-heading--xxsmall {
                                font-size: 1.3rem;
                                line-height: 1.229;
                            }
    
                            .featureItem{
                                display: inline-block;
                                width: 50%;
                                padding-right: 2%;
                                vertical-align: middle;
                            }
                        }
    
                    }
                }
            }
    
        }
    
        .sb-headerCrate{

        }
    }
 }

 @media (max-width: 479px), (min-width: 1024px){
    .sb-stores{

        .sb-contentCrate{
        
    
            .container--headerCrate{
  
    
                .controls{

    
                    .form_search{

    
                        
                        input{

                        }
    
                        .buttonContainer{

    
                            button{
       
                            }
                        }
                    }
    
                    .filterButton{

                    }
                }
            }
    
            .container--headerCrate {
   
            }
    
            .locationCardList{
    
                .locationList{
      
                    .locationListItem{
                    
                        .header___3UekJ{
                      
                        }
                    }
    
                    .isActive {
              
                    }
                }
    
                .noStoresCard{
          
                }
    
                .foot-privacy{
                    .color-textBlackSoft {
                      
                    }
                }
            }
    
            .locationDetail{
             
                .expandedLocationOverlay{
                  
    
                    .sb-overlay__closeBtnContainer{
                        
    
                        .sb-overlay__closeBtn {
                     
                        }
    
                        .sb-iconButton {
                          
                        }
                        
                        .color-textBlackSoft {
                       
                        }
                    }
    
                    .expandedLocationCardLabel{
                        .address-detail{
                           
                                .pb1{
                                    .sb-heading--medium {
                            
                                    }
        
                                    .color-textBlackSoft{
                                
                                    }
                                }
    
                                .info{

                                    -webkit-box-orient: vertical;
                                    -webkit-box-direction: normal;
                                    flex-direction: column;

                                    .address{
                                  
                                    }
    
                                    .links{
                                      
                                    }
                                }
                            
                        }
    
                        .time-detail{
    
                        }
                    }
                }
            }
        }
    
    }
 }