.sb-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 .5px 0 rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .24);
  overflow: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.sb-card__image {
  display: block;
  max-width: 100%;
  min-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center
}

.sb-card--imageLeft .sb-card__content,
.sb-card--imageRight .sb-card__content {
  overflow: hidden
}

.sb-card--imageSmall.sb-card--imageLeft {
  -ms-flex-direction: row;
  flex-direction: row
}

.sb-card--imageSmall.sb-card--imageRight {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

@media (min-width:480px) {
  .sb-card__imageBlockLeft,
  .sb-card__imageBlockRight {
    width: 50%;
    max-width: 300px
  }
}

.sb-card__imageBlockSmall {
  max-width: 25%;
  min-width: 90px
}

@media (min-width:768px) {
  .sb-card--imageLeft {
    -ms-flex-direction: row;
    flex-direction: row
  }
  .sb-card--imageRight {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }
  .sb-card__imageBlockSmall {
    max-width: 35%
  }
}