@font-face {
  font-family: "Gotham Screensmart Bold";
  src:
    url("https://content.sbuxtr.com/static/fonts/gothamSSbold.eot?#iefix") format("embedded-opentype"),
    url("https://content.sbuxtr.com/static/fonts/gothamSSbold.woff") format("woff"),
    url("https://content.sbuxtr.com/static/fonts/gothamSSbold.ttf") format("truetype"),
    url("https://content.sbuxtr.com/static/fonts/gothamSSbold.svg#Font") format("svg");
}

@font-face {
  font-family: "LanderGrandeBlack";
  src:
    url("https://content.sbuxtr.com/static/fonts/LanderGrandeBlack.ttf") format("truetype"),
}

@font-face {
  font-family: "Gotham Screensmart Book";
  src:
    url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.eot?#iefix") format("embedded-opentype"),
    url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.woff") format("woff"),
    url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.ttf") format("truetype"),
    url("https://content.sbuxtr.com/static/fonts/GothamSSm-Book.svg#GothamSSm-Book") format("svg");
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-BoldItalic.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-Bold.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-Light.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-LightItalic.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-LightItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-Regular.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-Italic.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-SemiBold.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SoDoSans";
  src:
    url("/static/fonts/SoDoSans-SemiBoldItalic.woff2") format("woff2"),
    url("/static/fonts/SoDoSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
