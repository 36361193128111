.sb-rewards {
    position: relative;
    z-index: 1;

    .sb-headerCrate {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .24), 0 4px 4px 0 rgba(0, 0, 0, .12);

        .container--headerCrate {
            display: block;
            width: 100%;
            padding: 0 1.6rem;
        }

        .sb-headerCrate--content {
            margin-top: auto;
            margin-bottom: auto;
            padding-bottom: 2.4rem;
        }
    }

    .sb-contentCrate {

        .egift2-content {
            padding: 20px;

            .top-inform {
                margin-bottom: 40px;

                .title {
                    font-weight: bold;
                    font-size: 24px;
                }

                .text {}
            }

            .top-list {
                .inform {
                    .title {
                        font-weight: bold;
                        font-size: 24px;
                    }

                    .text {
            
                    }
                }

                .top-list-capsul{
                    margin: auto -20px;
                    background-color: white;
                    .list-item {
                        display: inline-block;
                        vertical-align: top;
                        white-space: nowrap;
                        background-color: #fff;
                        padding: 25px;
                        width: 33.33%;
                        opacity: .3;
                        .list-img {
                            width: 140px;
                            
                            //height: 140px;
                            display: inline-block;
                            vertical-align: top;
                            text-align: center;
                            
                            img {
                                max-height: 100%;
                                vertical-align: middle;
                                max-width: 140px;
                            }

                            .title{
                                vertical-align: middle;
                                font-weight: bold;
                                font-size: 16px;
                            }
                        }

                        .list-detail {
                            width: 100%;
                            display: block;
                            white-space: normal;
                            vertical-align: top;

                        }
                    }

                    .active{
                        opacity: 1 !important;
                    }
                    
                }

                .tab-item-content {
                    background-color: hsla(0, 0%, 75.7%, .14901960784313725);
                    padding: 30px;
                    white-space: nowrap;
                    margin: auto -20px;

                    .area-img {
                        vertical-align: middle;
                        display: inline-block;
                        height: 180px;
                        width: 180px;
                        text-align: center;

                        img {
                            max-height: 100%;
                        }
                    }

                    .area-content {
                        width: calc(100% - 180px);
                        vertical-align: middle;
                        display: inline-block;
                        white-space: normal;
                        padding-left: 50px;

                        .area-title {
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }
            }

            .rewards-gift-promo-code{
                .title{
                    display: block;
                    font-weight: bold;
                    font-size: 24px;
                    margin-top: 15px;
                }

                .text{
                    margin-bottom: 5px;
                }

                #star-code{
                    background-color: #fff;
                    border-radius: 8px;
                    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
                    display: block;
                    padding: 12px 16px;
                    position: relative;
                    transition: border .2s linear .2s;
                    border: none;
                    color: rgba(0,0,0,.87);
                    font-size: 1.6rem;
                    width: 300px;
                    max-width: 100%;
                    
                }

                #rewards-action-btn{
                    color: rgba(0, 0, 0, 0.8);
                    border-color: rgba(0, 0, 0, 0.7);

                    &:hover{
                        background-color: rgba(0,0,0,.06);
                    }
                }
            }

            .download-area {
                margin: 20px -20px;
                background-color: hsla(0, 0%, 50.2%, .0784313725490196);
                padding: 30px;

                .title {
                    display: block;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                .area-capsul {
                    white-space: nowrap;

                    .area-img {
                        vertical-align: middle;
                        display: inline-block;
                        height: 180px;
                        width: 180px;
                        text-align: center;

                        img {
                            max-height: 100%;
                        }
                    }

                    .area-content {
                        width: calc(100% - 180px);
                        vertical-align: middle;
                        display: inline-block;
                        white-space: normal;
                        padding-left: 50px;

                        .area-title {
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }

            }

            .tabs {
                margin-top: 40px;

                .title {
                    font-weight: 700;
                    font-size: 24px;
                    display: block;
                }

                .sub-title {
                    font-weight: 600;
                    font-size: 17px;
                    display: block;
                }

                .tab-items {
                    box-shadow: 0 0 .5px 0 rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .24);
                    background: white;
                    border-radius: 2px;
                    padding: 20px;
                    cursor: pointer;
                    margin: auto -20px;

                    .active {
                        opacity: 1 !important;
                    }

                    .tab-item {
                        width: 33.3%;
                        display: inline-block;
                        vertical-align: top;
                        padding: 15px;
                        text-align: center;
                        opacity: 0.3;

                        .tab-img {
                            height: 160px;
                            width: 160px;
                            line-height: 160px;
                            text-align: center;
                            margin: 0 auto;

                            img {
                                max-height: 100%;
                            }
                        }

                        .tab-detail {
                            .tab-text {
                                display: block;
                                margin-top: 20px;
                                font-weight: bold;
                            }
                        }

                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }

                .tab-item-content {
                    background-color: hsla(0, 0%, 75.7%, .14901960784313725);
                    padding: 30px;
                    white-space: nowrap;
                    margin: auto -20px;

                    .area-img {
                        vertical-align: middle;
                        display: inline-block;
                        height: 180px;
                        width: 180px;
                        text-align: center;

                        img {
                            max-height: 100%;
                        }
                    }

                    .area-content {
                        width: calc(100% - 180px);
                        vertical-align: middle;
                        display: inline-block;
                        white-space: normal;
                        padding-left: 50px;

                        .area-title {
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }
            }

            .tips {
                margin-top: 40px;

                .tips-detail {
                    display: block;
                    width: 100%;
                    clear: both;
                    margin-bottom: 20px;

                    .title {
                        display: block;
                        font-weight: 700;
                        font-size: 24px;
                        display: block;
                    }
                }

                .tip {
                    background-color: #fff;
                    border-radius: 2px;
                    box-shadow: 0 0 .5px 0 rgba(0, 0, 0, .14), 0 1px 1px 0 rgba(0, 0, 0, .24);
                    width: calc(50% - 20px);
                    display: inline-block;
                    vertical-align: top;
                    white-space: normal;
                    margin-bottom: 20px;
                    margin-right: 40px;

                    .tip-img {
                        img {
                            width: 100%;
                        }
                    }

                    .tip-detail {
                        padding: 20px;
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            .status-inform {
                margin-top: 40px;

                .left {
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    padding-right: 2px;

                    .cap {

                        .top {
                            background-color: #016440;
                            padding: 10px;
                            color: white;
                            font-weight: bold;
                            text-align: center;
                            font-size: 22px;
                            margin-bottom: 15px;
                            position: relative;

                            &::before{
                                content: "";
                                display: inline-block;
                                width: 0;
                                height: 0;
                                border-color: #036340 transparent transparent;
                                border-style: solid;
                                border-width: 10px 10px 0;
                                position: absolute;
                                bottom: -10px;
                                left: calc(50% - 10px);
                            }
                        }

                        .mid {
                            background-color: #016440;
                            padding: 15px;
                            min-height: 365px;

                            .star-item {
                                display: block;
                                color: white;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .bottom-text {}
                }

                .right {
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 2px;

                    .cap {
                        .top {
                            background-color: #927e4c;
                            padding: 10px;
                            color: white;
                            font-weight: bold;
                            text-align: center;
                            font-size: 22px;
                            margin-bottom: 15px;
                            position: relative;

                            &::before{
                                content: "";
                                display: inline-block;
                                width: 0;
                                height: 0;
                                border-color: #927e4c transparent transparent;
                                border-style: solid;
                                border-width: 10px 10px 0;
                                position: absolute;
                                bottom: -10px;
                                left: calc(50% - 10px);
                            }
                        }

                        .mid {
                            background-color: #927e4c;
                            padding: 15px;
                            min-height: 365px;

                            .star-item {
                                display: block;
                                color: white;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .bottom-text {}
                }

                /* ===== Scrollbar CSS ===== */
                /* Firefox */
                * {
                    scrollbar-width: auto;
                    scrollbar-color: #303030 #ffffff;
                }

                /* Chrome, Edge, and Safari */
                *::-webkit-scrollbar {
                    width: 12px;
                }

                *::-webkit-scrollbar-track {
                    background: #ffffff;
                }

                *::-webkit-scrollbar-thumb {
                    background-color: #303030;
                    border-radius: 10px;
                    border: 3px solid #ffffff;
                }
            }

            .egift2-foot {
                margin-top: 40px;

                .foot-title {
                    font-weight: bold;
                }
            }
        }

        .sb-contentColumn {
            padding: 2.4rem 1.6rem 6.4rem;

            .sb-button--default.sb-button--black,
            .sb-button--default.sb-button--rewardsDefault {
                color: rgba(0, 0, 0, .87);
                border-color: rgba(0, 0, 0, .87);

                &:hover {
                    background-color: rgba(0, 0, 0, .06);
                }
            }

            .sb-button--positive.sb-button--black,
            .sb-button--positive.sb-button--rewardsDefault {
                background-color: #000;
                border-color: #000;

                &:hover {
                    background-color: rgba(0, 0, 0, .7);
                    border-color: rgba(0, 0, 0, .7);
                }
            }
        }


    }
}


@media (min-width: 1280px) {
    .sb-rewards {

        .sb-headerCrate {
            .sb-headerCrate--content {
                padding-left: 11.2rem !important;
                padding-right: 11.2rem !important;
            }
        }

        .sb-contentCrate {}
    }
}

@media (min-width: 1024px) {
    .sb-rewards {
        padding-top: 83px;

        .sb-headerCrate {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            width: 40vw;

            .container--headerCrate {
                padding: 0 3.2rem;
            }

        }

        .sb-contentCrate {
            margin-left: 40vw;

            .sb-contentColumn {
                //padding-top: 100px;
                padding-top: 60px;
                padding-bottom: 100px;
                max-width: 670px !important;

                .content___LdxU3 {
                    img {
                        display: inline-block;
                        margin-right: 50px;
                        vertical-align: middle;
                    }

                    .py33 {
                        display: inline-block;
                        width: 310px;
                        vertical-align: middle;
                        margin-top: 0px;
                        padding: 0;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .sb-rewards {

        .sb-headerCrate {}

        .sb-contentCrate {}

    }
}

@media (min-width: 480px) {
    .sb-rewards {

        .sb-headerCrate {}

        .sb-contentCrate {}

    }
}

@media (min-width: 375px) {
    .sb-rewards {

        .sb-headerCrate {}

        .sb-contentCrate {
            .sb-contentColumn--narrow {
                max-width: 95%;
                padding-left: 0;
                padding-right: 0;
            }

            .nutrition-box {
                .head {
                    padding: 20px 20px !important;
                }

                .nutrition-form {
                    padding: 20px 20px !important;
                }
            }

        }
    }
}

@media (max-width: 768px) {
    .sb-rewards {

        .sb-headerCrate {}

        .sb-contentCrate {
           .egift2-content {

            .top-list{
                .top-list-capsul{
                    .list-item{
                        //width: 100% !important;
                        .list-img{
                            display: block !important;
                            margin: 0 auto !important;
                            width: auto !important;
                            height: auto !important;
    
                            img{
                                max-width: 100px !important;
                            }
    
                            .title{
                                display: block;
                                font-size: 12px !important;
                            }
                        }
    
                        .list-detail{
                            display: block !important;
                            width: 100% !important;
                            padding-left: 0 !important;
                        }
                    }
                }

                .tab-item-content{
                    .area-img{
                        display: block !important;
                        margin: 0 auto !important;
                        width: auto !important;
                        height: auto !important;

                        img{
                            width: 180px !important;
                        }
                    }

                    .area-content{
                        display: block !important;
                        width: 100% !important;
                        padding-left: 0 !important;
                    }
                }
            }

            .download-area{
                .area-capsul{
                    .area-img{
                        display: block !important;
                        margin: 0 auto !important;
                    }

                    .area-content{
                        width: 100% !important;
                        display: block !important;
                        padding-left: 0 !important;
                    }
                }
               
            }

            .status-inform {
                .left{
                    width: 100% !important;
                    padding: 0 !important;
                }
                .right{
                    width: 100% !important;
                    padding: 0 !important;
                }
            }

            .tabs{
                .tab-items{
                    .tab-item{
                        //width: 100% !important;
                        .tab-img{
                            display: block !important;
                            margin: 0 auto !important;
                            width: auto !important;
                            height: auto !important;
                            line-height: 0 !important; 
                            img{
                                max-width: 100px !important;
                            }
    
                            .title{
                                display: block;
                                font-size: 12px !important;
                            }
                        }
    
                        .tab-detail{
                            display: block !important;
                            width: 100% !important;
                            padding-left: 0 !important;
                        }
                    }
                }

                .tab-item-content{
                    .area-img{
                        display: block !important;
                        margin: 0 auto !important;
                        width: auto !important;
                        height: auto !important;

                        img{
                            width: 180px !important;
                        }
                    }

                    .area-content{
                        display: block !important;
                        width: 100% !important;
                        padding-left: 0 !important;
                    }
                }
            }

            .tips{
                .tips-capsul{
                    .tip{
                        width: 100% !important;
                        margin-right: 0 !important;
                    }
                }
            }
           }
        }

    }
}